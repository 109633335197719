import React, { useRef, useState } from 'react';
import LinearProgressCountUp from '../../../../components/LinearProgressBar';
import TaskTabsModal from '../../../../components/Modal/TaskTabsModal';
import { TaskIcon } from '../../../../components/Icon';
import colors from '../../../../assets/theme/light/colors';
import Tooltip from '../../../../components/Tooltip';
import { useTranslation } from 'react-i18next';
import { formatDateTimeToString } from '../../../../UTC2Local';
import IconBadge from '../../../../components/StatusCell/IconBadge';




const RowsSubrows = ({ row, column, rowGuid, handleRefetchTree, data, updateData, getTotalFilters }) => {
  const [openModal, setOpenModal] = useState(false);
  const [projectID, setProjectID] = useState(null);
  const [taskID, setTaskID] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const type = useRef("task");

  const { t } = useTranslation();
  const ID = row?.task?.id;
  const mainTaskTitle = row?.task?.title;
  const mainTaskAssignedTo = row?.task?.assignedTo;
  const area = row?.task?.area?.description;
  const parentTask = row?.task?.parentName;
  const phase = row?.task?.ticketName;
  const project = row?.task?.projectName;
  const priority = row?.task?.priority;
  const progress = row?.task?.percentageComplete;
  const Status = row?.task?.status;
  const Billable = row?.task?.billable;
  const EstimatedStartDate = formatDateTimeToString(row?.task?.estimatedStartDate, false);
  const EstimatedEndDate = formatDateTimeToString(row?.task?.estimatedEndDate, false);
  const EstimatedTime = row?.task?.estimatedTime;
  const ActualStartDate = formatDateTimeToString(row?.task?.actualStartDate, false);
  const ActualEndDate = formatDateTimeToString(row?.task?.actualEndDate, false);
  const ActualTime = row?.task?.actualTime;
  const CreatedBy = row?.task?.createdBy
    ? `${row.task.createdBy.firstName || ''} ${row.task.createdBy.lastName || ''}`.trim()
    : null;
  const ModifiedBy = row?.task?.modifiedBy
    ? `${row.task.modifiedBy.firstName || ''} ${row.task.modifiedBy.lastName || ''}`.trim()
    : null;
  const CreatedAt = formatDateTimeToString(row?.task?.createdAt, false);
  const ModifiedAt = formatDateTimeToString(row?.task?.modifiedAt, false);

  const level = row?.task?.level ?? 0;
  const task = row?.task?.parentTaskGuid;


  const titleStyle = {
    display: "flex",
    cursor: 'pointer',
    color: isHovered ? '#1086ef' : null,
    alignItems:"center",
    textDecoration: isHovered ? 'underline' : 'none',
    marginLeft: `${level * 20}px`,
  };

  const handleTitleClick = () => {
    setTaskID(row?.task?.guid);
    setProjectID(row?.task?.projectId);
    setOpenModal(true);
  };

  let cellValue;
  if (column.id === 'Title') {
    cellValue = (
      <span
        onClick={handleTitleClick}
        style={titleStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span style={{ marginRight: "3px", marginTop:"2px" }}>
          {task == null && <Tooltip title={t("backlog.tooltip.task")}> <TaskIcon /></Tooltip>}
          {task != null && <Tooltip title={t("backlog.tooltip.subtask")}>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-forward" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke={colors.light_logo_blue} fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 11l4 4l-4 4m4 -4h-11a4 4 0 0 1 0 -8h1" />
            </svg></Tooltip>}
        </span>

        <Tooltip title={mainTaskTitle}>{mainTaskTitle}</Tooltip>
      </span>
    );
  } else if (column.id === 'ID') {
    cellValue = ID;
  } else if (column.id === 'Employee') {
    cellValue = mainTaskAssignedTo;
  } else if (column.id === 'Area') {
    cellValue = area;
  } else if (column.id === 'Parent') {
    cellValue = parentTask;
  } else if (column.id === 'Ticket') {
    cellValue = phase;
  } else if (column.id === 'Project') {
    cellValue = <Tooltip title={project} >{project}</Tooltip>;
  }
  //  else if (column.id === 'Priority') {
  //   cellValue = <FlagBadge priority={priority} tree={true} />;
  // }
   else if (column.id === 'Progress') {
    cellValue = <LinearProgressCountUp progress={progress} />;
  } else if (column.id === 'EstimatedStartDate') {
    cellValue = EstimatedStartDate;
  } else if (column.id === 'EstimatedEndDate') {
    cellValue = EstimatedEndDate;
  } else if (column.id === 'EstimatedTime') {
    cellValue = parseFloat(EstimatedTime).toFixed(2);
  } else if (column.id === 'ActualStartDate') {
    cellValue = ActualStartDate;
  } else if (column.id === 'ActualEndDate') {
    cellValue = ActualEndDate;
  } else if (column.id === 'ActualTime') {
    cellValue = parseFloat(ActualTime).toFixed(2);
  } else if (column.id === 'CreatedBy') {
    cellValue = CreatedBy;
  } else if (column.id === 'CreationDate') {
    cellValue = CreatedAt;
  } else if (column.id === 'ModifiedBy') {
    cellValue = ModifiedBy;
  } else if (column.id === 'ModificationDate') {
    cellValue = ModifiedAt;
  } else if (column.id === 'Billable') {
    cellValue = <IconBadge success={Billable} />;
  } 
  
   else {
    cellValue = null;
  }

  return (
    <React.Fragment>
      {cellValue}
      {openModal && (
        <TaskTabsModal
          showModal={openModal}
          setShowModal={(v) => setOpenModal(v)}
          loading={false}
          projectID={projectID}
          projectName={project}
          taskID={taskID}
          handleChangeTask={(v) => setTaskID(v)}
          handleChangeProject={(v) => setProjectID(v)}
          taskName={mainTaskTitle}
          refetch={handleRefetchTree}
          fromProject={true}
          open={JSON.parse(sessionStorage.getItem('unsavedChanges'))}
          modifyFormFlag={() =>
            sessionStorage.setItem('unsavedChanges', JSON.stringify(false))
          }
          setType={(v) => type.current=v}
          type={type.current}
          data={data}
          updateData={updateData}
          getTotalFilters={getTotalFilters}
          addNewSubtask={true}
        />
      )}
    </React.Fragment>
  );
};

export default RowsSubrows;
