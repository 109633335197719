import { gql } from "@apollo/client";

export const GET_ALL_EMPLOYEES = gql`
  query ($model: GetCompanyUsersRequestInput!) {
    GetCompanyUsersList(model: $model) {
      usersList {
        employees {
          employeeGuid
          firstName
          lastName
          email
          profilePictureURL
          resourceType
          phoneNumber
          
          role {
            name
            isAdmin
          }
          tenant{
            name
            tenantGuid
          }
          manager {
            employeeGuid
            firstName
            lastName
          }
          creationDate
          position
          isManager
          employmentType {
            employmentTypeGuid
            name
          }
          employeeStatus {
            mnemonic
            description
            employeeStatusGuid
          }
          workShift {
            workShiftGuid
            description
          }
          isOffice365User
          isAutoClockIn
          attendance
          location {
            locationGuid
            locationID
            name
          }
          employmentDate
          defaultWorkCompany {
            companyGuid
            name
          }
          timezone{
            value
            description
          }
         workType {

          workTypeGuid

          description

        }
 
          employmentStatus {

          employementStatusGuid

          description

          mnemonic

        }
        officialHoliday {
          officialHolidayGuid
          description
        }
 
          timeEntry
          contractor {
            contractorGuid
            name
          }
          department {
            departmentGuid
            name
          }
          isManager
          officialHolidayGuid
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_EMPLOYEES_DROPDOWN = gql`
  query ($model: GetCompanyUsersRequestInput!) {
    GetCompanyUsersList(model: $model) {
      usersList {
        employees {
          employeeGuid
          firstName
          lastName
          tenantGuid
          tenant{
            tenantGuid
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_EMPLOYEE_BY_ID = gql`
  query ($model: GetUserByIdInput!) {
    GetUserByID(model: $model) {
      userView {
        user {
          employeePTOs{
            employeePTOGuid
          }
          preferEmail
          preferSMS
          employeeGuid
          firstName
          lastName
          email
          phoneNumber
          timezone {
            timezoneGuid
            value
            description
          }
          resignationDate
          inactiveDate
          position
          isManager
          officialHoliday  {
            officialHolidayGuid
            description
          }
          profilePictureURL
          role {
            roleGuid
            name
            isAppUser
            isAdmin
          }
          contractor {
            contractorGuid
            name
          }
          resourceType
          employmentDate
          employmentType {
            employmentTypeGuid
            name
          }
          employmentStatus {
            employementStatusGuid
            description
            mnemonic
          }
          probation
          employeeStatus {
            mnemonic
            description
            employeeStatusGuid
          }
          manager {
            employeeGuid
            firstName
            lastName
          }
          location {
            locationGuid
            locationID
            name
          }
          workShift {
            workShiftGuid
            description
          }
          workType {
            workTypeGuid
            description
          }
          isOffice365User
          isAutoClockIn
          attendance
          timeEntry
          department {
            departmentGuid
            name
          }
          defaultWorkCompany {
            companyGuid
            name
          }
          canCreateKiosk
          creationDate
          createdByUser {
            email
          }
          modificationDate
          modifiedByUser {
            email
          }
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_ALL_EMPLOYEE_PROFILE_PICTURE = gql`
  query ($model: GetUserByIdInput!) {
    GetUserByID(model: $model) {
      userView {
        user {
          profilePictureURL
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_PROFILE= gql`
  mutation ($model: UpdateUserProfileRequestModelInput!) {
    UpdateUserProfile(model: $model) {
      user {
          employeeGuid
          firstName
          lastName
          phoneNumber
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation ($model: EditUserModelInput!) {
    EditUser(model: $model) {
      userView {
        user {
          employeeGuid
        }
        linkedUsers{
          employeeGuid
           firstName
           lastName
           email
       }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_EMPLOYEE = gql`
  mutation ($model: CreateUserModelInput!) {
    CreateUser(model: $model) {
      userView {
        user {
          employeeGuid
        }
        
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CREATE_APP_USER = gql`
  mutation ($model: CreateAppUserModelInput!) {
    CreateAppUser(model: $model) {
      userView {
        user {
          employeeGuid
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const DELETE_EMPLOYEES = gql`
  mutation ($model: DeleteUsersRequestInput!) {
    DeleteUsers(model: $model) {
      userDeviceResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CHANGE_EMPLOYEE_STATUS = gql`
  mutation ($model: ChangeUserStatusRequestModelInput!) {
    ChangeUserStatus(model: $model) {
      employeeStatus {
        employeeStatusGuid
        description
        mnemonic
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const RESEND_ACTIVATION_EMAIL = gql`
  mutation ($model: ResetPasswordForNewUserRequestInput!) {
    ResetPasswordForNewUser(model: $model) {
      forgotPasswordSent
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const CHECK_AVAILABILITY = gql`
query ($model: GetEmployeesAvailabilityRequestInput!) {
  GetEmployeesAvailability(model: $model) {
    employeesAvailabilityResponse {
      id
      title
      elements {
        id
        title
        start
        end
      }
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const UNLOCK_USER=gql`
query($employeeGuid:UUID!){
  UnlockUser (employeeGuid: $employeeGuid){
    unlockUserResponse
    errorCode
    errorMessage
    errorVariables
  }
}
`

