import React, { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import Tooltip from "../../../../../components/Tooltip";

//components
import {
  Grid,
  Box,
  TextField,
  MenuItem,
  Button,
  Menu,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Tab,
  FormLabel,
  Popover,
  Divider,
  Backdrop,
  CircularProgress,
  IconButton,
  Badge,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MiniTitle, Title } from "../../../../../components/Titles/FormTitles";
import AutoComplete from "../../../../../components/AutoComplete";
import FormModal from "../../../../../components/Modal/FormModal";

import { day_month_year } from "../../../../../utils/timezones";

import urls from "../../../../../urls";

//Date and Time
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

//translation
import { useTranslation } from "react-i18next";

//quill
import ReactQuill, { Quill } from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";

//hooks
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../../hooks/useMutationAuth";
import useAuth from "../../../../../hooks/useAuth";

//graphql
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../../graphql/employee";
import { GET_ALL_TASK_STATUSES } from "../../../../../graphql/taskStatus";
import { GET_ALL_TICKETS_DROPDOWN } from "../../../../../graphql/ticket";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../../graphql/project";
import { GET_ALL_PARENT_TASKS } from "../../../../../graphql/task";
import { GET_ALL_AREAS } from "../../../../../graphql/areas";
import { GET_ALL_TEAMS } from "../../../../../graphql/team";
import { LOGOUT_USER } from "../../../../../graphql/user";
import Mnemonics from "../../../../../Mnemonics.json";
import "./style.css";
import colors from "../../../../../assets/theme/light/colors";

import AreasList from "../../../Areas";
import componentDisplay from "../../../../../componentDisplay";
import TimeEntriesList from "../../../TimeEntries/TimeEntries-list";
import {
  AddTimeIcon,
  AlertIcon,
  ExternalReferenceIcon,
  getOptionPriorityIcon,
  ProjectIcon,
  SubtaskIcon,
  BillableIcon,
  TaskIcon,
} from "../../../../../components/Icon";
import { useSnackbar } from "notistack";
import axios from "axios";
import ReactParseHtml from "../../../../../components/Text/ReactParseHtml";
import { parseJwt } from "../../../../../utils/parseJwt";
import Attachment from "./Attachments";
import Comment from "./Comments";
import apiCall from "../../../../../hooks/useRest";
import { saveToken } from "../../../../../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";
import { compareStrings } from "../../../../../utils/functions";

import { v4 as uuidv4 } from "uuid";

import { Mention, MentionBlot } from "quill-mention";
import "quill-mention/autoregister";
import { randomNumberBetween } from "@mui/x-data-grid/utils/utils";
import { formatDateTimeToString } from "../../../../../UTC2Local";

var Parchment = Quill.import("parchment");
var Image = Quill.import("formats/image");

Image.className = "custom-image-size";
Quill.register(Image, true);

Quill.register({ "blots/mention": MentionBlot, "modules/mention": Mention });

const backendURL = process.env.REACT_APP_REST_URL;

const TaskMain = ({
  formData,
  fromTenant,
  fromTree,
  taskId,
  selectedProject,
  modal,
  taskGuid,
  subtask,
  parentTask,
  type,
  modifyFormFlag,
  handleChangeProject,
  ticket_status_list,
  // tickets_list,
  // ticketLoading,
  selectedTask,
  refetchFct,
  fetchTickets,
  anyChange,
  handleFormChanged,
  disabledFields,
  setMediaList,
  mediaList,
  handleAddSubtask,
  handleFetchTree,
  refetchTask,
  updateTaskQuick,
  attachments,
  handleSubmit,
  selectedArea,
  selectedExternalReference,
  showModal,
  quickAdd,
  taskGuidRef,
  setTaskStatuses,
  taskStatuses,
  employees_list,
  usersLoading,
}) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const { pID, pName } = useParams();

  const quillRef = React.useRef();

  const { taskStateColors } = colors;

  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];

  const {
    my_projects_form,
    my_employee_form,
    my_teams_employees,
    my_project_tickets_form,
  } = urls;

  const {
    logout,
    jwtToken,
    storeUserTenant,
    storeUserTenantName,
    userRoles,
    projectPermissions,
    storeUserGuid,
    isEmployee,
  } = useAuth();

  const dispatch = useDispatch();

  const { manageSystemConfigurations, manageTimeEntry, manageUsers, isAdmin } =
    userRoles;

  const [projectGuid, setProjectGuid] = React.useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [anchorElExternalReference, setAnchorElExternalReference] =
    useState(null);
  const [openAreaModal, setOpenAreaModal] = useState(false);
  const [areaClicked, setAreaClicked] = useState(false);
  const [parentClicked, setParentClicked] = useState(false);
  const [projectClicked, setProjectClicked] = useState(false);
  const [employeeClicked, setEmployeeClicked] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  const [ticketClicked, setTicketClicked] = useState(false);
  const [teamClicked, setTeamClicked] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);

  // const [mappedEmployees, setMappedEmployees] = useState(null);

  const [content, setContent] = useState("");
  const [value, setValue] = useState("1");
  const imageUrl = useRef("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //   if (selectedProject != null) setProjectGuid(selectedProject.projectGuid);
  // }, [selectedProject]);

  useEffect(() => {
    setFieldValue(area.name, selectedArea);
  }, [selectedArea]);

  useEffect(() => {
    setFieldValue(externalReference.name, selectedExternalReference);
  }, [selectedExternalReference]);

  // const {
  //   data: statusesList,
  //   tokenChange: statusWait,
  // } = useQueryAuth(GET_ALL_TASK_STATUSES, "GetTaskStatuses", {
  //   variables: {
  //     model: {
  //       tenantID: fromTenant ? (tenantID ? tenantID : null) : storeUserTenant,
  //       projectID: pID,
  //     },
  //   },
  //   skip: !taskGuidRef || wait,
  //   isWait: taskGuidRef
  // });

  // const ticketStatusList =
  //   statusesList &&
  //   statusesList.GetTaskStatuses &&
  //   statusesList.GetTaskStatuses.tasksStatus
  //     ? statusesList.GetTaskStatuses.tasksStatus
  //     : [];

  // useEffect(() => {
  //   if(ticketStatusList?.length > 0) setTaskStatuses(ticketStatusList)
  // }, [ticketStatusList?.length])

  // useEffect(() => {
  //   if(handleChangeProject !== undefined) handleChangeProject(projectGuid)
  // }, [projectGuid])

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    createMode,
    readOnly,
    wait,
    fromDashboard,
    fromProject,
    tenantID,
    handleChangeFlag,
    resetFields,
  } = formData;

  const {
    title,
    description,
    employee,
    team,
    task,
    status,
    ticket,
    project,
    priority,
    EstimatedStartDate,
    EstimatedEndDate,
    EstimatedTime,
    ActualStartDate,
    ActualEndDate,
    externalReference,
    ActualTime,
    ActualBillableTime,
    ActualNonBillableTime,
    synced,
    area,
    allowCreateTimeEntry,
    billable,
  } = formField;

  const {
    title: titleV,
    description: descriptionV,
    employee: employeeV,
    team: teamV,
    task: taskV,
    externalReference: externalReferenceV,
    ticket: ticketV,
    synced: syncedV,
    project: projectV,
    EstimatedStartDate: EstimatedStartDateV,
    EstimatedEndDate: EstimatedEndDateV,
    EstimatedTime: EstimatedTimeV,
    ActualStartDate: ActualStartDateV,
    ActualEndDate: ActualEndDateV,
    ActualTime: ActualTimeV,
    ActualBillableTime: ActualBillableTimeV,
    ActualNonBillableTime: ActualNonBillableTimeV,
    priority: priorityV,
    area: areaV,
    allowCreateTimeEntry: allowCreateTimeEntryV,
    billable: billableV,
  } = values;

  const projectDisabled =
    // !createMode ||
    // selectedProject ||
    isEmployee === Mnemonics.ResourceTypes.External || readOnly;
    
  useEffect(() => {
    if (createMode && !taskGuid) {
      setFieldValue(title.name, "");
      setFieldValue(EstimatedStartDate.name, null);
      setFieldValue(EstimatedEndDate.name, null);
      setFieldValue(EstimatedTime.name, Number(0).toFixed(2));
      setFieldValue(ActualStartDate.name, null);
      setFieldValue(ActualEndDate.name, null);
      setFieldValue(ActualTime.name, null);
      setFieldValue(ActualBillableTime.name, null);
      setFieldValue(ActualNonBillableTime.name, null);
      setFieldValue(description.name, "");
      setFieldValue(employee.name, null);
      if (parentTask?.area === null || parentTask?.area === undefined) {
        setFieldValue(area.name, null);
      }
      if (
        parentTask?.externalReference === null ||
        parentTask?.externalReference === undefined
      ) {
        setFieldValue(externalReference.name, "");
      }
      if (
        parentTask?.ticketGuid === null ||
        parentTask?.ticketGuid === undefined
      ) {
        setFieldValue(project.name, null);
        // setFieldValue(ticket.name, null);
      }
      setFieldValue(synced.name, false);
      setFieldValue(task.name, null);
    }
  }, [createMode]);

  useEffect(() => {
    if (teamV != null) {
      setFieldValue(employee.name, null);
    }
  }, [teamV]);

  useEffect(() => {
    if (resetFields === true && createMode === true) {
      setFieldValue(title.name, "");
      setFieldValue(description.name, "");
      setFieldValue(team.name, null);
      setFieldValue(employee.name, null);
    }
  }, [resetFields]);

  useEffect(() => {
    // const allImgs = document.querySelectorAll(".custom-image-size");
    // if(allImgs && allImgs.length > 0) {
    //   allImgs.forEach(img => {
    //     img.addEventListener("click", () => {
    //       // window.open(img.src)
    //       const image_window = window.open("", "_blank")
    //       image_window?.document.write(`
    //             <html>
    //               <head>
    //               </head>
    //               <body>
    //                 <img src=${img.src} alt="image" >
    //               </body>
    //             </html>
    //       `);
    //     })
    //   })

    // }
    const allImgs = document.querySelectorAll(".custom-image-size");

    const handleClick = (event) => {
      const img = event.target;
      const imageWindow = window.open("", "_blank");
      imageWindow?.document.write(`
        <html>
          <head></head>
          <body>
            <img src="${img.src}" alt="image">
          </body>
        </html>
      `);
    };

    if (allImgs && allImgs.length > 0) {
      allImgs.forEach((img) => {
        img.addEventListener("click", handleClick);
      });
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (allImgs && allImgs.length > 0) {
        allImgs.forEach((img) => {
          img.removeEventListener("click", handleClick);
        });
      }
    };
  }, []);
  
  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");

  const updateToken = (updatedToken) => {
    if (updatedToken) dispatch(saveToken(updatedToken));
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return (...args) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const removeImg = () => {
    const images = document.querySelectorAll("img");
    // Loop through each image element
    images.forEach((img) => {
      // Check if the src attribute contains "data:image/png;base64"
      if (img.src.startsWith("data:image/")) {
        // Remove the image element from the DOM
        img.remove();
      }
    });
  };

  async function uploadImage(formData, newImage) {
    setLoadingImage(true);
    const quill = quillRef.current;
    let range =
      quill.getEditorSelection() || quill?.editor?.selection?.savedRange;

    const res = await apiCall(
      backendURL + `/Task/UploadTaskImage`,
      "POST",
      formData,
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t
    );

    if (res) {
      let tempMediaList = mediaList;
      tempMediaList.push(res.data.fileName);
      setMediaList(tempMediaList);
      imageUrl.current = res.data.url;
      if (newImage === true) {
        removeImg();
        quill
          .getEditor()
          .insertEmbed(range.index, "image", `${imageUrl.current}`);
      }
      setLoadingImage(false);
    } else {
      setLoadingImage(false);
      removeImg();
    }

    return imageUrl.current;
  }
  
  function imageHandler() {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      // var file = input && input.files ? input.files[0] : null;
      var file = input.files[0];
      var imageType = /image.*/;

      if (file.type.match(imageType)) {
        var formData = new FormData();
        formData.append("image", file, file.name);
        formData.append("tenantID", storeUserTenant);
        if (taskGuid) formData.append("itemID", taskGuid);
      }
      uploadImage(formData, true);
    };
  }
  // const {
  //   data: usersList,
  //   loading: usersLoading,
  //   tokenChange: userWait,
  // } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
  //   variables: {
  //     model: {
  //       enableFilters: false,
  //       tenantID: fromTenant ? (tenantID ? tenantID : null) : storeUserTenant,
  //       userID: storeUserGuid,
  //       timeEntryDisabled: true,
  //       showAll: true,
  //     },
  //   },
  //   // skip: !employeeClicked,
  //   // isWait: employeeClicked,
  // });

  // const employees_list =
  //   usersList &&
  //   usersList.GetCompanyUsersList &&
  //   usersList.GetCompanyUsersList.usersList &&
  //   usersList.GetCompanyUsersList.usersList.employees
  //     ? usersList.GetCompanyUsersList.usersList.employees
  //     : [];

  // useEffect(() => {
  //   if(employees_list && Array.isArray(employees_list)) setMappedEmployees(employees_list?.map(employee => {
  //     return {
  //       id: employee.employeeGuid,
  //       value: employee.firstName + ' ' + employee.lastName
  //     };
  //   }));
  // }, [employees_list])

  // function suggestPeople(searchTerm) {
  //   let mappedEmployees = employees_list.map(employee => {
  //     return {
  //       id: employee.employeeGuid,
  //       value: employee.firstName + ' ' + employee.lastName
  //     };
  //   });
  //   // // console.log(mappedEmployees);
  //   return mappedEmployees.filter(person => person.value.includes(searchTerm));
  // }

  const modules = React.useMemo(
    () => ({
      toolbar: {
        handlers: {
          image: imageHandler,
        },
        container: [
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline"],
          [{ color: [] }, { background: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [({ direction: "" }, { direction: "rtl" })],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          // ["hight"],
          ["clean"],
          ["image"],
          ["link"],
        ],

        clipboard: {
          matchVisual: false,
        },
        imageResize: {
          parchment: Quill.import("parchment"),
          modules: ["Resize", "DisplaySize"],
        },
      },
      mention: {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ["@"],
        source: function (searchTerm, renderList) {
          const mappedEmployees = employees_list?.map((employee) => {
            return {
              id: employee.employeeGuid,
              value: employee.firstName + " " + employee.lastName,
            };
          });

          const matchedPeople = mappedEmployees?.filter((person) =>
            person.value.includes(searchTerm)
          );
          if (searchTerm.length === 0) {
            renderList(matchedPeople);
          } else {
            const matches = [];
            for (let i = 0; i < matchedPeople.length; i++) {
              if (
                ~matchedPeople[i].value
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase())
              ) {
                matches.push(matchedPeople[i]);
              }
            }
            renderList(matches, searchTerm);
          }
        },
        onSelect: (item, insertItem) => {
          insertItem(item);
        },
      },
    }),
    []
  );

  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
    "align",
    "background",
    "image",
    "color",
    "background-color",
    "link",
    "mention",
  ];

  useEffect(() => {
    if (createMode === true) setFieldValue(EstimatedStartDate.name, new Date());
  }, [createMode]);
  // useEffect(() => {
  //   if (projectGuid === null && projectV === null && !pID)
  //     setFieldValue(ticket.name, null);
  // }, [projectGuid, pID]);
  useEffect(() => {
    if (
      selectedProject !== null &&
      selectedProject !== undefined &&
      createMode
      // createMode &&
      // !quickAdd
    )
      setFieldValue(project.name, selectedProject);
  }, [selectedProject]);
  useEffect(() => {
    if (pID != null && pName != null && createMode && !quickAdd)
      setFieldValue(project.name, { projectGuid: pID, title: pName });
  }, [pID]);
  useEffect(() => {
    if (!priorityV && createMode === true)
      setFieldValue(priority.name, "Medium");
  }, [priorityV]);

  const handleQuillChange = (content, delta, source, editor) => {
    // setContent(editor.getText(content));
    if (touched[description.name] && !compareStrings(descriptionV, content))
      handleChangeFlag(touched[description.name]);
    setFieldValue(description.name, content);
  };

  const {
    data: areasList,
    loading: areaLoading,
    tokenChange: areasWait,
    refetch: refetchAreas,
  } = useQueryAuth(GET_ALL_AREAS, "GetAreasList", {
    variables: {
      model: {
        enableFilters: false,
        projectGuid: pID || projectV?.projectGuid || selectedProject?.projectGuid,
      },
    },
    skip: wait || (!areaClicked && !selectedProject) || (areaClicked && !selectedProject) || (!areaClicked && selectedProject),
    isWait: areaClicked,
  });

  const area_list =
    areasList &&
      areasList.GetAreasList &&
      areasList.GetAreasList.areasList &&
      areasList.GetAreasList.areasList.areas
      ? areasList.GetAreasList.areasList.areas
      : [];

  useEffect(() => {
    const quill = document.querySelector(".description-quill");
    const allContainers = document.querySelectorAll(".ql-container");
    const allToolbars = document.querySelectorAll(".ql-toolbar");

    // allToolbars.forEach((toolbar) => {
    //   if (toolbar && toolbar.style) {
    //     // toolbar.style.display = "none";
    //     // toolbar.style.borderTopLeftRadius = "5px";
    //     // toolbar.style.borderTopRightRadius = "5px";
    //   }
    // });

    allContainers.forEach((container) => {
      if (container && container.style) {
        // container.style.borderTop = "1px solid #cccccc";
        // container.style.borderRadius = "5px";
        container.style.borderBottomLeftRadius = "5px";
        container.style.borderBottomRightRadius = "5px";
      }
    });

    if (quill) {
      const regularToolbar = quill.querySelector(".ql-toolbar");
      const regularContainer = quill.querySelector(".ql-container");

      quill.addEventListener("mouseover", () => {
        if (regularToolbar && regularToolbar.style) {
          regularToolbar.style.display = "block";
        }
        if (regularContainer && regularContainer.style) {
          regularContainer.style.borderTopLeftRadius = "0px";
          regularContainer.style.borderTopRightRadius = "0px";
        }
      });

      if (!quillRef.current?.editor?.hasFocus()) {
        // if (regularToolbar && regularToolbar.style) {
        //   regularToolbar.style.display = "none";
        // }
        if (regularContainer && regularContainer.style) {
          regularContainer.style.borderTopLeftRadius = "5px";
          regularContainer.style.borderTopRightRadius = "5px";
        }
      }
      quill.addEventListener("mouseout", () => {
        // // // console.log("focused", quillRef.current.editor.hasFocus());
        // if(!quillRef.current.editor.hasFocus()) {
        // if (regularToolbar && regularToolbar.style) {
        //   regularToolbar.style.display = "none";
        // }
        // if (regularContainer && regularContainer.style) {
        //   regularContainer.style.borderTopLeftRadius = "5px";
        //   regularContainer.style.borderTopRightRadius = "5px";
        // }
        // }
      });
    }
  }, [value]);

  useEffect(() => {
    const toolbars = document.querySelectorAll(".ql-toolbar");

    toolbars?.forEach((toolbar) => {
      const regularToolbar = toolbar.closest(".regular-quill");
      const fullscreenToolbar = toolbar.closest(".fullscreen-quill");

      const regularMaximizeTool =
        regularToolbar?.querySelectorAll(".ql-maximize");
      const fullscreenMaximizeTool =
        fullscreenToolbar?.querySelectorAll(".ql-maximize");

      if (regularToolbar && regularMaximizeTool?.length == 0) {
        const child = document.createElement("span");
        child.setAttribute("class", "ql-formats");
        const button = document.createElement("button");
        button.type = "button";
        button.setAttribute("class", "ql-maximize");
        const svg = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "svg"
        );
        svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        svg.setAttribute(
          "class",
          "icon icon-tabler icon-tabler-arrows-diagonal"
        );
        svg.setAttribute("width", "20");
        svg.setAttribute("height", "20");
        svg.setAttribute("viewBox", "0 0 24 24");
        svg.setAttribute("stroke-width", "1.5");
        svg.setAttribute("stroke", "#000000");
        svg.setAttribute("fill", "none");
        svg.setAttribute("stroke-linecap", "round");
        svg.setAttribute("stroke-linejoin", "round");

        const path1 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        path1.setAttribute("stroke", "none");
        path1.setAttribute("d", "M0 0h24v24H0z");
        path1.setAttribute("fill", "none");

        const path2 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        path2.setAttribute("d", "M16 4l4 0l0 4");

        const path3 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        path3.setAttribute("d", "M14 10l6 -6");

        const path4 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        path4.setAttribute("d", "M8 20l-4 0l0 -4");

        const path5 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        path5.setAttribute("d", "M4 20l6 -6");

        svg.appendChild(path1);
        svg.appendChild(path2);
        svg.appendChild(path3);
        svg.appendChild(path4);
        svg.appendChild(path5);

        button.appendChild(svg);
        button.addEventListener("click", () => {
          setFullscreen(!fullscreen);
        });
        child.appendChild(button);
        toolbar.appendChild(child);
      } else if (fullscreenToolbar && fullscreenMaximizeTool?.length == 0) {
        const child = document.createElement("span");
        child.setAttribute("class", "ql-formats");
        const button = document.createElement("button");
        button.type = "button";
        button.setAttribute("class", "ql-minimize");
        const svg = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "svg"
        );
        svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        svg.setAttribute(
          "class",
          "icon icon-tabler icon-tabler-arrows-diagonal-minimize-2"
        );
        svg.setAttribute("width", "20");
        svg.setAttribute("height", "20");
        svg.setAttribute("viewBox", "0 0 24 24");
        svg.setAttribute("stroke-width", "1.5");
        svg.setAttribute("stroke", "#000000");
        svg.setAttribute("fill", "none");
        svg.setAttribute("stroke-linecap", "round");
        svg.setAttribute("stroke-linejoin", "round");

        const path1 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        path1.setAttribute("stroke", "none");
        path1.setAttribute("d", "M0 0h24v24H0z");
        path1.setAttribute("fill", "none");

        const path2 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        path2.setAttribute("d", "M18 10h-4v-4");

        const path3 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        path3.setAttribute("d", "M20 4l-6 6");

        const path4 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        path4.setAttribute("d", "M6 14h4v4");

        const path5 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        path5.setAttribute("d", "M10 14l-6 6");

        svg.appendChild(path1);
        svg.appendChild(path2);
        svg.appendChild(path3);
        svg.appendChild(path4);
        svg.appendChild(path5);

        button.appendChild(svg);
        button.addEventListener("click", () => {
          setFullscreen(!fullscreen);
        });
        child.appendChild(button);
        toolbar.appendChild(child);
      }
      return toolbar;
    });
  }, [fullscreen, anyChange, value]);

  function extractTypeFromDataURL(dataURL) {
    const base64String = dataURL.split(",")[0].split(";")[0];
    const type = base64String.substring(5);
    return type;
  }

  function base64ToBlob(base64Data, contentType = "image/png") {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  function base64ToFile(base64Data, filename, contentType) {
    const blob = base64ToBlob(base64Data, contentType);
    return new File([blob], filename, { type: contentType });
  }

  async function replaceAndUploadImage(htmlText, filenameTemplate) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlText, "text/html");

    const images = doc.querySelectorAll("img");
    let replacements = [];

    for (let img of images) {
      if (img.src.startsWith("data:image/")) {
        const base64String = img.src.split(",")[1]; // Extract base64 without the prefix
        const contentType = extractTypeFromDataURL(img.src);
        const ext = contentType.split("/")[1];
        const filename = `${generateRandomName()}.${ext}`;

        const file = base64ToFile(base64String, filename, contentType);

        // Prepare form data for the upload
        let formData = new FormData();
        formData.append("image", file, file.name);
        formData.append("tenantID", storeUserTenant);
        if (taskGuid) formData.append("itemID", taskGuid);

        // Upload the image and replace the src with the new URL
        const newUrl = await uploadImage(formData, false);
        img.src = newUrl; // Update the src in the DOM

        replacements.push(newUrl);
      }
    }
    return doc.body.innerHTML;
  }

  function generateRandomName(length = 12) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  useEffect(() => {
    const quillContainer = document.querySelector(".regular-quill");
    if (quillContainer) {
      const editor = quillContainer.querySelector(".ql-editor");
      if (editor) {
        editor?.addEventListener("paste", async (event) => {
          let data = event.clipboardData || window.clipboardData;
          let html = event.explicitOriginalTarget;
          if (data.files?.length > 0) {
            event.preventDefault();
            var file = data.files[0];
            var imageType = /image.*/;

            if (file.type.match(imageType)) {
              var formData = new FormData();
              formData.append("image", file, file.name);
              formData.append("tenantID", storeUserTenant);
              if (taskGuid) formData.append("itemID", taskGuid);
            }
            await uploadImage(formData, true);
          } else if (html?.innerHTML) {
            const innerHTML = await replaceAndUploadImage(
              html?.innerHTML,
              generateRandomName()
            );
            setFieldValue(description.name, innerHTML);
          }
        });
      }
    }
  }, []);

  // useEffect(() => {
  //   const qlMaximize = document.querySelectorAll(".ql-maximize")[0];

  //   if (qlMaximize !== null && qlMaximize !== undefined) {
  //     qlMaximize.addEventListener("click", () => {
  //       setFullscreen(!fullscreen);
  //     });
  //   }
  // }, [fullscreen]);

  const { data: teamData, tokenChange: teamWait } = useQueryAuth(
    GET_ALL_TEAMS,
    "GetTeamsList",
    {
      variables: {
        model: {
          // enableFilters: false,
          tenantGuid: fromTenant
            ? tenantID
              ? tenantID
              : null
            : storeUserTenant,
          // projectID: selectedProject?.projectGuid,
        },
      },
      skip: !teamClicked || !selectedProject,
      isWait: teamClicked,
    }
  );

  const teams_list = teamData?.GetTeamsList?.teamsList?.teams || [];

  // const {
  //   data: projectData,
  //   loading: projectLoading,
  //   tokenChange: projectWait,
  // } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
  //   variables: {
  //     model: {
  //       enableFilters: false,
  //       tenantID: fromTenant ? null : storeUserTenant,
  //       userID: storeUserGuid,
  //     },
  //   },
  //   skip: !projectClicked,
  //   isWait: projectClicked,
  // });

  // const {
  //   data: tasksList,
  //   loading: taskLoading,
  //   tokenChange: tasksWait,
  // } = useQueryAuth(GET_ALL_PARENT_TASKS, "GetTasks", {
  //   variables: {
  //     model: {
  //       tenantID: storeUserTenant,
  //       enableFilters: false,
  //       onGoingTasks: true,
  //       userID: storeUserGuid,
  //       projectID: projectV?.projectGuid,
  //     },
  //   },
  //   skip: !parentClicked,
  //   isWait: parentClicked,
  // });

  // const task_list =
  //   tasksList && tasksList.GetTasks && tasksList.GetTasks.tasks
  //     ? tasksList.GetTasks.tasks
  //     : [];

  // const projects_list =
  //   projectData?.GetAllProjects?.projectsList?.projectViews || [];

  // const {
  //   data: ticketData,
  //   loading: ticketLoading,
  //   tokenChange: ticketWait,
  // } = useQueryAuth(GET_ALL_TICKETS_DROPDOWN, "GetAllTickets", {
  //   variables: {
  //     model: {
  //       enableFilters: false,
  //       tenantID: storeUserTenant,
  //       projectID: projectV?.projectGuid,
  //     },
  //   },
  //   skip: projectV === null || projectV === undefined || projectV === "",
  //   isWait: projectV === null || projectV === undefined || projectV === "",
  // });

  // const tickets_list = ticketData?.GetAllTickets?.ticketsList?.tickets || [];

  // useEffect(() => {
  //   if (ticketData && handleFetchTree) handleFetchTree(true);
  // }, [ticketData]);

  // useEffect(() => {
  //   if (type === "subtask" || subtask === true) {
  //     setFieldValue(
  //       ticket.name,
  //       tickets_list?.filter((x) => x.ticketGuid === parentTask?.ticketGuid)[0]
  //     );
  //   }
  // }, [subtask, type]);

  useEffect(() => {
    if (createMode && ticket_status_list?.length > 0)
      setFieldValue(
        status.name,
        ticket_status_list.filter((s) => s.taskState?.mnemonic == "new")[0]
      );
  }, [ticket_status_list?.length, taskGuid]);

  useEffect(() => {
    if (
      ticketV !== null &&
      createMode === true &&
      ticketV?.area !== null &&
      parentTask?.taskGuid === undefined
    )
      setFieldValue(area.name, ticketV?.area);
    if (ticketV == null && !parentTask?.taskGuid)
      setFieldValue(area.name, null);
  }, [ticketV, parentTask]);

  // useEffect(() => {
  //   if (
  //     parentTask?.taskGuid !== null &&
  //     parentTask?.taskGuid !== undefined &&
  //     tickets_list?.length > 0
  //   ) {
  //     setFieldValue(task.name, parentTask);
  //     if (parentTask?.externalReference)
  //       setFieldValue(externalReference.name, parentTask?.externalReference);
  //     if (fromTree)
  //       setFieldValue(externalReference.name, parentTask?.externalReference);
  //     if (parentTask?.ticketGuid) {
  //       const selectedTicket = tickets_list?.filter(
  //         (x) => x.ticketGuid === parentTask?.ticketGuid
  //       )[0];
  //       // setFieldValue(ticket.name, selectedTicket);
  //     }
  //     if (parentTask?.area) {
  //       setFieldValue(area.name, parentTask?.area);
  //     }
  //   }
  // }, [parentTask?.taskGuid, tickets_list?.length]);

  useEffect(() => {
    if (parentTask?.taskGuid !== null && parentTask?.taskGuid !== undefined) {
      setFieldValue(task.name, parentTask);
    }
  }, [parentTask]);

  // useEffect(() => {
  //   if(area_list.length > 0) setAreaClicked(false)
  // },[area_list.length]);

  // useEffect(() => {
  //   if(employees_list.length > 0) setEmployeeClicked(false)
  // },[employees_list.length]);

  // useEffect(() => {
  //   if(projects_list.length > 0) setProjectClicked(false)
  // },[projects_list.length]);

  const DisplayTimeFields = () => {
    const isActualLate =
      EstimatedTimeV !== null &&
      ActualTimeV !== null &&
      parseFloat(ActualTimeV) > parseFloat(EstimatedTimeV) &&
      parseFloat(EstimatedTimeV) !== 0;

    const isStartDateLate =
      EstimatedStartDateV !== null &&
      ActualStartDateV !== null &&
      ActualStartDateV > EstimatedStartDateV;

    const isEndDateLate =
      EstimatedEndDateV !== null &&
      ActualEndDateV !== null &&
      ActualEndDateV > EstimatedEndDateV;

    return (
      <Grid
        container
        spacing={4}
        mb={0.5}
        ml={0}
        sx={{
          display: "flex",
          // justifyContent: "space-between",
          // alignItems: "flex-end",
          // flexDirection: "column"
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            paddingLeft: "0 !important",
            paddingBottom: "0 !important",
            paddingTop: "0px !important",
          }}
        >
          <MiniTitle
            style={{
              marginBottom: "10px",
              margin: "0 !important",
              padding: "0 !important",
            }}
          >
            {t("taskForm.startDate.label")}
          </MiniTitle>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            paddingLeft: "0 !important",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            paddingTop: "5px !important",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              lg={5}
              xl={5}>
              <FormLabel
                sx={{ fontFamily: "Poppins", fontSize: "14px" }}
              >
                {t(EstimatedStartDate.label)}
              </FormLabel></Grid>
            <Grid
              item
              xs={12}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              sx={{paddingLeft:"6px !important"}}>
              <DesktopDatePicker
                sx={{
                  ".MuiInputBase-input": {
                    fontSize: "14px !important",
                    paddingBottom: "0 !important",
                  },
                  "& .MuiInputBase-root::before": {
                    border: "none !important",
                  },
                  "& .MuiButtonBase-root": {
                    display: "none !important",
                  },
                }}
                format={
                  day_month_year.includes(userTimezone) === true
                    ? "dd-MM-yyyy"
                    : "MM-dd-yyyy"
                }
                timezone="UTC"
                // label={t(EstimatedStartDate.label)}
                fullWidth
                value={EstimatedStartDateV}
                disabled={
                  syncedV === true ||
                  isEmployee === Mnemonics.ResourceTypes.External ||
                  readOnly ||
                  false
                }
                onChange={(newValue) => {
                  setFieldValue(EstimatedStartDate.name, newValue);
                }}
                onBlur={() =>
                  setTouched({
                    ...touched,
                    [EstimatedStartDate.name]: true,
                  })
                }
                slotProps={{
                  desktopPaper: {
                    sx: {
                      boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                    },
                  },
                  textField: {
                    size: "small",
                    variant: "standard",

                    error:
                      touched[EstimatedStartDate.name] &&
                      errors[EstimatedStartDate.name],
                    helperText:
                      touched[EstimatedStartDate.name] &&
                      errors[EstimatedStartDate.name] &&
                      t(errors[EstimatedStartDate.name]),

                    onBlur: () =>
                      setTouched({
                        ...touched,
                        [EstimatedStartDate.name]: true,
                      }),
                  },
                }}
              /></Grid>
          </LocalizationProvider>
          
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            margin: "0",
            paddingTop: "20px !important",
            paddingLeft: "0 !important",
            display: "flex",
            alignItems: "center"
          }}
        >
          <Grid
            item
            xs={12}
            sm={5}
            md={5}
            lg={5}
            xl={5}
            sx={{ margin: "0", padding: "0" }}
          >
            <p className="timeFields">{t(ActualStartDate.label)}</p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            md={7}
            lg={7}
            xl={7}
            sx={{
              margin: "0",
              padding: "0",
              display: "flex",
              alignItems: "center",
              cursor: "default",
              // justifyContent: "flex-end",
            }}
          >
            <p className="timeFieldsV">
              {ActualStartDateV}
            </p>
            {isStartDateLate ? (
                <AlertIcon color="#ffbf00" width="20" height="20" title="Late" />
              ) : null}</Grid>
            
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            paddingLeft: "0 !important",
            paddingBottom: "0 !important",
            paddingTop: "15px !important",
          }}
        >
          <MiniTitle
            style={{
              marginBottom: "10px",
              margin: "0 !important",
              padding: "0 !important",
              fontSize: "13px !important",
            }}
          >
            {t("taskForm.endDate.label")}
          </MiniTitle>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            paddingLeft: "0 !important",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            paddingTop: "5px !important",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              lg={5}
              xl={5}>
              <FormLabel
                sx={{  fontFamily: "Poppins", fontSize: "14px" }}
              >
                {t(EstimatedEndDate.label)}
              </FormLabel></Grid>
            <Grid
              item
              xs={12}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              sx={{paddingLeft:"6px !important"}}>
              <DesktopDatePicker
                sx={{
                  ".MuiInputBase-input": {
                    fontSize: "14px !important",
                    paddingBottom: "0 !important",
                  },
                  "& .MuiInputBase-root::before": {
                    border: "none !important",
                  },
                  "& .MuiButtonBase-root": {
                    display: "none !important",
                  },
                  "& .MuiInputBase-root:hover .MuiInputBase-root::before": {
                    border: "1px solid rgba(0, 0, 0, 0.42) !important",
                  },
                }}
                format={
                  day_month_year.includes(userTimezone) === true
                    ? "dd-MM-yyyy"
                    : "MM-dd-yyyy"
                }
                timezone="UTC"
                // label={t(EstimatedEndDate.label)}
                fullWidth
                value={EstimatedEndDateV}
                disabled={
                  syncedV === true ||
                  isEmployee === Mnemonics.ResourceTypes.External ||
                  readOnly ||
                  false
                }
                onChange={(newValue) => {
                  setFieldValue(EstimatedEndDate.name, newValue);
                }}
                onBlur={() =>
                  setTouched({
                    ...touched,
                    [EstimatedEndDate.name]: true,
                  })
                }
                slotProps={{
                  desktopPaper: {
                    sx: {
                      boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                    },
                  },
                  textField: {
                    size: "small",
                    variant: "standard",

                    error:
                      touched[EstimatedEndDate.name] &&
                      errors[EstimatedEndDate.name],
                    helperText:
                      touched[EstimatedEndDate.name] &&
                      errors[EstimatedEndDate.name] &&
                      t(errors[EstimatedEndDate.name]),

                    onBlur: () =>
                      setTouched({
                        ...touched,
                        [EstimatedEndDate.name]: true,
                      }),
                  },
                }}
              /></Grid>
          </LocalizationProvider>
          
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            margin: "0",
            paddingLeft: "0 !important",
            display: "flex",
            paddingTop: "20px !important",
          }}
        >
          <Grid
            item
            xs={12}
            sm={5}
            md={5}
            lg={5}
            xl={5}
            sx={{ margin: "0", padding: "0" }}
          >
            <p className="timeFields">{t(ActualEndDate.label)}</p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            md={7}
            lg={7}
            xl={7}
            sx={{
              margin: "0",
              padding: "0",
              display: "flex",
              alignItems: "center",
              cursor: "default",
              // justifyContent: "flex-end",
            }}
          >
            <p className="timeFieldsV">
              {ActualEndDateV}
            </p>
            
              {isEndDateLate ? (
                <AlertIcon color="#ffbf00" width="20" height="20" title="Late" />
              ) : null}
            </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            paddingLeft: "0 !important",
            paddingBottom: "0 !important",
            paddingTop: "15px !important",
          }}
        >
          <MiniTitle
            style={{
              marginBottom: "10px",
              margin: "0 !important",
              padding: "0 !important",
            }}
          >
            {t("taskForm.time.label")}
          </MiniTitle>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            paddingLeft: "0 !important",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            paddingTop: "5px !important",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              lg={5}
              xl={5}>
              <FormLabel
                sx={{ width: "60%", fontFamily: "Poppins", fontSize: "14px" }}
              >
                {t(EstimatedTime.label)}
              </FormLabel></Grid> <Grid
                item
                xs={12}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                sx={{paddingLeft:"6px !important"}}>
              <TextField
                sx={{
                  width: "40%",
                  ".MuiInputBase-input": {
                    fontSize: "14px !important",
                    paddingBottom: "0 !important",
                  },
                  "& .MuiInputBase-root::before": {
                    border: "none !important",
                  },
                  "& .MuiButtonBase-root": {
                    display: "none !important",
                  },
                }}
                variant="standard"
                fullWidth
                // label={t(EstimatedTime.label)}
                value={EstimatedTimeV}
                disabled={
                  syncedV === true ||
                  isEmployee === Mnemonics.ResourceTypes.External ||
                  readOnly ||
                  false
                }
                onBlur={(e) => {
                  setTouched({
                    ...touched,
                    [EstimatedTime.name]: true,
                  });
                  setFieldValue(
                    EstimatedTime.name,
                    Number(e.target.value).toFixed(2)
                  );
                }}
                onChange={(e) => {
                  setFieldValue(EstimatedTime.name, e.target.value);
                }}
                error={touched[EstimatedTime.name] && errors[EstimatedTime.name]}
                helperText={
                  touched[EstimatedTime.name] &&
                  errors[EstimatedTime.name] &&
                  t(errors[EstimatedTime.name])
                }
              /></Grid>
          </LocalizationProvider>
          
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            margin: "0",
            paddingLeft: "0 !important",
            display: "flex",
            paddingTop: "20px !important",
          }}
        >
          <Grid
            item
            xs={12}
            sm={5}
            md={5}
            lg={5}
            xl={5}
            sx={{ margin: "0", padding: "0" }}
          >
            <p className="timeFields">{t(ActualTime.label)}</p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            md={7}
            lg={7}
            xl={7}
            sx={{
              margin: "0",
              padding: "0",
              display: "flex",
              alignItems: "center",
              // justifyContent: "flex-end",
            }}
          >
            <p className="timeFieldsV">{ActualTimeV}</p>
           
              {isActualLate ? (
                <AlertIcon color="#ff2825" width="20" height="20" title="Late" />
              ) : null}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            margin: "0",
            paddingLeft: "0 !important",
            display: "flex",
            paddingTop: "20px !important",
          }}
        >
          <Grid
            item
            xs={12}
            sm={5}
            md={5}
            lg={5}
            xl={5}
            sx={{ margin: "0", padding: "0" }}
          >
            <p className="timeFields timeFields-italic">
              {t(ActualBillableTime.label)}
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            md={7}
            lg={7}
            xl={7}
            sx={{
              margin: "0",
              padding: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            
              <p className="timeFieldsV timeFieldsV-italic">
                {ActualBillableTimeV}
              </p>
            </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            margin: "0",
            paddingLeft: "0 !important",
            display: "flex",
            paddingTop: "20px !important",
          }}
        >
          <Grid
            item
            xs={12}
            sm={5}
            md={5}
            lg={5}
            xl={5}
            sx={{ margin: "0", padding: "0" }}
          >
            <p className="timeFields timeFields-italic">
              {t(ActualNonBillableTime.label)}
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            md={7}
            lg={7}
            xl={7}
            sx={{
              margin: "0",
              padding: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p className="timeFieldsV timeFieldsV-italic">
              {ActualNonBillableTimeV}
            </p>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    const link = document.querySelector(".ql-link");
    const clean = document.querySelector(".ql-clean");
    const maximize = document.querySelector(".ql-maximize");
    const minimize = document.querySelector(".ql-minimize");
    const tooltip = document.querySelector(".tooltip-link");
    const tooltipClean = document.querySelector(".tooltip-clean");
    const tooltipMaximize = document.querySelector(".tooltip-maximize");
    const tooltipMinimize = document.querySelector(".tooltip-minimize");

    const input = document.querySelector(".ql-tooltip input");

    if (link != null) {
      link.addEventListener("mouseover", () => {
        tooltip.style.display = "block";
      });

      link.addEventListener("mouseout", () => {
        tooltip.style.display = "none";
      });
      link.addEventListener("click", () => {
        input.value = "http://";
      });
    }
    if (clean != null && tooltipClean != null) {
      clean.addEventListener("mouseover", () => {
        tooltipClean.style.display = "block";
      });

      clean.addEventListener("mouseout", () => {
        tooltipClean.style.display = "none";
      });
    }
    if (maximize != null && tooltipMaximize != null) {
      maximize.addEventListener("mouseover", () => {
        tooltipMaximize.style.display = "block";
      });

      maximize.addEventListener("mouseout", () => {
        tooltipMaximize.style.display = "none";
      });
    }
    if (minimize != null && tooltipMinimize != null) {
      minimize.addEventListener("mouseover", () => {
        tooltipMinimize.style.display = "block";
      });

      minimize.addEventListener("mouseout", () => {
        tooltipMinimize.style.display = "none";
      });
    }
  }, [fullscreen]);

  const statusContainer = () => {
    switch (syncedV) {
      case true:
        return (
          <Grid
            style={{
              //top: 0,
              right: 0,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              marginLeft: "10px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-rotate-clockwise"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#00b341"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4.05 11a8 8 0 1 1 .5 4m-.5 5v-5h5" />
            </svg>
            {t("taskForm.synced.label")}
          </Grid>
        );
      case false:
        return null;
      default:
        return null;
    }
  };

  const statusPicked = () => {
    return (
      <Grid
        style={{
          top: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
          marginLeft: "5px",
        }}
      >
        <Tooltip
          title={
            isEmployee === Mnemonics.ResourceTypes.External
              ? ""
              : t("task.tootlip.status")
          }
        >
          <Button
            onClick={handleStatusButtonClick}
            disabled={isEmployee === Mnemonics.ResourceTypes.External || false}
            sx={{
              ":hover": { backgroundColor: "transparent !important" },
              padding: 0,
            }}
          >
            {componentDisplay({
              compName: "BackgroundStatus",
              compProps: { row: values, size: 12, length: 25, widthAuto: true },
            })}
          </Button>
          <Menu
            anchorEl={anchorElStatus}
            open={Boolean(anchorElStatus)}
            onClose={handleStatusClose}
            sx={{
              ".MuiPaper-root": {
                right: "20px !important",
                boxShadow: `rgb(0 0 0 / 26%) 0px 1px 4px !important`,
                marginTop: "5px",
                padding: "0 5px",
              },
            }}
          >
            {ticket_status_list?.map((option) => (
              <MenuItem
                key={option?.taskStatusGuid}
                onClick={() => handleStatusMenuItemClick(option)}
              >
                {" "}
                {option?.description}
              </MenuItem>
            ))}
          </Menu>
        </Tooltip>
      </Grid>
    );
  };

  const displayExternalReference = () => {
    return (
      <Grid
        style={{
          top: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
        className="priorityButton"
      >
        <Tooltip title={t("task.tooltip.externalReference")}>
          <Button
            aria-describedby={externalReference.name}
            onClick={handleExternalReferenceClick}
          >
            <ExternalReferenceIcon color={colors.disabled_text_grey} />
          </Button>
        </Tooltip>
        <Popover
          id={externalReference.name}
          open={Boolean(anchorElExternalReference)}
          anchorEl={anchorElExternalReference}
          onClose={handleExternalReferenceClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            ".MuiPaper-root": {
              right: "20px !important",
              boxShadow: `rgb(0 0 0 / 26%) 0px 1px 4px !important`,
              marginTop: "5px",
              padding: "0 5px",
            },
          }}
        >
          <TextField
            sx={{
              ".MuiInputBase-input": {
                fontSize: "14px !important",
              },
            }}
            multiline
            variant="standard"
            fullWidth
            label={t(externalReference.label)}
            value={externalReferenceV}
            disabled={
              type === "subtask" ||
                subtask === true ||
                isEmployee === Mnemonics.ResourceTypes.External ||
                readOnly
                ? true
                : false || false
            }
            onBlur={() =>
              setTouched({
                ...touched,
                [externalReference.name]: true,
              })
            }
            onChange={(e) => {
              setFieldValue(externalReference.name, e.target.value);
            }}
            error={
              touched[externalReference.name] && errors[externalReference.name]
            }
            helperText={
              touched[externalReference.name] &&
              errors[externalReference.name] &&
              t(errors[externalReference.name])
            }
          />
        </Popover>
      </Grid>
    );
  };

  const handleExternalReferenceClick = (event) => {
    setAnchorElExternalReference(event.currentTarget);
  };

  const handleExternalReferenceClose = () => {
    setAnchorElExternalReference(null);
  };

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (value) => {
    setAnchorEl(null);
    if (createMode) {
      setTouched({ ...touched, [priority.name]: true });
      handleChangeFlag({ ...touched, [priority.name]: true });
    }
    // setFieldValue(priority.name, value);
    values.priority = value;
    if (
      updateTaskQuick &&
      !createMode &&
      !sessionStorage.getItem("unsavedChanges")
    )
      updateTaskQuick(null, value);
    else if (
      handleSubmit &&
      !createMode &&
      sessionStorage.getItem("unsavedChanges")
    ) {
      setTimeout(() => {
        handleSubmit(values, null, null, false);
      }, 1000);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusButtonClick = (event) => {
    setAnchorElStatus(event.currentTarget);
  };

  const handleStatusMenuItemClick = (value) => {
    setAnchorElStatus(null);
    if (createMode) {
      setTouched({ ...touched, [status.name]: true });
      handleChangeFlag({ ...touched, [status.name]: true });
    }
    // setFieldValue(status.name, value);
    values.status = value;
    if (
      updateTaskQuick &&
      !createMode &&
      !sessionStorage.getItem("unsavedChanges")
    )
      updateTaskQuick(value?.taskStatusGuid);
    else if (
      handleSubmit &&
      !createMode &&
      sessionStorage.getItem("unsavedChanges")
    ) {
      setTimeout(() => {
        handleSubmit(values, null, null, false);
      }, 1000);
    }
  };

  const handleStatusClose = () => {
    setAnchorElStatus(null);
  };

  const priorityStatus = () => {
    return (
      <div className="priorityButton">
        <Tooltip title={priorityV}>
          {" "}
          <Button
            onClick={handleButtonClick}
            disabled={
              readOnly ||
              isEmployee === Mnemonics.ResourceTypes.External ||
              false
            }
          >
            {getOptionPriorityIcon(priorityV)}
          </Button>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{
            ".MuiPaper-root": {
              right: "10px !important",
              boxShadow: `rgb(0 0 0 / 26%) 0px 1px 4px !important`,
              marginTop: "5px",
              padding: "0 5px",
            },
          }}
        >
          {Object.values(Mnemonics?.PriorityStatuses)
            .filter((option) => option !== Mnemonics.PriorityStatuses.None)
            .map((option) => (
              <MenuItem
                key={option}
                onClick={() => handleMenuItemClick(option)}
              >
                {" "}
                <Box
                  component="span"
                  sx={{ "& > svg": { mr: 2, flexShrink: 0 } }}
                >
                  {getOptionPriorityIcon(option)}
                </Box>
                {option}
              </MenuItem>
            ))}
        </Menu>
      </div>
    );
  };

  const handleOpenAreaModal = () => {
    setTimeout(() => {
      setOpenAreaModal(true);
    }, 800);
  };

  const reload = (tabNo) => {
    refetchTask();
    handleChange(null, tabNo);
  };

  return (
    <Box
      sx={
        fullscreen
          ? {
            height: "100% !important",
            border: "1px solid #eee",
            padding: "30px",
            overflowY: "hidden",
          }
          : {
            height: "100% !important",
            border: "1px solid #eee",
            padding: "30px",
            overflowY: "hidden",
          }
      }
    >
      <FormModal
        showModal={openAreaModal}
        setShowModal={(v) => setOpenAreaModal(v)}
        loading={false}
        refetch={refetchAreas}
        // open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        modifyFormFlag={modifyFormFlag}
        Child={AreasList}
      />

      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: fullscreen ? "100% !important" : "initial",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          spacing={2}
          gap={1}
          sx={fullscreen ? { height: "100% !important" } : {}}
        >
          <Grid
            container
            spacing={1}
            sx={fullscreen ? { height: "100% !important" } : {}}
          >
            <Grid
              container
              spacing={2}
              sx={fullscreen ? { height: "100% !important" } : {}}
            >
              {/* <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingBottom: "0",
                  color: colors.light_logo_blue,
                  fontSize: "1.15rem",
                  fontWeight: "500",
                  height: fullscreen ? "100% !important" : "initial",
                }}
              >
                <ProjectIcon color={colors.light_logo_blue} />
                {!createMode || (pID && !quickAdd) ? (
                  <span
                    class={`linkButton ${
                      manageTimeEntry !== "N" ||
                      projectPermissions?.some(
                        (x) => x.projectGuid === projectV?.projectGuid
                      ) ||
                      isAdmin
                        ? "linkButton-hover"
                        : ""
                    }`}
                    onClick={(event) => {
                      if (
                        manageTimeEntry !== "N" ||
                        projectPermissions?.some(
                          (x) => x.projectGuid === projectV?.projectGuid
                        ) ||
                        isAdmin
                      )
                        window.open(
                          my_projects_form.replace(
                            "/:pName/:pID",
                            `/${encodeURIComponent(
                              projectV?.title
                            )}/${encodeURIComponent(projectV?.projectGuid)}`
                          )
                        );
                      else event.preventDefault();
                    }}
                  >
                    {projectV?.title}
                  </span>
                ) : (
                  <AutoComplete
                    options={projects_list}
                    fullWidth
                    getOptionLabel={(option) => (option ? option.title : "")}
                    identifier={"projectGuid"}
                    disabled={projectDisabled || false}
                    value={projectV}
                    removeBorder
                    removeSX
                    sx={{
                      ".MuiOutlinedInput-root": { padding: "0 !important" },
                      "& .MuiFormLabel-root": {
                        fontSize: "1.15rem !important",
                        fontWeight: "500 !important",
                        marginTop: "-10px !important",
                        color: `${colors.light_logo_blue} !important`,
                        fontFamily: `'Poppins' !important`,
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "1.15rem !important",
                        lineHeight: "1.375rem !important",
                        fontWeight: "500 !important",
                        color: `${colors.light_logo_blue} !important`,
                        fontFamily: `'Poppins' !important`,
                      },
                      "& .MuiInputBase-root": {
                        marginTop: "0 !important",
                      },
                      "& .MuiSvgIcon-root": {
                        display: "none !important",
                      },
                      "& :hover .MuiSvgIcon-root": projectDisabled
                        ? {
                            display: "none !important",
                          }
                        : {
                            display: "initial !important",
                          },
                      "& .MuiInput-underline:before": {
                        borderBottom: "none !important",
                      },
                      "& :hover .MuiInput-underline:before": projectDisabled
                        ? {}
                        : {
                            borderBottom: "1px solid #bcbcbc !important",
                          },
                      "& :hover": projectDisabled
                        ? {}
                        : {
                            cursor: "pointer !important",
                          },
                    }}
                    onChange={(e, value) => {
                      e.preventDefault();
                      setFieldValue(project.name, value);
                      setFieldValue(task.name, null);
                      setFieldValue(area.name, null);
                      setFieldValue(externalReference.name, "");
                      setProjectGuid(value?.projectGuid);
                      // if (refetchFct !== undefined && value != undefined)
                      //   refetchFct(value?.projectGuid);
                      // if (fetchTickets !== undefined)
                      //   fetchTickets(value?.projectGuid);
                      if (handleChangeProject !== undefined)
                        handleChangeProject(value?.projectGuid);
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    required
                    loading={projectLoading}
                    label={t(project.label)}
                    textFieldLabel={t("taskForm.selectProject")}
                    removeLabel={projectV}
                    formData={formData}
                    name={project.name}
                    onOpen={() => setProjectClicked(true)}
                    autoFocus
                  />
                )}
              </Grid> */}

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={7.5}
                xl={7.5}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingTop: "0px",
                }}
              >
                <Title
                  style={{
                    padding: "5px 0 0",
                    marginRight: "10px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "5px",
                    }}
                  >
                    <TaskIcon />
                  </span>
                  <TextField
                    autoFocus={!createMode || (pID && !quickAdd)}
                    sx={{
                      ".MuiInputBase-input": {
                        fontSize: "1.25rem !important",
                        lineHeight: "1.375rem !important",
                        fontWeight: "500 !important",
                        borderBottom: "1px solid #949494 !important",
                      },
                      "& .MuiInputBase-input:hover": {
                        // borderBottom: "1px solid #bcbcbc !important",
                        // paddingLeft: "5px !important"
                      },
                      "& .MuiInputBase-input:focus": {
                        // borderBottom: "1px solid #bcbcbc !important",
                        // paddingLeft: "5px !important"
                      },
                    }}
                    variant="standard"
                    fullWidth
                    disabled={
                      isEmployee === Mnemonics.ResourceTypes.External ||
                      readOnly ||
                      false
                    }
                    value={titleV}
                    placeholder={
                      type === "subtask" || subtask === true
                        ? t("taskForm.newSubTask")
                        : t("taskForm.newTask")
                    }
                    required
                    onBlur={() =>
                      setTouched({ ...touched, [title.name]: true })
                    }
                    onChange={(e) => {
                      setFieldValue(title.name, e.target.value);
                    }}
                    error={touched[title.name] && errors[title.name]}
                    helperText={
                      touched[title.name] &&
                      errors[title.name] &&
                      t(errors[title.name])
                    }
                    InputProps={{ disableUnderline: true }}
                  />
                </Title>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4.5}
                xl={4.5}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "end",
                  paddingBottom: "0",
                }}
              >
                {!createMode && (
                  <div
                    style={{
                      width: "fit-content",
                      fontSize: "14px",
                      padding: "5px",
                      border: `1px solid ${colors.light_divider_color}`,
                      borderRadius: "5px",
                      borderColor: `${colors.light_logo_blue}`,
                      color: `${colors.light_logo_blue}`,
                    }}
                  >
                    {type === "subtask" || subtask === true
                      ? t("taskForm.subtaskInformation")
                      : t("taskForm.taskInformation")}{" "}
                    {taskId}
                  </div>
                )}
                {statusContainer()}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {statusPicked()}
                  {displayExternalReference()}
                  {priorityStatus()}
                  {
                    <div style={{ display: "flex", alignItems: "top" }}>
                      <Tooltip
                        title={
                          allowCreateTimeEntryV
                            ? t("taskForm.createTimeEntry.allowed")
                            : t("taskForm.createTimeEntry.notAllowed")
                        }
                      >
                        <IconButton
                          sx={{
                            ".MuiButtonBase-root": {
                              paddingTop: "10px",
                              paddingBottom: "0px",
                            },
                          }}
                          onClick={() => {
                            if (
                              updateTaskQuick &&
                              !createMode &&
                              !sessionStorage.getItem("unsavedChanges")
                            )
                              updateTaskQuick(
                                null,
                                null,
                                !allowCreateTimeEntryV
                              );
                            setFieldValue(
                              allowCreateTimeEntry.name,
                              !allowCreateTimeEntryV
                            );
                          }}
                        >
                          <AddTimeIcon
                            color={
                              allowCreateTimeEntryV
                                ? colors.light_logo_blue
                                : colors.icon_button_grey
                            }
                            width={"26"}
                            height={"26"}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={
                          billableV
                            ? t("taskForm.billable")
                            : t("taskForm.nonBillable")
                        }
                      >
                        <IconButton
                          style={{
                            "& .MuiButtonBase-root": {
                              paddingTop: "9px !important",
                            },
                          }}
                          onClick={() => {
                            if (
                              updateTaskQuick &&
                              !createMode &&
                              !sessionStorage.getItem("unsavedChanges")
                            )
                              updateTaskQuick(null, null, null, !billableV);
                            setFieldValue(billable.name, !billableV);
                          }}
                        >
                          <BillableIcon
                            color={
                              billableV
                                ? colors.green_color
                                : colors.icon_button_grey
                            }
                            width={"24"}
                            height={"24"}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  {/* <SubtaskIcon
                    t={t}
                    Tooltip={Tooltip}
                    color={colors.light_logo_blue}
                    handleClick={() => handleAddSubtask(selectedTask)}
                  /> */}
                </div>
              </Grid>
              {fullscreen ? (
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    position: "relative",
                    justifyContent: "space-between",
                    // marginBottom: "60px !important",
                    height: fullscreen ? "100% !important" : "initial",
                    margin: "10px 0 0 20px",
                    "& .quill": {
                      width: "100% !important",
                    },
                    "& .ql-container": {
                      height: fullscreen
                        ? !pID
                          ? "calc(100vh - 330px) !important"
                          : "calc(100vh - 330px) !important"
                        : "",
                    },
                    "& .ql-editor": {
                      height: "100% !important",
                      maxHeight: "inherit !important",
                    },
                  }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div
                      style={{ position: "relative" }}
                      onFocus={() =>
                        setTouched({ ...touched, [description.name]: true })
                      }
                    >
                      <Backdrop
                        sx={{
                          color: "#fff",
                          position: "absolute !important",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loadingImage}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                      <ReactQuill
                        className="fullscreen-quill"
                        ref={quillRef}
                        name="body"
                        value={descriptionV}
                        onChange={handleQuillChange}
                        readOnly={
                          isEmployee === Mnemonics.ResourceTypes.External
                        }
                        modules={modules}
                        formats={formats}
                        placeholder="Description"
                      // onKeyUp={handleKeyUp}
                      />
                      {/* {showSuggestions && (
                      <div
                        className="suggestion-list"
                        style={{ top: position.top + 40, left: position.left }}
                      >
                        {mentionSuggestions.map((suggestion) => (
                          <div
                            key={suggestion.id}
                            className="suggestion-item"
                            onClick={() => handleSuggestionClick(suggestion, '@')}
                          >
                            {suggestion.value}
                          </div>
                        ))}
                      </div>
                    )} */}
                    </div>
                    <div
                      className="tooltip-link"
                      style={{
                        fontFamily: "Poppins",
                        lineHeight: "21px",
                        backgroundColor: "#ffffff",
                        color: "#000",
                        borderRadius: "2px",
                        opacity: "0.8",
                        boxShadow: `0px 1px 4px ${colors.box_shadow_color}`,
                        padding: "13px !important",
                        position: "absolute",
                        top: "-30px",
                        right: 0,
                      }}
                    >
                      {t("tooltip.link")}
                    </div>
                    <div
                      className="tooltip-clean"
                      style={{
                        fontFamily: "Poppins",
                        lineHeight: "21px",
                        backgroundColor: "#ffffff",
                        color: "#000",
                        borderRadius: "2px",
                        opacity: "0.8",
                        boxShadow: `0px 1px 4px ${colors.box_shadow_color}`,
                        padding: "13px !important",
                        position: "absolute",
                        top: "-30px",
                        right: 0,
                      }}
                    >
                      {t("tooltip.clean")}
                    </div>
                    <div
                      className="tooltip-minimize"
                      style={{
                        fontFamily: "Poppins",
                        lineHeight: "21px",
                        backgroundColor: "#ffffff",
                        color: "#000",
                        borderRadius: "2px",
                        opacity: "0.8",
                        boxShadow: `0px 1px 4px ${colors.box_shadow_color}`,
                        padding: "13px !important",
                        position: "absolute",
                        top: "-30px",
                        right: 0,
                      }}
                    >
                      {t("tooltip.minimize")}
                    </div>
                    <div
                      className="tooltip-maximize"
                      style={{
                        fontFamily: "Poppins",
                        lineHeight: "21px",
                        backgroundColor: "#ffffff",
                        color: "#000",
                        borderRadius: "2px",
                        opacity: "0.8",
                        boxShadow: `0px 1px 4px ${colors.box_shadow_color}`,
                        padding: "13px !important",
                        position: "absolute",
                        top: "-30px !important",
                        right: 0,
                      }}
                    >
                      {t("tooltip.maximize")}
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={2}>
                      {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid> */}
                      {/* {(fromProject || fromDashboard) && pID == undefined && ( */}
                      {/* )} */}

                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <AutoComplete
                          options={area_list}
                          getOptionLabel={(option) =>
                            option ? option.description : ""
                          }
                          renderOption={(props, option) => (
                            <li {...props} key={uuidv4()}>
                              {option.description}
                            </li>
                          )}
                          disabled={
                            isEmployee === Mnemonics.ResourceTypes.External ||
                            readOnly ||
                            false
                          }
                          identifier={"areaGuid"}
                          value={areaV}
                          onChange={(e, value) => {
                            setFieldValue(area.name, value);
                          }}
                          loading={areaLoading}
                          label={t(area.label)}
                          name={area.name}
                          formData={formData}
                          showDisplayAdd={
                            isEmployee === Mnemonics.ResourceTypes.Employee &&
                            manageSystemConfigurations === "M" &&
                            (areaV === null ||
                              areaV === undefined ||
                              areaV === "")
                          }
                          handleClickAdd={() => handleOpenAreaModal()}
                          onOpen={() => setAreaClicked(true)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <AutoComplete
                          options={teams_list}
                          getOptionLabel={(option) =>
                            option ? option.description : ""
                          }
                          renderOption={(props, option) => (
                            <li {...props} key={uuidv4()}>
                              {option.description}
                            </li>
                          )}
                          identifier={"teamGuid"}
                          disabled={
                            isEmployee === Mnemonics.ResourceTypes.External ||
                            readOnly ||
                            false
                          }
                          value={teamV}
                          onChange={(e, value) => {
                            setFieldValue(team.name, value);
                          }}
                          loading={usersLoading}
                          label={t(team.label)}
                          name={team.name}
                          formData={formData}
                          onOpen={() => setTeamClicked(true)}
                          showDisplayView={
                            isEmployee === Mnemonics.ResourceTypes.Employee &&
                            manageUsers !== "N" &&
                            teamV
                          }
                          handleClickView={() =>
                            window.open(
                              my_teams_employees.replace(
                                "/:tName/:tID",
                                `/${encodeURIComponent(
                                  teamV?.description
                                )}/${encodeURIComponent(teamV?.teamGuid)}`
                              )
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <AutoComplete
                          options={employees_list}
                          getOptionLabel={(option) =>
                            option
                              ? option.firstName + " " + option.lastName
                              : ""
                          }
                          renderOption={(props, option) => (
                            <li {...props} key={uuidv4()}>
                              {option.firstName + " " + option.lastName}
                            </li>
                          )}
                          identifier={"employeeGuid"}
                          disabled={
                            isEmployee === Mnemonics.ResourceTypes.External ||
                            readOnly ||
                            false
                          }
                          value={employeeV}
                          onChange={(e, value) => {
                            setFieldValue(team.name, null);
                            setFieldValue(employee.name, value);
                          }}
                          loading={usersLoading}
                          label={t(employee.label)}
                          name={employee.name}
                          formData={formData}
                          onOpen={() => setEmployeeClicked(true)}
                          showDisplayView={
                            isEmployee === Mnemonics.ResourceTypes.Employee &&
                            manageUsers !== "N" &&
                            employeeV
                          }
                          handleClickView={() =>
                            window.open(
                              my_employee_form.replace(
                                "/:eName/:eID",
                                `/${encodeURIComponent(
                                  employeeV?.firstName +
                                  " " +
                                  employeeV?.lastName
                                )}/${encodeURIComponent(
                                  employeeV?.employeeGuid
                                )}`
                              )
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        {!fullscreen && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            class="grid-accordion"
            sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}
          >
            <Box sx={{ width: "100%", typography: "body1", marginTop: "15px" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      label={t("taskForm.details")}
                      value="1"
                      sx={{
                        textTransform: "capitalize !important",
                        fontFamily: "Poppins !important",
                      }}
                    />
                    {/* <Tab
                      label={t("taskForm.planning")}
                      value="2"
                      sx={{
                        textTransform: "capitalize !important",
                        fontFamily: "Poppins !important",
                      }}
                    /> */}
                    {/* <Tab
                      label={t("taskForm.comments")}
                      value="5"
                      sx={{
                        textTransform: "capitalize !important",
                        fontFamily: "Poppins !important",
                      }}
                    /> */}
                    {taskGuid && (
                      <Tab
                        label={
                          <Badge
                            badgeContent={attachments ? attachments.length : 0}
                            color="primary"
                            max={100}
                            sx={{
                              ".MuiBadge-badge": {
                                marginRight: "-10px !important",
                              },
                            }}
                          >
                            {t("taskForm.attachments")}
                          </Badge>
                        }
                        value="3"
                        sx={{
                          textTransform: "capitalize !important",
                          fontFamily: "Poppins !important",
                          padding: "12px 20px 12px 16px !important",
                        }}
                      />
                    )}
                    {taskGuid && allowCreateTimeEntryV && (
                      <Tab
                        label={t("taskForm.timeEntries")}
                        value="4"
                        sx={{
                          textTransform: "capitalize !important",
                          fontFamily: "Poppins !important",
                        }}
                      />
                    )}
                  </TabList>
                </Box>
                <TabPanel
                  value="1"
                  sx={{
                   overflowX: "auto !important",
                        maxHeight: "calc(100vh - 300px)",
                    padding: "0 0px 10px 0px !important",
                  }}
                >
                  <Grid container spacing={2} mt={2} sx={{display:"flex", justifyContent:"space-between", overflowX:"hidden !important"}}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={8}
                      xl={8}
                      mt={0}
                      className={
                        modal === true
                          ? "quill-modal quill-divider task-description"
                          : "quill-page quill-divider task-description"
                      }
                    >
                      <div>
                        <MiniTitle
                          style={{
                            margin: "10px 0",
                          }}
                        >
                          {t("taskForm.description")}
                        </MiniTitle>
                        <div
                          className="description-quill"
                          onFocus={() =>
                            setTouched({ ...touched, [description.name]: true })
                          }
                        >
                          <Backdrop
                            sx={{
                              color: "#fff",
                              position: "absolute !important",
                              zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={loadingImage}
                          >
                            <CircularProgress color="inherit" />
                          </Backdrop>
                          <ReactQuill
                            className="regular-quill description-quill"
                            ref={quillRef}
                            name="body"
                            value={descriptionV}
                            onChange={handleQuillChange}
                            readOnly={
                              isEmployee === Mnemonics.ResourceTypes.External
                            }
                            modules={modules}
                            formats={formats}
                            placeholder="Description"
                          // onKeyUp={handleKeyUp}
                          />
                          {/* {showSuggestions && (
                        <div
                          className="suggestion-list"
                          style={{ top: position.top + 40, left: position.left }}
                        >
                          {mentionSuggestions.map((suggestion) => (
                            <div
                              key={suggestion.id}
                              className="suggestion-item"
                              onClick={() => handleSuggestionClick(suggestion, '@')}
                            >
                              {suggestion.value}
                            </div>
                          ))}
                        </div>
                      )} */}
                        </div>
                        <div
                          className="tooltip-link"
                          style={{
                            fontFamily: "Poppins",
                            lineHeight: "21px",
                            backgroundColor: "#ffffff",
                            color: "#000",
                            borderRadius: "2px",
                            opacity: "0.8",
                            boxShadow: `0px 1px 4px ${colors.box_shadow_color}`,
                            padding: "13px !important",
                            position: "absolute",
                            top: "-10px",
                            right: 0,
                          }}
                        >
                          {t("tooltip.link")}
                        </div>
                        <div
                          className="tooltip-clean"
                          style={{
                            fontFamily: "Poppins",
                            lineHeight: "21px",
                            backgroundColor: "#ffffff",
                            color: "#000",
                            borderRadius: "2px",
                            opacity: "0.8",
                            boxShadow: `0px 1px 4px ${colors.box_shadow_color}`,
                            padding: "13px !important",
                            position: "absolute",
                            top: "-10px",
                            right: 0,
                          }}
                        >
                          {t("tooltip.clean")}
                        </div>
                        <div
                          className="tooltip-minimize"
                          style={{
                            fontFamily: "Poppins",
                            lineHeight: "21px",
                            backgroundColor: "#ffffff",
                            color: "#000",
                            borderRadius: "2px",
                            opacity: "0.8",
                            boxShadow: `0px 1px 4px ${colors.box_shadow_color}`,
                            padding: "13px !important",
                            position: "absolute",
                            top: "-10px",
                            right: 0,
                          }}
                        >
                          {t("tooltip.minimize")}
                        </div>
                        <div
                          className="tooltip-maximize"
                          style={{
                            fontFamily: "Poppins",
                            lineHeight: "21px",
                            backgroundColor: "#ffffff",
                            color: "#000",
                            borderRadius: "2px",
                            opacity: "0.8",
                            boxShadow: `0px 1px 4px ${colors.box_shadow_color}`,
                            padding: "13px !important",
                            position: "absolute",
                            top: "-10px",
                            right: 0,
                          }}
                        >
                          {t("tooltip.maximize")}
                        </div>
                        {taskGuid && (
                          <>
                            <MiniTitle
                              style={{
                                margin: "20px 0 0 0",
                              }}
                            >
                              {t("taskForm.comments")}
                            </MiniTitle>
                            <Comment
                              employees_list={employees_list}
                              attachments={attachments}
                              reload={reload}
                              taskGuid={taskGuid}
                              showModal={showModal}
                              wait={wait}
                            />
                          </>
                        )}
                      </div>
                    </Grid>
                   
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      xl={4}
                      sx={{
                        paddingTop: { lg: "32px !important", xl: "32px !important", md: "20px", xs: "20px", sm: "20px" },
                        // overflowY: "auto !important",
                        // overflowX: "auto !important",
                      }}
                    >
                      {DisplayTimeFields()}
                    </Grid>
                   
                  </Grid>
                </TabPanel>
                {/* <TabPanel
                  value="2"
                  sx={{
                    padding: "0 24px !important",
                    overflowY: "auto !important",
                    overflowX: {xs: "auto !important", sm: "auto !important", md: "auto !important", lg: "hidden !important", xl: "hidden !important"},
                  }}
                > */}
                {/* <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ padding: "0 !important" }}
                    >
                      <Typography
                        sx={{
                          fontStyle: "italic",
                          fontWeight: 600,
                          fontSize: "14px",
                        }}
                      >
                        {t("taskForm.planning")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingLeft: "15px !important" }}> */}
                {/* <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {DisplayTimeFields()}
                    </Grid>
                  </Grid> */}
                {/* </AccordionDetails>
                  </Accordion> 
                </TabPanel>*/}
                {/* <TabPanel
                  value="5"
                  sx={{
                    padding: "0 24px !important",
                    overflowY: "auto !important",
                  }}
                >
                  <Comment attachments={attachments} reload={reload} taskGuid={taskGuid}/>
                </TabPanel> */}
                <TabPanel
                  value="3"
                  sx={{
                    padding: "0 24px !important",
                    overflowY: "auto !important",
                  }}
                >
                  <Attachment
                    attachments={attachments}
                    reload={reload}
                    taskGuid={taskGuid}
                  />
                </TabPanel>
                {taskGuid && allowCreateTimeEntryV && (
                  <TabPanel
                    value="4"
                    sx={{
                      padding: "0 24px !important",
                      overflowY: "auto !important",
                      height: "450px !important",
                    }}
                  >
                    <TimeEntriesList
                      taskID={taskGuid}
                      taskName={titleV}
                      selectedTask={selectedTask}
                      handleFormChanged={handleFormChanged}
                      refetchTask={refetchTask}
                      handleFetchTree={handleFetchTree}
                      billable={billableV}
                    />
                  </TabPanel>
                )}
              </TabContext>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default TaskMain;
