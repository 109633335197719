import React, { useState, useEffect } from "react";

//routing
import { Link } from "react-router-dom";

import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  TablePagination,
  TableSortLabel,
  Box,
  Menu,
  MenuItem,
  Grid,
  Typography,
} from "@mui/material";

//utils
import { visuallyHidden } from "@mui/utils";

import { createTheme, ThemeProvider } from "@mui/material/styles";

//translation
import { useTranslation } from "react-i18next";

import colors from "../../../assets/theme/light/colors";

import "./style.css";

//components
import IALoadingButton from "../../IAButtons/IALoadingButton";
import TableColumnsDnD from "../../DragAndDrops/TableColumnsDnD";
import Tooltip from "../../../components/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddIcon from "@mui/icons-material/Add";
import { EditColumnsIcon, RefreshIcon, TableCollapseIcon, TableExpandIcon, TaskIcon } from "../../Icon";

import ColumnResize from "react-table-column-resizer";

export default function CollapsibleTable(props) {
  const {
    rows,
    columns,
    page,
    setPage,
    order,
    orderBy,
    search,
    setOrder,
    setOrderBy,
    defaultFilters,
    rowsPerPage,
    setRowsPerPage,
    numberRecords,
    fetchTableData,
    loading,
    refetch,
    tableMnemonic,
    generateTaskRoute,
    generateTicketRoute,
    removeRefresh,
    customButtons,
    hideEditColumns,
    setOpenModal,
    modalType,
    setTaskID,
    setTaskName,
    setTicketID,
    setTicketName,
    readOnly,
    edit,
  } = props;

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const [expanded, setExpanded] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [expandedIDs, setExpandedIDs] = useState([]);

  //opens Column Sort Change
  const [changeColumns, setChangeColumns] = useState(false);
  const [colSpan, setColSpan] = useState(0);

  const { pure_white } = colors;

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            padding: "10px !important",
            borderRadius: "2px !important",
            backgroundColor: "white !important",
            boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
            color: "black",
          },
        },
      },
    },
  });

  useEffect(() => {
    let count = 0;
    columns.forEach((col) => {
      if (col.visible) count++;
    });
    setColSpan(count);
  }, []);

  const handleExpand = (id) => {
    setExpanded(true);
    if (!expandedIDs.includes(id)) setExpandedIDs([...expandedIDs, id]);
  };

  const handleCollapse = (id) => {
    const updatedExpandedIDs = expandedIDs.filter(
      (expandedID) => expandedID !== id
    );
    if (updatedExpandedIDs.length === 0) setExpanded(false);
    setExpandedIDs(updatedExpandedIDs);
  };

  function handleExpandAll() {
    const tmpArr = [];
    rows.forEach((row) => {
      setExpanded(true);
      tmpArr.push(row.id);
    });
    setExpandAll(true);
    setExpandedIDs(tmpArr);
  }

  function handleCollapseAll() {
    setExpandAll(false);
    setExpanded(false);
    setExpandedIDs([]);
  }

  const handleRequestSort = (event, property) => {
    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(property);
    fetchTableData(
      page,
      rowsPerPage,
      order === "asc" ? "desc" : "asc",
      property,
      search,
      defaultFilters
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchTableData(
      newPage,
      parseInt(event.target.value, 10),
      order,
      orderBy,
      search,
      defaultFilters
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchTableData(
      0,
      parseInt(event.target.value, 10),
      order,
      orderBy,
      search,
      defaultFilters
    );
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        className="account-menu"
        MenuListProps={{ sx: { py: 0, width: "150px" } }}
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        style={{ textAlign: "center" }}
      >
        <MenuItem
          className="menu-item-hover"
          sx={{ paddingTop: "15px" }}
          onClick={() => {
            setTicketID(null);
            setTicketName(null);
            setOpenModal(true);
            edit.current = false;
            modalType.current = "phase";
          }}
        >
          <span style={{ fontFamily: "poppins", fontSize: "14px" }}>
            {t("phase")}
          </span>
        </MenuItem>
        <MenuItem
          className="menu-item-hover"
          onClick={() => {
            setTaskID(null);
            setTaskName(null);
            setOpenModal(true);
            edit.current = false;
            modalType.current = "task";
          }}
        >
          <span style={{ fontFamily: "poppins", fontSize: "14px" }}>
            {t("task")}
          </span>
        </MenuItem>
        <MenuItem
          className="menu-item-hover"
          onClick={() => {
            setTaskID(null);
            setTaskName(null);
            setOpenModal(true);
            edit.current = false;
            modalType.current = "subtask";
          }}
        >
          <span style={{ fontFamily: "poppins", fontSize: "14px" }}>
            {t("subtask")}
          </span>
        </MenuItem>
      </Menu>
    );
  };

  function Row(props) {
    const { row, collapsible } = props;
    const { tasks } = row;

    return (
      <React.Fragment>
        <TableRow className={collapsible ? "collapse-row" : "no-collapse-row"}>
          <TableCell
            className={collapsible ? "collapse-cell" : "no-collapse-cell"}
            sx={{
              width: "48px !important",
              height: "30px !important",
              position: "sticky",
              left: 0,
              zIndex: 3,
              backgroundColor: pure_white,
            }}
          >
            {" "}
            {collapsible && (
              <IconButton aria-label="expand row" size="small">
                {expanded && expandedIDs.length === 0 ? (
                  <KeyboardArrowUpIcon onClick={() => handleCollapse(row.id)} />
                ) : expanded && expandedIDs.includes(row.id) ? (
                  <KeyboardArrowUpIcon onClick={() => handleCollapse(row.id)} />
                ) : (
                  <KeyboardArrowDownIcon onClick={() => handleExpand(row.id)} />
                )}
              </IconButton>
            )}
          </TableCell>
          {columns &&
            columns.map((col, index) => {
              return col.visible === true &&
                col.link != null &&
                col.link === true ? (
                <>
                  <TableCell
                    key={index}
                    component={Link}
                    to={
                      collapsible
                        ? generateTicketRoute(row)
                        : generateTaskRoute(row)
                    }
                    className={
                      collapsible
                        ? "collapsible-link-table-cell"
                        : "no-collapsible-link-table-cell"
                    }
                    sx={{
                      width: col.width,
                      height: "30px !important",
                      textDecoration: "none",
                      position: col.sticky ? "sticky" : "relative",
                      backgroundColor: col.sticky ? pure_white : "transparent",
                      zIndex: col.sticky ? 2 : 1,
                      left: col.sticky ? "48px" : "unset",
                      maxWidth: col.width ? col.width : "initial",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    onClick={(e) => {
                      if (collapsible) {
                        modalType.current = "phase";
                        setTicketID(row.ticketGuid);
                        setTicketName(row.title);
                        setOpenModal(true);
                        edit.current = true;
                      } else {
                        modalType.current = "task";
                        setTaskID(row.taskGuid);
                        setTaskName(row.title);
                        setOpenModal(true);
                        edit.current = true;
                      }
                      e.preventDefault();
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {!collapsible && (
                        <span
                          style={{
                            paddingRight: "5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <TaskIcon />
                        </span>
                      )}
                      <span
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {col.renderCell(row)}
                      </span>
                    </div>
                  </TableCell>
                  {/* <td className={`column_resizer_body`} /> */}
                </>
              ) : (
                col.visible === true && (
                  <>
                    <TableCell
                      align={col.align}
                      className={
                        collapsible
                          ? "collapsible-table-cell"
                          : "no-collapsible-table-cell"
                      }
                      sx={{
                        width: col.width,
                        height: "30px !important",
                        position: col.sticky ? "sticky" : "relative",
                        right: index === columns.length - 1 ? 0 : "unset",
                        backgroundColor: col.sticky
                          ? pure_white
                          : "transparent",
                        zIndex: col.sticky ? 2 : 1,
                        left: col.sticky ? 0 : "unset",
                        "& .MuiTableCell-root": {
                          textAlign: col.align ? col.align : "left",
                        },
                        maxWidth: col.width ? col.width : "initial",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {col.renderCell(row)}
                    </TableCell>
                    {/* <td className={`column_resizer_body`} /> */}
                  </>
                )
              );
            })}
        </TableRow>
        {collapsible &&
          expanded &&
          (expandedIDs.length !== 0
            ? expandedIDs.includes(row.id)
            : expandAll) &&
          tasks &&
          tasks.length > 0 && (
            <TableRow>
              <TableCell
                className="spanned-column"
                colSpan={colSpan + 1}
                sx={{
                  padding: "0 !important",
                  borderBottom: "none",
                  height: "30px !important",
                }}
              >
                <Table size="small" sx={{ tableLayout: "fixed" }}>
                  <TableBody>
                    {tasks &&
                      tasks.length > 0 &&
                      tasks.map((task) => (
                        <Row key={task.id} row={task} collapsible={false} />
                      ))}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          )}
      </React.Fragment>
    );
  }

  return (
    <>
      {changeColumns ? (
        <TableColumnsDnD
          TableColumns={columns}
          SortID={orderBy}
          SortDirection={order}
          ChangeOrder={(v) => setOrder(v)}
          ChangeOrderBy={(v) => setOrderBy(v)}
          Table={tableMnemonic}
          handleClose={() => setChangeColumns(false)}
          refetch={refetch}
          refetchTableData={() =>
            fetchTableData(
              page,
              rowsPerPage,
              order,
              orderBy,
              search,
              defaultFilters
            )
          }
        />
      ) : null}
      <div className="collapsible-table-bar">
        {
          <div style={{ display: "flex", alignItems: "center" }}>
            {!expandAll && (
              <ThemeProvider theme={theme}>
                <Tooltip title={t("table.ExpandAll")}>
                  <IconButton onClick={() => handleExpandAll()}>
                    <TableExpandIcon color="#000000" />
                  </IconButton>
                </Tooltip>
              </ThemeProvider>
            )}
            {expandAll && (
              <ThemeProvider theme={theme}>
                <Tooltip title={t("table.CollapseAll")}>
                  <IconButton onClick={() => handleCollapseAll()}>
                    <TableCollapseIcon color="#000000" />
                  </IconButton>
                </Tooltip>
              </ThemeProvider>
            )}
            {!readOnly && (
              <Grid
                item
                className="new-work-item-button"
                sm={12}
                md={3}
                lg={3}
                xl={3}
                sx={{ display: "flex", alignItems: "center", padding: "5px" }}
                onClick={handleOpen}
              >
                <AddIcon size={20} sx={{ color: "var(--color-blue-main)" }} />
                <Typography
                  sx={{
                    padding: "0 5px",
                    color: "var(--color-gradients-dark-main)",
                    fontSize: "15px",
                  }}
                >
                  {t("plan.new.plan.item")}
                </Typography>
                <KeyboardArrowDownIcon
                  size={20}
                  sx={{ color: "var(--color-gradients-dark-main)" }}
                />
              </Grid>
            )}
            {!readOnly && renderMenu()}
          </div>
        }
        <div>
          {!removeRefresh && (
            <ThemeProvider theme={theme}>
              <Tooltip title={t("table.RefreshBtn")}>
                <IconButton
                  onClick={() =>
                    fetchTableData(
                      page,
                      rowsPerPage,
                      order,
                      orderBy,
                      search,
                      defaultFilters
                    )
                  }
                >
                  <RefreshIcon color="#000000" />
                </IconButton>
              </Tooltip>
            </ThemeProvider>
          )}
          {!hideEditColumns && (
            <ThemeProvider theme={theme}>
              <Tooltip title={t("table.EditColumns")}>
                <IconButton onClick={() => setChangeColumns((prev) => !prev)}>
                  <EditColumnsIcon color="#000000" />
                </IconButton>
              </Tooltip>
            </ThemeProvider>
          )}
          {customButtons &&
            customButtons.map((button, index) => {
              if (button.condition)
                if (button.icon)
                  return (
                    <ThemeProvider theme={theme}>
                      <Tooltip title={t(button.label)}>
                        <IconButton
                          key={index}
                          onClick={() => button.handleClick()}
                        >
                          {button.iconButton}
                        </IconButton>
                      </Tooltip>
                    </ThemeProvider>
                  );
                else
                  return (
                    <IALoadingButton
                      key={index}
                      label={t(button.label)}
                      className="new-record-btn"
                      dark
                      error={button.error}
                      startIcon={button.startIcon}
                      loading={button.loading}
                      disabled={button.disabled}
                      onClick={() => {
                        button.handleClick();
                      }}
                    />
                  );
              else return null;
            })}
        </div>
      </div>
      {!loading && (
        <Paper
          className="collapsible-table"
          sx={{ width: "100%", mb: 2, borderRadius: "5px" }}
        >
          <TableContainer
            className={"collapsible-table-height"}
            sx={{ borderRadius: "5px" }}
          >
            <Table size="small" sx={{ tableLayout: "fixed" }} stickyHeader>
              <TableHead>
                <TableRow className="collapsible-table-header">
                  <TableCell
                    sx={{
                      width: "48px",
                      position: "sticky",
                      left: 0,
                      zIndex: 4,
                      backgroundColor: pure_white,
                    }}
                  />
                  {columns &&
                    columns.map((headCell, index) => (
                      <React.Fragment key={headCell.id}>
                        {headCell.visible === true && (
                          <>
                            <TableCell
                              sx={{
                                width: headCell.width,
                                justifyContent: headCell.align
                                  ? headCell.align
                                  : "left",
                                left: headCell.sticky ? "48px" : "inherit",
                                zIndex: headCell.sticky ? 3 : 2,
                              }}
                              align={headCell.align ? headCell.align : "left"}
                              sortDirection={
                                orderBy === headCell.id ? order : false
                              }
                            >
                              {headCell.id === "PercentageComplete" ? (
                                <Tooltip title={t("progressBar.tooltip")}>
                                  <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={
                                      orderBy === headCell.id ? order : "asc"
                                    }
                                    onClick={(event) =>
                                      handleRequestSort(event, headCell.id)
                                    }
                                    sx={{
                                      paddingLeft:
                                        headCell.align !== "left"
                                          ? "1.5em"
                                          : "0em",
                                      fontFamily: "poppins  !important",
                                      whiteSpace: "break-spaces",
                                    }}
                                  >
                                    {t(headCell.label)}
                                    {orderBy === headCell.id ? (
                                      <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                          ? "sorted descending"
                                          : "sorted ascending"}
                                      </Box>
                                    ) : null}
                                  </TableSortLabel>
                                </Tooltip>
                              ) : (
                                <TableSortLabel
                                  active={orderBy === headCell.id}
                                  direction={
                                    orderBy === headCell.id ? order : "asc"
                                  }
                                  onClick={(event) =>
                                    handleRequestSort(event, headCell.id)
                                  }
                                  sx={{
                                    paddingLeft:
                                      headCell.align !== "left"
                                        ? "1.5em"
                                        : "0em",
                                    fontFamily: "poppins  !important",
                                    whiteSpace: "break-spaces",
                                  }}
                                >
                                  {t(headCell.label)}
                                  {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                      {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                    </Box>
                                  ) : null}
                                </TableSortLabel>
                              )}
                            </TableCell>
                          </>
                        )}
                      </React.Fragment>
                    ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.map((row) => (
                  <Row key={row.id} row={row} collapsible={true} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={numberRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  );
}
