import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import {
  GET_ALL_EMPLOYEE_REFERENCES,
  DELETE_EMPLOYEE_REFERENCES_LIST,
} from "../../../../graphql/employeeReference";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local, {UTC2Time} from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
const EmployeeReferencesList = ({ fromTenant = false }) => {
  const { tID, tName, eID, eName } = useParams();

  const {
    new_employee_reference,
    employee_reference_form,
    my_employee_reference_form,
    my_new_employee_reference,
  } = urls;

  const { userRoles } = useAuth();

  const {manageUsers} = userRoles

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_EMPLOYEE_REFERENCES_LIST,
    "DeleteListOfEmployeeReferences"
  );

  const filtersObject = [
    {
      type: "text",
      label: "employeeReference.list.filters.overtimeReference",
      name: "name",
    },
  ];

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables["EmployeeReferences"],
          userID: eID,
        },
      },
    },
    {
      onCompleted: (response) => {
        const col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay, UTC2Time)
          );

          if (parsed.columns) setColumns(parsed.columns);

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: GetAllEmployeeRefs, loading } = useLazyQueryAuth(
    GET_ALL_EMPLOYEE_REFERENCES,
    "GetAllEmployeeReferences"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      GetAllEmployeeRefs(
        {
          variables: {
            model: {
              userID: eID,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData, re]);

  const rows =
    data &&
    data.GetAllEmployeeReferences &&
    data.GetAllEmployeeReferences.employeeReferencesOutput &&
    data.GetAllEmployeeReferences.employeeReferencesOutput.employeeReferenceList
      ? data.GetAllEmployeeReferences.employeeReferencesOutput.employeeReferenceList
      : [];
  const numberOfRecords =
    data &&
    data.GetAllEmployeeReferences &&
    data.GetAllEmployeeReferences.employeeReferencesOutput &&
    data.GetAllEmployeeReferences.employeeReferencesOutput.totalNumberOfRecords
      ? data.GetAllEmployeeReferences.employeeReferencesOutput.totalNumberOfRecords
      : 0;

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            list: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("employeeReference.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0)
      }
    );
  };

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: fromTenant
      ? "employeeReference.list.search.label.fromTenant"
      : "employeeReference.list.search.label",
    refetch,
    removeFilters:true,
    tableMnemonic: Mnemonic.Tables["EmployeeReferences"],
    filtersObject,
    generateRoute: (row) =>
      fromTenant
        ? employee_reference_form
            .replace("/:tName/:tID", `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`)
            .replace("/:eName/:eID", `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`)
            .replace(
                "/:rID",
                `/${encodeURIComponent(
                row?.employeeReferenceGuid
                )}`
            )
        : my_employee_reference_form
            .replace("/:eName/:eID", `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`)
            .replace(
              "/:rID",
              `/${encodeURIComponent(
                row?.employeeReferenceGuid
              )}`
            ),
    newText: "employeeReference.list.new.label",
    newLink: fromTenant
      ? new_employee_reference.replace("/:tName/:tID", `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`)
      .replace("/:eName/:eID", `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`)
      : my_new_employee_reference
      .replace("/:eName/:eID", `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`),
    deleteText: "employeeReference.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    hideEditColumns: true,
    hideSearchBar: true,
    hideFilters: true,
    readOnly: manageUsers!=="M"
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        
        <DataTable {...pagination} fromEmployee identifier={"employeeReferenceGuid"} hasCheckbox />
       
      )}
    </div>
  );
};

export default EmployeeReferencesList;
