import React, { useEffect, useState, useRef } from "react";

//routing
import { useNavigate, useParams } from "react-router-dom";

//schemas
import form from "./schema/form";
import { getValidation } from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import {
  Box,
  Grid,
  CircularProgress,
  Popper,
  Grow,
  MenuList,
  MenuItem,
  ClickAwayListener,
  ButtonGroup,
  Button,
  Paper,
  IconButton,
  Menu,
  Divider,
  ListItemText,
  ListItemIcon,
  Popover,
  Typography,
  Tooltip,
} from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import TaskMain from "./components/taskMain";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";

import { formatDateTimeToString, formatDateTimeZone } from "../../../../UTC2Local";
import urls from "../../../../urls";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import {
  GET_TASK_BY_ID,
  CREATE_TASK,
  UPDATE_TASK,
  DELETE_TASK,
  UPDATE_TASK_QUICK,
  GET_TASK_SUBSCRIPTION,
} from "../../../../graphql/task";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../graphql/project";
import { GET_ALL_TASKS_DROPDOWN } from "../../../../graphql/task";

import Mnemonic from "../../../../Mnemonics.json";
import TaskTabsModal from "../../../../components/Modal/TaskTabsModal";
import TimeEntryDrawer from "../../Timesheets/Timesheets-Form/components/TimeEntryDrawer";
import PaperComponent from "../../../../components/Modal/PaperComponent";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  CloseIcon,
  DeleteIcon,
  MoveIcon,
  ProjectIcon,
  RefreshIcon,
  SubtaskIconOutlined,
  VerticalActionsIcon,
} from "../../../../components/Icon";
import colors from "../../../../assets/theme/light/colors";

import ConfirmationModal from "../../../../components/Modal/ConfirmationModal";
import CustomButton from "../../../../components/CustomButton";
import MoveTaskModal from "./components/MoveTaskModal";
import { LoadingButton } from "@mui/lab";
import hasEmptyTags from "../../../../utils/hasEmptyTags";
import { useCookies } from "react-cookie";
import { GET_ALL_TASK_STATUSES } from "../../../../graphql/taskStatus";
import AutoComplete from "../../../../components/AutoComplete";
import { GET_ALL_TICKETS_DROPDOWN } from "../../../../graphql/ticket";
import apiCall from "../../../../hooks/useRest";
import { LOGOUT_USER } from "../../../../graphql/user";
import { Tree } from "antd";

import "./style.css";
import { saveToken } from "../../../../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";
import { useSubscription } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
import { day_month_year } from "../../../../utils/timezones";

const backendURL = process.env.REACT_APP_REST_URL;

const TaskForm = ({
  formKey,
  outSide = false,
  fromPlan = false,
  fromTenant = false,
  fromProject = false,
  fromDashboard = false,
  fromTree = false,
  showModal = false,
  subtask = false,
  projectID,
  projectName,
  taskID,
  handleChangeTask,
  handleChangeTaskName,
  handleChangeTaskObject,
  handleChangeOpenTimeEntry,
  refetchData,
  // handleEnableRefetch,
  handleCloseModal,
  parent = null,
  resetFields,
  modal,
  type,
  openTimeEntry,
  refetchFct,
  handleChangeProject,
  ticket_status_list,
  selectedTaskObject,
  handleReset,
  handleSetParent = () => {},
  handleFormChanged = () => {},
  fetchTickets,
  disabledFields,
  handleAddSubtask = () => {},
  handleFetchTree = () => {},
  wait = false,
  handleSelectFirstTask = () => {},
  data,
  updateData,
  quickAdd,
  handleEnableRefetch,
  newSubtask,
  handleResetFields = () => {},
  keyValue,
  handleNewSubtask = () => {},
  edit,
  handleEditMode = () => {},
  setType = () => {},
}) => {
  const { tkID, tskName, tskID, pID, pName } = useParams();

  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies();

  const { t } = useTranslation();

  const userTimezone = Intl.DateTimeFormat() .resolvedOptions() .timeZone.split("/")[0];

  const dateFormat = day_month_year.includes(userTimezone) === true ? "DD-MM-YYYY" : "MM-DD-YYYY";

  const navigate = useNavigate();

  const { my_projects_form, my_project_tickets_form } = urls;

  const [createMode, setCreateMode] = useState(false);

  const options = [t("taskForm.save"), t("taskForm.save.close")];

  const {
    jwtToken,
    logout,
    userRoles,
    isEmployee,
    storeUserGuid,
    projectPermissions,
    storeUserTenant,
  } = useAuth();

  const [openModal, setOpenModal] = useState(false);

  const [projectGuid, setProjectGuid] = useState(null);
  const [focused, setFocused] = useState(null);

  const [attachments, setAttachments] = useState([]);

  const { manageTimeEntry, isAdmin } = userRoles;

  const { formField } = form;

  // useEffect(() => {
  //   if (
  //     tskID !== taskID &&
  //     tskID &&
  //     taskID &&
  //     handleChangeTaskObject !== undefined
  //   )
  //     handleChangeTaskObject({ taskGuid: tskID, title: tskName });
  //   else if (tskID !== taskID && tskID && handleChangeTaskObject !== undefined)
  //     handleChangeTaskObject({ taskGuid: tskID, title: tskName });
  //   else if (tskID !== taskID && taskID && handleChangeTaskObject !== undefined)
  //     handleChangeTaskObject({ taskGuid: taskID, title: tskName });
  // }, [tskID, taskID]);

  const {
    title,
    description,
    employee,
    team,
    status,
    project,
    ticket,
    EstimatedStartDate,
    EstimatedEndDate,
    EstimatedTime,
    ActualStartDate,
    synced,
    externalReference,
    ActualEndDate,
    ActualTime,
    ActualBillableTime,
    ActualNonBillableTime,
    priority,
    area,
    task,
    allowCreateTimeEntry,
    billable,
  } = formField;

  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState(initialValues);
  const [taskId, setTaskId] = useState("");
  const [ticketArea, setTicketArea] = useState(null);
  const [parentTask, setParentTask] = useState(parent);
  const task_guid = useRef("");
  const editMode = useRef(edit);
  const [tenantID, setTenantID] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedExternalReference, setSelectedExternalReference] =
    useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [selectedTask, setSelectedTask] = useState(
    selectedTaskObject ? selectedTaskObject : null
  );
  const [percentage, setPercentage] = useState("");
  const [button1Submitting, setButton1Submitting] = useState(false);
  const [button2Submitting, setButton2Submitting] = useState(false);
  const [openTimeEntryModal, setOpenTimeEntryModal] = useState(false);
  const [timeEntryEditMode, setTimeEntryEditMode] = useState(false);
  const [subtaskValidation, setSubtaskValidation] = useState(false);
  const [validation, setValidation] = useState(false);
  const [timeEntryGuid, setTimeEntryGuid] = useState(null);
  const [mediaList, setMediaList] = useState([]);
  const saveAndClose = useRef(false);
  const [open, setOpen] = useState(false);
  const [fetchedTree, setFetchedTree] = useState(false);
  const [refetchFlag, setRefetchFlag] = useState(false);
  const [treeData, setTreeData] = useState(null);
  const [updatedModifiedAt, setUpdatedModifiedAt] = useState(null);
  const [modifiedAt, setModifiedAt] = useState(null);

  const [mentionsList, setMentionsList] = useState([]);

  const [openMoveTaskDialog, setOpenMoveTaskDialog] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openRefreshModal, setOpenRefreshModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorTreeEl, setAnchorTreeEl] = useState(null);
  const [modified, setModified] = useState(false);
  const anchorRef = useRef(null);
  const openMenu = Boolean(anchorEl);
  const openTree = Boolean(anchorTreeEl);
  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);
  const formType = useRef(type ? type : "task");
  const newKeyValue = useRef(null);

  useEffect(() => {
    // if ((resetFields === true || quickAdd === true) && !editMode.current) {
    if ((resetFields === true) && !editMode.current) {
      setFormData(initialValues)
      setSubtaskValidation(
        (parentTask && parentTask?.taskGuid !== undefined) ||
          subtask ||
          type === "subtask" 
      );
      let f = document.getElementById("form-task");
      f?.reset();
      task_guid.current = null;
      editMode.current = false;
      handleEditMode(false);
      setSelectedTask(null);
      handleChangeTask(null);
      if (!newSubtask) { 
        setParentTask(null);
        formikRef.current?.setFieldValue(task.name, null);
      } else {
        formikRef.current?.setFieldValue(task.name, selectedTask);
        if (selectedTask?.project && newSubtask) { 
          setSelectedProject(selectedTask?.project);
          formikRef.current?.setFieldValue(project.name, selectedTask?.project);
        } else if (parentTask?.project && newSubtask) { 
          setSelectedProject(parentTask?.project);
          formikRef.current?.setFieldValue(project.name, parentTask?.project);
        }
      }
      if (type === "subtask") formikRef.current?.setFieldValue(ticket.name, selectedTask);
      if (handleResetFields) handleResetFields(false);
      setUpdatedModifiedAt(null);
      setModifiedAt(null);
      setModified(false);
    }
  }, [resetFields, quickAdd, formKey]);

  // useEffect(() => {
  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");
  const updateToken = (updatedToken) => {
    if (updatedToken) dispatch(saveToken(updatedToken));
  };

  useSubscription(GET_TASK_SUBSCRIPTION, {
    fetchPolicy: "no-cache",
    variables: {
      id: taskID || tskID || task_guid.current,
    },
    onData: ({ data }) => {
      const key = data?.data?.checkTaskUpdatedSubscription?.taskView?.key;
      newKeyValue.current = key;
      setUpdatedModifiedAt(
        data?.data?.checkTaskUpdatedSubscription?.taskView?.task?.modifiedAt
      );
      if (keyValue === key)
        setModifiedAt(
          data?.data?.checkTaskUpdatedSubscription?.taskView?.task?.modifiedAt
        );
      // else setModified(false);
    },
  });

  async function getTaskTree() {
    setFetchedTree(true);
    // const parsedToken = parseJwt(jwtToken);
    const response = await apiCall(
      backendURL +
        `/Task/GetSingleTaskTree/${taskID || tskID || task_guid.current}`,
      "GET",
      null,
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t
    );

    if (response) {
      setTreeData([response?.data]);
    }
    setFetchedTree(false);
  }

  useEffect(() => {
    if (
      (taskID || tskID || task_guid.current) &&
      parentTask &&
      parentTask.parentTaskGuid
    )
      getTaskTree();
  }, [taskID, tskID, task_guid.current]);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenTree = (event) => {
    if (parentTask?.parentTaskGuid) setAnchorTreeEl(event.currentTarget);
  };
  const handleCloseTree = () => {
    setAnchorTreeEl(null);
  };
  const [selectedIndex, setSelectedIndex] = useState(
    localStorage.getItem("SaveButtonPreference")
      ? parseInt(localStorage.getItem("SaveButtonPreference"))
      : 1
  );

  const handleClick = () => {
    selectedIndex === 1
      ? (saveAndClose.current = true)
      : (saveAndClose.current = false);
  };
  const handleMenuItemClick = (event, index, values, actions) => {
    localStorage.setItem("SaveButtonPreference", index);
    setSelectedIndex(index);
    setOpen(false);
    handleSubmit(values, actions, index);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCloseList = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const { data: statusesList, tokenChange: statusWait } = useQueryAuth(
    GET_ALL_TASK_STATUSES,
    "GetTaskStatuses",
    {
      variables: {
        model: {
          tenantID: fromTenant ? (tenantID ? tenantID : null) : storeUserTenant,
          projectID: pID,
        },
      },
      skip: (task_guid.current && !showModal) || (!showModal && !tskID),
      isWait: !task_guid.current || showModal || tskID,
      // skip: !showModal,
      // isWait: showModal
    }
  );

  const ticketStatusList =
    statusesList &&
    statusesList.GetTaskStatuses &&
    statusesList.GetTaskStatuses.tasksStatus
      ? statusesList.GetTaskStatuses.tasksStatus
      : [];
      
  useEffect(() => {
    setSubtaskValidation(
      (parentTask && parentTask?.taskGuid !== undefined) ||
        subtask ||
        type === "subtask" 
    );
  }, [parentTask, subtask, type]);

  useEffect(() => {
    setValidation(getValidation(subtaskValidation)[0]);
  }, [subtaskValidation]);

  useEffect(() => {
    setOpenTimeEntryModal(openTimeEntry);
  }, [openTimeEntry]);

  const {
    data: usersList,
    loading: usersLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
        timeEntryDisabled: true,
        showAll: true,
        // projectID: selectedProject?.projectGuid,
        // checksPermissions: true,
      },
    },
    skip: !showModal || statusWait,
    isWait: showModal,
  });

  const {
    data: projectData,
    loading: projectLoading,
    tokenChange: projectWait,
  } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: fromTenant ? null : storeUserTenant,
        userID: storeUserGuid,
      },
    },
    skip: !showModal || !createMode || statusWait || employeeWait,
    isWait: showModal || createMode,
  });

  const projects_list =
    projectData?.GetAllProjects?.projectsList?.projectViews || [];

  const employees_list =
    usersList &&
    usersList.GetCompanyUsersList &&
    usersList.GetCompanyUsersList.usersList &&
    usersList.GetCompanyUsersList.usersList.employees
      ? usersList.GetCompanyUsersList.usersList.employees
      : [];

  const {
    data: tasksList,
    tokenChange: tasksWait,
    loading: taskLoading,
  } = useQueryAuth(GET_ALL_TASKS_DROPDOWN, "GetTasks", {
    variables: {
      model: {
        tenantID: storeUserTenant,
        enableFilters: false,
        onGoingTasks: false,
        projectID: selectedProject?.projectGuid,
      },
    },
    skip: !showModal || employeeWait || projectWait || !selectedProject || statusWait || !createMode || !((parentTask && parentTask?.taskGuid !== undefined) ||
        subtask ||
        type === "subtask" ),
    isWait: showModal && createMode,
  });

  const task_list =
    tasksList && tasksList.GetTasks && tasksList.GetTasks.tasks
      ? tasksList.GetTasks.tasks
      : [];

  const {
    data: ticketData,
    loading: ticketsLoading,
    tokenChange: ticketWait,
  } = useQueryAuth(GET_ALL_TICKETS_DROPDOWN, "GetAllTickets", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        projectID: selectedProject?.projectGuid,
      },
    },
    skip: !selectedProject || employeeWait || projectWait || tasksWait || statusWait || ((parentTask && parentTask?.taskGuid !== undefined) ||
        subtask ||
        type === "subtask" ), 
    isWait: selectedProject,
  });

  const ticketsList = ticketData?.GetAllTickets?.ticketsList?.tickets || [];
  // const readOnly =
  // fromPlan === true
  //   ? (projectPermissions?.some((x) => x.projectGuid === projectGuid) &&
  //       manageTimeEntry !== "M") ||
  //     manageTimeEntry !== "M"
  //   : parentTask || subtask || type === "subtask" 
  //   ? false
  //   : manageTimeEntry !== "M";

  const readOnly = disabledFields
    ? parentTask?.taskGuid || subtask || type === "subtask" 
      ? false
      : manageTimeEntry !== "M"
      ? true
      : false
    : parentTask?.taskGuid || subtask || type === "subtask" 
    ? false
    : manageTimeEntry !== "M"
    ? true
    : false;

  useEffect(() => {
    if (pID != null && pName != null)
      setSelectedProject({ projectGuid: pID, title: pName });
  }, [pID, pName]);

  useEffect(() => {
    if (projectID != null && projectName != null)
      setSelectedProject({ projectGuid: projectID, title: projectName });
  }, [projectID, projectName]);

  useEffect(() => {
    setCreateMode(!tskName && !tskID && !taskID && !task_guid.current);
    editMode.current = !(!tskName && !tskID && !taskID && !task_guid.current);
  }, [tskName, tskID, taskID, task_guid.current]);

  // useEffect(() => {
  //   if (taskID || tskID || task_guid.current) {
  //     getData();
  //   }
  // }, [taskID, tskID, task_guid.current]); // This useEffect will trigger whenever 'id' changes

  // const getData = () => {
    
  const {
    data: taskData,
    loading: getDataByIDLoading,
    refetch: refetchTask,
    tokenChange: taskWait
  } = useQueryAuth(
    GET_TASK_BY_ID,
    "GetTask",
    {
      variables: { id: taskID || tskID || task_guid.current },
      skip:
        createMode ||
        statusWait || employeeWait || (createMode ? projectWait : false) || (!createMode ? tasksWait : false) ||
        (selectedProject ? employeeWait : false) ||
        (!taskID && !tskID && !task_guid.current) ||
        (task_guid.current && createMode),
      isWait: wait || (!tskID && !taskID && !task_guid.current),
    },
    {
      onCompleted: (response) => {
        const data = response?.GetTask?.taskView?.task || {};
        const taskView = response?.GetTask?.taskView || {};
        setPercentage(data?.percentageComplete);
        setFormData({
          [title.name]: data.title || "",
          [description.name]: data.description || "",
          [status.name]: data.status || null,
          [employee.name]: data.employee || null,
          [team.name]: data.team || null,
          [ticket.name]: data.ticket || null,
          [priority.name]: data.priority || null,
          [project.name]: data.ticket?.project || null,
          [EstimatedStartDate.name]:
            data?.estimatedStartDate == null
              ? null
              : new Date(formatDateTimeZone(data?.estimatedStartDate, false)),
          [EstimatedEndDate.name]:
            data?.estimatedEndDate == null
              ? null
              : new Date(formatDateTimeZone(data?.estimatedEndDate, false)),
          [ActualStartDate.name]:
            data?.actualStartDate == null
              ? null
              : formatDateTimeToString(data.actualStartDate, false, false, dateFormat),
          [ActualEndDate.name]:
            data?.actualEndDate == null
              ? null
              : formatDateTimeToString(data.actualEndDate, false, false, dateFormat),
          [synced.name]: data?.synced || false,
          [externalReference.name]: data?.externalReference || null,
          [EstimatedTime.name]:
            data?.estimatedTime !== null
              ? parseFloat(data?.estimatedTime).toFixed(2)
              : null,
          [ActualTime.name]:
            data?.actualTime !== null
              ? parseFloat(data?.actualTime).toFixed(2)
              : null,
          [ActualBillableTime.name]:
            data?.actualBillableTime !== null
              ? parseFloat(data?.actualBillableTime).toFixed(2)
              : null,
          [ActualNonBillableTime.name]:
            data?.actualNonBillableTime !== null
              ? parseFloat(data?.actualNonBillableTime).toFixed(2)
              : null,
          [allowCreateTimeEntry.name]:
            data?.allowCreateTimeEntry !== null
              ? data?.allowCreateTimeEntry
              : true,
          [billable.name]: data?.billable !== null ? data?.billable : true,
          [area.name]: data?.area || null,
          [task.name]: taskView?.parentTask || null,
        });
        setSelectedTicket(data?.ticket || null);
        setTenantID(data.ticket?.project?.company?.tenant?.tenantGuid);
        setSelectedProject({
          projectGuid: data?.ticket?.project?.projectGuid,
          title: data?.ticket?.project?.title,
        });
        setProjectGuid(data.ticket?.projectGuid);
        setTaskId(data?.id);
        setTicketArea(data?.ticket?.area);
        setParentTask(taskView?.parentTask || null);
        setModifiedAt(taskView?.task?.modifiedAt);
        setUpdatedModifiedAt(taskView?.task?.modifiedAt);
        handleSetParent({
          taskGuid: taskView?.parentTask?.taskGuid,
          title: taskView?.parentTask?.title,
          ticketGuid: taskView?.parentTask?.ticketGuid,
          externalReference: taskView?.parentTask?.externalReference,
          area: taskView?.parentTask?.area,
        });
        setSelectedExternalReference(data?.externalReference || null);
        setSelectedTask(data);
        // if (handleChangeTaskObject) handleChangeTaskObject(data);
        setCreateMode(false);
        // handleResetFields(false);
        // task_guid.current = data.taskGuid;
        editMode.current = true;
        handleEditMode(true);

        setAttachments(data?.taskAttachments || []);

        var tempDiv = document.createElement("div");
        tempDiv.innerHTML = data.description;

        // Get the first span element
        var spanElements = tempDiv.querySelectorAll("span.mention");

        const mentionsArr = [];
        spanElements.forEach((mention) => {
          mentionsArr.push(mention.dataset.id);
        });
        setMentionsList(mentionsArr);
        sessionStorage.removeItem("unsavedChanges");
      },
    }
  );

  const { cfunction: deleteRecord, loading: deleteLoading } = useMutationAuth(
    DELETE_TASK,
    "DeleteTask"
  );

  const { cfunction: editTaskData } = useMutationAuth(UPDATE_TASK, "EditTask");

  const { cfunction: updateTaskQuickData } = useMutationAuth(
    UPDATE_TASK_QUICK,
    "UpdateTaskQuick"
  );

  const { cfunction: createTaskData } = useMutationAuth(
    CREATE_TASK,
    "CreateTask"
  );

  const updateTaskQuick = (
    status,
    priority,
    allowCreateTimeEntry,
    billable
  ) => {
    // handleEnableRefetch(true);
    if (updatedModifiedAt > modifiedAt) setOpenWarningModal(true);
    else {
      updateTaskQuickData(
        {
          variables: {
            model: {
              taskGuid: tskID || taskID || task_guid.current,
              userID: storeUserGuid,
              statusGuid: status,
              priority: priority,
              allowCreateTimeEntry: allowCreateTimeEntry,
              billable: billable,
              key: keyValue,
            },
          },
        },
        (response) => {
          if (
            response["UpdateTaskQuick"].errorCode !== null &&
            response["UpdateTaskQuick"].errorCode !== ""
          ) {
            enqueueSnackbar(t(response["UpdateTaskQuick"].errorMessage), {
              variant: "error",
              autoHideDuration: 5000,
            });
            if (refetchTask) refetchTask();
          } else {
            if (
              response["UpdateTaskQuick"]?.updateTaskQuick
                ?.editTaskQuickResponse === false
            ) {
              enqueueSnackbar(t("projectForm.status.change.warning"), {
                variant: "warning",
                autoHideDuration: 5000,
              });
              setSelectedTask(
                response["UpdateTaskQuick"]?.updateTaskQuick?.task
              );
              sessionStorage.removeItem("unsavedChanges");
            } else {
              enqueueSnackbar(t("projectForm.updatedSuccessfully"), {
                variant: "success",
              });
              sessionStorage.removeItem("unsavedChanges");
              if (handleNewSubtask) handleNewSubtask(false);
              setSelectedTask(
                response["UpdateTaskQuick"]?.updateTaskQuick?.task
              );
              if (refetchData) refetchData();
            }
            handleEnableRefetch();

            setTimeout(() => {
              refetchTask();
            }, 1000);
          }
        },
        (error) => {
          if (refetchTask) refetchTask();
        }
      );
    }
  };

  const handleDelete = () => {
    deleteRecord(
      {
        variables: {
          id: selectedTask?.taskGuid,
        },
      },
      (response) => {
        if (
          response["DeleteTask"].errorCode !== null &&
          response["DeleteTask"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["DeleteTask"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          setOpenConfirmationModal(false);
        } else {
          enqueueSnackbar(t("task.list.delete.success"), {
            variant: "success",
          });
          setOpenConfirmationModal(false);
          handleEnableRefetch();
          // if (refetchFct) refetchFct(selectedProject?.projectGuid || projectID);
          handleSelectFirstTask();
        }
      }
    );
  };

  function updateTaskByGuid(updatedTask, guid) {
    return data?.filter((task) => {
      const hasMatchingGuid = guid === task.task.guid;
      if (hasMatchingGuid) {
        return updatedTask;
      }
      if (task.subtasks) task.subtasks = updateTaskByGuid(task.subtasks, guid);
      return true;
    });
  }

  function CreateTaskData(values, actions, mentionsArr) {
    if (saveAndClose.current === false) setButton1Submitting(true);
    else setButton2Submitting(true);

    var htmlString = values[description.name];
    let updatedDescription = htmlString.replace(
      /<p>/g,
      '<p style="margin:0px">'
    );

    if (hasEmptyTags(updatedDescription)) updatedDescription = "";

    var startDate = values[EstimatedStartDate.name]
      ? new Date(values[EstimatedStartDate.name])
      : null;
    if (startDate) {
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      startDate.setMilliseconds(0);
    }
    createTaskData(
      {
        variables: {
          model: {
            title: values[title.name],
            priority: values[priority.name],
            description: updatedDescription,
            employeeGuid: values[employee.name]?.employeeGuid,
            teamGuid: values[team.name]?.teamGuid,
            taskStatusGuid: values[status.name]?.taskStatusGuid,
            ticketGuid: !tkID ? selectedTicket?.ticketGuid : tkID,
            estimatedStartDate: startDate ? startDate.toISOString() : null,
            estimatedEndDate: values[EstimatedEndDate.name]
              ? new Date(values[EstimatedEndDate.name]).toISOString()
              : null,
            externalReference: values[externalReference.name],
            estimatedStartDateString: new Date(values[EstimatedStartDate.name]).toDateString(),
            allowTimeEntry: values[allowCreateTimeEntry.name],
            billable: values[billable.name],
            estimatedTime: parseFloat(values[EstimatedTime.name]),
            areaGuid: values[area.name]?.areaGuid,
            parentTaskGuid: parentTask?.taskGuid || null,
            userID: storeUserGuid,
            media: mediaList,
            tenantGuid: storeUserTenant,
            mentions: mentionsArr,
          },
        },
      },
      (response) => {
        if (
          response["CreateTask"].errorCode !== null &&
          response["CreateTask"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateTask"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          setButton1Submitting(false);
          setButton2Submitting(false);
        } else {
          const data = response.CreateTask?.task;
          enqueueSnackbar(t("projectForm.createdSuccessfully"), {
            variant: "success",
          });
          editMode.current = true;
          handleEditMode(true);
          task_guid.current = data?.taskGuid;
          handleEnableRefetch(false);
          // if (!outSide) {
          // if (handleChangeTask !== undefined) handleChangeTask(data?.taskGuid);
          // setMediaList([]);
          // if (handleChangeTaskName) handleChangeTaskName(data?.title);
          // // }
          // if (handleReset) handleReset(false);
          sessionStorage.removeItem("unsavedChanges");
          // if (handleNewSubtask) handleNewSubtask(false);
          // if (handleFormChanged) handleFormChanged(false);
          if (handleChangeTaskObject) handleChangeTaskObject(data);
          // // if (refetchFct) refetchFct(data?.ticket?.project?.projectGuid);
          // setSelectedProject({
          //   projectGuid: data?.ticket?.project?.projectGuid,
          //   title: data?.ticket?.project?.title,
          // });
          // if (refetchFct) refetchFct(values[project.name]?.projectGuid);
          // if (
          //   pID != null &&
          //   pName != null &&
          //   !fromDashboard &&
          //   refetchData === undefined
          // )
          //   navigate(
          //     my_project_direct_tasks.replace(
          //       "/:pName/:pID",
          //       `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          //     )
          //   );
          // // else if (
          // //   pID != null &&
          // //   pName != null &&
          // //   !fromDashboard &&
          // //   refetchData !== undefined
          // // )
          // //   refetchData();
          // // else if (refetchData !== undefined && saveAndClose.current) {
          // //   refetchData();
          // // }
          // else if (!fromDashboard && refetchData === undefined)
          //   navigate(project_backlog);
          setTimeout(() => {
            if (saveAndClose.current === true && handleCloseModal) {
              sessionStorage.removeItem("unsavedChanges");
              handleCloseModal();
              // if (refetchData) refetchData();
            }
          }, 200);
          setSelectedTask(data);
        }
        setButton1Submitting(false);
        setButton2Submitting(false);
      },
      (error) => {
        setButton1Submitting(false);
        setButton2Submitting(false);
      }
    );
  }

  function EditTaskData(values, actions, mentionsArr) {
    if (saveAndClose.current === false) setButton1Submitting(true);
    else setButton2Submitting(true);

    var htmlString = values[description.name];
    let updatedDescription = htmlString.replace(
      /<p>/g,
      '<p style="margin:0px">'
    );
    if (hasEmptyTags(updatedDescription)) updatedDescription = "";

    var startDate = values[EstimatedStartDate.name]
      ? new Date(values[EstimatedStartDate.name])
      : null;
    if (startDate) {
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      startDate.setMilliseconds(0);
    }

    editTaskData(
      {
        variables: {
          model: {
            priority: values[priority.name],
            taskId: tskID || taskID || task_guid.current,
            title: values[title.name],
            description: updatedDescription,
            employeeGuid: values[employee.name]?.employeeGuid,
            teamGuid: values[team.name]?.teamGuid,
            taskStatusGuid: values[status.name]?.taskStatusGuid,
            ticketGuid: !tkID ? selectedTicket?.ticketGuid : tkID,
            estimatedStartDate: startDate ? startDate.toISOString() : null,
            estimatedEndDate: values[EstimatedEndDate.name]
              ? new Date(values[EstimatedEndDate.name]).toISOString()
              : null,
              estimatedStartDateString: new Date(values[EstimatedStartDate.name]).toDateString(),

            allowTimeEntry: values[allowCreateTimeEntry.name],
            externalReference: values[externalReference.name],
            estimatedTime: parseFloat(values[EstimatedTime.name]),
            areaGuid: values[area.name]?.areaGuid,
            parentTaskGuid: parentTask?.taskGuid || null,
            userID: storeUserGuid,
            mentions: mentionsArr,
            key: keyValue,
          },
        },
      },
      (response) => {
        if (
          response["EditTask"].errorCode !== null &&
          response["EditTask"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["EditTask"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          setButton1Submitting(false);
          setButton2Submitting(false);
        } else {
          const data = response.EditTask?.task;
          enqueueSnackbar(t("projectForm.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
          setMediaList([]);
          handleEnableRefetch();
          // if (refetchFct) refetchFct(values[project.name]?.projectGuid);
          // if (handleFormChanged) handleFormChanged(false);
          // if (handleReset) handleReset(false);
          // if (handleChangeTaskObject) handleChangeTaskObject(data);
          if (handleNewSubtask) handleNewSubtask(false);
          task_guid.current = data?.taskGuid;
          // if (
          //   pID != null &&
          //   pName != null &&
          //   !fromDashboard &&
          //   refetchData !== undefined
          // )
          //   refetchData();
          // else if (refetchData !== undefined && saveAndClose.current) {
          //   refetchData();
          // }
          setTimeout(() => {
            if (saveAndClose.current === true && handleCloseModal) {
              sessionStorage.removeItem("unsavedChanges");
              handleCloseModal();
              // if (refetchData) refetchData();
            }
          }, 200);
          setSelectedTask(data);
        }
        setButton1Submitting(false);
        setButton2Submitting(false);
      },
      (error) => {
        setButton1Submitting(false);
        setButton2Submitting(false);
      }
    );
  }

  const handleSubmit = (
    values,
    actions,
    index,
    saveAndCloseFlag = true,
    refreshFlag = false
  ) => {
    index === 1
      ? saveAndCloseFlag === false
        ? (saveAndClose.current = false)
        : (saveAndClose.current = true)
      : index === undefined
      ? selectedIndex === 1
        ? (saveAndClose.current = true)
        : (saveAndClose.current = false)
      : (saveAndClose.current = false);
    // handleEnableRefetch(true);

    const fullscreenQuill = document.querySelector(".fullscreen-quill");
    const regularQuill = document.querySelector(".regular-quill");
    const mentionsArr = [];

    if (fullscreenQuill) {
      const mentionFullscreenSpans =
        fullscreenQuill?.querySelectorAll(".mention");
      if (mentionFullscreenSpans)
        mentionFullscreenSpans.forEach((mention) => {
          if (!mentionsList.includes(mention.dataset.id))
            mentionsArr.push(mention.dataset.id);
        });
    }
    if (regularQuill) {
      const mentionRegularSpans = regularQuill?.querySelectorAll(".mention");
      if (mentionRegularSpans)
        mentionRegularSpans?.forEach((mention) => {
          if (!mentionsList.includes(mention.dataset.id))
            mentionsArr.push(mention.dataset.id);
        });
    }
    setMentionsList([...mentionsList, ...mentionsArr]);
    if (refreshFlag === true) setOpenRefreshModal(true);
    else if (updatedModifiedAt > modifiedAt) setOpenWarningModal(true);
    else {
      if (createMode && !editMode.current) {
        CreateTaskData(values, actions, mentionsArr);
      } else {
        EditTaskData(values, actions, mentionsArr);
      }
    }
    // refetch()
  };

  const handleOpenModal = () => {
    handleCloseModal();
    setTimeout(() => {
      setOpenModal(true);
    }, 500);
  };

  useEffect(() => {
    if (taskID === parentTask?.taskGuid) setParentTask(null);
  }, [parentTask]);

  const handleChangeFlag = (touched) => {
    if (touched) sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
    // if (handleFormChanged !== undefined) handleFormChanged(true);
  };

  const handleOK = () => {
    setOpenStatusModal(false);
    enqueueSnackbar(t("projectForm.updatedSuccessfully"), {
      variant: "success",
    });
    sessionStorage.removeItem("unsavedChanges");
  };

  const handleRefresh = () => {
    // EditTaskData(formikValuesRef.current, null, mentionsList);
    refetchTask();
    formikRef.current?.setValues(formData);
    // setRefetchFlag(!refetchFlag);
    // setModified(true);
    handleCancel();
  };

  const handleCancel = () => {
    if (openWarningModal) setOpenWarningModal(false);
    if (openRefreshModal) setOpenRefreshModal(false);
  };

  const customModalButtons = () => (
    <>
      <CustomButton onClick={handleDelete} label={t("dialog.yes")} />
      <div>
        <CustomButton
          onClick={() => setOpenConfirmationModal(false)}
          label={t("dialog.cancel")}
        />
      </div>
    </>
  );

  const customStatusModalButtons = () => (
    <>
      <CustomButton onClick={handleRefresh} label={t("dialog.refresh")} />
      <div>
        <CustomButton onClick={handleCancel} label={t("dialog.cancel")} />
      </div>
    </>
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === "s" && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const projectDisabled =
    // !createMode ||
    // selectedProject ||
    isEmployee === Mnemonic.ResourceTypes.External || readOnly;

  const styleAutocomplete = (disabled) => {
    return {
      ".MuiOutlinedInput-root": {
        padding: "0 !important",
      },
      "& .MuiFormLabel-root": {
        fontSize: "1.02rem !important",
        fontWeight: "500 !important",
        marginTop: "-10px !important",
        color: `${colors.light_logo_blue} !important`,
        fontFamily: `'Poppins' !important`,
      },
      "& .MuiInputBase-input": {
        fontSize: "1.02rem !important",
        lineHeight: "1.375rem !important",
        fontWeight: "500 !important",
        color: `${colors.light_logo_blue} !important`,
        fontFamily: `'Poppins' !important`,
        paddingBottom: focused ? "initial" : "13px !important",
      },
      "& .MuiInputBase-root": {
        marginTop: "0 !important",
      },
      "& .MuiSvgIcon-root": {
        color: `${colors.light_logo_blue} !important`,
        //   display: "none !important",
      },
      "& :hover .MuiSvgIcon-root": {
        color: `${colors.light_logo_blue} !important`,
      },
      //   disabled
      //     ? {
      //         display: "none !important",
      //       }
      //     : {
      //         display: "initial !important",
      //       },
      "& .MuiInput-underline:before": {
        borderBottom: "none !important",
      },
      "& :hover .MuiInput-underline:before": disabled
        ? {}
        : {
            borderBottom: "1px solid #bcbcbc !important",
          },
      "& :hover": disabled
        ? {}
        : {
            cursor: "pointer !important",
          },
    };
  };
  
  return (
    <>
      {/* <TaskTabsModal
        showModal={openModal}
        setShowModal={(v) => setOpenModal(v)}
        loading={false}
        projectID={projectID}
        projectName={projectName}
        taskID={parentTask?.taskGuid}
        handleChangeTask={handleChangeTask}
        taskName={parentTask?.title}
        refetch={refetchData}
        fromProject={true}
        open={formFlag}
        modifyFormFlag={() => sessionStorage.setItem("unsavedChanges", JSON.stringify(false))}
        type={parentTask?.parentTaskGuid ? "subtask" : "task"}
      /> */}
      <MoveTaskModal
        selectedTask={selectedTask}
        parentTask={parentTask}
        openDialog={openMoveTaskDialog}
        handleClose={() => setOpenMoveTaskDialog(false)}
        refetechTask={refetchTask}
        changeNewlyOpen={openMoveTaskDialog}
        handleFetchTree={handleFetchTree}
        handleSelectFirstTask={handleSelectFirstTask}
        t={t}
      />
      <ConfirmationModal
        contentText={t("taskForm.user.refresh.record.confirmation")}
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openRefreshModal}
        handleClose={() => setOpenRefreshModal(false)}
        customButtons={customStatusModalButtons}
        t={t}
      />
      <ConfirmationModal
        contentText={t("taskForm.user.delete.record.confirmation")}
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openConfirmationModal}
        handleClose={() => setOpenConfirmationModal(false)}
        customButtons={customModalButtons}
        t={t}
      />
      <ConfirmationModal
        contentText={t("taskForm.updated.record.warning")}
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openWarningModal}
        handleClose={() => setOpenWarningModal(false)}
        customButtons={customStatusModalButtons}
        t={t}
      />
      {/* <ConfirmationModal
        contentText={t("dialog.user.status.change.warning")}
        title={t("dialog.warning")}
        icon={"warning"}
        openDialog={openStatusModal}
        handleClose={() => setOpenStatusModal(false)}
        customButtons={customStatusModalButtons}
        t={t}
      /> */}
      <TimeEntryDrawer
        PaperComponent={PaperComponent}
        openTimeEntryModal={openTimeEntryModal}
        setOpenTimeEntryModal={(v) => {
          handleChangeOpenTimeEntry(v);
          setOpenTimeEntryModal(v);
          setTimeEntryEditMode(false);
        }}
        timeSheetGuid={null}
        timeEntryGuid={timeEntryGuid}
        description={null}
        synced={false}
        fromTimeEntriesList={true}
        refetchData={undefined}
        refetchTasks={undefined}
        editMode={timeEntryEditMode}
        handleEditMode={(v) => setTimeEntryEditMode(v)}
        handleChangeTimeEntryGuid={(v) => setTimeEntryGuid(v)}
        employees={employees_list}
        selectedTask={selectedTaskObject}
        tasks={task_list}
        group={true}
        t={t}
      />
      <Box
        my={0}
        sx={{
          height: "100% !important",
          width: "100% !important",
        }}
      >
        <Grid
          container
          justifyContent="left"
          alignItems="stretch"
          sx={fromTree ? { height: "100%" } : { height: "100%" }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              height: "100% !important",
              // overflow: "auto !important",
            }}
            // sx={fromProject ? { paddingTop: "0 !important" } : {}}k
          >
            <ContainerCard
              sx={
                showModal === true
                  ? {
                      backgroundColor: "tranparent",
                      boxShadow: "none !important",
                      marginBottom: showModal
                        ? "0px !important"
                        : "40px !important",
                      height: showModal ? "95vh !important" : "initial",
                    }
                  : fromTree
                  ? {
                      boxShadow: "none !important",
                      marginBottom: "40px !important",
                      height: showModal ? "100% !important" : "initial",
                      backgroundColor: "transparent !important",
                    }
                  : {
                      boxShadow: "none !important",
                      marginBottom: "40px !important",
                      height: showModal ? "100% !important" : "initial",
                      backgroundColor: "transparent !important",
                    }
              }
              // sx={{position: "absolute", inset: "70px 50px 50px 50px"}}
            >
              {getDataByIDLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="77vh"
                  width="100%"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${
                    createMode ? "creation" : "edit"
                  }-${tskID || taskID}`}
                  initialValues={formData}
                  validationSchema={validation}
                  onSubmit={handleSubmit}
                  enableReinitialize
                  sx={{
                    padding: "0px !important",
                    height: "100% !important",
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setTouched,
                    actions,
                  }) => {
                    formikValuesRef.current = values;

                    return (
                      <Form
                        id={"form-task"}
                        autoComplete="off"
                        className="taskForm"
                        onChange={() => {
                          handleResetFields(false);
                          handleReset(false);
                          handleChangeFlag(touched);
                        }}
                        sx={{
                          padding: "0px !important",
                          height: "calc(100% - 50px) !important",
                        }}
                      >
                        <Box p={0} pt={0} sx={{ height: "100% !important" }}>
                          <Box
                            mb={2}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                              height: "100% !important",
                            }}
                          >
                            <Grid
                              container
                              spacing={1}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={9}
                                lg={9}
                                xl={9}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  paddingBottom: "0",
                                  color: colors.light_logo_blue,
                                  fontSize: "1.02rem",
                                  fontWeight: "500",
                                }}
                              >
                                <span
                                  aria-describedby={"tree"}
                                  onMouseEnter={handleOpenTree}
                                >
                                  <ProjectIcon color={colors.light_logo_blue} />
                                </span>
                                {!createMode ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      maxWidth: "50%",
                                    }}
                                  >
                                    <span
                                      class={`linkButton ${
                                        manageTimeEntry !== "N" ||
                                        projectPermissions?.some(
                                          (x) =>
                                            x.projectGuid ===
                                            selectedProject?.projectGuid
                                        ) ||
                                        isAdmin
                                          ? "linkButton-hover"
                                          : ""
                                      }`}
                                      onClick={(event) => {
                                        if (
                                          manageTimeEntry !== "N" ||
                                          projectPermissions?.some(
                                            (x) =>
                                              x.projectGuid ===
                                              selectedProject?.projectGuid
                                          ) ||
                                          isAdmin
                                        )
                                          window.open(
                                            my_projects_form.replace(
                                              "/:pName/:pID",
                                              `/${encodeURIComponent(
                                                selectedProject?.title
                                              )}/${encodeURIComponent(
                                                selectedProject?.projectGuid
                                              )}`
                                            )
                                          );
                                        else event.preventDefault();
                                      }}
                                    >
                                      {selectedProject?.title}
                                    </span>
                                  </div>
                                ) : (
                                  <AutoComplete
                                    options={projects_list}
                                    getOptionLabel={(option) =>
                                      option ? option.title : ""
                                    }
                                    renderOption={(props, option) => (
                                      <li {...props} key={uuidv4()}>
                                        {option.title}
                                      </li>
                                    )}
                                    identifier={"projectGuid"}
                                    value={selectedProject}
                                    removeBorder
                                    removeSX
                                    sx={{
                                      ...styleAutocomplete(projectDisabled),
                                      width: "40%",
                                    }}
                                    onChange={(e, value) => {
                                      e.preventDefault();
                                      setSelectedProject(value);
                                      setSelectedTicket(null);
                                      setParentTask(null);
                                      setSelectedArea(null);
                                      setFieldValue(project.name, value);
                                    }}
                                    onFocus={() => setFocused(true)}
                                    onBlur={() => setFocused(false)}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }}
                                    required
                                    loading={projectLoading}
                                    label={t(project.label)}
                                    textFieldLabel={t("taskForm.selectProject")}
                                    removeLabel={selectedProject}
                                    name={project.name}
                                    autoFocus
                                  />
                                )}
                                {(type === "subtask"  ||
                                  subtask === true ||
                                  parentTask?.taskGuid) &&
                                  (createMode ? (
                                    <>
                                      <span
                                        style={{
                                          paddingRight: "10px",
                                          paddingBottom: "5px",
                                        }}
                                      >
                                        /
                                      </span>
                                      <AutoComplete
                                        options={task_list}
                                        getOptionLabel={(option) =>
                                          option ? option.title : ""
                                        }
                                        renderOption={(props, option) => (
                                          <li {...props} key={uuidv4()}>
                                            {option.title}
                                          </li>
                                        )}
                                        identifier={"taskGuid"}
                                        required={
                                          manageTimeEntry === "N" ||
                                          isEmployee ===
                                            Mnemonic.ResourceTypes.External ||
                                          type === "subtask"  ||
                                          subtask === true
                                        }
                                        disabled={readOnly || !selectedProject}
                                        value={parentTask}
                                        sx={{
                                          ...styleAutocomplete(!createMode),
                                          width: "60%",
                                        }}
                                        onFocus={() => setFocused(true)}
                                        onBlur={() => setFocused(false)}
                                        onChange={(e, value) => {
                                          setParentTask(value);
                                          // if (!pID)
                                          //   setSelectedProject(
                                          //     value
                                          //       ? value.ticket?.project
                                          //       : selectedProject
                                          //   );
                                          setSelectedTicket(
                                            value
                                              ? value.ticket
                                              : selectedTicket
                                          );
                                          setSelectedExternalReference(null);
                                          // setFieldValue(
                                          //   area.name,
                                          //   value ? value.area : null
                                          // );
                                          // setFieldValue(
                                          //   externalReference.name,
                                          //   value
                                          //     ? value.externalReference
                                          //     : null
                                          // );
                                        }}
                                        loading={taskLoading}
                                        label={t(task.label)}
                                        name={task.name}
                                        textFieldLabel={t(
                                          "taskForm.selectParentTask"
                                        )}
                                        // formData={formData}
                                        removeLabel={parentTask?.taskGuid}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <span
                                        style={{
                                          paddingRight: "10px",
                                          paddingBottom: "5px",
                                        }}
                                      >
                                        {parentTask?.parentTaskGuid ? (
                                          <span> /.../ </span>
                                        ) : (
                                          " / "
                                        )}
                                      </span>
                                      <span
                                        class={`linkButton`}
                                        style={{
                                          maxWidth: "50% !important",
                                        }}
                                      >
                                        {parentTask?.title}
                                      </span>
                                      <Popover
                                        id={"tree"}
                                        open={openTree}
                                        anchorEl={anchorTreeEl}
                                        onClose={handleCloseTree}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "left",
                                        }}
                                      >
                                        <Tree
                                          treeData={treeData}
                                          defaultExpandAll
                                          selectable={false}
                                          fieldNames={{
                                            title: "label",
                                            key: "id",
                                            children: "children",
                                          }}
                                        />
                                      </Popover>
                                    </>
                                  ))}

                                {type !== "subtask" && 
                                  !subtask &&
                                  !parentTask?.taskGuid &&
                                  (createMode ? (
                                    <>
                                      <span
                                        style={{
                                          paddingRight: "10px",
                                          paddingBottom: "5px",
                                        }}
                                      >
                                        /
                                      </span>
                                      <AutoComplete
                                        options={ticketsList}
                                        getOptionLabel={(option) =>
                                          option ? option.title : ""
                                        }
                                        renderOption={(props, option) => (
                                          <li {...props} key={uuidv4()}>
                                            {option.title}
                                          </li>
                                        )}
                                        identifier={"ticketGuid"}
                                        disabled={
                                          (fromProject &&
                                            !projectGuid &&
                                            !selectedProject) ||
                                          (fromDashboard && !createMode) ||
                                          (parentTask?.taskGuid !== null &&
                                            parentTask?.taskGuid !==
                                              undefined &&
                                            parentTask?.taskGuid !== "") ||
                                          isEmployee ===
                                            Mnemonic.ResourceTypes.External ||
                                          readOnly ||
                                          false
                                        }
                                        value={selectedTicket}
                                        onFocus={() => setFocused(true)}
                                        onBlur={() => setFocused(false)}
                                        onChange={(e, value) => {
                                          setSelectedTicket(value);
                                          setFieldValue(ticket.name, value);
                                        }}
                                        required
                                        loading={ticketsLoading}
                                        label={t(ticket.label)}
                                        name={ticket.name}
                                        // formData={formData}
                                        removeLabel={selectedTicket}
                                        removeBorder
                                        removeSX
                                        textFieldLabel={t(
                                          "taskForm.selectPhase"
                                        )}
                                        sx={{
                                          ...styleAutocomplete(!createMode),
                                          width: "60%",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <span
                                        style={{
                                          paddingRight: "10px",
                                          paddingBottom: "5px",
                                        }}
                                      >
                                        /{" "}
                                      </span>
                                      <span
                                        class={`linkButton ${
                                          manageTimeEntry !== "N" ||
                                          projectPermissions?.some(
                                            (x) =>
                                              x.projectGuid ===
                                              selectedProject?.projectGuid
                                          ) ||
                                          isAdmin
                                            ? "linkButton-hover"
                                            : ""
                                        }`}
                                        onClick={(event) => {
                                          if (selectedTicket)
                                            window.open(
                                              my_project_tickets_form
                                                .replace(
                                                  "/:pName/:pID",
                                                  `/${encodeURIComponent(
                                                    selectedProject?.title
                                                  )}/${encodeURIComponent(
                                                    selectedProject?.projectGuid
                                                  )}`
                                                )
                                                .replace(
                                                  "/:tkName/:tkID",
                                                  `/${encodeURIComponent(
                                                    selectedTicket?.title
                                                  )}/${encodeURIComponent(
                                                    selectedTicket?.ticketGuid
                                                  )}`
                                                )
                                            );
                                          else event.preventDefault();
                                        }}
                                      >
                                        {selectedTicket?.title}
                                      </span>
                                    </>
                                  ))}
                              </Grid>
                              {isEmployee !==
                                Mnemonic.ResourceTypes.External && (
                                // ((manageTimeEntry === 'N' ? !createMode && parentTask !== null && parentTask !== undefined ? true : false : true)) &&
                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                  <Box
                                    // width="100%"
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    mb={1}
                                    sx={{
                                      ".MuiButtonGroup-firstButton": {
                                        textTransform: "capitalize !important",
                                        fontFamily: "Poppins !important",
                                        fontSize: "13px !important",
                                      },
                                      "& .Mui-disabled": {
                                        backgroundColor: `${colors.disabled_button_color} !important`,
                                      },
                                      "& .MuiButtonGroup-firstButton:not(.Mui-disabled)":
                                        {
                                          backgroundColor: `${colors.light_logo_blue} !important`,
                                          borderColor: `${colors.light_logo_blue} !important`,
                                          textTransform:
                                            "capitalize !important",
                                          fontFamily: "Poppins !important",
                                          fontSize: "13px !important",
                                        },
                                      "& .MuiButton-root:not(.Mui-disabled)": {
                                        backgroundColor: `${colors.light_logo_blue} !important`,
                                        borderColor: `${colors.light_logo_blue} !important`,
                                      },
                                      "& .MuiMenuItem-root": {
                                        fontFamily: "Poppins !important",
                                        fontSize: "13px !important",
                                      },
                                    }}
                                  >
                                    {showModal ? (
                                      <>
                                        <ButtonGroup
                                          variant="contained"
                                          ref={anchorRef}
                                          aria-label="Button group with a nested menu"
                                          sx={{
                                            height: "30px !important",
                                            fontFamily: "Poppins !important",
                                            marginRight: "10px",
                                          }}
                                        >
                                          <LoadingButton
                                            type="submit"
                                            sx={{ color: "white" }}
                                            disabled={readOnly}
                                            loading={
                                              button1Submitting ||
                                              button2Submitting
                                            }
                                          >
                                            {/* {(button1Submitting || button2Submitting) ? } */}
                                            {options[selectedIndex]}
                                          </LoadingButton>
                                          <Button
                                            size="small"
                                            aria-controls={
                                              open
                                                ? "split-button-menu"
                                                : undefined
                                            }
                                            aria-expanded={
                                              open ? "true" : undefined
                                            }
                                            aria-label="select merge strategy"
                                            aria-haspopup="menu"
                                            onClick={handleToggle}
                                            disabled={readOnly}
                                            sx={{
                                              minWidth: "10px !important",
                                              width: "10px !important",
                                            }}
                                          >
                                            <ArrowDropDownIcon />
                                          </Button>
                                        </ButtonGroup>
                                        <Popper
                                          sx={{
                                            zIndex: 1,
                                          }}
                                          open={open}
                                          anchorEl={anchorRef.current}
                                          role={undefined}
                                          transition
                                          disablePortal
                                        >
                                          {({ TransitionProps, placement }) => (
                                            <Grow
                                              {...TransitionProps}
                                              style={{
                                                transformOrigin:
                                                  placement === "bottom"
                                                    ? "center top"
                                                    : "center bottom",
                                              }}
                                            >
                                              <Paper>
                                                <ClickAwayListener
                                                  onClickAway={handleCloseList}
                                                >
                                                  <MenuList
                                                    id="split-button-menu"
                                                    autoFocusItem
                                                  >
                                                    {options.map(
                                                      (option, index) => (
                                                        <MenuItem
                                                          key={option}
                                                          disabled={index === 2}
                                                          selected={
                                                            index ===
                                                            selectedIndex
                                                          }
                                                          onClick={(event) =>
                                                            handleMenuItemClick(
                                                              event,
                                                              index,
                                                              values,
                                                              actions
                                                            )
                                                          }
                                                        >
                                                          {option}
                                                        </MenuItem>
                                                      )
                                                    )}
                                                  </MenuList>
                                                </ClickAwayListener>
                                              </Paper>
                                            </Grow>
                                          )}
                                        </Popper>{" "}
                                      </>
                                    ) : (
                                      <IALoadingButton
                                        disabled={button1Submitting || readOnly}
                                        loading={button1Submitting}
                                        type="submit"
                                        label={t("taskForm.save")}
                                        sx={{
                                          marginRight: "10px",
                                          textTransform:
                                            "capitalize !important",
                                          fontFamily: "Poppins !important",
                                          fontSize: "13px !important",
                                        }}
                                      />
                                    )}
                                    {/* <IALoadingButton
                                  disabled={button1Submitting || readOnly}
                                  loading={button1Submitting}
                                  type="submit"
                                  label={t("taskForm.save")}
                                />
                                {showModal === true && (
                                  <span style={{ paddingLeft: "10px" }}>
                                    <IALoadingButton
                                      disabled={button2Submitting || readOnly}
                                      loading={button2Submitting}
                                      type="submit"
                                      onClick={() => (saveAndClose.current = true)}
                                      label={t("taskForm.save.close")}
                                    />
                                  </span>
                                )} */}
                                    {(!createMode || editMode.current) && (
                                      <>
                                        <Tooltip
                                          title={t(
                                            "taskForm.btn.refresh.label"
                                          )}
                                        >
                                          <IconButton
                                            id="task-button"
                                            aria-controls={
                                              open ? "task-menu" : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                              open ? "true" : undefined
                                            }
                                            onClick={() =>
                                              handleSubmit(
                                                values,
                                                actions,
                                                selectedIndex,
                                                true,
                                                true
                                              )
                                            }
                                            sx={{
                                              // border: `1px solid ${colors.light_divider_color}`,
                                              marginRight: "5px !important",
                                            }}
                                          >
                                            <RefreshIcon
                                              color={colors.icon_button_grey}
                                            />
                                          </IconButton>
                                        </Tooltip>

                                        <IconButton
                                          id="task-button"
                                          aria-controls={
                                            open ? "task-menu" : undefined
                                          }
                                          aria-haspopup="true"
                                          aria-expanded={
                                            open ? "true" : undefined
                                          }
                                          onClick={handleClickMenu}
                                          sx={{
                                            border: `1px solid ${colors.light_divider_color}`,
                                          }}
                                        >
                                          <VerticalActionsIcon
                                            color={colors.icon_button_grey}
                                          />
                                        </IconButton>
                                      </>
                                    )}
                                    {showModal === true && (
                                      <IconButton
                                        onClick={() => handleCloseModal()}
                                      >
                                        <CloseIcon
                                          color={colors.icon_button_grey}
                                          width={"24"}
                                          stroke={"1.5"}
                                        />
                                      </IconButton>
                                    )}
                                    {(!createMode || editMode.current) && (
                                      <Menu
                                        id="task-menu"
                                        anchorEl={anchorEl}
                                        open={openMenu}
                                        onClose={handleCloseMenu}
                                        MenuListProps={{
                                          "aria-labelledby": "task-button",
                                        }}
                                        sx={{
                                          ".MuiPaper-root": {
                                            right: "20px !important",
                                            boxShadow: `rgb(0 0 0 / 26%) 0px 1px 4px !important`,
                                            marginTop: "5px",
                                          },
                                        }}
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            editMode.current = false;
                                            task_guid.current = "";
                                            setType("subtask");
                                            handleResetFields(true);
                                            handleEditMode(false);
                                            setParentTask(selectedTask);
                                            handleAddSubtask(selectedTask);
                                            handleCloseMenu();
                                          }}
                                        >
                                          <ListItemIcon>
                                            <SubtaskIconOutlined
                                              color={"#00000095"}
                                            />
                                          </ListItemIcon>
                                          <ListItemText>
                                            {t("taskForm.subtask.addNew")}
                                          </ListItemText>
                                        </MenuItem>
                                        {(((manageTimeEntry === "M" ||
                                          isAdmin) &&
                                          (parentTask == null ||
                                            parentTask?.taskGuid == null)) ||
                                          (parentTask !== null &&
                                            parentTask?.taskGuid != null)) && (
                                          <MenuItem
                                            onClick={() => {
                                              setOpenMoveTaskDialog(true);
                                              handleCloseMenu();
                                            }}
                                          >
                                            <ListItemIcon>
                                              <MoveIcon color={"#00000095"} />
                                            </ListItemIcon>
                                            <ListItemText>
                                              {t("taskForm.move.task")}
                                            </ListItemText>
                                          </MenuItem>
                                        )}
                                        {(((manageTimeEntry === "M" ||
                                          isAdmin) &&
                                          (parentTask == null ||
                                            parentTask?.taskGuid == null)) ||
                                          (parentTask !== null &&
                                            parentTask?.taskGuid != null)) && (
                                          <>
                                            <Divider />
                                            <MenuItem
                                              onClick={() => {
                                                setOpenConfirmationModal(true);
                                                handleCloseMenu();
                                              }}
                                            >
                                              <ListItemIcon>
                                                <DeleteIcon
                                                  color={colors.error}
                                                />
                                              </ListItemIcon>
                                              <ListItemText
                                                sx={{
                                                  color: `${colors.error}`,
                                                }}
                                              >
                                                {t("taskForm.delete.task")}
                                              </ListItemText>
                                            </MenuItem>
                                          </>
                                        )}
                                      </Menu>
                                    )}
                                  </Box>
                                </Grid>
                              )}
                            </Grid>
                            <TaskMain
                              formData={{
                                values,
                                touched,
                                formField: form.formField,
                                errors,
                                setFieldValue,
                                setTouched,
                                createMode,
                                readOnly: readOnly,
                                fromProject,
                                fromDashboard,
                                tenantID,
                                navigate,
                                handleChangeFlag,
                                resetFields,
                                wait: tasksWait,
                              }}
                              taskId={taskId}
                              fromTenant={fromTenant}
                              selectedProject={selectedProject}
                              modal={modal}
                              ticketArea={ticketArea}
                              percentage={percentage}
                              taskGuid={taskID || tskID || task_guid.current}
                              type={
                                (type === "subtask"  && createMode) ||
                                subtask ||
                                parentTask?.taskGuid !== undefined
                                  ? "subtask"
                                  : "task"
                              }
                              parentTask={parentTask}
                              handleOpenModal={handleOpenModal}
                              subtask={
                                parentTask !== null &&
                                parentTask?.taskGuid !== undefined
                                  ? parentTask?.taskGuid !== undefined
                                  : subtask
                              }
                              modifyFormFlag={() =>
                                sessionStorage.setItem(
                                  "unsavedChanges",
                                  JSON.stringify(false)
                                )
                              }
                              fromTree={fromTree}
                              handleChangeProject={handleChangeProject}
                              ticket_status_list={
                                ticket_status_list || ticketStatusList
                              }
                              selectedTask={selectedTask}
                              refetchFct={refetchFct}
                              fetchTickets={fetchTickets}
                              handleFormChanged={handleFormChanged}
                              disabledFields={disabledFields}
                              setMediaList={setMediaList}
                              mediaList={mediaList}
                              handleAddSubtask={handleAddSubtask}
                              handleFetchTree={handleFetchTree}
                              refetchTask={refetchTask}
                              updateTaskQuick={updateTaskQuick}
                              handleSubmit={handleSubmit}
                              showModal={showModal}
                              attachments={attachments}
                              selectedArea={selectedArea}
                              selectedExternalReference={
                                selectedExternalReference
                              }
                              // handleEnableRefetch={handleEnableRefetch}
                              quickAdd={quickAdd}
                              employees_list={employees_list}
                              usersLoading={usersLoading}
                              // wait={statusWait || employeeWait || projectWait || tasksWait || ticketWait}
                              wait={statusWait || employeeWait || (createMode ? projectWait : false) || (!createMode ? tasksWait || taskWait : false)}
                            />
                          </Box>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </ContainerCard>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TaskForm;
