import {
    Button,
    Dialog,
    IconButton,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from "@mui/material";
import PaperComponent from "./PaperComponent";
import { CloseIcon } from "../Icon";
  
  const PopUpList = ({
    title,
    content,
    openDialog,
    handleClose,
    t,
  }) => {
    return (
      <Dialog
        open={openDialog}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: "move", display: "flex", alignItems: "center" }}
          id="draggable-dialog-title"
        >
          <span style={{ marginLeft: "5px" }}>{title}</span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: "rgba(0, 0, 0, 0.04)",
          }}
        >
          <CloseIcon color={"#9e9e9e"} width={"24"} stroke={"1.5"} />
        </IconButton>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default PopUpList;