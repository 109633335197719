import React, { useState, useEffect, useCallback, useRef } from "react";

//components
import DataTable from "../../../../components/Tables/DataTable";
import AutoComplete from "../../../../components/AutoComplete";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";

import Tooltip from "../../../../components/Tooltip";
//graphql
import {
  GET_ALL_TIMESHEETS,
  GET_TIMESHEETS_LIST,
} from "../../../../graphql/timesheet";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../graphql/project";
import { GET_ALL_TASKS_WITH_PROJECT } from "../../../../graphql/task";
import { LOGOUT_USER } from "../../../../graphql/user";

import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

import { UTC2Time, formatDateTimeToString } from "../../../../UTC2Local";

//external components
import { Box, CircularProgress, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import * as FileSaver from "file-saver";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import PaperComponent from "../../../../components/Modal/PaperComponent";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";

import { useTranslation } from "react-i18next";
import EditRecord from "../Timesheets-Form/components/EditRecord";
import TaskTabsModal from "../../../../components/Modal/TaskTabsModal";
import TimeEntryDrawer from "../Timesheets-Form/components/TimeEntryDrawer";
import { useDispatch } from "react-redux";
import colors from "../../../../assets/theme/light/colors";

import * as XLSX from "xlsx";
import apiCall from "../../../../hooks/useRest";
import { saveToken } from "../../../../app/reducers/tokenSlice";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { v4 as uuidv4 } from "uuid";

const backendURL = process.env.REACT_APP_REST_URL;

const TimesheetListApproved = () => {
  const { storeUserGuid, storeUserTenant, isManager,logout } = useAuth();
  const { dark_logo_blue } = colors;
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [clicked, setClicked] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [timeEntry, setTimeEntry] = React.useState(null);

  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [linkCell, setLinkCell] = useState(false);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalDuration, setTotalDuration] = useState(0);
  const [search, setSearch] = useState("");
  const [timesheetObj, setTimesheetObj] = useState(null);
  const [rows, setRows] = useState([]);
  const [taskID, setTaskID] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [projectID, setProjectID] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const type = useRef("task");

  const timeSheetGuid = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");
  const [dateFromSheet, setDateFromSheet] = useState("");
  const [dateToSheet, setDateToSheet] = useState("");
  const {
    data: timesheetListData,
    loading: timesheetListLoading,
    tokenChange: timesheetListWait,
  } = useQueryAuth(GET_ALL_TIMESHEETS, "GetTimeSheetList", {
    variables: {
      model: {
        enableFilters: false,
        tenantGuid: storeUserTenant,
        userID: storeUserGuid,
        pending: false,
      },
    },
  });

  const timesheets_list =
    timesheetListData?.GetTimeSheetList?.timeSheetList?.timeSheets || [];
    const updateToken = (updatedToken) => {
      if(updatedToken) dispatch(saveToken(updatedToken))
     }  
  
  const {
    data: employeeData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        workshiftGuid: timesheetObj?.workShift?.workShiftGuid,
        isManagerUsersList: isManager === true ? true : null,
        userID: storeUserGuid,
        timeEntryDisabled: true,
      },
    },
    skip: timesheetObj === null,
    isWait: true,
  });
  const employeeList =
    employeeData?.GetCompanyUsersList?.usersList?.employees || [];

  const {
    data: projectsData,
    loading: projectLoading,
    tokenChange: projectsWait,
  } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
      },
    },
    skip: timesheetListWait,
    isWait: true,
  });

  const projectsList =
    projectsData?.GetAllProjects?.projectsList?.projectViews || [];

  const {
    data: tasksList,
    loading: taskLoading,
    tokenChange: tasksWait,
  } = useQueryAuth(GET_ALL_TASKS_WITH_PROJECT, "GetTasks", {
    variables: {
      model: {
        tenantID: storeUserTenant,
        enableFilters: false,
        onGoingTasks: true,
        timeEntriesCreationAllowed: true,
      },
    },
    skip: timesheetListWait || projectsWait,
    isWait: true,
  });

  const task_list =
    tasksList && tasksList.GetTasks && tasksList.GetTasks.tasks
      ? tasksList.GetTasks.tasks
      : [];

  const filtersObject = [
    {
      label: "timesheet.list.filters.employees",
      name: "employee",
      type: "multiple",
      getOptionLabel: (option) =>
        option ? option.firstName + " " + option.lastName : "",
      options: employeeList,
      access: "employeeGuid",
      loading: employeeLoading,
    },
    {
      label: "timesheet.list.filters.projects",
      name: "project",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.title : ""),
      options: projectsList,
      access: "projectGuid",
      loading: projectLoading,
    },
    {
      label: "timesheet.list.filters.tasks",
      name: "task",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.title : ""),
      options: task_list,
      access: "taskGuid",
      loading: taskLoading,
      group: true,
      groupBy: (option) => option.ticket?.project?.title,
    },
    {
      type: "date",
      label: "timesheet.list.filters.date",
      name: "date",
    },
  ];

  const {
    loading: getColumnsLoading,
    refetch: refetchColumns,
    tokenChange: columnsWait,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.ApprovedTimesheetsList,
          userID: storeUserGuid,
        },
      },
      skip: timesheetListWait || projectsWait || tasksWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        const col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        const actions = ({ compProps }) => {
          let props = compProps;
          return (
            <EditRecord
              {...props}
              handleClick={(v) => setClicked(v)}
              handleRowSelect={(t) => setTimeEntry(t)}
              handleEditMode={(v) => setEditMode(v)}
            />
          );
        };

        if (col) {
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay, UTC2Time, actions, null, formatDateTimeToString)
          );
          // if (parsed.columns)
          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
          const colmns = parsed.columns;
          if (colmns) {
            colmns.forEach((col) => {
              if (col.id === "date")
                return (col.renderCell = (row) => {
                  return formatDateTimeToString(row?.startTime, false);
                });
            });
            setLinkCell(colmns.some((col) => col.link === true));
            setColumns(colmns);
          }
        }
      },
    }
  );

  const {
    mfunction: getAllTimesheets,
    loading,
    refetch: refetchTimeSheet,
  } = useLazyQueryAuth(GET_TIMESHEETS_LIST, "GetTimeSheetTimeEntries");

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllTimesheets(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              filters: filters,
              orderColumn: orderBy,
              orderDirection: order,
              timesheetGuid: timeSheetGuid.current,
              userID: storeUserGuid
            },
          },
          skip: timeSheetGuid.current == null,
          isWait: true,
        },
        (response) => {
          setData(response);
          setRows(
            response?.GetTimeSheetTimeEntries?.timeSheetTimeEntries?.timeEntries
          );
          setTotalDuration(
            response?.GetTimeSheetTimeEntries?.timeSheetTimeEntries
              ?.totalDuration
          );
          setDateFromSheet(UTC2Local(response?.GetTimeSheetTimeEntries?.timeSheetTimeEntries.dateFrom))
          setDateToSheet(UTC2Local(response?.GetTimeSheetTimeEntries?.timeSheetTimeEntries.dateTo))
        
        }
      );
    },
    []
  );

  const numberRecords =
    data &&
    data.GetTimeSheetTimeEntries &&
    data.GetTimeSheetTimeEntries.timeSheetTimeEntries &&
    data.GetTimeSheetTimeEntries.timeSheetTimeEntries.timeEntries &&
    data.GetTimeSheetTimeEntries.timeSheetTimeEntries.totalNumberOfRecords
      ? data.GetTimeSheetTimeEntries.timeSheetTimeEntries.totalNumberOfRecords
      : 0;

  useEffect(() => {
    if (!columnsWait && !employeeWait && timesheetObj)
      fetchTableData(page, rowsPerPage, order, orderBy, search);
  }, [timesheetObj, employeeWait]);

  const handleOpenModal = (row) => {
    setTaskID(row?.taskGuid);
    setProjectID(row?.projectGuid);
    setTaskName(row?.task);
    setProjectName(row?.project);
    setOpenModal(true);
  };

  const showCustomDiv = () => {
    return <span style={{fontSize: "14px", width: "10vw", display: "flex"}}>{t("timesheet.total.duration")} {" "} {totalDuration.toFixed(2)}</span>
  }

  async function exportTimeToExcel() {
    setExportLoading(true);
   
    const res = await apiCall(
      backendURL + `/ExportExcel/exportTimeSheet`,
      "POST",
      {"items":rows,
        "dateFrom":dateFromSheet,
        "dateTo":dateToSheet
      },
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t,
      null,
      'blob'
    );

    if (res) {
      let fileName = "ApprovedTimeSheet"+Date.now()+".xlsx"; // Default file name

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const blob = new Blob([res.data], { type: fileType });

      FileSaver.saveAs(blob, fileName);
      enqueueSnackbar(t("report.exportSuccessfully"), { variant: "success" });
      
    }
    setExportLoading(false);

  }
  
  const pagination = {
    rows: rows,
    setRows,
    columns,
    filtersObject,
    defaultFilters,
    setDefaultFilters,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    hideFilters: timeSheetGuid.current == null ? true : false,
    refetch: refetchColumns,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords,
    page,
    rowsPerPage,
    hideSearchBar: true,
    hideRefreshButton: true,
    hideAddButton: true,
    fetchTableData,
    loading: loading || getColumnsLoading,
    searchText: "timesheet.list.search.label",
    tableMnemonic: Mnemonic.Tables["ApprovedTimesheetsList"],
    readOnly: false,
    // hideEditColumns: true,
    removeRefresh: false,
    linkCell,
    handleOpenModal,
    showCustomDiv
  };
  return (
    <div>
      <TaskTabsModal
        showModal={openModal}
        setShowModal={(v) => setOpenModal(v)}
        loading={loading}
        projectID={projectID}
        projectName={projectName}
        taskID={taskID}
        handleChangeProject={(v) => setProjectID(v)}
        handleChangeTask={(v) => setTaskID(v)}
        taskName={taskName}
        fromProject={true}
        refetch={refetchTimeSheet}
        setType={(v) => type.current=v}
        type={type.current}
        open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        modifyFormFlag={() =>
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false))
        }
      />
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        !timesheetListLoading && (
          <>
          <Grid item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ paddingTop: "10px", display: "flex", alignItems: "end",direction:"rtl" }}>
                <IALoadingButton

                onClick={() => exportTimeToExcel()}
                loading={loading}
                style={{
                  width: "fit-content",
                  backgroundColor: dark_logo_blue,
                  borderRadius: "50px",
                  height: "36px",
                  marginRight: "10px",
                }}
                label={t("exportTimeSheet")}
                disabled={!rows || exportLoading || rows.length<1}
              />
                </Grid>
            <TimeEntryDrawer
              PaperComponent={PaperComponent}
              openTimeEntryModal={clicked}
              setOpenTimeEntryModal={(v) => setClicked(setClicked)}
              timeSheetGuid={timeSheetGuid.current}
              timeEntryGuid={timeEntry?.timeEntryGuid}
              description={timeEntry?.description}
              synced={timeEntry?.synced}
              editMode={editMode}
              handleEditMode={(v) => setEditMode(v)}
              employees={employeeList}
              tasks={task_list}
              t={t}
            />
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ paddingTop: "10px", display: "flex", alignItems: "end" }}
            >
              <Tooltip title={t("timesheets.tooltip.timesheet")}>
                <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                  <AutoComplete
                    options={timesheets_list}
                    getOptionLabel={(option) =>
                      (option
                        ? option.workShift.description +
                          "  ( " +
                          UTC2Local(option.startDate, true) +
                          "   —   " +
                          UTC2Local(option.endDate, true)
                        : "") + "  ) "
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={uuidv4()}>
                        {option.workShift.description +
                          "  ( " +
                          UTC2Local(option.startDate, true) +
                          "   —   " +
                          UTC2Local(option.endDate, true)}
                      </li>
                    )}
                    identifier={"timeSheetGuid"}
                    value={timesheetObj}
                    onChange={(e, value) => {
                      setTimesheetObj(value);
                      timeSheetGuid.current = value?.timeSheetGuid;
                      setPage(0);
                      if (value === null) setRows([]);
                    }}
                    loading={timesheetListLoading}
                    label={t("timesheet.autoComplete.label")}
                  />
                </Grid>
              </Tooltip>
           
              
              
            </Grid>
           
            <Box
              sx={{
                marginTop: {
                  xs: "0px !important",
                  sm: "0px !important",
                  md: "0px !important",
                  lg: "-45px !important",
                  xl: "-45px !important",
                },
              }}
            >
               
              <DataTable
                {...pagination}
                identifier="timeEntryGuid"
                heightClassName="table-sheet-height"
                isFixed
              />
            </Box>
            
          </>
        )
      )}
    </div>
  );
};

export default TimesheetListApproved;
