import React, { useState, useEffect, useCallback } from "react";

//components
import DataTable from "../../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useAuth from "../../../../../hooks/useAuth";
import { useParams } from "react-router-dom";
//graphql
import { GET_USER_TABLE_PREFERENCES } from "../../../../../graphql/userTablePreferences";
import { GET_ALL_MIDDLEWARE_JOBS_LIST } from "../../../../../graphql/middlewareJobs";
//editors
import { Parse } from "../../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../../Mnemonics.json";


//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../../UTC2Local";
import componentDisplay from "../../../../../componentDisplay";

const MiddlewareList = ({ handleSetJobsList = {}, jobsList = [], refetchTable = false, fromTenant, wait }) => {
    const { tID } = useParams();

    const { storeUserGuid,userRoles } = useAuth();
    const { isEntelligence } = userRoles;

    const [data, setData] = useState(null);
    const [defaultFilters, setDefaultFilters] = useState({});
    const [columns, setColumns] = useState([]);
    const [order, setOrder] = useState("");
    const [orderBy, setOrderBy] = useState("");
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [search, setSearch] = useState("");
    const [rows, setTableRows] = useState([]);

    const {
        loading: getColumnsLoading,
        tokenChange: columnsWait,
        refetch,
    } = useQueryAuth(
        GET_USER_TABLE_PREFERENCES,
        "GetUserTablePreferences",
        {
            variables: {
                model: {
                    table: Mnemonic.Tables.MiddlewareJobList,
                    userID: storeUserGuid,
                },
            },
            skip: wait,
            isWait: true
        },
        {
            onCompleted: (response) => {
                const col =
                    response &&
                        response.GetUserTablePreferences &&
                        response.GetUserTablePreferences.columns
                        ? response.GetUserTablePreferences.columns
                        : null;

                if (col) {
                    //we parse the json string to a json object
                    const parsed = JSON.parse(col, (key, value) =>
                        Parse(key, value, UTC2Local, componentDisplay)
                    );

                    if (parsed.columns) setColumns(parsed.columns);

                    if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

                    if (parsed.defaultSortDirection)
                        setOrder(parsed.defaultSortDirection);
                }

            },
        }
    );

    const { mfunction: GetTenantMiddlewareJobs, loading, refetch: refetchData } = useLazyQueryAuth(
        GET_ALL_MIDDLEWARE_JOBS_LIST,
        "GetTenantMiddlewareJobs");

    const fetchTableData = useCallback(
        (
            page = 0,
            pageSize = 0,
            order = "",
            orderBy = "",
            search = "",
            filters = null
        ) => {
            GetTenantMiddlewareJobs(
                {
                    variables: {
                        model: {
                            tenantGuid: tID,
                        },

                    },
                },
                (response) => {
                    setData(response);
                    setTableRows(response?.GetTenantMiddlewareJobs?.tenantMiddlewareJobsView?.middlewareJobs)
                }
            );
        },
        []
    );

    useEffect(() => {
        if (!columnsWait)
            fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
    }, [columnsWait, fetchTableData]);

    useEffect(() => {
        if (!columnsWait)
            refetchData();
    }, [refetchTable]);

   
    const numberOfRecords =
        data &&
            data.GetTenantMiddlewareJobs &&
            data.GetTenantMiddlewareJobs.tenantMiddlewareJobsView &&
            data.GetTenantMiddlewareJobs.tenantMiddlewareJobsView.totalNumberOfRecords
            ? data.GetTenantMiddlewareJobs.tenantMiddlewareJobsView.totalNumberOfRecords
            : 0;

    const customButtons = [
        {
            label: "tenants.middlewareJob.enable",
            handleClick: () => {

                // Create a copy of the jobs list
                const updatedJobsList = [...jobsList];
                const rowsClone = structuredClone(rows);
                // Check if tenantMiddlewareJobGuid already exists in the jobsList
                selected.forEach(one => {
                    const existingJobIndex = updatedJobsList.findIndex(job => job.tenantMiddlewareJobGuid === one);

                    if (existingJobIndex !== -1) {
                        // If the job with the same GUID exists, update its enabled property
                        updatedJobsList[existingJobIndex].enabled = true;
                    } else {
                        // If the job with the GUID doesn't exist, add it to the updated list
                        updatedJobsList.push({
                            tenantMiddlewareJobGuid: one,
                            enabled: true,
                        });
                    }
                    rowsClone.forEach(clone => {
                        if (clone.tenantMiddlewareJobGuid === one)
                            clone.jobEnabled = true
                    })
                });

                // Update the jobs list using the updatedJobsList
                handleSetJobsList(updatedJobsList);
                setTableRows(rowsClone);
            },
            condition: selected && selected.length > 0,
            icon: true,
            iconButton: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#00b341" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M9 12l2 2l4 -4" />
            </svg>,
        },
        {
            label: "tenants.middlewareJob.disable",
            handleClick: () => {
                // Create a copy of the jobs list
                const updatedJobsList = [...jobsList];
                const rowsClone = structuredClone(rows);

                // Check if tenantMiddlewareJobGuid already exists in the jobsList
                selected.forEach(one => {
                    const existingJobIndex = updatedJobsList.findIndex(job => job.tenantMiddlewareJobGuid === one);

                    if (existingJobIndex !== -1) {
                        // If the job with the same GUID exists, update its enabled property
                        updatedJobsList[existingJobIndex].enabled = false;
                    } else {
                        // If the job with the GUID doesn't exist, add it to the updated list
                        updatedJobsList.push({
                            tenantMiddlewareJobGuid: one,
                            enabled: false,
                        });
                    }
                    rowsClone.forEach(clone => {
                        if (clone.tenantMiddlewareJobGuid === one)
                            clone.jobEnabled = false
                    })
                });

                // Update the jobs list using the updatedJobsList
                handleSetJobsList(updatedJobsList);
                setTableRows(rowsClone);
            },
            condition: selected && selected.length > 0,
            icon: true,
            iconButton: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-ban" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff2825" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                <path d="M5.7 5.7l12.6 12.6" />
            </svg>
        }


    ];




    const pagination = {
        rows,
        columns,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        selected,
        setSelected,
        defaultFilters,
        setDefaultFilters,
        search,
        setSearch,
        setPage,
        setRowsPerPage,
        numberRecords: numberOfRecords,
        page,
        rowsPerPage,
        fetchTableData,
        loading: loading || columnsWait,
        searchText: "tenant.list.search.label",
        refetch,
        removeFilters: true,
        hideEditColumns: true,
        hideSearchBar: true,
        removeSelectAll: true,
        removeRefresh: true,
        tableMnemonic: Mnemonic.Tables.MiddlewareJobList,
        customButtons,

    };




    return (
        <div>
            {getColumnsLoading ? (
                <ContainerCard
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px",
                    }}
                >
                    <CircularProgress />
                </ContainerCard>
            ) : (
                <DataTable
                    {...pagination}
                    identifier={"tenantMiddlewareJobGuid"}
                    hasCheckbox ={fromTenant && !isEntelligence  ? false : true}
                    middlewareJob
                />
            )}
        </div>
    );
};

export default MiddlewareList;



