import urls from "./urls";

//authorization
import Authorization from "./authorization";

import RolePermissions from "./classes/RolePermissions";

//colors
import colors from "./assets/theme/light/colors";

import {
  AttendanceManagementIcon,
  BacklogIcon,
  ConfigIcon,
  DashboardIcon,
  NewsIcon,
  ReportsIcon,
  RequestsIcon,
  TeamsIcon,
  TimeEntryIcon,
  TimeManagementIcon,
  TimesheetIcon,
  UserIcon
} from "./components/Icon";

import AppliedInvoices from "./pages/admin-configuration/Applied Invoices";
import AttendanceSheetList from "./pages/admin-configuration/AttendanceSheets/AttendanceSheets-list";
import AttendancesList from "./pages/admin-configuration/AttendanceSheets/AttendanceSheets-list/AttendancesList";
import AttendanceSheetsListTabRoutes from "./pages/admin-configuration/AttendanceSheets/AttendanceSheets-list/routes/AttendanceSheetsListTabRoutes";
import CompanyForm from "./pages/admin-configuration/Companies/Companies-form";
import CompanyList from "./pages/admin-configuration/Companies/Companies-list";
import CompanyTabs from "./pages/admin-configuration/Companies/Companies-Tabs";
import CompanyTabRoutes from "./pages/admin-configuration/Companies/Companies-Tabs/routes/CompanyTabRoutes";
import ContractorsList from "./pages/admin-configuration/Contractors/Contractors-list";
import DepartmentsList from "./pages/admin-configuration/Departments/Departments-list";
import EmployeeReferencesForm from "./pages/admin-configuration/EmployeeReferences/EmployeeReferences-form";
import EmployeeReferencesTabs from "./pages/admin-configuration/EmployeeReferences/EmployeeReferences-tabs";
import EmployeeReferenceTabRoutes from "./pages/admin-configuration/EmployeeReferences/EmployeeReferences-tabs/routes/EmployeeReferenceTabRoutes";
import EmployeeAvailability from "./pages/admin-configuration/Employees/Employee-availability";
import EmployeeForm from "./pages/admin-configuration/Employees/Employee-form";
import EmployeesList from "./pages/admin-configuration/Employees/Employees-list";
import EmployeeTabs from "./pages/admin-configuration/Employees/Employees-tabs";
import EmployeeTabRoutes from "./pages/admin-configuration/Employees/Employees-tabs/routes/EmployeeTabRoutes";
import LoanedItemsList from "./pages/admin-configuration/LoanedItems/LoanedItems-list";
import LocationForm from "./pages/admin-configuration/Locations/Locations-form";
import LocationsList from "./pages/admin-configuration/Locations/Locations-list";
import LocationTabs from "./pages/admin-configuration/Locations/Locations-tabs";
import LocationsTabRoutes from "./pages/admin-configuration/Locations/Locations-tabs/routes/LocationsTabRoutes";
import LogDetails from "./pages/admin-configuration/Logs/components/log_details";
import LogsList from "./pages/admin-configuration/Logs/Logs-list";
import NewsDetails from "./pages/admin-configuration/News/news-info";
import News from "./pages/admin-configuration/News/news-list";
import OfficialHolidayForm from "./pages/admin-configuration/OfficialHolidays/OfficialHolidays-Form";
import OfficialHolidaysList from "./pages/admin-configuration/OfficialHolidays/OfficialHolidays-list";
import OfficialHolidaysTabs from "./pages/admin-configuration/OfficialHolidays/OfficialHolidays-Tabs";
import OfficialHolidayTabRoutes from "./pages/admin-configuration/OfficialHolidays/OfficialHolidays-Tabs/routes/OfficialHolidayTabRoutes";
import OnBoardingForm from "./pages/admin-configuration/OnBoardingTasks/OnBoardingTasks-form";
import OnBoardingTasks from "./pages/admin-configuration/OnBoardingTasks/OnBoardingTasks-list";
import Invoices from "./pages/admin-configuration/Projects/Project-invoices";
import ProjectPlan from "./pages/admin-configuration/Projects/Project-plan";
import ProjectTree from "./pages/admin-configuration/Projects/Project-tree";
import ProjectForm from "./pages/admin-configuration/Projects/Projects-form";
import {
  default as ProjectList,
  default as ProjectsList
} from "./pages/admin-configuration/Projects/Projects-list";
import ProjectTabs from "./pages/admin-configuration/Projects/Projects-tabs";
import ProjectsTabRoutes from "./pages/admin-configuration/Projects/Projects-tabs/routes/ProjectsTabRoutes";
import PTOForm from "./pages/admin-configuration/PTOs/PTOs-form";
import PTOList from "./pages/admin-configuration/PTOs/PTOs-List";
import PTOTabs from "./pages/admin-configuration/PTOs/PTOs-tabs";
import PTOTabRoutes from "./pages/admin-configuration/PTOs/PTOs-tabs/routes/PTOTabRoutes";
import ReleaseNotesCards from "./pages/admin-configuration/ReleaseNotes/release-list";
import ReportsTabs from "./pages/admin-configuration/Reports/Reports-tabs";
import ReportsTabRoutes from "./pages/admin-configuration/Reports/Reports-tabs/routes/ReportsTabRoutes";
import DaysUsedHistory from "./pages/admin-configuration/Requests/DaysUsedHistory";
import RequestList from "./pages/admin-configuration/Requests/Requests-List";
import RequestListTabRoutes from "./pages/admin-configuration/Requests/Requests-List/route/RequestListTabRoutes";
import RequestTabs from "./pages/admin-configuration/Requests/Requests-Tabs";
import RequestTabRoutes from "./pages/admin-configuration/Requests/Requests-Tabs/routes/RequestTabRoutes";
import RoleForm from "./pages/admin-configuration/Roles/Roles-form";
import RolesList from "./pages/admin-configuration/Roles/Roles-list";
import RoleTabs from "./pages/admin-configuration/Roles/Roles-Tabs";
import RoleTabRoutes from "./pages/admin-configuration/Roles/Roles-Tabs/routes/RoleTabRoutes";

//kiosk devices 
import KioskForm from "./pages/admin-configuration/Kiosk-Devices/Kiosk-Devices-form";
import KioskDevicesList from "./pages/admin-configuration/Kiosk-Devices/Kiosk-Devices-list";
import KioskDevicesTabs from "./pages/admin-configuration/Kiosk-Devices/Kiosk-Devices-tabs";
import KioskDevicesTabRoutes from "./pages/admin-configuration/Kiosk-Devices/Kiosk-Devices-tabs/routes/KioskTabRoutes";


import SkillsList from "./pages/admin-configuration/Skills/Skills-list";
import TaskForm from "./pages/admin-configuration/Tasks/Tasks-form";
import TasksBacklog from "./pages/admin-configuration/Backlog";
import TasksList from "./pages/admin-configuration/Tasks/Tasks-list";
import RunningTasks from "./pages/admin-configuration/Tasks/Tasks-list/RunningTasks";
import TaskTabs from "./pages/admin-configuration/Tasks/Tasks-tabs";
import TasksTabRoutes from "./pages/admin-configuration/Tasks/Tasks-tabs/routes/TasksTabRoutes";
import TaskStatusList from "./pages/admin-configuration/TaskStatus/TaskStatus-list";
import TeamEmployeeList from "./pages/admin-configuration/Teams/Team-Employees-list";
import TeamsList from "./pages/admin-configuration/Teams/Teams-list";
import TenantForm from "./pages/admin-configuration/Tenants/Tenants-form";
import TicketForm from "./pages/admin-configuration/Tickets/Tickets-form";
import TicketsList from "./pages/admin-configuration/Tickets/Tickets-list";
import TicketTabs from "./pages/admin-configuration/Tickets/Tickets-tabs";
import TicketsTabRoutes from "./pages/admin-configuration/Tickets/Tickets-tabs/routes/TicketsTabRoutes";
import TicketStatusList from "./pages/admin-configuration/TicketStatus/TicketStatus-list";
import TimeEntriesList from "./pages/admin-configuration/TimeEntries/TimeEntries-list";
import MyTimesheet from "./pages/admin-configuration/Timesheets/MyTimesheet";
import TimesheetList from "./pages/admin-configuration/Timesheets/Timesheets-List";
import TimesheetsListTabRoutes from "./pages/admin-configuration/Timesheets/Timesheets-List/routes/TimesheetsListTabRoutes";
import Users from "./pages/admin-configuration/Users/Users-list";
import WorkShiftForm from "./pages/admin-configuration/WorkShifts/WorkShift-form";
import WorkShiftTabs from "./pages/admin-configuration/WorkShifts/Workshift-Tabs";
import WorkShiftTabRoutes from "./pages/admin-configuration/WorkShifts/Workshift-Tabs/routes/WorkShiftTabRoutes";
import WorkShiftList from "./pages/admin-configuration/WorkShifts/WorkShifts-list";
import Dashboard from "./pages/dashboard";
import DashboardTabRoutes from "./pages/dashboard/routes/DashboardTabRoutes";
import PasswordForm from "./pages/profile/password-form";
import ProfileTabs from "./pages/profile/profile-Tabs";
import ProfileTabRoutes from "./pages/profile/profile-Tabs/routes/ProfileTabRoutes";
import ExcelTimeSheet from "./pages/admin-configuration/Timesheets/InputExportTime"
import ImportExportTime from "./pages/admin-configuration/Timesheets/InputExportTime";
import ExcelTimeSheetTabRoutes from "./pages/admin-configuration/Timesheets/InputExportTime/routes/TimeSheetsListTabRoutes";
import PrintableInvoices from "./pages/admin-configuration/Projects/Project-invoices/printable_invoices";
import TenantTabs from "./pages/admin-configuration/Tenants/Tenants-Tabs";
import TenantTabRoutes from "./pages/admin-configuration/Tenants/Tenants-Tabs/routes/TenantTabRoutes";
import MonthlyTimesheet from "./pages/admin-configuration/Timesheets/MonthlyTimesheet";
import NewTimesheet from "./pages/admin-configuration/Timesheets/MonthlyTimesheet/new-timesheet";

const user_routes = [
  {
    type: "authentication",
    name: "admin-auth",
    path: urls.admin,
    isAdmin: false,
    element: <Authorization type={["not-entelligence"]} />,
    routes_inside: [
      {
        type: "nested",
        name: "dashboard",
        path: urls.my_dashboard,
        element: <Dashboard />,
        display: true,
        icon: <DashboardIcon color={colors.pure_white} />,
        label: "sideNav.Dashboard",
        routes_inside: [
          {
            type: "route",
            name: "dashboard",
            path: urls.my_dashboard,
            element: <DashboardTabRoutes value={3} />,
          },

          {
            type: "route",
            name: "dashboard",
            path: urls.my_dashboard_projects,
            element: <DashboardTabRoutes value={2} />,
          },
        ],
      },
      /**User Configurations */
      {
        type: "collapse",
        name: "userConfigurations",
        display: (role) =>
          !role ||
          role.isAppUser ||
          (role.manageSystemConfigurations === "N" &&
            role.manageParameters === "N")
            ? false
            : true,
        label: "sideNav.systemManagement",
        icon: <ConfigIcon color={colors.pure_white} />,
        inline_routes: [
          /**Tenant */
          {
            type: "nested",
            name: "my-tenants",
            path: urls.my_tenant_tabs,
            element: <TenantTabs myTenant />,
            routes_inside: [
              {
                type: "route",
                name: "my-tenants",
                path: urls.my_tenant,
                element: <TenantTabRoutes myTenant value={0} />,
              },
              {
                type: "route",
                name: "my-tenants",
                path: urls.my_tenant_licenses,
                element: <TenantTabRoutes myTenant value={1} />,
              },
            ],
          },
          {
            type: "route",
            name: "my-profile",
            path: urls.my_profile_employee_form_days_used,
            element: <DaysUsedHistory fromProfile />,
          },
          {
            type: "nested",
            name: "my-profile",
            path: urls.my_profile,
            element: <ProfileTabs />,
            routes_inside: [
              {
                type: "route",
                name: "my-profile",
                path: urls.my_profile,
                element: <ProfileTabRoutes value={0} />,
              },
              {
                type: "route",
                name: "my-profile",
                path: urls.my_ptos2,
                element: <ProfileTabRoutes value={1} />,
              },
              {
                type: "route",
                name: "my-profile",
                path: urls.my_skills2,
                element: <ProfileTabRoutes value={2} />,
              },
              {
                type: "route",
                name: "my-profile",
                path: urls.my_teams_profile,
                element: <ProfileTabRoutes value={4} />,
              },
              {
                type: "route",
                name: "my-loaned-items",
                path: urls.my_loaned_items,
                element: <ProfileTabRoutes value={5} />,
              },
            ],
          },

          {
            type: "route",
            name: "my-profile",
            path: urls.change_password,
            element: <PasswordForm />,
          },
          {
            type: "authentication",
            name: "admin-company-auth",
            path: urls.my_new_company,
            display: "manageParameters",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageParameters", ["M"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "my-company",
                path: urls.my_new_company,
                element: <CompanyForm myCompany />,
              },
            ],
          },

          {
            type: "authentication",
            name: "admin-company-auth",
            path: urls.my_company,
            display: "manageParameters",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageParameters", ["M", "R"])]}
              />
            ),
            routes_inside: [
              /**Companies */
              {
                type: "route",
                name: "my-company",
                path: urls.my_companies_list,
                element: <CompanyList myCompany />,
                display: (role) => true,
                label: "sideNav.myCompanies",
              },

              {
                type: "nested",
                name: "my-company",
                path: urls.my_company_tabs,
                element: <CompanyTabs myCompany />,
                routes_inside: [
                  {
                    type: "route",
                    name: "my-company",
                    path: urls.my_company_form,
                    element: <CompanyTabRoutes value={0} myCompany />,
                  },
                ],
              },
            ],
          },

          {
            type: "authentication",
            name: "admin-role-auth",
            path: urls.my_roles,
            display: "manageParameters",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageParameters", ["M", "R"])]}
              />
            ),
            routes_inside: [
              /**Roles */
              {
                type: "route",
                name: "my-roles",
                path: urls.my_roles_list,
                element: <RolesList myRoles />,
                display: (role) => true,
                label: "sideNav.myRoles",
              },

              {
                type: "authentication",
                name: "admin-new-location-auth",
                path: urls.my_new_role,
                display: "manageSystemConfigurations",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[
                      new RolePermissions("manageSystemConfigurations", ["M"]),
                    ]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "my-roles",
                    path: urls.my_new_role,
                    element: <RoleForm myRoles />,
                  },
                ],
              },

              {
                type: "nested",
                name: "my-roles",
                path: urls.my_roles_tabs,
                element: <RoleTabs myRoles />,
                routes_inside: [
                  {
                    type: "route",
                    name: "roles",
                    path: urls.my_roles_form,
                    element: <RoleTabRoutes value={0} myRoles />,
                  },
                ],
              },
            ],
          },
          {
            type: "authentication",
            name: "admin-role-auth",
            path: urls.my_kiosk_devices,
            display: "manageParameters",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageParameters", ["M", "R"])]}
              />
            ),
            routes_inside: [
              /**Roles */
              {
                type: "route",
                name: "kiosk-devices",
                path: urls.kiosk_devices_list,
                element: <KioskDevicesList myKioskDevices />,
                display: (role) => true,
                label: "sideNav.myKioskDevices",
              },

              {
                type: "authentication",
                name: "admin-new-location-auth",
                path: urls.new_kiosk_device,
                display: "manageSystemConfigurations",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[
                      new RolePermissions("manageSystemConfigurations", ["M"]),
                    ]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "kiosk-devices",
                    path: urls.new_kiosk_device,
                    element: <KioskForm myKioskDevices />,
                  },
                ],
              },

              {
                type: "nested",
                name: "kiosk-devices",
                path: urls.kiosk_devices_tabs,
                element: <KioskDevicesTabs myKioskDevices />,
                routes_inside: [
                  {
                    type: "route",
                    name: "roles",
                    path: urls.kiosk_devices_form,
                    element: <KioskDevicesTabRoutes value={0} myKioskDevices />,
                  },
                ],
              },
            ],
          },
          {
            type: "authentication",
            name: "admin-department-auth",
            path: urls.my_departments,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              /**Departments */
              {
                type: "route",
                name: "my-departments",
                path: urls.my_departments_list,
                element: <DepartmentsList myDepartments />,
                display: (role) => true,
                label: "sideNav.myDepartments",
              },
            ],
          },
          {
            type: "authentication",
            name: "admin-workshift-auth",
            path: urls.my_workshifts,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              /**Work Shifts */
              {
                type: "route",
                name: "my-workshifts",
                path: urls.my_workshifts_list,
                element: <WorkShiftList myWorkShifts />,
                display: (role) => true,
                label: "sideNav.myWorkShifts",
              },

              {
                type: "authentication",
                name: "admin-workshift-auth",
                path: urls.my_new_workshift,
                display: "manageSystemConfigurations",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[
                      new RolePermissions("manageSystemConfigurations", ["M"]),
                    ]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "my-workshifts",
                    path: urls.my_new_workshift,
                    element: <WorkShiftForm myWorkShifts />,
                  },
                ],
              },

              {
                type: "nested",
                name: "my-workshifts",
                path: urls.my_workshifts_tabs,
                element: <WorkShiftTabs myWorkShifts />,
                routes_inside: [
                  {
                    type: "route",
                    name: "workshifts",
                    path: urls.my_workshifts_form,
                    element: <WorkShiftTabRoutes value={0} myWorkShifts />,
                  },
                ],
              },
            ],
          },
          {
            type: "authentication",
            name: "admin-location-auth",
            path: urls.my_locations,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              /**Locations */
              {
                type: "route",
                name: "my-locations",
                path: urls.my_locations_list,
                element: <LocationsList myTenant />,
                display: (role) => true,
                label: "sideNav.myLocations",
              },
              {
                type: "authentication",
                name: "admin-new-location-auth",
                path: urls.my_new_location,
                display: "manageSystemConfigurations",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[
                      new RolePermissions("manageSystemConfigurations", ["M"]),
                    ]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "my-locations",
                    path: urls.my_new_location,
                    element: <LocationForm myTenant />,
                  },
                ],
              },

              {
                type: "nested",
                name: "my-locations",
                path: urls.my_locations_tabs,
                element: <LocationTabs myTenant />,
                routes_inside: [
                  {
                    type: "route",
                    name: "my-locations",
                    path: urls.my_locations_form,
                    element: <LocationsTabRoutes value={0} myTenant />,
                  },
                  {
                    type: "route",
                    name: "my-locations",
                    path: urls.my_locations_wifi,
                    element: <LocationsTabRoutes value={1} myTenant />,
                  },
                ],
              },
            ],
          },

          /**Official Holidays */
          {
            type: "authentication",
            name: "admin-official-holiday-auth",
            path: urls.my_official_holidays,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "my-official-holidays",
                path: urls.my_official_holidays,
                display: (role) => true,
                label: "sideNav.myOfficialHolidays",
                element: <OfficialHolidaysList />,
              },
            ],
          },

          {
            type: "authentication",
            name: "admin-official-holiday-auth",
            path: urls.my_official_holidays_tabs,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "nested",
                name: "my-official-holidays",
                path: urls.my_official_holidays_tabs,
                element: <OfficialHolidaysTabs />,
                routes_inside: [
                  {
                    type: "route",
                    name: "my-official-holidays",
                    path: urls.my_official_holidays_form,
                    element: <OfficialHolidayTabRoutes value={0} />,
                  },
                ],
              },
            ],
          },
          {
            type: "authentication",
            name: "admin-new-official-holiday-auth",
            path: urls.new_my_official_holidays,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "tenants",
                path: urls.new_my_official_holidays,
                element: <OfficialHolidayForm />,
              },
            ],
          },

          /**PTOS */
          {
            type: "authentication",
            name: "admin-pto-auth",
            path: urls.my_ptos,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "my-time-offs",
                path: urls.my_ptos,
                display: (role) => true,
                label: "sideNav.myPTOs",
                element: <PTOList />,
              },
            ],
          },

          {
            type: "authentication",
            name: "admin-pto-auth",
            path: urls.my_ptos_tabs,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "nested",
                name: "my-time-offs",
                path: urls.my_ptos_tabs,
                element: <PTOTabs />,
                routes_inside: [
                  {
                    type: "route",
                    name: "my-time-offs",
                    path: urls.my_ptos_form,
                    element: <PTOTabRoutes value={0} />,
                  },
                ],
              },
            ],
          },
          {
            type: "authentication",
            name: "admin-pto-auth",
            path: urls.new_my_pto,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "my-time-offs",
                path: urls.new_my_pto,
                element: <PTOForm />,
              },
            ],
          },
          // ///OnBoarding Tasks
          {
            type: "authentication",
            name: "onboarding-tasks",
            path: urls.onBoarding_tasks,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "onboarding-tasks",
                path: urls.onBoarding_tasks,
                display: (role) => true,
                label: "sideNav.onBoardingTasks",
                element: <OnBoardingTasks />,
              },
            ],
          },
          {
            type: "authentication",
            name: "admin-onBoardingTask-auth",
            path: urls.new_onBoarding_task,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "admin-onBoardingTask-auth",
                path: urls.new_onBoarding_task,
                element: <OnBoardingForm />,
              },
            ],
          },
          {
            type: "authentication",
            name: "admin-onBoardingTask-auth",
            path: urls.onBoarding_task_form,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "admin-onBoardingTask-auth",
                path: urls.onBoarding_task_form,
                element: <OnBoardingForm />,
              },
            ],
          },
          /**phase Status */
          {
            type: "authentication",
            name: "admin-phase-status-auth",
            path: urls.my_ticket_status,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "my-phase-statuses",
                path: urls.my_ticket_status_list,
                display: (role) => true,
                label: "sideNav.myTicketStatuses",
                element: <TicketStatusList />,
              },
            ],
          },

          /**Task Status */
          {
            type: "authentication",
            name: "admin-task-status-auth",
            path: urls.my_task_status,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "my-task-statuses",
                path: urls.my_task_status_list,
                display: (role) => true,
                label: "sideNav.myTaskStatuses",
                element: <TaskStatusList />,
              },
            ],
          },

          // /**AREAS */
          // {
          //   type: "authentication",
          //   name: "areas",
          //   path: urls.areas,
          //   display: "manageSystemConfigurations",
          //   element: (
          //     <Authorization
          //       type={["roles"]}
          //       roles={[
          //         new RolePermissions("manageSystemConfigurations", ["M", "R"]),
          //       ]}
          //     />
          //   ),
          //   routes_inside: [
          //     {
          //       type: "route",
          //       name: "areas",
          //       path: urls.areas,
          //       display: (role) => true,
          //       label: "sideNav.areas",
          //       element: <Areas />,
          //     },
          //   ],
          // },

          /**Skills */
          {
            type: "authentication",
            name: "admin-skills-auth",
            path: urls.my_skills,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "my-skills",
                path: urls.my_skills_list,
                display: (role) => true,
                label: "sideNav.mySkills",
                element: <SkillsList />,
              },
            ],
          },

          /**Contractors */
          {
            type: "authentication",
            name: "contractors-auth",
            path: urls.my_contractors,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "my-contractors",
                path: urls.my_contractors_list,
                display: (role) => true,
                label: "sideNav.myContractors",
                element: <ContractorsList />,
              },
            ],
          },
          {
            type: "authentication",
            name: "users",
            path: urls.users,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "users",
                path: urls.users,
                display: (role) => true,
                label: "sideNav.users",
                element: <Users />,
              },
            ],
          },
          //Logs
          {
            type: "route",
            name: "my-logs",
            display: (role) => true,
            path: urls.my_logs_list,
            element: <LogsList />,
            label: "sideNav.Logs",
          },
          {
            type: "route",
            name: "mylogDetails",
            display: false,
            path: urls.my_log_details,
            element: <LogDetails />,
            label: "sideNav.Log-Details",
          },
          {
            type: "route",
            name: "newsDetails",
            display: false,
            path: urls.news_details,
            element: <NewsDetails fromDetails />,
            label: "sideNav.newsDetails",
          },
          {
            type: "route",
            name: "newsForm",
            display: false,
            path: urls.new_news,
            element: <NewsDetails fromDetails />,
            label: "sideNav.newsDetails",
          },
        ],
      },
      /**Employee Reference */
      {
        type: "authentication",
        name: "entelligence-employee-reference-auth",
        path: urls.my_employee_reference,
        display: "manageUsers",
        element: (
          <Authorization
            type={["roles"]}
            roles={[new RolePermissions("manageUsers", ["M", "R"])]}
          />
        ),
        routes_inside: [
          {
            type: "nested",
            name: "my-employee-reference",
            path: urls.my_employee_reference_tabs,
            element: <EmployeeReferencesTabs />,
            routes_inside: [
              {
                type: "route",
                name: "tickets",
                path: urls.my_employee_reference_form,
                element: <EmployeeReferenceTabRoutes value={0} />,
              },
            ],
          },
        ],
      },
      {
        type: "authentication",
        name: "entelligence-employee-reference-auth",
        path: urls.my_new_employee_reference,
        display: "manageUsers",
        element: (
          <Authorization
            type={["roles"]}
            roles={[new RolePermissions("manageUsers", ["M"])]}
          />
        ),
        routes_inside: [
          {
            type: "route",
            name: "my-employee-reference",
            path: urls.my_new_employee_reference,
            element: <EmployeeReferencesForm />,
          },
        ],
      },

      /**Employees */
      {
        type: "route",
        name: "my-employees",
        path: urls.my_employees_list,
        element: <EmployeesList myEmployees />,
        display: (role) =>
          !role || role.isAppUser || role.manageUsers === "N" ? false : true,
        label: "sideNav.myEmployees",
        icon: <UserIcon color={colors.pure_white} width="21" height="21" />,
      },
      /**Teams */
      {
        type: "authentication",
        name: "admin-teams-auth",
        path: urls.my_teams,
        display: "manageUsers",
        element: (
          <Authorization
            type={["roles"]}
            roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
          />
        ),
        routes_inside: [
          {
            type: "route",
            name: "my-teams",
            path: urls.my_teams_list,
            display: (role) =>
              role.isEntelligence ||
              role.isAdmin ||
              role.manageTimeEntry !== "N"
                ? true
                : false,
            label: "sideNav.myTeams",
            element: <TeamsList />,
            icon: <TeamsIcon color={colors.pure_white} />,
          },
          {
            type: "authentication",
            name: "admin-team-employees-auth",
            path: urls.my_teams_employees,
            display: "manageUsers",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageUsers", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "my-teams",
                path: urls.my_teams_employees,
                element: <TeamEmployeeList />,
              },
            ],
          },
        ],
      },

      {
        type: "authentication",
        name: "admin-new-employee-auth",
        path: urls.my_new_employee,
        display: "manageUsers",
        element: (
          <Authorization
            type={["roles"]}
            roles={[new RolePermissions("manageUsers", ["M"])]}
          />
        ),
        routes_inside: [
          {
            type: "route",
            name: "my-employees",
            path: urls.my_new_employee,
            element: <EmployeeForm myEmployees />,
          },
        ],
      },
      {
        type: "route",
        name: "employees",
        path: urls.my_employee_form_days_used,
        element: <DaysUsedHistory />,
      },
      {
        type: "nested",
        name: "my-employees",
        path: urls.my_employee_tabs,
        element: <EmployeeTabs myEmployees />,
        routes_inside: [
          {
            type: "route",
            name: "my-employees",
            path: urls.my_employee_form,
            element: <EmployeeTabRoutes myEmployees value={0} />,
          },
          {
            type: "route",
            name: "my-employees",
            path: urls.my_employee_reference_list,
            element: <EmployeeTabRoutes myEmployees value={1} />,
          },
          {
            type: "route",
            name: "my-employees",
            path: urls.my_employee_ptos,
            element: <EmployeeTabRoutes myEmployees value={2} />,
          },
          {
            type: "route",
            name: "my-employees",
            path: urls.my_employee_skills,
            element: <EmployeeTabRoutes myEmployees value={3} />,
          },
          {
            type: "route",
            name: "my-employees",
            path: urls.my_employee_teams,
            element: <EmployeeTabRoutes myEmployees value={4} />,
          },
          {
            type: "route",
            name: "loaned-items",
            path: urls.loaned_items,
            element: <EmployeeTabRoutes myEmployees value={5} />,
          },
          {
            type: "route",
            name: "onboarding-tasks",
            path: urls.employee_onboarding_tasks,
            element: <EmployeeTabRoutes myEmployees value={6} />,
          },
          {
            type: "route",
            name: "employee_projects",
            path: urls.employee_projects_list,
            element: <EmployeeTabRoutes myEmployees value={7} />,
          },
        ],
      },
      //   ],
      // },
      {
        type: "collapse",
        name: "timeManagement",
        display: (role) =>
          !role ||
          (role.isAppUser ? false : true && role.manageTimeEntry !== "N"),
        label: "sideNav.timeManagement",
        icon: <TimeManagementIcon color={colors.pure_white} />,
        inline_routes: [
          {
            type: "authentication",
            name: "admin-project-auth",
            path: urls.my_projects,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              /**Projects */
              {
                type: "route",
                name: "my-projects",
                path: urls.my_projects_list,
                element: <ProjectsList />,
                display: (role) => true,
                label: "sideNav.myProjects",
              },
              {
                type: "authentication",
                name: "admin-new-project-auth",
                path: urls.my_new_project,
                display: "manageTimeEntry",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[new RolePermissions("manageTimeEntry", ["M"])]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "my-projects",
                    path: urls.my_new_project,
                    element: <ProjectForm />,
                  },
                ],
              },

              {
                type: "nested",
                name: "my-projects",
                path: urls.my_projects_tabs,
                element: <ProjectTabs />,
                routes_inside: [
                  {
                    type: "route",
                    name: "my-projects",
                    path: urls.my_projects_form,
                    element: <ProjectsTabRoutes value={0} />,
                  },
                  {
                    type: "authentication",
                    name: "my-projects-members--auth",
                    path: urls.project_members,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "my-projects-members-",
                        path: urls.project_members,
                        element: <ProjectsTabRoutes value={1} />,
                      },
                    ],
                  },
                  {
                    type: "authentication",
                    name: "admin-phase-auth",
                    path: urls.project_plan,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "my-projects",
                        path: urls.project_plan,
                        element: <ProjectsTabRoutes value={2} fromCompany />,
                      },
                    ],
                  },
                  {
                    type: "authentication",
                    name: "admin-phase-auth",
                    path: urls.project_area,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "my-projects",
                        path: urls.project_area,
                        element: <ProjectsTabRoutes value={5} fromCompany />,
                      },
                    ],
                  },
                  {
                    type: "authentication",
                    name: "admin-phase-auth",
                    path: urls.invoices,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "my-projects",
                        path: urls.invoices,
                        element: <ProjectsTabRoutes value={6} fromCompany />,
                      },
                    ],
                  },

                  {
                    type: "authentication",
                    name: "admin-phase-auth",
                    path: urls.project_tree,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "my-projects",
                        path: urls.project_tree,
                        element: <ProjectsTabRoutes value={3} fromCompany />,
                      },
                    ],
                  },
                  {
                    type: "authentication",
                    name: "admin-phase-auth",
                    path: urls.my_project_tickets,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "my-projects",
                        path: urls.my_project_tickets,
                        element: <ProjectsTabRoutes value={3} fromCompany />,
                      },
                    ],
                  },
                  {
                    type: "authentication",
                    name: "my-tickets-auth",
                    path: urls.my_project_direct_tasks,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "my-tickets",
                        path: urls.my_project_direct_tasks,
                        element: <ProjectsTabRoutes value={4} />,
                      },
                    ],
                  },
                ],
              },
            ],
          },

          /**Tickets */
          {
            type: "authentication",
            name: "admin-phase-auth",
            path: urls.my_project_tickets,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "my-tickets",
                path: urls.my_project_tickets_list,
                element: <TicketsList />,
                display: (role) => false,
                label: "sideNav.myTickets",
              },
              {
                type: "authentication",
                name: "admin-new-phase-auth",
                path: urls.my_new_project_ticket,
                display: "manageTimeEntry",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[new RolePermissions("manageTimeEntry", ["M"])]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "my-tickets",
                    path: urls.my_new_project_ticket,
                    element: <TicketForm />,
                  },
                ],
              },

              {
                type: "nested",
                name: "my-tickets",
                path: urls.my_project_tickets_tabs,
                element: <TicketTabs />,
                routes_inside: [
                  {
                    type: "route",
                    name: "my-tickets",
                    path: urls.my_project_tickets_form,
                    element: <TicketsTabRoutes value={0} />,
                  },
                  {
                    type: "authentication",
                    name: "my-tickets-auth",
                    path: urls.my_project_tasks,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "my-tickets",
                        path: urls.my_project_tasks,
                        element: <TicketsTabRoutes value={1} />,
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            type: "authentication",
            name: "appliedInvoices",
            path: urls.appliedInvoices,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "appliedInvoices",
                path: urls.appliedInvoices,
                element: <AppliedInvoices />,
              },
            ],
          },
          {
            type: "authentication",
            name: "favorite-appliedInvoices",
            path: urls.favorite_appliedInvoices,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "favorite-appliedInvoices",
                path: urls.favorite_appliedInvoices,
                element: <AppliedInvoices />,
              },
            ],
          },
          /**Tasks */
          {
            type: "authentication",
            name: "admin-task-auth",
            path: urls.my_project_tasks,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "my-projects",
                path: urls.my_project_tasks,
                element: <ProjectsTabRoutes value={1} fromCompany />,
              },
            ],
          },

          //Printable Invoice
          {
            type: "authentication",
            name: "admin-task-auth",
            path: urls.invoices,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "my-projects",
                path: urls.printable_invoices,
                element: <PrintableInvoices />,
              },
            ],
          },
          {
            type: "authentication",
            name: "admin-task-auth",
            path: urls.favorite_invoices,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "my-projects",
                path: urls.favorite_printable_invoices,
                element: <PrintableInvoices fromFavorites={true} />,
              },
            ],
          },
          {
            type: "authentication",
            name: "admin-task-auth",
            path: urls.my_project_tasks,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "my-tasks",
                path: urls.my_project_tasks_list,
                element: <TasksList />,
                display: (role) => false,
                label: "sideNav.myTasks",
              },
              {
                type: "authentication",
                name: "admin-new-task-auth",
                path: urls.my_new_project_task,
                display: "manageTimeEntry",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[new RolePermissions("manageTimeEntry", ["M"])]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "my-tasks",
                    path: urls.my_new_project_task,
                    element: <ProjectTree />,
                  },
                ],
              },
              {
                type: "authentication",
                name: "admin-new-task-auth",
                path: urls.my_new_project_subtask,
                display: "manageTimeEntry",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[new RolePermissions("manageTimeEntry", ["M"])]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "my-tasks",
                    path: urls.my_new_project_subtask,
                    element: <ProjectTree subtask />,
                  },
                ],
              },

              {
                type: "nested",
                name: "my-tasks",
                path: urls.my_project_tasks_tabs,
                element: <TaskTabs />,
                routes_inside: [
                  {
                    type: "route",
                    name: "my-tasks",
                    path: urls.my_project_tasks_form,
                    element: <TasksTabRoutes value={0} />,
                  },
                  {
                    type: "route",
                    name: "my-tasks",
                    path: urls.my_project_tasks_time_entries,
                    element: <TasksTabRoutes value={1} />,
                  },
                ],
              },
            ],
          },
          {
            type: "authentication",
            name: "admin-task-auth",
            path: urls.my_project_direct_tasks,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "authentication",
                name: "admin-new-task-auth",
                path: urls.my_direct_new_project_task,
                display: "manageTimeEntry",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "my-tasks",
                    path: urls.my_direct_new_project_task,
                    element: <ProjectTree fromProject />,
                  },
                ],
              },
              {
                type: "authentication",
                name: "admin-new-task-auth",
                path: urls.my_direct_new_project_subtask,
                display: "manageTimeEntry",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "my-tasks",
                    path: urls.my_direct_new_project_subtask,
                    element: <ProjectTree fromProject subtask />,
                  },
                ],
              },

              {
                type: "nested",
                name: "my-tasks",
                path: urls.my_project_direct_tasks_tabs,
                element: <TaskTabs fromProject />,
                routes_inside: [
                  {
                    type: "route",
                    name: "my-tasks",
                    path: urls.my_project_direct_tasks_form,
                    element: <TasksTabRoutes value={0} fromProject />,
                  },
                  {
                    type: "route",
                    name: "my-tasks",
                    path: urls.my_project_direct_tasks_time_entries,
                    element: <TasksTabRoutes value={1} fromProject />,
                  },
                ],
              },
            ],
          },

          //My Favorite Projects
          {
            type: "authentication",
            name: "favorite-projects",
            path: urls.favorite_projects,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "nested",
                name: "my-favorite-projects",
                path: urls.my_favorite_projects_tabs,
                element: <ProjectTabs fromFavorites />,
                routes_inside: [
                  {
                    type: "route",
                    name: "my-projects",
                    path: urls.my_favorite_projects_form,
                    element: (
                      <ProjectsTabRoutes fromFavorites={true} value={0} />
                    ),
                  },
                  {
                    type: "authentication",
                    name: "my-favorite-projects-members--auth",
                    path: urls.favorite_project_members,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "my-favorite-projects-members-",
                        path: urls.favorite_project_members,
                        element: <ProjectsTabRoutes value={1} />,
                      },
                    ],
                  },
                  {
                    type: "authentication",
                    name: "admin-phase-auth",
                    path: urls.favorite_project_plan,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "my-favorite-projects",
                        path: urls.favorite_project_plan,
                        element: <ProjectsTabRoutes value={2} fromCompany />,
                      },
                    ],
                  },
                  {
                    type: "authentication",
                    name: "admin-phase-auth",
                    path: urls.favorite_project_area,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "my-favorite-projects",
                        path: urls.favorite_project_area,
                        element: <ProjectsTabRoutes value={5} fromCompany />,
                      },
                    ],
                  },
                  {
                    type: "authentication",
                    name: "admin-phase-auth",
                    path: urls.favorite_invoices,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "my-projects",
                        path: urls.favorite_invoices,
                        element: (
                          <ProjectsTabRoutes value={6} fromFavorites={true} />
                        ),
                      },
                    ],
                  },

                  {
                    type: "authentication",
                    name: "my-tickets-auth",
                    path: urls.my_favorite_project_direct_tasks,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "my-tickets",
                        path: urls.my_favorite_project_direct_tasks,
                        element: <ProjectsTabRoutes value={4} />,
                      },
                    ],
                  },
                ],
              },
              {
                type: "route",
                name: "favorite-projects",
                path: urls.favorite_projects,
                display: (role) => true,
                label: "sideNav.favProjects",
                element: <ProjectList fromFavorites />,
              },
              // {
              //   type: "route",
              //   name: "my-projects",
              //   path: urls.my_favorite_projects_form,
              //   element: <ProjectForm  />,
              // },
              {
                type: "authentication",
                name: "favorite-project-form",
                path: urls.my_new_favorite_project,
                display: "manageTimeEntry",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[new RolePermissions("manageTimeEntry", ["M"])]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "my-projects",
                    path: urls.my_new_favorite_project,
                    element: <ProjectForm fromFavorites={true} />,
                  },
                ],
              },
            ],
          },

          // Employee Availability
          {
            type: "authentication",
            name: "admin-employee-availability-auth",
            path: urls.my_employees_availability,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "check-employees-availability",
                path: urls.my_employees_availability,
                display: (role) => true,
                label: "sideNav.myEmployeeAvailability",
                element: <EmployeeAvailability />,
              },
            ],
          },

          /**Timesheet */
          {
            type: "authentication",
            name: "admin-timesheet-auth",
            path: urls.my_timesheets,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "my-timesheets",
                path: urls.my_approved_timesheets,
                display: () => "monthlyTimesheet:true",
                label: "sideNav.myTimesheets",
                element: <MonthlyTimesheet />,
              },
              {
                type: "route",
                name: "my-timesheets",
                path: urls.my_created_timesheets,
                label: "sideNav.myTimesheets",
                element: <NewTimesheet />,
              },
              {
                type: "route",
                name: "my-timesheets",
                path: urls.my_approved_timesheet_form,
                label: "sideNav.myTimesheets",
                element: <NewTimesheet />,
              },
              {
                type: "nested",
                name: "my-timesheets",
                path: urls.my_timesheets_list,
                display: "monthlyTimesheet:false",
                label: "sideNav.myTimesheets",
                element: <TimesheetList />,
                routes_inside: [
                  {
                    type: "route",
                    name: "my-timesheets",
                    path: urls.my_timesheets_list_pending,
                    display: () => "monthlyTimesheet:false",
                    element: <TimesheetsListTabRoutes value={0} />,
                  },
                  {
                    type: "route",
                    name: "my-timesheets",
                    path: urls.my_timesheets_list_approved,
                    display: () => "monthlyTimesheet:false",
                    element: <TimesheetsListTabRoutes value={1} />,
                  },
                ],
              },
            ],
          },
          // /**Monthly Timesheet */
          // {
          //   type: "authentication",
          //   name: "admin-timesheet-auth",
          //   path: urls.my_timesheets,
          //   display: "manageTimeEntry",
          //   element: (
          //     <Authorization
          //       type={["roles"]}
          //       roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
          //     />
          //   ),
          //   routes_inside: [
          //     {
          //       type: "route",
          //       name: "my-timesheets",
          //       path: urls.my_timesheets_list,
          //       display: (role, auth) =>  true,
          //       label: "sideNav.myTimesheets",
          //       element: <NewTimesheet />,
          //     },
          //   ],
          // },
          //Running Tasks
          {
            type: "authentication",
            name: "admin-running-tasks-auth",
            path: urls.running_tasks,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "running-tasks",
                path: urls.running_tasks,
                display: (role) => true,
                label: "sideNav.myRunningTasks",
                element: <RunningTasks />,
              },
            ],
          },

           //Input Output Time
           {
            type: "authentication",
            name: "import-output-time",
            path: urls.import_output_time,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              
              {
                    type: "nested",
                    name: "import-output-time",
                    path: urls.import_output_time_list,
                    display: true,
                    label: "sideNav.importExportTime",
                    element: <ImportExportTime />,
                    routes_inside: [
                  
                    
                      // {
                      //   type: "route",
                      //   name: "import-time",
                      //   path: urls.import_time,
                      //   element: <ExcelTimeSheetTabRoutes value={0} />,
                      // },
                      {
                        type: "route",
                        name: "export-time",
                        path: urls.export_time_by_resource,
                        element: <ExcelTimeSheetTabRoutes value={1} />,
                      },
                      {
                        type: "route",
                        name: "excel_time_entries",
                        path: urls.excel_time_entries,
                        element: <ExcelTimeSheetTabRoutes value={2} />,
                      },
                  
                ],
                  },
              
            ],
          },
        ],
      },
      {
        type: "collapse",
        name: "attendanceManagement",
        display: (role) =>
          !role ||
          (role.isAppUser ? false : true && role.manageAttendance !== "N"),
        label: "sideNav.attendanceManagement",
        icon: <AttendanceManagementIcon color={colors.pure_white} />,
        inline_routes: [
          /**Attendancesheet */
          {
            type: "authentication",
            name: "admin-attendance-sheet-auth",
            path: urls.my_attendance_sheets,
            display: "manageAttendance",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageAttendance", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "nested",
                name: "my-attendance-sheets",
                path: urls.my_attendance_sheets_list,
                display: true,
                label: "sideNav.myAttendanceSheets",
                element: <AttendanceSheetList />,
                routes_inside: [
                  {
                    type: "route",
                    name: "my-timesheets",
                    path: urls.my_attendance_sheets_list_pending,
                    element: <AttendanceSheetsListTabRoutes value={0} />,
                  },
                  {
                    type: "route",
                    name: "my-timesheets",
                    path: urls.my_attendance_sheets_list_approved,
                    element: <AttendanceSheetsListTabRoutes value={1} />,
                  },
                ],
              },
            ],
          },
          //Running Attendances
          {
            type: "authentication",
            name: "admin-running-attendances-auth",
            path: urls.running_attendances,
            display: "manageAttendance",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageAttendance", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "running-attendances",
                path: urls.running_attendances,
                display: (role) => true,
                label: "sideNav.myRunningAttendances",
                element: <AttendancesList />,
              },
            ],
          },
        ],
      },
      /**Backlog */
      {
        type: "route",
        name: "backlog",
        path: urls.project_backlog,
        display: (role) => (role.manageTimeEntry !== null ? true : false),
        label: "sideNav.backlog",
        element: <TasksList fromProject fromDashboard outSide />,
        icon: <BacklogIcon color={colors.pure_white} />,
      },
     
      {
        type: "route",
        name: "backlog",
        path: urls.project_backlog_new_task,
        element: <ProjectTree fromProject outSide />,
      },
      {
        type: "route",
        name: "backlog",
        path: urls.project_backlog_new_subtask,
        element: <ProjectTree fromProject outSide subtask />,
      },
      {
        type: "route",
        name: "backlog",
        path: urls.project_backlog_tasks,
        element: <ProjectTree fromProject outSide />,
      },
     

      /**My Time */
      {
        type: "route",
        name: "time-entries-list",
        path: urls.my_time_entries,
        display: (role) => (role.manageTimeEntry !== null ? true : false),
        label: "sideNav.timeEntries",
        element: <TimeEntriesList myTime />,
        icon: <TimeEntryIcon color={colors.pure_white} />,
      },

      /**My Timesheet */
      {
        type: "route",
        name: "time-sheet",
        path: urls.my_timesheet,
        display: false,
        // display: () => true,
        label: "sideNav.my.timesheet",
        element: <MyTimesheet />,
        icon: <TimesheetIcon color={colors.pure_white} />,
      },

   

      /**My Attendance */
      {
        type: "route",
        name: "attendance-list",
        path: urls.my_attendances,
        display: (role) => (role.manageAttendance !== null ? true : false),
        label: "sideNav.attendances",
        element: <AttendancesList myAttendance />,
        icon: <TimesheetIcon color={colors.pure_white} />,
      },
      /**Requests */
      {
        type: "nested",
        name: "my-requests",
        path: urls.my_requests_list,
        display: true,
        label: "sideNav.myRequests",
        element: <RequestList />,
        icon: <RequestsIcon color={colors.pure_white} />,
        routes_inside: [
          {
            type: "route",
            name: "my-requests",
            path: urls.my_requests,
            element: <RequestListTabRoutes value={0} />,
          },
          {
            type: "route",
            name: "my-requests",
            path: urls.my_received_requests,
            element: <RequestListTabRoutes value={1} />,
          },
        ],
      },
      {
        type: "nested",
        name: "my-requests",
        path: urls.my_requests_tabs,
        element: <RequestTabs />,
        routes_inside: [
          {
            type: "route",
            name: "my-requests",
            path: urls.my_attendance_requests_form,
            element: <RequestTabRoutes value={0} />,
          },
          {
            type: "route",
            name: "my-requests",
            path: urls.my_new_attendance_request,
            element: <RequestTabRoutes value={0} newRequest />,
          },
          {
            type: "route",
            name: "my-requests",
            path: urls.my_requests_form,
            element: <RequestTabRoutes value={0} />,
          },
          {
            type: "route",
            name: "my-requests",
            path: urls.my_new_request,
            element: <RequestTabRoutes value={0} newRequest />,
          },
        ],
      },
      /**Reports */
      {
        type: "authentication",
        name: "admin-reports-auth",
        path: urls.reportsTabs,
        display: "manageAttendance&&manageTimeEntry",
        element: (
          <Authorization
            type={["roles"]}
            roles={[
              new RolePermissions("manageAttendance", ["M", "R", "N"]),
              new RolePermissions("manageTimeEntry", ["M", "R", "N"]),
            ]}
          />
        ),
        routes_inside: [
          {
            type: "nested",
            name: "reports",
            path: urls.reports,
            display: "manageAttendance&&manageTimeEntry",
            label: "sideNav.reports",
            element: <ReportsTabs />,
            icon: <ReportsIcon color={colors.pure_white} />,
            routes_inside: [
              {
                type: "route",
                name: "reports",
                path: urls.reports_employee_timesheet,
                element: <ReportsTabRoutes value={0} />,
              },
              {
                type: "route",
                name: "reports",
                path: urls.reports_employee_attendance_sheet,
                element: <ReportsTabRoutes value={1} />,
              },
              {
                type: "route",
                name: "reports",
                path: urls.reports_attendance_by_employee,
                element: <ReportsTabRoutes value={2} />,
              },
              {
                type: "route",
                name: "reports",
                path: urls.reports_time_by_employee,
                element: <ReportsTabRoutes value={3} />,
              },
              {
                type: "route",
                name: "reports",
                path: urls.reports_time_by_project,
                element: <ReportsTabRoutes value={4} />,
              },
              {
                type: "route",
                name: "reports",
                path: urls.reports_time_by_contractor,
                element: <ReportsTabRoutes value={5} />,
              },
              {
                type: "route",
                name: "reports",
                path: urls.reports_time_by_external,
                element: <ReportsTabRoutes value={6} />,
              },
              {
                type: "route",
                name: "reports",
                path: urls.reports_excel,
                element: <ReportsTabRoutes value={7} />,
              },
            ],
          },
        ],
      },
      {
        type: "route",
        name: "news",
        display: (role) => (role && role.isAdmin ? true : false),
        path: urls.news_list,
        element: <News fromTenant />,
        label: "sideNav.news",
        icon: <NewsIcon color={colors.pure_white} />,
      },
      {
        type: "route",
        name: "releaseNotesDetails",
        display: false,
        path: urls.user_releaseNotes_details,
        element: <ReleaseNotesCards showCards />,
        label: "sideNav.releaseNotesDetails",
      },
    ],
  },
];

export default user_routes;
