import React, { useState, useEffect, useCallback } from "react";

//get filter types
import {
  UPDATE_VALUE,
  UPDATE_LIST,
  UPDATE_OBJECT,
  UPDATE_DATE,
  CLEAR_VALUE,
} from "../../types/filterTypes";

//external components
import {
  Switch,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
  RadioGroup,
  InputLabel,
  Radio,
} from "@mui/material";

//components
import RoundedTextField from "../TextFields/RoundedTextField";
import RoundedDatePicker from "../DateField/RoundedDatePicker";
import AutoComplete from "../AutoComplete";
//hooks
import { useTranslation } from "react-i18next";

import "./style.css";
import colors from "../../assets/theme/light/colors";

const FilterField = ({
  label = "",
  name = "",
  properties = {},
  type = "",
  filtersState = {},
  handleSaveState = () => {},
  handleFilters = () => {},
  fetchData = () => {},
  setSelectedData = () => {},
  getOptionLabel = () => "",
  options = [],
  fieldsToClear = [],
  access = "",
  loading = false,
  disabled = false,
  group = false,
  groupBy = () => "",
  selectedData = null,
  disabledProperty,
  disabledValue,
}) => {
  const { t } = useTranslation();

  const [multipleData, setMultipleData] = useState([]);
  const [data, setData] = useState(selectedData);
  const [dropdownFocused, setDropdownFocused] = useState(null);
  const resetDataToMultipleData = useCallback((array = []) => {
    let arr = options.filter((x) => array.includes(eval("x." + access)));
    setMultipleData(arr);
  }, []);


  useEffect(() => {
    if (
      type === "multiple" &&
      filtersState &&
      filtersState[name] &&
      filtersState[name].length > 0
    ) {
      resetDataToMultipleData(filtersState[name]);
    }
  }, [resetDataToMultipleData]);

  useEffect(() => {
    if (type === "multiple") {
      if (
        (!filtersState[name] || filtersState[name].length === 0) &&
        multipleData.length > 0
      ) {
        setMultipleData([]);
      }
    } else if (type === "autocomplete") {
      if (!filtersState[name] && data) {
        setData(null);
      }
    }
  }, [filtersState]);

  if (type === "date") {
    return (
      <RoundedDatePicker
        label={t(label)}
        name={name}
        disabled={disabled}
        value={filtersState && filtersState[name] ? new Date(filtersState[name]) : null}
        onChange={(newValue) => {
          handleSaveState({ value: newValue ? newValue.toDateString() : newValue, property: name });
          handleFilters({
            type: UPDATE_DATE,
            payload: { value: newValue ? newValue.toDateString() : newValue, property: name },
          });
        }}
      />
    );
  }

  if (type === "text") {
    return (
      <RoundedTextField
        label={t(label)}
        fullWidth
        name={name}
        disabled={disabled}
        size="small"
        value={filtersState && filtersState[name] ? filtersState[name] : ""}
        onChange={(e) => {
          handleSaveState({ value: e.target.value, property: name });
          handleFilters({
            type: UPDATE_VALUE,
            payload: {
              value: e.target.value,
              property: name,
            },
          });
        }}
        InputProps={{ inputProps: properties }}
      />
    );
  }

  if (type === "number") {
    return (
      <RoundedTextField
        label={t(label)}
        fullWidth
        type="number"
        name={name}
        disabled={disabled}
        size="small"
        onKeyPress={(e) => {
          if (
            e.nativeEvent.key === "-" ||
            e.nativeEvent.key === "e" ||
            e.nativeEvent.key === "."
          ) {
            e.preventDefault();
          }
        }}
        value={filtersState && filtersState[name] ? filtersState[name] : null}
        onChange={(e) => {
          handleSaveState({ value: e.target.value, property: name });
          handleFilters({
            type: UPDATE_VALUE,
            payload: {
              value:
                e.target.value !== null && e.target.value !== ""
                  ? parseInt(e.target.value)
                  : null,
              property: name,
            },
          });
        }}
        InputProps={{ inputProps: properties }}
      />
    );
  }

  if (type === "text/number") {
    return (
      <RoundedTextField
        label={t(label)}
        fullWidth
        type="number"
        name={name}
        disabled={disabled}
        size="small"
        onKeyPress={(e) => {
          if (
            e.nativeEvent.key === "-" ||
            e.nativeEvent.key === "e" ||
            e.nativeEvent.key === "."
          ) {
            e.preventDefault();
          }
        }}
        value={filtersState && filtersState[name] ? filtersState[name] : null}
        onChange={(e) => {
          handleSaveState({ value: e.target.value, property: name });
          handleFilters({
            type: UPDATE_VALUE,
            payload: {
              value: e.target.value,
              property: name,
            },
          });
        }}
        InputProps={{ inputProps: properties }}
      />
    );
  }

  if (type === "multiple") {
    return (
      <AutoComplete
        multiple
        loading={loading}
        options={options}
        getOptionLabel={getOptionLabel}
        identifier={access}
        name={name}
        disabled={disabled}
        size="small"
        key={name}
        value={multipleData}
        groupBy={groupBy}
        onOpen={() => fetchData(true)}
        onClose={() => fetchData(false)}
        onChange={(event, value) => {
          handleSaveState({
            value: value.length == 0 ? null : value,
            property: name,
          });
          setMultipleData(value);
          handleFilters({
            type: UPDATE_LIST,
            payload: { value: value, property: name, access: access },
          });
          setSelectedData(value);
        }}
        label={t(label)}
        roundedTextField
      />
    );
  }

  if (type === "multiple" && group === true) {
    return (
      <AutoComplete
        multiple
        loading={loading}
        options={options}
        getOptionLabel={getOptionLabel}
        identifier={access}
        name={name}
        disabled={disabled}
        size="small"
        key={name}
        value={multipleData}
        onOpen={() => fetchData(true)}
        onClose={() => fetchData(false)}
        onChange={(event, value) => {
          handleSaveState({
            value: value.length == 0 ? null : value,
            property: name,
          });
          setMultipleData(value);
          handleFilters({
            type: UPDATE_LIST,
            payload: { value: value, property: name, access: access },
          });
          setSelectedData(value);
        }}
        label={t(label)}
        roundedTextField
      />
    );
  }

  if (type === "dropdown") {
    return (
      <FormControl
        fullWidth
        size="small"
        sx={{
          ".MuiInputBase-root": {
            borderRadius: "50px !important",
          },
        }}
      >
        <InputLabel
          id={name}
          shrink={dropdownFocused || (filtersState && filtersState[name])}
          sx={dropdownFocused || (filtersState && filtersState[name]) ? {
              fontSize: "14px !important",
              position: "absolute",
              top: 0,
              left: 0,
              transformOrigin: "top left",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              zIndex: '2 !important',
              backgroundColor: `${colors.pure_white} !important`,
              padding: "0 0 0 10px !important",
            
          } : {
            fontSize: "14px !important",
          }}
        >
          {t(label)}
        </InputLabel>
        <Select
          labelId={name}
          id={name}
          name={name}
          disabled={disabled}
          placeholder={t(label)}
          value={filtersState && filtersState[name] ? filtersState[name] : null}
          label={t(label)}
          sx={{
            fontSize: "14px !important",
          }}
          onFocus={() => setDropdownFocused(true)}
          onBlur={() => setDropdownFocused(true)}
          onChange={(event, value) => {
            handleSaveState({ value: event.target.value, property: name });
            handleFilters({
              type: UPDATE_VALUE,
              payload: {
                value: event.target.value,
                property: name,
              },
            });
          }}
        >
          {options &&
            options.map((option, index) =>
              option.value == "" ? (
                <MenuItem key={index} value={option.value} selected>
                  {option.label}
                </MenuItem>
              ) : (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              )
            )}
        </Select>
      </FormControl>
    );
  }

  if (type === "radio-group") {
    return (
      <>
        <InputLabel sx={{fontSize: "14px !important"}}>{t(label)}</InputLabel>
        <FormControl fullWidth size="small"
            sx={{".MuiTypography-root": {fontSize: "14px !important"}}}>
          <RadioGroup
            row
            aria-labelledby={name}
            name={name}
            disabled={disabled}
            value={
              filtersState && filtersState[name] ? filtersState[name] : "none"
            }
            onChange={(event, value) => {
              handleSaveState({
                value: Number.parseInt(event.target.value),
                property: name,
              });
              handleFilters({
                type: UPDATE_VALUE,
                payload: {
                  value: Number.parseInt(event.target.value),
                  property: name,
                },
              });
            }}
          >
            {options &&
              options.map((option, index) => (
                <FormControlLabel
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  key={index}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </>
    );
  }
  
  if (type === "check") {
    return (
      <FormControlLabel
        sx={{
          ".MuiTypography-root": { fontSize: "14px !important" },
          marginLeft: "5px !important",
        }}
        control={
          <Switch
            size="small"
            name={name}
            disabled={(disabledProperty !== undefined && disabledValue !== undefined) && filtersState[disabledProperty] == disabledValue
            }
            checked={filtersState[name] != null ? filtersState[name] : false}
            onChange={(event, value) => {
              handleFilters({
                type: UPDATE_VALUE,
                payload: { value: value, property: name },
              });
              handleSaveState({ value: value, property: name });
            }}
          />
        }
        label={t(label)}
      />
    );
  }

  if (type === "autocomplete") {
    return (
      <AutoComplete
        loading={loading}
        options={options}
        getOptionLabel={getOptionLabel}
        identifier={access}
        disabled={disabled}
        name={name}
        size="small"
        value={data}
        onOpen={() => fetchData(true)}
        onClose={() => fetchData(false)}
        onChange={(event, value) => {
          setData(value);
          handleSaveState({ value: value, property: name });
          handleFilters({
            type: UPDATE_OBJECT,
            payload: { value: value, property: name, access: access },
          });
          setSelectedData(value);
          fieldsToClear.forEach(field => {
            handleFilters({
              type: CLEAR_VALUE,
              payload: {
                value: null,
                property: field,
              },
            });
          })
        }}
        roundedTextField
        label={t(label)}
      />
    );
  }

  return null;
};

export default FilterField;
