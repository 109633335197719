import { gql } from "@apollo/client";

export const GET_ALL_TIMESHEETS = gql`
query ($model: GetTimeSheetListRequestModelInput!) {
    GetTimeSheetList(model: $model) {
      timeSheetList {
        timeSheets {
          timeSheetGuid
          description
          createdAt
          createdBy {
            firstName
            lastName
          }
          status
          workShift {
            workShiftGuid
            description
          }
          startDate
          endDate
          synced
        }
        totalNumberOfRecords
      }
      errorCode
      errorMessage
      errorVariables
    }
  }
`;

export const GET_TIMESHEETS_LIST = gql`
query ($model: GetTimeEntriesByTimeSheetRequestModelInput!) {
  GetTimeSheetTimeEntries(model: $model) {
    timeSheetTimeEntries {
      projectName
      dateFrom
      dateTo
      timeEntries {
        timeEntryGuid
        startTime
        duration
        modifiedBy{email}
        createdBy{email}
        creationDate
        modificationDate
        description
        employee 
        task
        taskID 
        project
        synced
        automaticallyClosed
        parentTaskGuid
        projectGuid
        taskGuid
        createdBy {
          email
        }
        creationDate
        modifiedBy {
          email
        }
        modificationDate
      }
      
      totalNumberOfRecords
      totalDuration
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const GET_TIMESHEET_BY_ID = gql`
query($id: UUID!) {
  GetTimeSheetById(timeSheetId: $id) {
    timeSheet {
      timeSheetGuid
      workShift {
        description
      }
      startDate
      endDate
    }
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const APPROVE_TIMESHEET = gql`
mutation($model: EditTimeSheetStatusRequestInput!){
  ApproveTimeSheet(model: $model) {
     timeSheet {
        timeSheetGuid
     }
     errorCode
     errorMessage
     errorVariables
  }
}
`;

export const DELETE_TIMESHEET=gql`
mutation($timesheetGuid:UUID!){
  DeleteTimeSheet (timeSheetId: $timesheetGuid) {
    success
    errorCode
    errorMessage
    errorVariables
  }
}
`

export const POST_TIMESHEET=gql`
mutation($model:ExternalTimeEntriesCreateRequestInput!){
  PostExternalTimeSheet (model: $model){
    postExternalTimeSheetResponse
    errorCode
    errorMessage
    errorVariables
  }
}
`;

export const CREATE_APPROVED_TIMESHEET=gql`
mutation ($model: CreateApprovedTimeSheetRequestInput!) {
  CreateApprovedTimeSheet(model: $model) {
    success
    errorCode
    errorMessage
    errorVariables
  }
} 
`

export const REOPEN_APPROVED_TIMESHEET=gql`
mutation ($id: UUID!) {
  ReopenApprovedTimeSheet(id: $id) {
    errorCode
    errorMessage
    errorVariables
    timeSheetView {
      project {
        projectGuid
        title
      }
      endDate
      startDate
    }
  }
} 
`
export const REMOVE_TIMEENTRIES=gql`
mutation ($model: RemoveTimeEntriesModelInput!) {
  RemoveTimeEntries(model: $model) {
    errorCode
    errorMessage
    errorVariables
    success
  }
} 
`