import React, { useState, useEffect, useCallback } from "react";

//routing
import { useNavigate, useParams } from "react-router-dom";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql

import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import {
  GET_ALL_INVOICES_LIST,
  UNDO_INVOICE,
} from "../../../../graphql/invoices";
import { PrintIcon, UndoIcon } from "../../../../components/Icon";
import colors from "../../../../assets/theme/light/colors";

const InvoicesList = ({ fromFavorites = false }) => {
  const { pID, pName, iName, iID } = useParams();

  const navigate = useNavigate();

  const {
    invoices,
    favorite_invoices,
    printable_invoices,
    favorite_printable_invoices,
    favorite_appliedInvoices,
    appliedInvoices
  } = urls;


  const { storeUserGuid, userRoles, storeUserTenant } = useAuth();

  const { manageParameters } = userRoles;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables["Invoices"],
          userID: storeUserGuid,
        },
      },
    },
    {
      onCompleted: (response) => {
        const col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) {
            const updatedColumns = parsed.columns.map((column) => {
              if (column.id === "Type") {
                return {
                  ...column,
                  renderCell: (row) => {
                    const typeValue = row?.type;
                    if (typeValue === "INVOICE") {
                      return "Invoice";
                    } else if (typeValue === "CN") {
                      return "Credit Memo";
                    }
                    return typeValue;
                  },
                };
              }
              return column;
            });

            setColumns(updatedColumns);
          }
          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: getAllInvoices, loading } = useLazyQueryAuth(
    GET_ALL_INVOICES_LIST,
    "GetProjectInvoicesList"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllInvoices(
        {
          variables: {
            model: {
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyword: search,
              projectGuid: pID,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData, re]);

  const rows =
    data &&
    data.GetProjectInvoicesList &&
    data.GetProjectInvoicesList.projectInvoicesListResponse &&
    data.GetProjectInvoicesList.projectInvoicesListResponse.projectInvoices
      ? data.GetProjectInvoicesList.projectInvoicesListResponse.projectInvoices
      : [];
  const numberOfRecords =
    data &&
    data.GetProjectInvoicesList &&
    data.GetProjectInvoicesList.projectInvoicesListResponse &&
    data.GetProjectInvoicesList.projectInvoicesListResponse.totalNumberOfRecords
      ? data.GetProjectInvoicesList.projectInvoicesListResponse
          .totalNumberOfRecords
      : 0;

  const { mfunction: undoRecord, loading: undoLoading } = useMutationAuth(
    UNDO_INVOICE,
    "UndoInvoicesAllocation"
  );

  const handleUndo = () => {
    undoRecord(
      {
        variables: {
          projectGuid: pID,

        },
      },
      (response) => {
        enqueueSnackbar(t("invoices.list.undo.success"), {
          variant: "success",
        });
      }
    );
  };

  const customButtons = [
    {
      label: "project.list.print",
      handleClick: () => {
        navigate(
          fromFavorites
            ? favorite_printable_invoices.replace(
                "/:pName/:pID",
                `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
              )
            : printable_invoices.replace(
                "/:pName/:pID",
                `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
              )
        );
      },
      condition: true,
      icon: true,
      iconButton: <PrintIcon color={"#000000"} />,
    },
    {
      label: "invoices.btn.undo",
      handleClick: () => {
        handleUndo();
      },
      condition: !selected || selected.length === 0,
      disabled: !rows || rows?.length === 0,
      icon: true,
      iconButton: (
        <UndoIcon
          sx={{
            color: "var(--color--light-logo-blue)",
            fontSize: "23px !important",
            border: "1px solid var(--color--light-logo-blue)",
            padding: "2px",
            borderRadius: "50%",
          }}
        />
      ),
    },
  ];

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "invoice.list.search.label",
    refetch,
    removeFilters: true,
    tableMnemonic:  "invoices",
    removeFilters: true,
        customButtons,
    readOnly: manageParameters !== "M",
    disableRowClick : (row) => row?.type == "CN",
    generateRoute: (row) => row?.type == "CN" ?"" : fromFavorites ?
      favorite_appliedInvoices
        .replace(
          "/:pName/:pID",
          `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
        )
        .replace(
          "/:iName/:iID",
          `/${encodeURIComponent(row?.documentNo)}/${encodeURIComponent(row?.projectInvoiceGuid)}`
        ) :
      appliedInvoices
        .replace(
          "/:pName/:pID",
          `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
        )
        .replace(
          "/:iName/:iID",
          `/${encodeURIComponent(row?.documentNo)}/${encodeURIComponent(row?.projectInvoiceGuid)}`)
  };


  

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable {...pagination} identifier={"projectInvoiceGuid"} />
      )}
    </div>
  );
};

export default InvoicesList;
