import React from "react";

import { useParams } from "react-router-dom";

//components
import { Grid, Box } from "@mui/material";
import { Title } from "../../../../../components/Titles/FormTitles";
import AutoComplete from "../../../../../components/AutoComplete";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useAuth from "../../../../../hooks/useAuth";

import Tooltip from "../../../../../components/Tooltip"
//gql
import { GET_ALL_COMPANIES } from "../../../../../graphql/company";
import { GET_ALL_RESOURCES } from "../../../../../graphql/resource";

import { v4 as uuidv4 } from "uuid";

const Main = ({ formData }) => {
  const { t } = useTranslation();

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();
  const { manageUsers } = userRoles;

  const { tID } = useParams();

  const {
    values,
    formField,
    setFieldValue,
    readOnly,
    fromTenant
  } = formData;

  const {
    company,
    overtimeExternalResource,
    regularPayExternalResource,
  } = formField;

  const {
    company: companyV,
    overtimeExternalResource: overtimeExternalResourceV,
    regularPayExternalResource: regularPayExternalResourceV,
  } = values;

  const { 
    data: companiesList, 
    loading: companyLoading,
  } = useQueryAuth(GET_ALL_COMPANIES,"GetAllCompanies", {
      variables: {
        model: {
          enableFilters: false,
          userID: storeUserGuid,
          tenantID: fromTenant ? tID : storeUserTenant,
        },
      },
    }
  );

  const companies_list =
    companiesList &&
    companiesList.GetAllCompanies &&
    companiesList.GetAllCompanies.compList &&
    companiesList.GetAllCompanies.compList.companies
      ? companiesList.GetAllCompanies.compList.companies
      : [];

    const { 
    data: resourcesList, 
    loading: resourcesLoading,
    } = useQueryAuth(GET_ALL_RESOURCES,"GetAllResources", {
        variables: {
            model: {
                tenantID: fromTenant ? tID : storeUserTenant,
                companyCode: companyV?.externalReference
            },
        },
        skip: companyV === null,
        isWait: true
    });

    const resources_list =
    resourcesList &&
    resourcesList.GetAllResources &&
    resourcesList.GetAllResources.resourcesList
        ? resourcesList.GetAllResources.resourcesList
          : [];
 
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="flex_space-between_center"
        >
          <Title style={{padding: "10px 0"}}>
          {t("employeeReference.employeeReferenceInformation")}
          </Title>
        </Grid>
        
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <AutoComplete
                autoFocus
                options={companies_list}
                getOptionLabel={(option) => (option ? option.name : "")}
                identifier={"companyGuid"}
                disabled={readOnly || manageUsers === "R"}
                value={companyV}
                onChange={(e, value) => {
                  setFieldValue(company.name, value);
                }}
                loading={companyLoading}
                label={t(company.label)}
                name={company.name}
                formData={formData}
                required
              />
            </Grid>

            <Tooltip title={t("references.tooltip.regularPayExternalResource")}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <AutoComplete
                  key={uuidv4()}
                  options={resources_list}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.name}
                    </li>
                  )}
                  identifier={"resourceGuid"}
                  disabled={readOnly || manageUsers === "R" || companyV == null}
                  value={regularPayExternalResourceV}
                  onChange={(e, value) => {
                    setFieldValue(regularPayExternalResource.name, value);
                  }}
                  loading={resourcesLoading}
                  label={t(regularPayExternalResource.label)}
                  name={regularPayExternalResource.name}
                  formData={formData}
                  required
                />
              </Grid>
            </Tooltip>

            <Tooltip title={t("references.tooltip.overtimeExternalResource")}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <AutoComplete
                  options={resources_list}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.name}
                    </li>
                  )}
                  identifier={"resourceGuid"}
                  disabled={readOnly || manageUsers === "R" || companyV == null}
                  value={overtimeExternalResourceV}
                  onChange={(e, value) => {
                    setFieldValue(overtimeExternalResource.name, value);
                  }}
                  loading={resourcesLoading}
                  label={t(overtimeExternalResource.label)}
                  name={overtimeExternalResource.name}
                  formData={formData}
                  required={companyV?.tenant?.tenantMiddlewareInfo?.connector?.name === "Connector-1"}
                />
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
