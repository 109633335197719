import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import useAuth from "../../../../hooks/useAuth";

import { Grid, CircularProgress, Autocomplete } from "@mui/material";

import RoundedTextField from "../../../../components/TextFields/RoundedTextField";
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import TaskTabsModal from "../../../../components/Modal/TaskTabsModal";
import RoundedDatePicker from "../../../../components/DateField/RoundedDatePicker";
import AutoComplete from "../../../../components/AutoComplete";
import Tooltip from "../../../../components/Tooltip";
import { GET_EMPLOYEE_TIMESHEET } from "../../../../graphql/reports";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../graphql/project";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";

import useQueryAuth from "../../../../hooks/useQueryAuth";
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";

import urls from "../../../../urls";

import { useSnackbar } from "notistack";

import { EmployeeTimesheetTable } from "./_table";

import colors from "../../../../assets/theme/light/colors";

import { handleValidateDates } from "../../../../utils/functions";
import AutoCompletePaper from "../../../../components/Paper/AutoCompletePaper";
import { NextIcon, PreviousIcon } from "../../../../components/Icon";
import { DATE_FILTER_DATA, handleDateBackward, handleDateForward, handleTimeBetween } from "../../../../utils/period";

import { v4 as uuidv4 } from "uuid";

function EmployeeTimesheet() {
  const { storeUserTenant, userRoles, user, storeUserGuid, timeEntryEnabled } =
    useAuth();

  const { dark_logo_blue } = colors;

  const { t } = useTranslation();

  const data_filter = DATE_FILTER_DATA(t);

  const { enqueueSnackbar } = useSnackbar();

  const { my_projects_form, my_project_direct_tasks_form } = urls;

  const { manageTimeEntry } = userRoles;

  const [re, setRe] = useState(false);

  const nowDate = new Date();

  const firstDayOfWeek = new Date(nowDate);
  firstDayOfWeek.setDate(
    nowDate.getDate() - nowDate.getDay() + (nowDate.getDay() === 0 ? -6 : 1)
  );

  const [dateFrom, setDateFrom] = useState(firstDayOfWeek);

  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  const [dateTo, setDateTo] = useState(lastDayOfWeek);

  const [open, setOpen] = useState(false);

  const [projectIDs, setProjectIDs] = useState([]);
  const [employeeID, setEmployeeID] = useState(
    user && timeEntryEnabled ? user.user?.employeeGuid : null
  );

  const [employeeValue, setEmployeeValue] = useState(
    user && timeEntryEnabled ? user.user : null
  );
  const [projectsValue, setProjectsValue] = useState([]);

  const [dateVal, setDateVal] = useState(
    data_filter.find((x) => x.mnemonic === "week")
  );
  const [fetchEmployees, setFetchEmployees] = useState();
  const [fetchProjects, setFetchProjects] = useState();

  const [taskID, setTaskID] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [projectID, setProjectID] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [timer, setTimer] = useState(null);
  const [list, setList] = useState(null);
  const type = useRef("task");

  const {
    data: employeeData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
        timeEntryDisabled: true,
      },
    },
    skip: !fetchEmployees,
    isWait: fetchEmployees,
  });

  const employeeList =
    employeeData?.GetCompanyUsersList?.usersList?.employees || [];

  const {
    data: projectsData,
    loading: projectLoading,
    tokenChange: projectWait,
  } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
      },
    },
    skip: !fetchProjects,
    isWait: fetchProjects,
  });

  const projectsList =
    projectsData?.GetAllProjects?.projectsList?.projectViews || [];

  const {
    mfunction: getData,
    refetch,
    loading: dataLoading,
  } = useLazyQueryAuth(GET_EMPLOYEE_TIMESHEET, "GetEmployeeTimesheet");

  // const list = projectData?.GetEmployeeTimesheet?.employeeTimesheet || null;
  // useEffect(() => {
  //   if(!projectWait && !employeeWait && projectData) setList(projectData?.GetEmployeeTimesheet?.employeeTimesheet)
  // }, [projectWait, employeeWait, projectData])
  function disableDates(date) {
    const oneDayBefore = new Date(dateFrom);
    oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
    return date < oneDayBefore;
  }

  useEffect(() => {
    const oneDayBefore = new Date(dateFrom);
    oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
    if (!employeeWait && !projectWait && employeeID && oneDayBefore <= dateTo) {
      getData(
        {
          variables: {
            model: {
              projectGuids: projectIDs.length > 0 ? projectIDs : null,
              employeeGuid: employeeID,
              dateFrom: new Date(dateFrom).toDateString(),
              dateTo: new Date(dateTo).toDateString(),
              userID: storeUserGuid,
            },
          },
          skip: employeeWait || projectWait,
          isWait: true,
        },
        (response) => {
          if (response["GetEmployeeTimesheet"].errorMessage == null)
            setList(response?.GetEmployeeTimesheet?.employeeTimesheet);
          else
            enqueueSnackbar(t(response["GetEmployeeTimesheet"].errorMessage), {
              variant: "error",
              autoHideDuration: 5000,
            });
        }
      );
    }
  }, [projectWait, employeeWait, re]);

  const handleOpenModal = (event, row) => {
    event.preventDefault();
    setTaskID(row?.taskGuid);
    setProjectID(row?.projectGuid);
    setTaskName(row?.taskName);
    setProjectName(row?.projectTitle);
    setOpenModal(true);
  };

  // const handleValidateDates = (value) => {
  //   if (timer) {
  //     //if timer is set, we empty it so it's not called and wait another delay
  //     clearTimeout(timer);
  //     setTimer(null);
  //   }

  //   //set timer for after 2 seconds of wait
  //   setTimer(
  //     setTimeout(() => {
  //       const oneDayBefore = new Date(dateFrom);
  //   oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
  //       if (value < oneDayBefore)
  //         enqueueSnackbar(t("employeeTimesheet.invalid.date"), {
  //           variant: "error",
  //           autoHideDuration: 5000
  //         });
  //       else setDateTo(value);
  //     }, 500)
  //   );
  // };

  return (
    <>
      <TaskTabsModal
        showModal={openModal}
        setShowModal={(v) => setOpenModal(v)}
        loading={dataLoading}
        projectID={projectID}
        projectName={projectName}
        taskID={taskID}
        handleChangeProject={(v) => setProjectID(v)}
        handleChangeTask={(v) => setTaskID(v)}
        taskName={taskName}
        refetch={refetch}
        fromProject={true}
        setType={(v) => type.current=v}
        type={type.current}
        open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        modifyFormFlag={() =>
          sessionStorage.setItem("unsavedChanges", JSON.stringify(false))
        }
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ContainerCard
          className="projects-card"
          sx={{
            width: "100%",
            padding: "10px",
            display: "grid",
            gridTemplateColumn: "1fr",
            backgroundColor: "transparent !important",
            boxShadow: "none !important",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <Grid container spacing={1}>
            {manageTimeEntry !== "N" && (
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={3}
                xl={3}
                sx={{ marginBottom: "10px !important" }}
              >
                <AutoComplete
                  loading={employeeLoading}
                  options={employeeList}
                  getOptionLabel={(option) =>
                    option ? option.firstName + " " + option.lastName : ""
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.firstName + " " + option.lastName}
                    </li>
                  )}
                  identifier={"employeeGuid"}
                  size="small"
                  value={employeeValue}
                  onOpen={() => setFetchEmployees(true)}
                  onChange={(event, value) => {
                    setEmployeeValue(value);
                    setEmployeeID(value?.employeeGuid);
                  }}
                  sx={{
                    "& .MuiInputBase-input": { fontSize: "13px !important" },
                  }}
                  roundedTextField
                  label={t("reports.label.employee")}
                  required
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={3}
              xl={3}
              sx={{ marginBottom: "10px !important" }}
            >
              <AutoComplete
                multiple
                loading={projectLoading}
                options={projectsList}
                getOptionLabel={(option) => (option ? option.title : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.title}
                  </li>
                )}
                identifier={"projectGuid"}
                size="small"
                value={projectsValue}
                onOpen={() => setFetchProjects(true)}
                onChange={(event, value) => {
                  setProjectsValue(value);
                  let tmp = [];
                  value.map((ids, inxex) => {
                    tmp.push(ids.projectGuid);
                    return tmp;
                  });
                  setProjectIDs(tmp);
                }}
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px !important" },
                }}
                label={t("reports.label.projects")}
                roundedTextField
              />
            </Grid>

            <Grid item xs={12} sm={1} md={manageTimeEntry !== "N" ? 4 : 8} lg={manageTimeEntry !== "N" ? 6 : 9} xl={manageTimeEntry !== "N" ? 6 : 9}></Grid>

            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
              <RoundedDatePicker
                className="date_picker_styles"
                label={t("projectDashboard.label.DateFrom")}
                value={dateFrom}
                disableFuture
                onChange={(newValue) => {
                  setDateFrom(newValue);
                  setDateVal(data_filter.find((x) => x.mnemonic === "custom"));
                }}
                onClose={() => setOpen(true)}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
              <RoundedDatePicker
                className="date_picker_styles"
                label={t("projectDashboard.label.DateTo")}
                value={dateTo}
                // disableFuture
                shouldDisableDate={disableDates}
                autoFocus={open}
                open={open}
                onClose={() => setOpen(false)}
                onClick={() => setOpen(!open)}
                onOpen={() => setOpen(!open)}
                onChange={(newValue) => {
                  handleValidateDates(
                    timer,
                    setTimer,
                    dateFrom,
                    newValue,
                    setDateTo,
                    enqueueSnackbar,
                    t
                  );
                  setDateVal(data_filter.find((x) => x.mnemonic === "custom"));
                }}
                // renderInput={(params) => (
                //   <RoundedTextField removeMinWidth {...params} />
                // )}
              />
            </Grid>
            <Tooltip title={t("tooltip.PreviousPeriod")}>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <PreviousIcon
                  color={dateVal.mnemonic === "custom" ? "#73717380" : "#737173"}
                  onClick={() => {
                    if (dateVal.mnemonic !== "custom")
                      handleDateBackward(
                        dateFrom,
                        dateTo,
                        dateVal,
                        setDateFrom,
                        setDateTo
                      );
                  }}
                />
              </Grid>
            </Tooltip>
            <Grid item xs={12} sm={4} md={2} lg={1.5} xl={1.5}>
              <Autocomplete
                options={data_filter}
                getOptionLabel={(option) => (option ? t(option.name) : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {t(option.name)}
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  value == "" || option.mnemonic === value.mnemonic
                }
                size="small"
                disableClearable
                value={dateVal}
                onChange={(event, value) => {
                  setDateVal(value);
                  handleTimeBetween(value.mnemonic, setDateFrom, setDateTo);
                }}
                fullWidth
                PaperComponent={(props) => <AutoCompletePaper {...props} />}
                renderInput={(params) => (
                  <RoundedTextField
                    label={t("dateFilter.label")}
                    size="small"
                    removeMinWidth
                    {...params}
                  />
                )}
                sx={{".MuiInputBase-input": {fontSize: "14px !important"}}}
              />
            </Grid>
            <Tooltip title={t("tooltip.NextPeriod")}>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <NextIcon 
                  color={dateVal.mnemonic === "custom" ? "#73717380" : "#737173"}
                  onClick={() => {
                    if (dateVal.mnemonic !== "custom")
                      handleDateForward(
                        dateFrom,
                        dateTo,
                        dateVal,
                        setDateFrom,
                        setDateTo
                      );
                  }} 
                />
              </Grid>
            </Tooltip>
            <Tooltip
              title={
                manageTimeEntry !== "N" && t("reports.tooltip.employee.search")
              }
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                lg={2}
                xl={2}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <IALoadingButton
                  onClick={() => setRe((val) => !val)}
                  // disabled={!initialLoad}
                  style={{
                    width: "150px",
                    backgroundColor: dark_logo_blue,
                    borderRadius: "50px",
                    height: "36px",
                  }}
                  // loading={initialLoad === true}
                  label={t("projectDashboard.Search")}
                />
              </Grid>
            </Tooltip>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ overflowX: "auto" }}
          >
            {dataLoading && (
              <div
                style={{
                  color: "grey",
                  textAlign: "center",
                  padding: "5px",
                  fontFamily: "poppins  !important",
                }}
              >
                <CircularProgress />
              </div>
            )}
            {!dataLoading && list && (
              <EmployeeTimesheetTable
                data={list}
                t={t}
                handleOpenModal={handleOpenModal}
                generateProjectRoute={(row) =>
                  my_projects_form.replace(
                    "/:pName/:pID",
                    `/${encodeURIComponent(
                      row?.projectTitle
                    )}/${encodeURIComponent(row?.projectGuid)}`
                  )
                }
                generateTaskRoute={(row) =>
                  my_project_direct_tasks_form
                    .replace(
                      "/:pName/:pID",
                      `/${encodeURIComponent(
                        row?.projectTitle
                      )}/${encodeURIComponent(row?.projectGuid)}`
                    )
                    .replace(
                      "/:tskName/:tskID",
                      `/${encodeURIComponent(
                        row?.taskName
                      )}/${encodeURIComponent(row?.taskGuid)}`
                    )
                }
              />
            )}
            {!dataLoading && !list && (
              <div
                style={{
                  color: "grey",
                  textAlign: "center",
                  padding: "5px",
                  fontFamily: "poppins  !important",
                }}
              >
                {t("employeeTimesheet.no.timeLog")}
              </div>
            )}
          </Grid>
        </ContainerCard>
      </Grid>
    </>
  );
}

export default EmployeeTimesheet;
