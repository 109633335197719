import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
} from "@mui/material";

import useQueryAuth from "../../../../hooks/useQueryAuth";
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import { useSubscription } from "@apollo/client";
import colorsLight from "../../../../assets/theme/light/colors";

import "./clockinout.css";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import useAuth from "../../../../hooks/useAuth";

import useMutationAuth from "../../../../hooks/useMutationAuth.js";
import {
  CLOCK_IN_OUT,
  GET_LOCATIONS,
  GET_LOCATION_OPTIONS,
  GET_PROJECTS_TASKS_FOR_USER,
  GET_USER_DAY_LOG,
  GET_DAY_LOG_SUBSCRIPTION,
} from "../../../../graphql/clockin-out.js";
import { enqueueSnackbar } from "notistack";
import Mnemonics from "../../../../Mnemonics.json";

import { UTC2Time } from "../../../../UTC2Local.js";
import MyTime from "./timer";
import LongText from "../../../../components/Text/LongText";
import { CloseIcon } from "../../../../components/Icon";
import { useTranslation } from "react-i18next";

const ClockInOut = ({ setRefetchApis, refetchApis, wait, refetchOpenTask }) => {
  const { t } = useTranslation();
  const { grey_background, disabled_text_grey } = colorsLight;
  const { storeUserGuid, attendanceEnabled } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [options, setOptions] = useState(null);
  const [locationSelectionView, setLocationSelectionView] = useState(true);
  const [clockOutTime, setClockOutTime] = useState(null);
  const [locationState, setLocation] = useState("");
  const [siteOffice, setSiteOffice] = useState("");
  const [currentTime, setCurrentTime] = useState(null);
  const [checkedIn, setCheckedIn] = useState(() => {
    const storedCheckedIn = localStorage.getItem("checkedIn");
    return storedCheckedIn ? JSON.parse(storedCheckedIn) : false;
  });
  const [workTypeState, setWorkTypeState] = useState(() => {
    const storedWorkType = localStorage.getItem("workType");
    return storedWorkType ? storedWorkType.replace(/\\/g, "") : null;
  });
  const [locationIDState, setLocationIDState] = useState(() => {
    const storedLocationID = localStorage.getItem("location");

    return storedLocationID ? storedLocationID.replace(/\\/g, "") : null;
  });
  const [dayLog, setDayLog] = useState(null);
  const [clockInTime, setClockInTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [clockInLocationAddress, setClockInLocationAddress] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingLocation, setIsSubmittingLocation] = useState(false);
  const [isTabVisible, setIsTabVisible] = useState(true);

  const handleVisibilityChange = React.useCallback(() => {
    setIsTabVisible(document.visibilityState === "visible");
    if (!wait) {
      refetchOpenTask().then((res) => dayLogRefetch());
    }
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isTabVisible]);

  useEffect(() => {
    if (refetchApis === true) {
      dayLogRefetch();
    }
  }, [refetchApis]);

  useSubscription(GET_DAY_LOG_SUBSCRIPTION, {
    fetchPolicy: "no-cache",
    variables: {
      userID: storeUserGuid,
    },
    onData: ({ data }) => {
      const dataLog = data?.data?.userDayLog?.dayLogResponse?.dayLog;
      setSiteOffice(dataLog?.location?.name || null);
      setCheckedIn(
        data?.data?.userDayLog?.dayLogResponse !== null ? true : false
      );
      localStorage.setItem(
        "checkedIn",
        JSON.stringify(
          data?.data?.userDayLog?.dayLogResponse !== null ? true : false
        )
      );
      setDayLog(dataLog || null);
      setClockInTime(dataLog?.checkInTime || null);
      setClockInLocationAddress(dataLog?.workType?.mnemonic || null);
      setStartTime(dataLog?.checkInTime || null);
      setLocation(dataLog?.location?.name || null);
      setCurrentTime(
        data?.data?.userDayLog?.dayLogResponse?.currentTime || null
      );
      setWorkTypeState(dataLog?.workType?.workTypeGuid);
    },
  });

  const togglePlayPause = () => {
    const newState = !checkedIn;

    if (!newState) {
      closeModalHandler();
      setOpenModal(false);
      setSelectedLocation(null);
      setClockInLocationAddress(null);
      setClockInTime(null);
      setStartTime(null);
    } else {
      dayLogRefetch();
    }

    setCheckedIn(newState);
    localStorage.setItem("checkedIn", JSON.stringify(newState));
  };

  const { data: locationsData, tokenChange: locationsListWait } = useQueryAuth(
    GET_LOCATIONS,
    "GetAllWorkTypes",
    {
      skip: !openModal,
    }
  );

  const LocationsList = locationsData?.GetAllWorkTypes?.workTypes || [];

  const { refetch: dayLogRefetch } = useQueryAuth(
    GET_USER_DAY_LOG,
    "GetUserDayLog",
    {
      variables: {
        userGuid: storeUserGuid,
      },
      skip: wait || locationsListWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        const data = response?.GetUserDayLog?.dayLogResponse?.dayLog;

        setRefetchApis(false);
        setSiteOffice(data?.location?.name || null);
        setCheckedIn(
          response?.GetUserDayLog?.dayLogResponse !== null ? true : false
        );
        setDayLog(data || null);
        setClockInTime(data?.checkInTime || null);
        setClockInLocationAddress(data?.workType?.mnemonic || null);
        setStartTime(data?.checkInTime || null);
        setLocation(data?.location?.name || null);
        setCurrentTime(
          response?.GetUserDayLog?.dayLogResponse?.currentTime || null
        );
        setWorkTypeState(data?.workType?.workTypeGuid);
      },
    }
  );

  const { mfunction: getoption, loading: optionsLoading } = useLazyQueryAuth(
    GET_LOCATION_OPTIONS,
    "GetLocationsByWorkType"
  );

  const getLocationOptionFun = (location) => {
    getoption(
      {
        variables: {
          model: {
            workTypeID: location?.workTypeGuid,
            userID: storeUserGuid,
          },
        },
      },
      (response) => {
        setOptions(response?.GetLocationsByWorkType?.locations || []);
        setIsSubmittingLocation(false);
      }
    );
  };

  const { mfunction: getSites, loading: sitesLoading } = useLazyQueryAuth(
    GET_PROJECTS_TASKS_FOR_USER,
    "GetProjectsAndTasksForUser"
  );

  const getSitesFunction = (location) => {
    getSites(
      {
        variables: {
          model: {
            fromWeb: true,
            workTypeID: location?.workTypeGuid,
            userId: storeUserGuid,
            longitude: "",
            latitude: "",
          },
        },
      },
      (response) => {
        const projectAndTask =
          response?.GetProjectsAndTasksForUser?.projectsAndTasks?.projects.map(
            (pro) => pro
          );

        setOptions(projectAndTask || []);
        setIsSubmittingLocation(false);
      }
    );
  };

  const {
    mfunction: checkInOut,
    loading: clockInLoading,
    DialogComponent,
  } = useMutationAuth(CLOCK_IN_OUT, "ManageDayLog");
  const checkInOutFunction = (location) => {
    const currentDateTime = new Date();
    localStorage.setItem("location", location);
    localStorage.getItem("workType");

    checkInOut(
      {
        variables: {
          model: {
            employeeID: storeUserGuid,
            workTypeID:
              location?.mnemonic === Mnemonics.CheckInLocations.HOME
                ? location?.workTypeGuid
                : workTypeState,
            taskID: null,

            locationID: checkedIn
              ? dayLog?.location?.locationGuid || selectedLocation?.locationGuid
              : location?.location?.locationGuid ||
                location?.locationGuid ||
                selectedLocation?.locationGuid ||
                null,
            webClockin: true,
            checkIn: !checkedIn,
            dateTime: currentDateTime.toISOString(),
            dayLogID: checkedIn ? dayLog?.dayLogGuid : null,
          },
        },
      },
      (response) => {
        enqueueSnackbar({
          message: !checkedIn
            ? "Check-in successful!"
            : "Check-out successful!",
          variant: "success",
        });

        if (checkedIn === true) {
          dayLogRefetch();

          setClockInTime(currentDateTime);
          setStartTime(currentDateTime);
          setCurrentTime(response?.ManageDayLog?.dayLog?.currentTime || null);
        } else {
          setClockOutTime(currentDateTime);
          setLocation("");
          setSiteOffice("");
          setClockInTime(null);
          setStartTime(null);
          setOpenModal(false);
          comeBack();
          setClockInLocationAddress(null);
          setDayLog(null);
        }

        togglePlayPause();
      }
    );
    setIsSubmitting(false);
  };

  const handleLocationClick = (location) => {
    setIsSubmittingLocation(true);
    setIsSubmitting(true);
    setSelectedLocation(location);
    setCurrentTime(null);
    if (location?.mnemonic === Mnemonics.CheckInLocations.HOME) {
      setLocation("Home");
      setLocationIDState("Home");
      localStorage.setItem("location", "Home");
      localStorage.setItem("workType", location?.workTypeGuid);
      setWorkTypeState(location?.workTypeGuid);

      checkInOutFunction(location);

      closeModalHandler();
      setIsSubmittingLocation(false);
    } else if (location?.mnemonic === Mnemonics.CheckInLocations.OFFICE) {
      setLocation("");
      localStorage.setItem("workType", location?.workTypeGuid);
      setWorkTypeState(location?.workTypeGuid);

      getLocationOptionFun(location);
    } else if (location?.mnemonic === Mnemonics.CheckInLocations.SITE) {
      setLocation("");
      localStorage.setItem("workType", location?.workTypeGuid);
      setWorkTypeState(location?.workTypeGuid);

      getSitesFunction(location);
    } else {
      setLocationSelectionView(false);
    }
  };

  const openModalHandler = () => {
    setOpenModal(true);
    setLocationSelectionView(true);
    setIsSubmitting(true);
  };

  const closeModalHandler = () => {
    if (!isSubmittingLocation) {
      setOpenModal(false);
      setSelectedLocation(null);
      setIsSubmitting(false);
    }
  };

  const getLocationIcon = (locationType) => {
    switch (locationType) {
      case "workfromhome":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-home"
            width="35"
            height="35"
            viewBox="0 0 24 24"
            strokeWidth="1"
            stroke="var(--color-standard-blue)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
            <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
            <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
          </svg>
        );
      case "workfromoffice":
        return (
          <svg
            xmlns="
          http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-device-desktop"
            width="35"
            height="35"
            viewBox="0 0 24 24"
            strokeWidth="1"
            stroke="var(--color-standard-blue)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />

            <path d="M3 5a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1v-10z" />

            <path d="M7 20h10" />

            <path d="M9 16v4" />

            <path d="M15 16v4" />
          </svg>
        );
      case "workfromsite":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-building-factory-2"
            width="35"
            height="35"
            viewBox="0 0 24 24"
            strokeWidth="1"
            stroke="var(--color-standard-blue)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 21h18" />
            <path d="M5 21v-12l5 4v-4l5 4h4" />
            <path d="M19 21v-8l-1.436 -9.574a.5 .5 0 0 0 -.495 -.426h-1.145a.5 .5 0 0 0 -.494 .418l-1.43 8.582" />
            <path d="M9 17h1" />
            <path d="M14 17h1" />{" "}
          </svg>
        );
      default:
        return null;
    }
  };

  const comeBack = () => {
    setOptions([]);

    setTimeout(() => {
      setLocationSelectionView(true);
      setSelectedLocation(null);
    }, 0);
  };

  return (
    <Grid container className="clock-in-out">
      {DialogComponent}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="clock-in-out-time"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={8}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          style={
            checkedIn === true
              ? {
                  marginLeft: "-1rem",
                  position: "relative",
                  fontSize: "20px",
                  background: grey_background,
                  borderRadius: "100%",
                  maxWidth: "120px",
                  minWidth: "120px",
                  height: "120px",
                  boxShadow: "0px 0px 10px rgba(24,117,214,255)",
                  animation: "borderAnimation 2s infinite",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : {
                  marginLeft: "-1rem",
                  border: "3px solid transparent",
                  fontSize: "20px",
                  color: disabled_text_grey,
                  background: grey_background,
                  borderRadius: "100%",
                  maxWidth: "120px",
                  minWidth: "120px",
                  height: "120px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
          }
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              display: "contents",
            }}
          >
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {checkedIn === true && clockInTime && currentTime ? (
                <MyTime
                  currentTime={currentTime}
                  checkedIn={checkedIn}
                  clockInTime={clockInTime}
                  dayLogRefetch={dayLogRefetch}
                  refetchOpenTask={refetchOpenTask}
                />
              ) : (
                "00:00:00"
              )}
            </p>
          </div>
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          onClick={() => {
            if (attendanceEnabled) {
              if (checkedIn === true) {
                checkInOutFunction();
                setTimeout(() => {
                  setCheckedIn(JSON.parse(localStorage.getItem("checkedIn")));
                  setClockInTime(null);
                  setStartTime(null);
                }, 300);
              } else {
                openModalHandler();
              }
            }
          }}
        >
          {isSubmitting === true ? (
            <span
              style={{
                border:
                  attendanceEnabled === false
                    ? "1px solid rgba(155, 155, 155, 0.8)"
                    : "1px solid rgb(63, 181, 74, 0.8)",
                color:
                  attendanceEnabled === false
                    ? "rgba(155, 155, 155, 1)"
                    : "rgb(62, 186, 18)",
                fontFamily: "poppins  !important",
                borderRadius: "5px",
                padding: "12px 12px 5px 13px",
                textAlign: "right",
                display: "inline-block",
                verticalAlign: "middle",
                cursor: attendanceEnabled === false ? "default" : "pointer",
              }}
            >
              <CircularProgress color="inherit" size={20} />
            </span>
          ) : checkedIn === true ? (
            <span
              style={{
                border: "1px solid rgba(244, 67, 53, 1)",
                color: "rgba(244, 67, 53, 1)",
                fontFamily: "poppins  !important",
                borderRadius: "5px",
                padding: "12px 12px 5px 13px",
                textAlign: "right",
                display: "inline-block",
                verticalAlign: "middle",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-player-pause-filled"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="#F44335"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M9 4h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h2a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2z"
                  strokeWidth="0"
                  fill="currentColor"
                />{" "}
                <path
                  d="M17 4h-2a2 2 0 0 0 -2 2v12 a2 2 0 0 0 2 2h2a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2z"
                  strokeWidth="0"
                  fill="currentColor"
                />
              </svg>{" "}
            </span>
          ) : (
            <span
              style={{
                border:
                  attendanceEnabled === false
                    ? "1px solid rgba(155, 155, 155, 0.8)"
                    : "1px solid rgb(63, 181, 74, 0.8)",
                color:
                  attendanceEnabled === false
                    ? "rgba(155, 155, 155, 1)"
                    : "rgb(62, 186, 18)",
                fontFamily: "poppins  !important",
                borderRadius: "5px",
                padding: "12px 12px 5px 13px",
                textAlign: "right",
                display: "inline-block",
                verticalAlign: "middle",
                cursor: attendanceEnabled === false ? "default" : "pointer",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-player-play-filled"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="var(--color--green-color)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z"
                  strokeWidth="0"
                  fill="currentColor"
                />
              </svg>
            </span>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        mt={1}
        mb={0.5}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "normal",
          marginLeft: "-1rem",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-run"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          strokeWidth="1.3"
          stroke="var(--color--light-logo-blue)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ marginRight: "0.5rem" }}
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M13 4m-1.3 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
          <path d="M4 17l5 1l.75 -1.5" />
          <path d="M15 21l0 -4l-4 -3l1 -6" />
          <path d="M7 12l0 -3l5 -1l3 3l3 1" />
        </svg>
        <p
          style={{
            fontSize: "13px",
            margin: 0,
            color: !checkedIn ? disabled_text_grey : "inherit",
          }}
        >
          {UTC2Time(startTime) || "-- : --"}
        </p>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        mb={0.5}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "normal",
          marginLeft: "-1rem",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-map-pin"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          strokeWidth="1.3"
          stroke="var(--color--light-logo-blue)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ marginRight: "0.5rem" }}
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
          <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
        </svg>
        <p
          style={{
            fontSize: "13px",
            margin: 0,
            letterSpacing: 0,
            color: !checkedIn ? disabled_text_grey : "inherit",
          }}
        >
          {checkedIn === true && dayLog
            ? LongText({
                text: locationState
                  ? locationState
                  : siteOffice
                  ? siteOffice
                  : "Home",
                length: 19,
              })
            : "- -"}
        </p>
      </Grid>

      {openModal && !checkedIn && (
        <div style={{ overflowX: "hidden" }}>
          <Modal
            open={openModal}
            onClose={closeModalHandler}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ContainerCard
              sx={{
                padding: "20px",
                width: "fit-content",
                minWidth: "350px",
                backgroundColor: "#f5f5f5",
                position: "relative  !important",
                "& .MuiBackdrop-root": {
                  position: "absolute !important",
                  backgroundColor: "rgb(179 183 189 / 50%) !important",
                },
              }}
              className="quick-info-card-container-2"
            >
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isSubmittingLocation}
                onClick={() => {}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {locationSelectionView ? (
                  <span className="modal-header">
                    {selectedLocation &&
                    selectedLocation.mnemonic !==
                      Mnemonics.CheckInLocations.HOME ? (
                      // If selectedLocation is not null, show "Come Back" icon
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <IconButton onClick={comeBack}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-arrow-narrow-left"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#000000"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M5 12l14 0" />
                            <path d="M5 12l4 4" />
                            <path d="M5 12l4 -4" />
                          </svg>
                        </IconButton>
                        {selectedLocation?.mnemonic ===
                        Mnemonics.CheckInLocations.OFFICE ? (
                          <span style={{ fontSize: "17px" }}>
                            {t("clockin.location.office")}
                          </span>
                        ) : selectedLocation?.mnemonic ===
                          Mnemonics.CheckInLocations.SITE ? (
                          <span style={{ fontSize: "17px" }}>
                            {t("clockin.location.project")}
                          </span>
                        ) : null}
                      </div>
                    ) : (
                      // If selectedLocation is null, show "Specify where you are working from" text
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-current-location"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          strokeWidth="1"
                          stroke="#1875d6"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                          <path d="M12 12m-8 0a8 8 0 1 0 16 0a8 8 0 1 0 -16 0" />
                          <path d="M12 2l0 2" />
                          <path d="M12 20l0 2" />
                          <path d="M20 12l2 0" />
                          <path d="M2 12l2 0" />
                        </svg>
                        <p
                          style={{
                            fontSize: "17px",
                            fontWeight: "700px",
                            marginLeft: "4px",
                          }}
                        >
                          {t("clockin.location")}
                        </p>
                      </>
                    )}
                  </span>
                ) : (
                  <IconButton onClick={comeBack}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-arrow-narrow-left"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#000000"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M5 12l14 0" />
                      <path d="M5 12l4 4" />
                      <path d="M5 12l4 -4" />
                    </svg>
                  </IconButton>
                )}
                <IconButton onClick={closeModalHandler}>
                  <CloseIcon color={"#000000"} width={"20"} stroke={"1"} />
                </IconButton>
              </div>

              {selectedLocation?.mnemonic !== "workfromhome" &&
              optionsLoading ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    rowGap: 16,
                    overflow: "hidden",
                    justifyContent: "center",
                    minWidth: "350px",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : selectedLocation === null || selectedLocation === {} ? (
                <div style={{ padding: "20px" }}>
                  {/* Display LocationsList as mini squares with icons */}
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    {LocationsList?.map((location) => (
                      <div
                        key={location?.workTypeGuid}
                        className="squareLocation"
                        onClick={() => handleLocationClick(location)}
                      >
                        <div
                          className="iconSquare"
                          style={{
                            width: "75px",
                            height: "75px",
                            borderRadius: "4px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow:
                              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                          }}
                        >
                          {getLocationIcon(location?.mnemonic)}
                        </div>
                        <p style={{ textAlign: "center", marginTop: "5px" }}>
                          {location?.mnemonic ===
                          Mnemonics.CheckInLocations?.HOME
                            ? t("clockin.location.home")
                            : location?.mnemonic ===
                              Mnemonics.CheckInLocations?.OFFICE
                            ? t("clockin.location.office")
                            : location?.mnemonic ===
                              Mnemonics.CheckInLocations?.SITE
                            ? t("clockin.location.site")
                            : null}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : !optionsLoading &&
                options &&
                selectedLocation.mnemonic ===
                  Mnemonics.CheckInLocations.OFFICE ? (
                options.length !== 0 ? (
                  <div
                    style={{
                      padding: "20px",
                      maxHeight: "450px",
                      overflow: "auto",
                    }}
                  >
                    {/* Display LocationsList as mini rectangles with icons */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {options.map((option) => (
                        <div
                          key={option?.locationGuid}
                          className="rectangleLocation"
                          onClick={() => checkInOutFunction(option)}
                        >
                          <ContainerCard
                            className="iconRectangle"
                            style={{
                              padding: "3px",
                              width: "300px",
                              height: "60px",
                              borderRadius: "4px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "15px",
                                fontWeight: "bold",
                                marginBottom: "5px",
                                padding: "0 0 0 3px",
                              }}
                            >
                              {option?.name}
                            </p>
                            <p
                              style={{
                                fontSize: "13px",
                                fontWeight: "medium",
                                alignItems: "center",
                                display: "flex",
                                padding: "1px 0 0 3px",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-map-pin"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                strokeWidth="1"
                                stroke="#1875d6"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                              </svg>
                              <span style={{ marginLeft: "3px" }}>
                                {" "}
                                {option?.address}
                              </span>
                            </p>
                          </ContainerCard>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  selectedLocation?.length === 0 && (
                    <div>{t("clockin.no.locations")}</div>
                  )
                )
              ) : !optionsLoading &&
                options &&
                selectedLocation.mnemonic ===
                  Mnemonics.CheckInLocations.SITE ? (
                options.length !== 0 ? (
                  <div
                    style={{
                      padding: "20px",
                      maxHeight: "450px",
                      overflow: "auto",
                    }}
                  >
                    {/* Display LocationsList as mini rectangles with icons */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {options?.map((option) => (
                        <div
                          key={option?.location?.locationGuid}
                          className="rectangleLocation"
                          onClick={() => checkInOutFunction(option)}
                        >
                          <ContainerCard
                            className="iconRectangle"
                            style={{
                              padding: "3px",
                              width: "300px",
                              height: "60px",
                              borderRadius: "4px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "flex-start",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "15px",
                                fontWeight: "bold",
                                marginBottom: "5px",
                                padding: "0 0 0 3px",
                              }}
                            >
                              {option?.title}
                            </p>
                            <p
                              style={{
                                fontSize: "13px",
                                fontWeight: "medium",
                                alignItems: "center",
                                display: "flex",
                                padding: "1px 0 0 3px",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-map-pin"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                strokeWidth="1"
                                stroke="#1875d6"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                              </svg>{" "}
                              <span style={{ marginLeft: "3px" }}>
                                {option?.location?.name}
                              </span>
                            </p>
                          </ContainerCard>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  selectedLocation?.length === 0 && (
                    <div>{t("no.projects.sites")}</div>
                  )
                )
              ) : null}
            </ContainerCard>
          </Modal>
        </div>
      )}
    </Grid>
  );
};

export default ClockInOut;
