const translations = {
  //login
  "login.email": "Email",
  "login.password": "Password",
  "login.button": "Login",
  "login.forgotPassword": "Forgot Password?",

  //subscription
  "subscription.login": "Already have an account?",
  "subscription.setup": "Set up your Activ365 account ",
  "subscription.start": "Get started for free",
  "subscription.firstName": "First Name",
  "subscription.lastName": "Last Name",
  "subscription.tenantDescription": "Tenant Description (Doing Business As)",
  "subscription.timezone": "Timezone",
  "subscription.phone": "Phone Number",
  "subscription.country": "Country",
  "subscription.position": "Position",
  "subscription.email": "Email",
  "subscription.firstName.required": "First Name is required",
  "subscription.lastName.required": "Last Name is required",
  "subscription.tenantDescription.required": "Tenant Description is required",
  "subscription.timezone.required": "Timezone is required",
  "subscription.phone.required": "Phone Number is required",
  "subscription.email.required": "Email is required",
  "subscription.email.invalidFormat": "Invalid Format",
  "subscription.country.required": "Country is required",
  "subscription.position.required": "Position is required",
  "susbscription.agreement.one": "By signing up, you agree to our ",
  "susbscription.agreement.two": "Terms of use",
  "susbscription.agreement.three": " and the storing of data as per our ",
  "susbscription.agreement.four": "Privacy Policy",
  "setup.completed": "Setup Completed",
  "setup.activate.one": "Go activate your email",
  "setup.activate.two":
    "Account activation mail has been sent to your email address. Check your inbox (including your span folder) and click on the link in the mail to activate your account.",
  "setup.use.email": "You can then use email address and password to login in.",
  "setup.close.tab": "Kindly close this tab to proceed.",
  "setup.login.now": "Log in now",

  //forgot password
  "forgotPassword.email": "Email",
  "forgotPassword.button": "Get Reset Link",
  "forgotPassword.login": "Login",
  "forgotPassword.title": "Forgot Password",
  "forgotPassword.text":
    "An email will be sent with a link to reset your password.",

  //reset password
  "resetPassword.password": "Password",
  "resetPassword.confirmPassword": "Confirm Password",
  "resetPassword.button": "Reset Password",
  "resetPassword.title": "Reset Password",
  "resetPassword.text": "Enter your new password.",

  //otp
  "verifyLogin.title": "Verify Login",
  "verifyLogin.text": "Please enter the code that was sent to you.",
  "verifyLogin.login": "Logout",
  "verifyLogin.didntReceiveCode": "Didn't receive a code?",
  "verifyLogin.resendCode": "Resend Code",

  //user
  "user.accountSettings": "Account settings",
  "user.resentSuccessfully": "OTP Code Resent Successfully",
  "user.sentSuccessfully": "Reset Link Sent Successfully",
  "user.resetSuccessful": "Password Reset Successfully",

  //device token
  "deviceToken.title": "New Device",
  "deviceToken.message":
    "You are logging from a new device, would you like to save it?\n\n(PS: This website uses cookies to save your device. By clicking Save you are directly accepting the use of cookies to save your device.)",
  "deviceToken.notOK": "Don't Save",
  "deviceToken.OK": "Save",

  "welcome.msg": "Welcome to Activ 365",
  "profile": "Profile",
  "tenant": "Tenant",
  "logout": "Logout",
  "change.password": "Change Password",
  "no.notifications": "No notifications to display",

  //sidenav
  "sideNav.Dashboard": "Dashboard",
  "sideNav.AdminConfig": "Configurations",
  "sideNav.Tenants": "Tenants",
  "sideNav.Companies": "Companies",
  "sideNav.Projects": "Open Projects",
  "sideNav.Roles": "Roles",
  "sideNav.errors": "System Errors",
  "sideNav.help": "Help",
  "sideNav.Employees": "Resources",
  "sideNav.UserConfig": "Configurations",
  "sideNav.myCompanies": "Companies",
  "sideNav.myRoles": "Roles",
  "sideNav.myDepartments": "Departments",
  "sideNav.myWorkShifts": "WorkShifts",
  "sideNav.myEmployees": "Resources",
  "sideNav.myLocations": "Locations",
  "sideNav.myProjects": "Open Projects",
  "sideNav.favProjects": "My Favorite Projects",
  "sideNav.Tickets": "Phases",
  "sideNav.myOfficialHolidays": "Official Holidays",
  "sideNav.myPTOs": "Time Off",
  "sideNav.myRequests": "Approval Requests",
  "sideNav.tenantRequests": "Requests",
  "sideNav.myTaskStatuses": "Task Statuses",
  "sideNav.myTicketStatuses": "Phase Statuses",
  "sideNav.mySkills": "Skills",
  "sideNav.myEmployeeAvailability": "Resources Availability",
  "sideNav.myTimesheets": "Timesheets",
  "sideNav.attendanceManagement": "Attendance Management",
  "sideNav.timeManagement": "Time Management",
  "sideNav.Logs": "Logs",
  "sideNav.systemManagement": "System Management",
  "sideNav.Log-Details": "Log Details",
  "sideNav.backlog": "Tasks Backlog",
  "sideNav.board": "Board",
  "sideNav.reports": "Reports",
  "sideNav.users": "Locked Users",
  "sideNav.myAttendanceSheets": "Attendance Sheets",
  "sideNav.myTeams": "Teams",
  "sideNav.myRunningTasks": "Running Tasks",
  "sideNav.myRunningAttendances": "Running Attendances",
  "sideNav.timeEntries": "My Time",
  "sideNav.attendances": "My Attendance",
  "sideNav.my.timesheet": "My Timesheet",
  "sideNav.myContractors": "Contractors",
  "sideNav.importExportTime": "Import Export Time",

  //Table
  "table.noData": "No records to display.",
  "table.EditColumns": "Edit Columns",
  "table.ChangeOrder": "Change Order",
  "table.AddNew": "Add New Record",
  "table.Delete": "Delete",
  "table.Save": "Save",
  "table.Edit": "Edit",
  "table.ExpandAll": "Expand All",
  "table.CollapseAll": "Collapse All",
  "table.Cancel": "Cancel",
  "table.RefreshBtn": "Refresh Data",
  "table.Notifs": "Notifications",
  "table.MoveUp": "Move Up",
  "table.MoveDown": "Move Down",
  "table.squares": "List/Tiles",
  "table.selectMultiple": "Select Multiple",
  "table.FilterTitle": "Filter",
  "table.FilterTitleApplied": "Filters are applied",
  "table.filters.apply": "Apply",
  "table.filters.save": "Save Filter",
  "table.filters.save.new": "Save as New",
  "table.filters.update": "Update Filter",
  "table.filters.clear": "Clear",
  "table.filters.all": "All",
  "table.edit.columns.save": "Apply",
  "table.edit.columns.clear": "Clear",
  "table.edit.columns.cancel": "Cancel",
  "table.edit.columns.reset": "Reset",
  "table.filters.applied": "Filters are Applied",
  "table.no.filters.applied": "No Filters are Applied",
  "table.fill.requiredFields": "Please fill the required fields",
  "table.edit.columns.confirm.message":
    "Are you sure you want to reset columns? You will lose any customization already applied.",

  //tenant list
  "tenant.list.column.Name": "Description",
  "tenant.list.column.numFullLicense": "Number of Full Licenses",
  "tenant.list.column.numSelfService": "Number of Self Service",
  "tenant.list.column.projectRadiusInMobileApp": "Project Radius",
  "tenant.list.column.isActive": "Status",
  "tenant.list.column.trial": "Trial",
  "tenant.list.column.numberOfUsedLicenses": "Number Of Used Licenses",
  "tenant.list.column.trialExpirationDate": "Trial Expiration Date",
  "tenant.list.column.CreationDate": "Creation Date",
  "tenant.list.search.label": "Search by Description",
  "tenant.list.new.label": "New Tenant",
  "tenant.list.status.active.label": "Active",
  "tenant.list.status.inactive.label": "Inactive",
  "tenants.middlewareJob.enable": "Enable",
  "tenantForm.task.label": "Non Billable Task",
  "TenantForm.task.requiredMessage": "Task is required",
  "tooltip.timesheetBy":
    "Weekly Timesheets are recommended for tenants where resources work on weekly hours basis and can be paid overtime. In this case timesheets are linked to Workshifts for approval. Monthly Timesheets are recommended for tenants where resources work on project basis hours. In this case timesheets are linked to projects for approval.",
  "tenants.middlewareJob.disable": "Disable",
  "tenant.list.delete.label": "Delete",
  "tenant.list.delete.success": "Deleted Successfully",
  "tenant.list.reset.success": "Reset Successfully",
  "tenant.list.filter.name": "Description",
  "tenant.list.filter.status": "Status",
  "tenant.list.filter.trial": "Trial",
  "tenant.enter.name.delete":
    "Please type in the name of the tenant if you wish to delete.",
  "tenant.enter.name.reset":
    "Please type in the name of the tenant if you wish to reset.",
  "project.list.filter.client": "Client",
  "project.list.filter.isOpen": "Status",
  "tenant.tooltip.attendance":
    "Enable Attendance Management module at the level of the tenant: clock in/out, attendance sheet etc...",
  "tenant.tooltip.timeEntry":
    "Enable Time Management module at the level of the tenant: projects, timesheets, etc ...",
  "tenant.tooltip.syncTimeEntry":
    "Synchronize time entries to Business Central.",
  "tenant.tooltip.allowTimesheetReopening":
    "Allow reopening Timesheets after approval. Not recommended if time entries are synced based on monthly or weekly number of hours.",
  "tenant.tooltip.allowAttendanceSheetReopening":
    "Allow reopening Attendancesheet after approval. Not recommended if attendance entries are synced based on monthly or weekly number of hours.",
  "tenant.tooltip.postJournal":
    "Specifies if time entries are created in the Job Journal or Posted to the Job Ledger Entries (In Business Central).",

  "tenant.tooltip.task":
    "Status used when synchronizing Job Task Lines from Business Central where Job Task Type is posting.",
  "tenant.tooltip.phase":
    "Status used when synchronizing Job Task Lines from Business Central where Job Task Type is not posting.",
  "tenant.tooltip.logretention":
    "Number of days for retention of Middleware Jobs logs. It is recommended to keep a 7 days log. The maximum allowed is 14 days.",
  "tenant.tooltip.stack.pills":
    "Click to choose the fields to include in the Job Journal Description. You can choose one of the 2 Resource Names options and one of the 2 dates fields.",
  "tenant.tooltip.postJournalDescription":
    "Specifies how the system will fill the Job Journal Descrition when synchronizing time entries to Business Central",

  "trial.ends.today": "Your free trial ends today. ",
  "trial.ends.x.days": "Your free trial ends in ",
  "trial.ends.days": " days ",
  "trial.subscribe": "Click here to subscribe ",
  "tenant.management.tab": "Advanced Management",
  "tenant.reset.data.title": "Reset Data",
  "tenant.reset.data.msg":
    "Reset data will delete all Projects, Phases, Tasks, TimeSheets, Time entries, Attendance sheets, Attendance entries, Time off etc. Only Configurations tables, Resources, and Teams will not be deleted.",
  "tenant.delete.tenant.title": "Delete Tenant",
  "tenant.delete.tenant.msg":
    "Once you delete this tenant, all data will be deleted and tenant data will not be recoverable.",
  "dialog.user.delete.tenant.confirmation":
    "Are you sure you want to delete this tenant? All data will be deleted and will not be recoverable.",
  "dialog.user.reset.tenant.confirmation":
    "Are you sure you want to reset this tenant? Deleted data will not be recoverable.",
  "dialog.download": "Download",
  "dialog.ok": "OK",

  //Breadcrumbs
  "breadcrumbs.tenants": "Tenants",
  "breadcrumbs.tenantManagement": "Tenant Management",
  "breadcrumbs.users": "Locked Users",
  "breadcrumbs.newTenant": "New Tenant",
  "breadcrumbs.dashboard": "Dashboard",
  "breadcrumbs.help": "Help",
  "breadcrumbs.errors": "System Errors",
  "breadcrumbs.myCompanies": "Companies",
  "breadcrumbs.newCompany": "New Company",
  "breadcrumbs.tenantCompanies": "Companies",
  "breadcrumbs.newTenantCompany": "New Company",
  "breadcrumbs.favprolist": "My Favorite Projects",
  "breadcrumbs.logDetails": "Log Details",
  "breadcrumbs.tenantLicenses": "Licenses",
  "breadcrumbs.roles": "Roles",
  "breadcrumbs.tenantRoles": "Roles",
  "breadcrumbs.newRole": "New Role",
  "breadcrumbs.newTenantRoles": "New Role",
  "breadcrumbs.tenantWorkShifts": "Work Shifts",
  "breadcrumbs.departments": "Departments",
  "breadcrumbs.tenantDepartments": "Departments",
  "breadcrumbs.newDepartment": "New Department",
  "breadcrumbs.newTenantDepartments": "New Department",
  "breadcrumbs.newTenantWorkShift": "New WorkShift",
  "breadcrumbs.newWorkShift": "New WorkShift",
  "breadcrumbs.employees": "Resources",
  "breadcrumbs.newemployee": "New Resource",
  "breadcrumbs.workShifts": "WorkShifts",
  "breadcrumbs.myEmployees": "Resources",
  "breadcrumbs.myNewEmployees": "New Resource",
  "breadcrumbs.myTenant": "Tenant",
  "breadcrumbs.tenantLocations": "Locations",
  "breadcrumbs.newLocations": "New Location",
  "breadcrumbs.myLocations": "Locations",
  "breadcrumbs.myNewLocation": "New Location",
  "breadcrumbs.companyLocations": "Locations",
  "breadcrumbs.tenantOfficialHolidays": "Official Holidays",
  "breadcrumbs.newTenantOfficialHolidays": "New Official Holidays",
  "breadcrumbs.myNewProject": "New Project",
  "breadcrumbs.myProjects": "Projects",
  "breadcrumbs.tenantProjects": "Projects",
  "breadcrumbs.newProject": "New Project",
  "breadcrumbs.myTickets": "Phases",
  "breadcrumbs.myNewTicket": "New Phase",
  "breadcrumbs.tenantTickets": "Phases",
  "breadcrumbs.newTicket": "New Phase",
  "breadcrumbs.myTasks": "Backlog",
  "breadcrumbs.myNewTask": "New Task",
  "breadcrumbs.myNewSubTask": "New Subtask",
  "breadcrumbs.tenantTasks": "Backlog",
  "breadcrumbs.newTask": "New Task",
  "breadcrumbs.newSubTask": "New Subtask",
  "breadcrumbs.myOfficialHolidays": "Official Holidays",
  "breadcrumbs.newOfficialHoliday": "New Official Holiday",
  "breadcrumbs.dailyDayLogs": "Attendance",
  "breadcrumbs.myDashboard": "Dashboard",
  "breadcrumbs.myDailyDayLogs": "Attendance",
  "breadcrumbs.tenantPTOs": "Time Off",
  "breadcrumbs.newTenantPTO": "New Time Off",
  "breadcrumbs.myPTOs": "Time Off",
  "breadcrumbs.newPTO": "New Time Off",
  "breadcrumbs.request": "Requests",
  "breadcrumbs.newRequest": "New Request",
  "breadcrumbs.newAttendanceRequest": "New Request",
  "breadcrumbs.attendanceRequest": "Missed Attendance Request",
  "breadcrumbs.requests": "Requests",
  "breadcrumbs.employeeDaysUsed": "Days Used",
  "breadcrumbs.myProfile": "My Profile",
  "breadcrumbs.changePassword": "Change Password",
  "breadcrumbs.myProfile.myPtos2": "Time Off",
  "breadcrumbs.myProfile.mySkills2": "Skills",
  "breadcrumbs.myProfile.adminMyPTOs": "Time Off",
  "breadcrumbs.myProfile.adminMySkills": "Skills",
  "breadcrumbs.myProfile.adminMyTeams": "Teams",
  "breadcrumbs.myProfile.myTeams": "Teams",
  "breadcrumbs.myTaskStatus": "Task Status",
  "breadcrumbs.myNewTaskStatus": "New Task Status",
  "breadcrumbs.tenantTaskStatus": "Task Status",
  "breadcrumbs.tenantNewTaskStatus": "New Task Status",
  "breadcrumbs.tenantTicketStatus": "Phase Status",
  "breadcrumbs.tenantNewTicketStatus": "New Phase Status",
  "breadcrumbs.myTicketStatus": "Phase Status",
  "breadcrumbs.myNewTicketStatus": "New Phase Status",
  "breadcrumbs.projectAnalytics": "Projects",
  "breadcrumbs.employeeReference": "Resource Reference",
  "breadcrumbs.referenceInformation": "Reference Information",
  "breadcrumbs.newEmployeeReference": "New Resource Reference",
  "breadcrumbs.employeeTimeOffs": "Time Off",
  "breadcrumbs.employeeSkills": "Skills",
  "breadcrumbs.employeeTeams": "Teams",
  "breadcrumbs.timesheets": "Timesheets",
  "breadcrumbs.newTimesheet": "Approve Timesheet",
  "breadcrumbs.timesheetsPending": "Pending",
  "breadcrumbs.timesheetsApproved": "Approved",
  "breadcrumbs.backlog": "Backlog",
  "breadcrumbs.board": "Board",
  "breadcrumbs.attendanceSheets": "Attendance Sheets",
  "breadcrumbs.attendanceSheetsPending": "Pending",
  "breadcrumbs.attendanceSheetsApproved": "Approved",
  "breadcrumbs.skills": "Skills",
  "breadcrumbs.mySkills": "Skills",
  "breadcrumbs.employeesAvailability": "Resources Availability",
  "breadcrumbs.employeeTimesheet": "Resource Timesheet",
  "breadcrumbs.employeeAttendanceSheet": "Resource Attendance Sheet",
  "breadcrumbs.attendanceByEmployee": "Attendance By Resource",
  "breadcrumbs.timeByEmployee": "Time By Resource",
  "breadcrumbs.timeByProject": "Time By Project",
  "breadcrumbs.timeByContractor": "Time By Contractor",
  "breadcrumbs.timeByExternal": "Time By External Reference",
  "breadcrumbs.myTeams": "Teams",
  "breadcrumbs.runningTasks": "Running Tasks",
  "breadcrumbs.runningAttendances": "Running Attendances",
  "breadcrumbs.timeEntries": "My Time",
  "breadcrumbs.attendances": "My Attendance",
  "breadcrumbs.myTimesheet": "My Timesheet",
  "breadcrumbs.myContractors": "Contractors",
  "breadcrumbs.exportReport": "Export Report",
  "breadcrumbs.importExportTime": "Import Time",
  "breadcrumbs.importTime": "Import",
  "breadcrumbs.exportTime": "Export",
  "breadcrumbs.importTimeByResource": "Import Time By Resource",
  "breadcrumbs.excelBCFormat": "Excel BC Format",
  "breadcrumbs.importExportTimeEntries": "Import Time Entries",
  "breadcrumbs.printView": "Print Allocations",

  //Table Columns
  "columns.savedSuccessfully": "Table Columns Saved Successfully",
  "columns.resetSuccessfully": "Table Columns Reset Successfully",
  "columns.notReset": "Table Columns Already Set to Default",
  "columns.label": "Label",
  "columns.visibility": "Visibility",
  "columns.sortedByDefault": "Sorted By Default",
  "columns.sortDirection": "Sort Direction",
  "columns.title": "Edit Table Columns",
  "columns.ascending": "Ascending",
  "columns.descending": "Descending",

  //Tenant Tabs
  "tenantTabs.form": "Info",
  "tenantTabs.companies": "Companies",
  "tenantTabs.licenses": "Licenses",
  "tenantTabs.roles": "Roles",
  "tenantTabs.departments": "Departments",
  "tenantTabs.workshifts": "WorkShifts",
  "tenantTabs.locations": "Locations",
  "tenantTabs.officialHolidays": "Official Holidays",
  "tenantTabs.projects": "Projects",
  "tenantTabs.PersonTimeOffs": "Time Off",
  "tenantTabs.Request": "Requests",
  "tenantTabs.employees": "Resources",
  "tenantTabs.TaskStatus": "Task Statuses",
  "tenantTabs.TicketStatus": "Phase Statuses",
  "tenantTabs.skills": "Skills",
  "tenantTabs.Availability": "Resources Availability",

  //Tenant Form
  "tenantForm.save": "Save",
  "tenantForm.name.label": "Description",
  "tenantForm.name.requiredMessage": "Description is required",
  "TenantForm.description.label": "Description",
  "TenantForm.description.requiredMessage": "Description is required",
  "TenantForm.status.label": "Status",
  "TenantForm.status.requiredMessage": "Status is required",
  "TenantForm.ticket.label": "Phase",
  "TenantForm.ticket.requiredMessage": "Phase is required",
  "tenantForm.licenseType.label": "Type of Licenses",
  "TenantForm.licenseType.requiredMessage": "Type of Licenses is required",
  "tenantForm.numberOfUsedLicenses.label": "Number of Licenses Used",
  "TenantForm.numberOfUsedLicenses.requiredMessage":
    "Number of Licenses Used is required",
  "tenantForm.numberOfLicenses.label": "Number of Licenses Purchased",
  "TenantForm.numberOfLicenses.requiredMessage":
    "Number of Licenses Purchased is required",
  "tenantForm.projectRadius.label": "Project Radius (m)",
  "TenantForm.projectRadius.requiredMessage": "Project Radius is required",
  "tenantForm.location.label": "Location",
  "TenantForm.location.requiredMessage": "Location is required",
  "tenantForm.updatedSuccessfully": "Saved Successfully",
  "tenantForm.creationDate": "Creation Date",
  "tenantForm.sideInfo": "Side Information",
  "tenantForm.tenantInformation": "Tenant Information",
  "tenantForm.licensesInformation": "Licenses Information",
  "tenantForm.status.active": "Active",
  "tenantForm.status.inactive": "Inactive",
  "tenantForm.button.deactivate": "Deactivate",
  "tenantForm.button.activate": "Activate",
  "tenantForm.statusChange.success": "Saved Successfully",
  "tenantForm.whatField": "Help",
  "tenantForm.attendance.label": "Attendance Enabled",
  "TenantForm.attendance.requiredMessage": "Attendance switch is required",
  "TenantForm.timeEntry.requiredMessage": "Time Entry switch is required",
  "tenantForm.timeEntry.label": "Time Entry Enabled",
  "tenantForm.syncTimeEntries.label": "Sync Time Entries",
  "TenantForm.syncTimeEntries.requiredMessage":
    "Sync Time Entries switch is required",
  "TenantForm.taskStatuses.requiredMessage": "Task Statuses field is required",
  "tenantForm.systemConfiguration": "System Configuration",
  "tenantForm.taskStatuses.label": "Task Default Status",
  "TenantForm.phaseStatuses.requiredMessage":
    "Phase Statuses field is required",
  "tenantForm.phaseStatuses.label": "Phase Default Status",
  "TenantForm.LogCleanUpRuntime.requiredMessage":
    "Log/Notifications Cleanup Time is required",
  "tenantForm.LogCleanUpRuntime.label": "Log/Notifications Cleanup Time",
  "TenantForm.LogRetentionDays.requiredMessage":
    "Log/Notifications Retention Days field is required",
  "tenantForm.LogRetentionDays.label": "Log/Notifications Retention Days",
  "TenantForm.connector.requiredMessage": "Connector ID is required",
  "tenantForm.connector.label": "Connector",
  "TenantForm.middlewareJob.requiredMessage":
    "MiddleWare Job field is required",
  "tenantForm.middlewareJob.label": "Middleware Job",
  "tenantForm.MiddlewareJobs": "Middleware Jobs",
  "tenantForm.postJournal.label": "Post Job Journal",
  "tenantForm.postJournal.requiredMessage":
    "Post Job Journal field is required",
  "tenantForm.postJournalDescription.label": "Job Journal Descritpion Value",
  "tenantForm.postJournalDescription.requiredMessage":
    "Job Journal Descritpion Value field is required",
  "tenant.tooltip.connector":
    "Connector-1: Syncs time entries to Business Central weekly. Regular time and Overtime use different Business Central resources." +
    "\nConnector-2: Syncs time entries to Business Central monthly. No Overtime is recorded. Over usage and under usage of resource is logged in a non billable job created in Business Central and specified in the fields below (Company, Job, Task)",
  "tenantForm.attendance.settings": "Attendance Settings",
  "tenantForm.timeEntry.settings": "Time Entry Settings",
  "tenantForm.timeEntry.sync": "Sync Time Entries Settings",
  "tenantForm.trial.license": "Trial License",
  "tenantForm.trial.label": "Trial",
  "tenantForm.trialExpirationDate.label": "Trial Expiration Date",
  "tenantForm.subscribedFrom.label": "Subscribed From",
  "BCName": "BC Resource Name",
  "activName": "Activ Resource Name",
  "dateFrom": "Date To",
  "dateFromTo": "Date From To",
  "clear": "Clear",

  //company list
  "company.list.search.label": "Search by Description or Tenant",
  "company.list.search.label.fromTenant": "Search by Description",
  "company.list.new.label": "New Company",
  "company.list.column.Name": "Description",
  "company.list.column.Tenant": "Tenant",
  "company.list.column.creationDate": "Creation Date",
  "company.list.column.createdBy": "Created By",
  "company.list.filters.name": "Description",
  "company.list.filters.tenant": "Tenant",
  "company.list.filters.creationDateFrom": "Creation Date From",
  "company.list.filters.creationDateTo": "Creation Date To",
  "company.list.filters.createdBy": "Created By",
  "company.list.delete.label": "Delete",
  "company.list.delete.success": "Deleted Successfully",

  //company tabs
  "companyTabs.form": "Info",
  "companyTabs.location": "Locations",

  //company form
  "companyForm.sideInfo": "Side Information",
  "companyForm.name.label": "Description",
  "companyForm.name.requiredMessage": "Company Description is required",
  "companyForm.tenant.label": "Tenant",
  "companyForm.tenant.requiredMessage": "Tenant is required",
  "companyForm.externalReference.label": "External Reference",
  "companyForm.externalReference.requiredMessage":
    "External Reference is required",
  "companyForm.save": "Save",
  "companyForm.creationDate": "Creation Date",
  "companyForm.createdBy": "Created By",
  "companyForm.modifiedBy": "Modified By",
  "companyForm.modificationDate": "Modification Date",
  "companyForm.createdSuccessfully": "Saved Successfully",
  "companyForm.updatedSuccessfully": "Saved Successfully",
  "companyForm.connectionString.label": "Connection String",
  "companyForm.connectionString.requiredMessage":
    "Connection String is required",
  "companyForm.companyInformation.title": "Company Information",
  "middlewareJob.list.column.middlewareJob": "Description",
  "middlewareJob.list.column": "Status",
  "companyForm.password.label": "Password",
  "companyForm.password.requiredMessage": "Password is required",
  "companyForm.userName.requiredMessage": "Username is required",
  "companyForm.userName.label": "Username",
  "companyForm.version.label": "Business Central Version",
  "companyForm.version.requiredMessage": "Business Central Version is required",
  "companyForm.clientId.label": "Client Id",
  "companyForm.clientId.requiredMessage": "Client Id is required",
  "companyForm.secretId.requiredMessage": "Secret Id is required",
  "companyForm.secretId.label": "Secret Id",
  "companyForm.tenantId.requiredMessage": "Tenant Id is required",
  "companyForm.tenantId.label": "Tenant Id",
  "version.on.prem": "On Prem Business central",
  "version.on.cloud": "On Cloud Business central",

  //Roles List
  "role.list.delete.success": "Deleted Successfully",
  "role.list.search.label.fromTenant": "Search By Name",
  "role.list.new.label": "New Role",
  "role.list.delete.label": "Delete",
  "roles.list.filters.name": "Name",
  "role.list.column.Name": "Name",
  "role.list.search.label": "Search By Name",

  //Role Form
  "roleForm.name.label": "Name",
  "roleForm.name.requiredMessage": "Name is required",
  "roleForm.isAdmin.label": "Admin Role",
  "roleForm.description.label": "Description",
  "roleForm.manageSystemConfigurations.label":
    "Can Manage System Configurations",
  "roleForm.manageUsers.label": "Can Manage Users",
  "roleForm.manageAttendance.label": "Can Manage Attendance",
  "roleForm.manageTimeEntry.label": "Can Manage Time",
  "roleForm.save": "Save",
  "roleForm.sideInfo": "Side Information",
  "roleForm.roleInformation": "Role Information",
  "roleForm.rolePermissions": "Permissions",
  "roleForm.manageSystemConfigurations.requiredMessage":
    "Manage System Configurations Role is required",
  "roleForm.manageUsers.requiredMessage": "Manage Users Role is required",
  "roleForm.manageAttendance.requiredMessage": "Manage Attendance is required",
  "roleForm.manageTimeEntry.requiredMessage": "Manage Time is required",
  "roleForm.manageParameters.requiredMessage":
    "Manage System Configurations is required",
  "roleForm.role.value.manage": "Manage",
  "roleForm.role.value.read": "Read-Only",
  "roleForm.role.value.noaccess": "No Access",
  "roleForm.createdSuccessfully": "Saved Successfully",
  "roleForm.updatedSuccessfully": "Saved Successfully",
  "roleForm.manageParameters.label": "Can Manage System Parameters",
  "roleForm.whatField": "Help",
  "role.tooltip.manageSystemConfigurations":
    "Role responsible for managing Departments, Locations, Workshifts, Official Holidays, Timeoff, Task and Phase Statuses, Skills",
  "role.tooltip.manageAttendance":
    "Role responsible for managing Resources attendance",
  "role.tooltip.manageTimeEntry":
    "Role responsible for managing Projects, Timesheets and Resources avalability",
  "role.tooltip.manageParameters":
    "Role responsible for managing Tenants, Roles and Companies",

  //role tabs
  "roleTabs.form": "Info",

  //department list
  "department.list.search.label": "Search by Description",
  "department.list.search.label.fromTenant": "Search by Description",
  "department.list.new.label": "New Department",
  "department.list.column.Name": "Description*",
  "department.list.filters.name": "Description",
  "department.list.delete.label": "Delete",
  "department.list.delete.success": "Deleted Successfully",

  //department tabs
  "departmentTabs.form": "Info",

  //workshifts list
  "workshifts.list.delete.success": "Deleted Successfully",
  "workshifts.list.search.label": "Search by Description",
  "workshifts.list.new.label": "New WorkShift",
  "workshifts.list.delete.label": "Delete",
  "workshift.list.column.Description": "Description",
  "workshift.list.column.totalWeeklyHours": "Total Weekly Hours",
  "workshift.filters.label.description": "Description",
  "workshift.filters.label.minTotalWeeklyHours": "Minimum Total Weekly Hours",
  "workshift.filters.label.maxTotalWeeklyHours": "Maximum Total Weekly Hours",

  //work shift tabs
  "workshift.form": "Info",

  //work shift form
  "workShift.description.label": "Description",
  "workShift.description.requiredMessage": "Description is required",
  "workShift.startHour.label": "Start Time",
  "workShift.startHour.requiredMessage": "Start Time is required",
  "workShift.endHour.label": "End Time",
  "workShift.endHour.requiredMessage": "End Time is required",
  "workShift.isWorkDay.label": "Working Day",
  "workShift.startHour.invalidDateFormat": "Invalid Date Format",
  "workShift.endHour.invalidDateFormat": "Invalid Date Format",
  "workShift.endHour.invalidDateDifference":
    "End Time cannot be before start Time",
  "workShiftForm.save": "Save",
  "workShiftForm.workShiftInformation": "WorkShift Information",
  "workshiftForm.createWorkShift": "Saved Successfully",
  "workShiftForm.updatedSuccessfully": "Saved Successfully",
  "workShift.breakTime.label": "Break Time",
  "workShift.breakTime.requiredMessage": "Break Time is required",
  "workShift.breakTime.invalidFormat": "Invalid Format",
  "workShift.copyFromFirstDay": "Copy From First Day to Other Days",
  "workShift.StartOfWeek.label": "First Day of Week",
  "workShift.totalWeeklyHours": "Total Weekly Hours: {{hours}}h {{minutes}}m",
  "workShift.edit.errorMessage":
    "You cannot edit this workshift, it has records linked to it",

  //department Form
  "departmentForm.name.label": "Description",
  "departmentForm.name.requiredMessage": "Description is required",
  "departmentForm.departmentInformation": "Department Information",
  "departmentForm.createdSuccessfully": "Saved Successfully",
  "departmentForm.updatedSuccessfully": "Saved Successfully",

  //employees list
  "employee.list.search.label": "Search by Name",
  "employee.list.search.label.fromTenant": "Search by Name",
  "employee.list.new.label": "New Resource",
  "employee.list.delete.label": "Delete",
  "employee.list.column.FirstName": "First Name",
  "employee.list.column.LastName": "Last Name",
  "employee.list.column.Email": "Email",
  "employee.list.column.EmploymentType": "Employment Type",
  "employee.list.column.Workshift": "Workshift",
  "employee.list.column.Manager": "Manager",
  "employee.list.column.IsManager": "Is Manager",
  "employee.list.column.IsAutoClockIn": "Is Auto Clock In",
  "employee.list.column.IsOffice365User": "Is Office365 User",
  "employee.list.column.Department": "Department",
  "employee.list.column.Tenant": "Tenant",
  "employee.list.column.Position": "Position",
  "employee.list.column.createdBy": "Created By",
  "employee.list.column.creationDate": "Creation Date",
  "employee.list.column.Role": "Role",
  "employee.list.column.PhoneNumber": "Phone",
  "employee.list.column.EmployeeStatus": "Profile Status",
  "employee.list.filters.name": "Name",
  "employee.list.filters.Tenant": "Tenant",
  "employee.list.filters.departments": "Department",
  "employee.list.filters.employmentTypes": "Employment Type",
  "employee.list.filters.workShifts": "WorkShift",
  "employee.list.filters.managers": "Manager",
  "employee.list.filters.holidays": "Holiday",
  "employee.list.filters.email": "Email",
  "employee.list.filters.contractor": "Contractor",
  "employee.list.filters.resourceType": "Resource Type",
  "employee.list.filters.employmentStatus": "Employment Status",
  "employee.list.filters.Status": "Status",
  "employee.list.filters.creationDateFrom": "Creation Date From",
  "employee.list.filters.creationDateTo": "Creation Date To",
  "employee.list.filters.isManager": "Is Manager",
  "employee.list.filters.isAutoClockin": "Is Auto Clock In",
  "employee.list.filters.workTypes": "Work Type",
  "employee.list.filters.company": "Company",
  "employee.list.filters.isAppUser": "Is Self-Service",
  "employee.list.filters.createdBy": "Created By",
  "employee.list.delete.success": "Deleted Successfully",
  "phoneNumber.format":
    "Please try adding the phone number in this format '+(country code) phone number'. Remember to use the same number and format when logging in to the Mobile App.",

  //employee form
  "employeeForm.createdBy": "Created By",
  "employeeForm.creationDate": "Creation Date",
  "employeeForm.modifiedBy": "Modified By",
  "employeeForm.modificationDate": "Modification Date",
  "employeeForm.unlock": "Unlock",
  "employeeForm.unlockSuccessfully": "User(s) Unlocked Successfully",
  "employeeForm.firstName.label": "First Name",
  "employeeForm.firstName.requiredMessage": "First Name is required",
  "employeeForm.lastName.label": "Last Name",
  "employeeForm.lastName.requiredMessage": "Last Name is required",
  "employeeForm.email.label": "Email",
  "employeeForm.email.requiredMessage": "Email is required",
  "employeeForm.phoneNumber.label": "Phone Number",
  "employeeForm.phoneNumber.requiredMessage": "Phone Number is required",
  "employeeForm.role.label": "Role",
  "employeeForm.role.requiredMessage": "Role is required",
  "employeeForm.department.label": "Department",
  "employeeForm.department.requiredMessage": "Department is required",
  "employeeForm.workShift.label": "Work Shift",
  "employeeForm.workShift.requiredMessage": "Work Shift is required",
  "employeeForm.workType.label": "Work Type",
  "employeeForm.workType.requiredMessage": "Work Type is required",
  "employeeForm.employmentType.label": "Employment Type",
  "employeeForm.employmentType.requiredMessage": "Employment Type is required",
  "employeeForm.isOffice365User.label": "Is Office365 User",
  "employeeForm.isOffice365User.requiredMessage": "isOffice365User is required",
  "employeeForm.defaultWorkCompany.label": "Default Work Company",
  "employeeForm.defaultWorkCompany.requiredMessage":
    "Default Work Company is required",
  "employeeForm.isAutoClockIn.label": "Is AutoClockIn",
  "employeeForm.isAutoClockIn.requiredMessage": "IsAutoClockIn is required",
  "employeeForm.isAppUser.label": "Is Self-Service",
  "employeeForm.isAppUser.requiredMessage": "is Self-Service is required",
  "employeeForm.directManager.label": "Direct Manager",
  "employeeForm.directManager.requiredMessage": "Direct Manager is required",
  "employeeForm.tenant.label": "Tenant",
  "employeeForm.employeeInformation": "Resource Information",
  "employeeForm.workInformation": "Work Settings",
  "employeeForm.userInformation": "User Settings",
  "employeeForm.employmentInformation": "Employment Settings",
  "employeeForm.createdSuccessfully": "Saved Successfully",
  "employeeForm.updatedSuccessfully": "Saved Successfully",
  "employeeForm.homeLocation.label": "Home Location",
  "employeeForm.timeZone.label": "Time Zone (GMT)",
  "employeeForm.timeZone.requiredMessage": "Time Zone is required",
  "employeeForm.employmentDate.label": "Employment Date",
  "employeeForm.employmentDate.requiredMessage": "Employment Date is required",
  "employeeForm.employmentDate.invalidMessage": "Invalid Date",
  "employeeForm.position.label": "Position",
  "employeeForm.position.requiredMessage": "Position is required",
  "employeeForm.isManager.label": "Is Manager",
  "employeeForm.isManager.requiredMessage": "IsManager is required",
  "employeeForm.profilePicture": "Profile Picture",
  "employeeForm.profilePicture.label": "Profile Picture",
  "employeeForm.profilePicture.requiredMessage": "Profile Picture is required",
  "employeeForm.regularPayExternalReference.label":
    "RegularPayExternalReference",
  "employeeForm.regularPayExternalReference.requiredMessage":
    "RegularPayExternalReference is required",
  "employeeForm.overtimeExternalReference.label": "OvertimeExternalReference",
  "employeeForm.overtimeExternalReference.requiredMessage":
    "OvertimeExternalReference is required",
  "employeeForm.officialHoliday.label": "Holiday Calendar",
  "employeeForm.officialHoliday.requiredMessage":
    "Holiday Calendar is required",
  "employeeForm.employeePTOs": "Time Off",
  "employeeForm.addPTO": "Add Timeoff",
  "employeeForm.ptoList.addPTO": "Add Timeoff",
  "employeeForm.PTOs.NumberOfDays": "Number of Days: {{numDays}}",
  "employeeForm.create.ptoList.Description": "Description",
  "employeeForm.create.ptoList.NumberOfDays": "Number of Days",
  "employeeForm.title.editPTOs": "Edit Timeoff",
  "employeeForm.create.ptoList.remove": "Delete Timeoff",
  "employeeForm.save": "Save",
  "employeeForm.sideInfo": "Side Information",
  "employeeForm.userActivation": "Resend Activation Email",
  "employeeForm.deactivate": "Deactivate",
  "employeeForm.activate": "Activate",
  "employeeForm.activatedSuccessfully": "User Activated Successfully",
  "employeeForm.deactivatedSuccessfully": "User Deactivated Successfully",
  "employeeForm.deleteSuccessful": "User Deleted Successfully",
  "employeeForm.resentSuccessfully": "Email Resent Successfully",
  "employeeForm.employeeStatus.label": "Employment Status",
  "employeeForm.employeeStatus.requiredMessage":
    "Employment Status is required",
  "employeeForm.probation.label": "Probation (months)",
  "employeeForm.probation.requiredMessage": "Probation time is required",
  "employeeForm.externalReferences": "External References",
  "employeeForm.linkedUsersButton": "Close",
  "employeeForm.linkedUsersTitle": "Warning",
  "employeeForm.linkedUsersText":
    "You cannot remove the flag manager from this employee as they are manager on the following employees:",
  "employeeForm.profileSaved": "Profile Picture Changed Successfully",
  "employeeForm.resignationDate": "Resignation Date",
  "employeeForm.resignationDate.label": "Resignation Date",
  "employeeForm.resignationDate.requiredMessage":
    "Resignation date is required",
  "employeeForm.inactiveDate.label": "Inactivity Date",
  "employeeForm.deactivationDate": "Deactivation Date",
  "employeeForm.attendance.label": "Attendance Enabled",
  "employeeForm.timeEntry.label": "Time Entry Enabled",
  "employeeForm.preferEmail.label": "Receive OTP by Email",
  "employeeForm.preferEmail.requiredMessage": "",
  "employeeForm.preferSMS.label": "Receive OTP by SMS",
  "employeeForm.preferSMS.requiredMessage": "",
  "employee.tooltip.selfservice":
    "Specifies if this user is using a Self Service license",
  "employee.tooltip.attendance":
    "Specifies if is employee has the Attendance Management module enabled.",
  "employee.tooltip.timeEntry":
    "Specifies if is employee has the Time Management module enabled.",
  "employee.tooltip.email":
    "Email is not mandatory for Self-Service employees unless they need to login to Activ from the web.",
  "references.tooltip.overtimeExternalResource":
    "Resource used for creating Overtime Job Entries cost.",
  "references.tooltip.regularPayExternalResource":
    "Resource used for creating Job Entries cost.",
  "employeeForm.resourceType.label": "Resouce Type",
  "employeeForm.resourceType.requiredMessage": "Resouce Type is required",
  "employeeForm.contractor.label": "Contractor",
  "employeeForm.canCreateKiosk.label": "Can create kiosk",
  "employeeForm.contractor.requiredMessage": "Contractor is required",
  "dialog.user.change.date.confirmation":
    "Changing the Employment date of the Resource will affect the TimeOff Calculation. If you wish to proceed and change it, you might need to adjust any previously created Entitlements for the Resource.",
  "dialog.proceed": "Proceed with Start date change",
  "dialog.dontproceed": "Cancel Start date change",

  //Locations list
  "location.list.delete.success": "Deleted Successfully",
  "location.list.search.label.fromTenant": "Search by Description or Address",
  "location.list.new.label": "New Location",
  "location.list.delete.label": "Delete",
  "location.list.column.Name": "Description",
  "location.list.column.Address": "Address",
  "location.list.column.Country": "Country",
  "location.list.column.company": "Company",
  "location.list.column.IsOnSite": "Office Branch",
  "location.list.search.label": "Search by Description or Address",

  //employee tabs
  "employeeTabs.form": "Info",
  "employeeRefTabs.form": "External References",
  "employeeTabs.timeOffs": "Time Off",
  "employeeTabs.skills": "Skills",
  "employeeTabs.teams": "Teams",

  //location tabs
  "locationTabs.form": "Info",
  "locationTabs.accessPoints": "Access Points",

  //Location Form
  "locationForm.name.label": "Description",
  "locationForm.name.requiredMessage": "Description is required",
  "locationForm.address.label": "Address",
  "locationForm.address.requiredMessage": "Address is required",
  "locationForm.latitude.label": "Latitude",
  "locationForm.latitude.requiredMessage": "Latitude is required",
  "locationForm.longitude.label": "Longitude",
  "locationForm.longitude.requiredMessage": "Longitude is required",
  "locationForm.radius.label": "Radius",
  "locationForm.radius.requiredMessage": "Radius is required",
  "locationForm.isOnSite.label": "Office Branch",
  "locationForm.UnitOfMeasure.label": "Unit of Measure",
  "locationForm.UnitOfMeasure.requiredMessage": "Unit of Measure is required",
  "locationForm.country.label": "Country",
  "locationForm.country.requiredMessage": "Country is required",
  "locationForm.company.label": "Company",
  "locationForm.company.requiredMessage": "Company is required",
  "locationForm.createdBy": "Created By",
  "locationForm.creationDate": "Creation Date",
  "locationForm.modifiedBy": "Modified By",
  "locationForm.modificationDate": "Modification Date",
  "locationForm.createdSuccessfully": "Saved Successfully",
  "locationForm.updatedSuccessfully": "Saved Successfully",
  "locaiton.list.filters.name": "Description",
  "location.list.filters.address": "Address",
  "location.list.filters.onSite": "Office Branch",
  "location.list.filters.isOnSite": "On Site",
  "location.list.filters.isNotOnSite": "Not On Site",
  "location.list.filters.countries": "Countries",
  "location.list.filters.companies": "Companies",
  "locationForm.locationInformation.title": "Location Information",
  "locationForm.locationAddress.title": "Address Information",
  "locationForm.applicationGeolocationInformation.title":
    "Application Geolocation Information",
  "location.tooltip.officebranch":
    "Specifies if this location is an Office Branch for clocking in from office or if it is a site location.",

  //Location access Points
  "location.access.points.list.column.sSID": "SSID*",
  "location.access.points.list.column.subnet": "Subnet*",
  "locationAccessPoint.createdSuccessfully": "Saved Successfully",
  "locationAccessPoint.updatedSuccessfully": "Saved Successfully",
  "locationAccessPoint.list.delete.success": "Deleted Successfully",
  "locationAccessPoint.list.search.label": "Search",
  "locationAccessPoint.list.delete.label": "Delete",
  "locationAccessPoint.cannot.create":
    "Cannot create access point to a site branch",

  //projects list
  "project.list.search.label": "Search by Title",
  "project.list.new.label": "New Project",
  "project.list.delete.label": "Delete",
  "project.list.column.Title": "Title",
  "project.list.column.Description": "Description",
  "project.list.column.IsOpen": "Status",
  "project.list.column.Company": "Company",
  "project.list.column.Location": "Location",
  "project.list.column.createdBy": "Created By",
  "project.list.column.creationDate": "Creation Date",
  "project.list.column.modifiedBy": "Modified By",
  "project.list.column.modificationDate": "Modification Date",
  "project.list.column.Client": "Client",
  "project.list.column.PercentageComplete": "Progress",
  "project.list.column.estimatedEndDate": "Estimated End Date",
  "project.list.column.estimatedStartDate": "Estimated Start Date",
  "project.list.column.time": "",
  "project.list.column.estimatedTime": "Estimated Time (h)",
  "project.list.column.actualEndDate": "Actual End Date",
  "project.list.column.actualStartDate": "Actual Start Date",
  "project.list.column.actualTime": "Actual Time (h)",
  "project.list.print": "Print Allocations",
  "project.filters.label.title": "Title",
  "project.filters.label.description": "Description",
  "project.filters.label.company": "Company",
  "progressBar.tooltip": "Progress equals Actual time / Estimated time",

  //Project Tabs
  "projectTabs.form": "Info",
  "projectTabs.tickets": "Phases",
  "projectTabs.tasks": "Tasks",
  "projectTabs.plan": "Plan",
  "projectTabs.tree": "Tree",
  "projectTabs.members": "Members",
  "projectTabs.Areas": "Areas",
  "breadcrumbs.areas": "Areas",

  //official holidays
  "officialHoliday.list.column.description": "Description",
  "officialHoliday.list.column.year": "Year",

  //Official Holiday List
  "officialHoliday.list.delete.success": "Deleted Successfully",
  "officialHoliday.list.search.label": "Search by Description",
  "officialHoliday.list.new.label": "New Holidays",
  "officialHoliday.list.delete.label": "Delete",

  //Official Holiday Tabs
  "officialHolidays.form": "Info",

  //Official Holiday Form
  "officialHolidayForm.description.label": "Description",
  "officialHolidayForm.description.requiredMessage": "Description is required",
  "officialHolidayForm.save": "Save",
  "officialHolidays.officialHolidayInformation.title":
    "Official Holiday Information",
  "officialHolidays.officialHolidayDays.title": "Copy Holidays From Last Year",
  "officialHolidays.officialHolidayDays.copyDays": "Copy to next year",
  "officialHolidays.officialHolidayDays.addDay": "Add Day",
  "officialHolidays.officialHolidayDays.deleteDay":
    "Remove Day ({{dayDescription}})",
  "officialHolidays.officialHolidayDays.description": "Day Description",
  "officialHolidays.officialHolidayDays.date": "Date",
  "officialHolidayForm.createdSuccessfully": "Saved Successfully",
  "officialHolidayForm.updatedSuccessfully": "Saved Successfully",
  "officialHolidays.year": "Year",
  "officialHolidays.officialHolidayDays.copyDays.success":
    "Copied to next year",
  "duplicate.dates.error": "Dates must be unique.",

  //Project Form
  "projectForm.title.label": "Title",
  "projectForm.title.requiredMessage": "Title is required",
  "projectForm.description.label": "Description",
  "projectForm.description.requiredMessage": "Description is required",
  "projectForm.isOpen.label": "Is Active",
  "projectForm.isOpen.requiredMessage": "Is Active is required",
  "projectForm.location.label": "Location",
  "projectForm.location.requiredMessage": "Location is required",
  "projectForm.client.label": "Client",
  "projectForm.client.requiredMessage": "Client is required",
  "projectForm.tenant.label": "Tenant",
  "projectForm.tenant.requiredMessage": "Tenant is required",
  "projectForm.company.label": "Company",
  "projectForm.company.requiredMessage": "Company is required",
  "projectForm.externalReference.label": "External Reference",
  "projectForm.externalReference.requiredMessage":
    "External Reference is required",
  "projectForm.synced": "Synced",
  "projectForm.favorite": "Mark as favorite",
  "projectForm.projectInformation": "Project Information",
  "projectForm.save": "Save",
  "favoriteProject.success.added": "Project is marked as favorite",
  "favoriteProject.success.removed": "This project is removed from favorites",
  "projectForm.status.label": "Project Status",
  "projectForm.status.requiredMessage": "Project Status is required",
  "projectForm.createdSuccessfully": "Saved Successfully",
  "projectForm.updatedSuccessfully": "Saved Successfully",
  "projectForm.status.change.warning":
    "Saved Successfully. Please be aware that the status you just assigned to this task is Posterior to the statuses of its subtasks.",
  "projects.list.delete.success": "Deleted Successfully",
  "projectForm.projectLocation": "Project Location",
  "projectForm.addLocation": "Add Location",
  "project.tooltip.regularPayExternalResource":
    "Specifies the Job No. in Business Central.",
  "projectPermission.list.column.resource": "Resource*",
  "projectPermission.add.new.resource": "Add Resource",
  "assignResourcesToProject.success": "Saved Successfully",
  "projectPermission.list.search.label": "Search",
  "projectPermission.list.delete.label": "Deleted Successfully",

  //Tickets Tabs
  "ticketTabs.form": "Info",
  "ticketTabs.tickets": "Tasks",

  //Tickets list
  "ticket.list.search.label": "Search by Title",
  "ticket.list.search.label.fromTenant": "Search by Title",
  "ticket.list.new.label": "New Phase",
  "ticket.list.delete.label": "Delete",
  "ticket.list.column.Name": "Title",
  "ticket.list.column.Description": "Description",
  "ticket.list.column.Company": "Company",
  "ticket.list.column.Status": "Status",
  "ticket.list.column.Client": "Client",
  "ticket.filters.label.name": "Title",
  "ticket.filters.label.description": "Description",
  "ticket.filters.label.company": "Company",
  "ticket.filters.label.status": "Status",
  "ticket.list.column.id": "ID",
  "ticket.list.displayPlan": "Plan",

  //Ticket Form
  "ticketForm.name.label": "Title",
  "ticketForm.newTicket": "New Phase",
  "ticketForm.externalReference.label": "External Reference",
  "ticketForm.synced.label": "Synced",
  "ticketForm.name.requiredMessage": "Title is required",
  "ticketForm.description.label": "Description",
  "ticketForm.description.requiredMessage": "Description is required",
  "ticketForm.project.label": "Project",
  "ticketForm.project.requiredMessage": "Project is required",
  "ticketForm.status.label": "Status",
  "ticketForm.status.requiredMessage": "Status is required",
  "ticketForm.ticketInformation": "Phase",
  "ticketForm.save": "Save",
  "ticketForm.createdSuccessfully": "Saved Successfully",
  "ticketForm.updatedSuccessfully": "Saved Successfully",
  "ticket.list.delete.success": "Deleted Successfully",
  "ticketForm.time": "Estimated",
  "ticketForm.EstimatedTime.label": "Estimated",
  "ticketForm.EstimatedEndDate.label": "Estimated",
  "ticketForm.EstimatedStartDate.label": "Estimated",
  "ticketForm.ActualStartDate.label": "Actual",
  "ticketForm.startDate.label": "Start Date",
  "ticketForm.endDate.label": "End Date",
  "ticketForm.ActualEndDate.label": "Actual",
  "ticketForm.ActualTime.label": "Actual",
  "ticketForm.time.label": "Time (h)",
  "ticketForm.area.label": "Area",
  "phase.tooltip.regularPayExternalResource":
    "Specifies the Job Task No. in Business Central (Tasks where Job Task Type is not posting).",

  //Tasks Tabs
  "taskTabs.form": "Info",
  "taskTabs.tasks": "Tasks",
  "taskTabs.timeEntries": "Time Entries",
  "taskTabs.subtasks": "Subtasks",

  //Tasks list
  "task.list.search.label": "Search by ID or Title",
  "task.list.search.label.fromTenant": "Search by ID or Title",
  "task.list.new.label": "New Task",
  "task.list.delete.label": "Delete",
  "task.list.column.Title": "Title",
  "task.list.column.PercentageComplete": "Progress",
  "task.list.column.BillableTasksPercentage": "Billable Time",
  "task.list.column.estimatedEndDate": "Estimated End Date",
  "task.list.column.estimatedStartDate": "Estimated Start Date",
  "task.list.column.time": " ",
  "task.list.column.ExternalReference": "External Reference",
  "task.list.column.estimatedTime": "Estimated Time (h)",
  "task.list.column.actualEndDate": "Actual End Date",
  "task.list.column.actualStartDate": "Actual Start Date",
  "task.list.column.actualTime": "Actual Time (h)",
  "task.list.column.Phase": "Phase",
  "task.list.column.Employee": "Assigned To",
  "task.list.column.Status": "Status",
  "task.list.column.Project": "Project",
  "task.list.column.Ticket": "Phase",
  "task.list.column.Area": "Area",
  "task.list.column.Description": "Description",
  "task.list.column.Priority": "Priority",
  "task.list.column.createdBy": "Created By",
  "task.list.column.creationDate": "Creation Date",
  "task.list.column.modifiedBy": "Modified By",
  "task.list.column.modificationDate": "Modification Date",
  "task.list.column.Parent": "Parent Task",
  "task.filters.label.title": "Title",
  "task.filters.label.description": "Description",
  "task.filters.label.employee": "Resource",
  "task.filters.label.status": "Status",
  "task.filters.label.ticket": "Phase",
  "task.filters.label.parent": "Parent Task",
  "task.filters.label.project": "Project",
  "task.filters.label.unassignedTasks": "Unassigned Tasks",
  "task.filters.label.area": "Area",
  "task.filters.label.teams": "Teams",
  "task.filters.label.billable": "Billable",
  "task.filters.label.nonBillable": "Non Billable",
  "task.list.filters.priority": "Priority",
  "task.list.column.id": "ID",
  "task.list.exportToExcel": "Export",
  "task.list.importExcel": "Import",
  "task.list.exportToExcel.dialog.title": "Export Time entries",
  "task.list.deleteFilter.dialog.text":
    "Are you sure you want to delete this filter?",
  "task.list.exportToExcel.fileName": "File Name",
  "task.list.savedSuccessfully": "Saved Successfully",
  "task.list.deletedSuccessfully": "Deleted Successfully",

  //Task Form
  "taskForm.title.label": "Title",
  "taskForm.synced.label": "Synced",
  "taskform.actual": "Actual",
  "Priority": "Priority",
  "taskForm.priority.requiredMessage": "Priority is required",
  "taskForm.allowCreateTimeEntry.requiredMessage":
    "Allow Time Entries Creation is required",
  "taskForm.createTimeEntry.allowed":
    "Time entry creation is allowed on this task. Click to disable it",
  "taskForm.createTimeEntry.notAllowed":
    "Time entry creation is not allowed on this Task. Click to enable it",
  "taskForm.billable": "Billable Task",
  "taskForm.nonBillable": "Non Billable Task",
  "taskForm.priority.label": "Priority",
  "taskForm.allowCreateTimeEntry.label": "Allow Time Entries Creation",
  "taskForm.billable.label": "Billable",
  "taskForm.billable.requiredMessage": "Billable is required",
  "taskForm.taskStatus.label": "Task Status",
  "taskForm.externalReference.label": "External Reference",
  "taskForm.newTask": "Enter Task Name",
  "taskForm.selectProject": "Select Project",
  "taskForm.selectPhase": "Select Phase",
  "taskForm.selectParentTask": "Select Parent Task",
  "taskForm.newSubTask": "Enter Subtask Name",
  "taskForm.title.requiredMessage": "Title is required",
  "taskForm.description.label": "Description",
  "taskForm.description.requiredMessage": "Description is required",
  "taskForm.employee.label": "Assign To (Resource)",
  "taskForm.team.label": "Assign To (Team)",
  "taskForm.task.label": "Parent Task",
  "taskForm.status.label": "Status",
  "taskForm.status.requiredMessage": "Status is required",
  "taskForm.ticket.label": "Phase",
  "taskForm.task.requiredMessage": "Task is required",
  "taskForm.ticket.requiredMessage": "Phase is required",
  "taskForm.startDate.label": "Start Date",
  "taskForm.startDate.requiredMessage": "Estimated Start Date is required",
  "taskForm.endDate.label": "End Date",
  "taskForm.endDate.requiredMessage": "Estimated End Date is required",
  "taskForm.taskInformation": "Task",
  "taskForm.subtaskInformation": "Subtask",
  "taskForm.save": "Save",
  "taskForm.save.close": "Save & Close",
  "taskForm.createdSuccessfully": "Saved Successfully",
  "taskForm.updatedSuccessfully": "Saved Successfully",
  "taskForm.movedSuccessfully": "Moved Successfully",
  "task.list.delete.success": "Deleted Successfully",
  "taskForm.planning": "Planning",
  "taskForm.EstimatedTime.label": "Estimated",
  "taskForm.EstimatedTime.requiredMessage": "",
  "taskForm.EstimatedEndDate.requiredMessage": "",
  "taskForm.EstimatedEndDate.label": "Estimated",
  "taskForm.EstimatedStartDate.requiredMessage": "",
  "taskForm.EstimatedStartDate.label": "Estimated",
  "taskForm.ActualStartDate.requiredMessage": "",
  "taskForm.ActualStartDate.label": "Actual",
  "taskForm.ActualEndDate.requiredMessage": "",
  "taskForm.ActualEndDate.label": "Actual",
  "taskForm.ActualNonBillableTime.requiredMessage": "",
  "taskForm.ActualNonBillableTime.label": "Actual Non Billable",
  "taskForm.ActualBillableTime.requiredMessage": "",
  "taskForm.ActualBillableTime.label": "Actual Billable",
  "taskForm.ActualTime.requiredMessage": "",
  "taskForm.ActualTime.label": "Actual",
  "taskForm.time.label": "Time (h)",
  "taskForm.project.label": "Project",
  "taskForm.project.requiredMessage": "Project is required",
  "taskForm.area.label": "Area",
  "taskForm.Priority": "Priority",
  "taskForm.attachments": "Attachments",
  "taskForm.add.attachment": "Add attachment",
  "taskForm.no.attachments": "No attachments have been added",
  "taskForm.description": "Description",
  "taskForm.comments": "Comments",
  "taskForm.comment.placeholder": "Add a comment. Use @ to mention a person.",
  "taskForm.treeView.tasks": "Tasks ",
  "taskForm.treeView.actualTime": "Actual: ",
  "taskForm.treeView.actualTime.hour": "h",
  "task.tootlip.status": "Click to change status",
  "task.tooltip.externalReference":
    "Specifies the Job Task No. in Business Central (Tasks where Job Task Type is posting). ",
  "task.tooltip.expandTree": "Open Tree View",
  "task.tooltip.maximizeTree": "Maximize Tree View",
  "task.tooltip.minimizeTree": "Minimize Tree View",
  "task.tooltip.closeTree": "Close Tree View",
  "task.tooltip.actualTime": "Actual Time",
  "taskForm.details": "Details",
  "taskForm.timeEntries": "Time Entries",
  "taskForm.delete.task": "Delete",
  "taskForm.move.task": "Move",
  "taskForm.subtask.addNew": "Add Subtask",
  "taskForm.user.delete.record.confirmation":
    "Are you sure you want to delete this record?",
  "taskForm.user.refresh.record.confirmation":
    "Are you sure you want to refresh?",
  "taskForm.btn.refresh.label": "Refresh",
  "taskForm.updated.record.warning":
    "This task has been changed by another user since you started editing it. You will need to refresh the data first in order to proceed",
  "taskModal.project.label": "Target Project",
  "taskModal.project.requiredMessage": "Target Project is required",
  "taskModal.phase.label": "Target Phase",
  "taskModal.phase.requiredMessage": "Target Project is required",
  "taskModal.task.label": "Target Parent Task",
  "taskModal.label.moveTo": "Move To",
  "taskModal.includeSubtasks": "Include Subtasks",
  "attachment.deletedSuccessfully": "Deleted Successfully",
  "attachment.uploadedSuccessfully": "Uploaded Successfully",
  "comment.deletedSuccessfully": "Deleted Successfully",
  "comment.editedSuccessfully": "Edited Successfully",
  "comment.addedSuccessfully": "Added Successfully",

  ////Time Entry Side Info
  "Duration.totalHours": "Total Displayed",
  "Duration.weeksTotal": "My Week's Hours",
  "Duration.todaysTotal": "My Hours Today",

  //Data Card
  "dataCrd.noDataToDisplay": "No records to display",

  //utils
  "timeDiff.HoursAndMinutes":
    "{{hours}} hour{{hPlural}} and {{minutes}} minute{{mPlural}}",
  "timeDiff.HoursOnly": "{{hours}} hour{{hPlural}}",
  "timeDiff.MinutesOnly": "{{minutes}} minute{{mPlural}}",
  "timeDiff.fullDay": "Full Day",

  //Dashboard
  "dashboardTabs.dashboard": "Dashboard",
  "dashboardTabs.dailyDayLogs": "Attendance",
  "dashboardTabs.projectsAndTasks": "Projects",

  //Time Offs List
  "ptos.list.delete.success": "Deleted Successfully",
  "ptos.list.search.label": "Search By Description or Assign Date",
  "ptos.list.new.label": "New Timeoff",
  "ptos.list.delete.label": "Delete",
  "ptos.list.column.Description": "Description",
  "ptos.list.column.Employee": "Employee",
  "ptos.list.column.Date": "Date",
  "ptos.list.column.pTO": "Time Off Description",
  "ptos.list.column.NumberOfDays": "Number of Units",
  "ptos.list.column.DateUnit": "Date Unit",
  "ptos.list.column.Paid": "Paid",
  "ptos.list.column.PTOAssignDates": "Assign Dates",
  "ptos.list.column.PTOStatus": "Status",
  "ptos.list.column.EmploymentType": "Employment Type",

  //Time Offs Tabs
  "ptoTabs.form": "Info",

  //Time Off Form
  "ptoForm.description.label": "Description",
  "ptoForm.description.requiredMessage": "Description is required",
  "ptoForm.paid.label": "Paid",
  "ptoForm.accrued.label": "Monthly Accrual",
  "ptoForm.accumulated.label": "Cumulative Remaining Units",
  "ptoForm.numberOfDays.label": "Number of Units",
  "ptoForm.numberOfDays.requiredMessage": "Number of Units is required",
  "ptoForm.employmentType.label": "Employment Type",
  "ptoForm.employmentType.requiredMessage": "Employment Type is required",
  "ptoForm.assignDate.label": "Assign Date",
  "ptoForm.assignDate.requiredMessage": "Assign Date is required",
  "ptoForm.status.label": "Status",
  "ptoForm.status.requiredMessage": "Status is required",
  "ptoForm.dateUnit.label": "Date Unit",
  "ptoForm.dateUnit.requiredMessage": "Date Unit is required",
  "ptoForm.save": "Save",
  "ptoForm.PTOInformation": "Time Off Information",
  "ptoForm.updatedSuccessfully": "Saved Successfully",
  "ptoForm.createdSuccessfully": "Saved Successfully",
  "ptoForm.button.deactivate": "Deactivate",
  "ptoForm.button.activate": "Activate",
  "ptoForm.statusChange.success": "Saved Successfully",
  "ptoForm.seniorities.label": "Seniorities",
  "ptoForm.From.label": "From Year(including)",
  "ptoForm.From.requiredMessage": "From Year is required",
  "ptoForm.To.label": "To Year(excluding)",
  "ptoForm.To.requiredMessage": "To Year is required",
  "ptoForm.NewNumberOfDays.label": "Number of Units",
  "ptoForm.NewNumberOfDays.requiredMessage": "Number of Units is required",
  "ptoForm.DateUnit.label": "Date Unit",
  "ptoForm.DateUnit.requiredMessage": "Date Unit is required",
  "ptoForm.Seniorities": "Seniority-based",
  "ptoForm.seniority.deleteDay": "Delete Seniority",
  "ptoForm.seniority.addSeniority": "Add Seniority",

  "ptos.list.filters.description": "Description",
  "ptos.list.column.NumberOfUnits": "Number of Units",
  "ptos.list.filters.maxNumberDays": "Max Number of Days",
  "ptos.list.filters.minNumberDays": "Min Number of Days",
  "ptos.list.filters.paid": "Paid",
  "ptos.list.filters.assignDates": "Assign Dates",
  "ptos.list.filters.statuses": "Status",

  "ptoTable.AddPTO": "Add time off",

  /**Skills list */
  "employeeSkills.list.search.label": "Search by Description",
  "skillTable.AddSkill": "Add skills",
  "employeeForm.skillList.addSkill": "Add Skill",

  /**Employee Pto Table */
  "employeeptos.list.column.Description": "Description",
  "employeeptos.list.column.NumberOfDays": "Number of Units",
  "employeeptos.list.column.daysUsed": "Units Used",
  "employeeptos.list.column.daysRemaining": "Units Remaining",
  "employeeptos.list.column.PTOStatus": "Status",
  "employeeptos.list.column.EmploymentType": "Employment Type",
  "employeePTOs.list.search.label": "Search by Description",
  "employeePtoForm.addPTO": "Assign Timeoff",
  "employeeSkillForm.addSkill": "Assign Skill",
  "assignPtoToEmployee.success": "Saved Successfully",
  "employeePTOs.list.filters.description": "Description",
  "employeePTOs.list.filters.minDaysUsed": "Minimum Days Used",
  "employeePTOs.list.filters.maxDaysUsed": "Maximum Days Used",
  "employeePTOs.list.filters.minNumberOfDays": "Minimum Number of Days",
  "employeePTOs.list.filters.maxNumberOfDays": "Maximum Number of Days",
  "employeePTOs.list.filters.minRemainingDays": "Minimum Remaining Days",
  "employeePTOs.list.filters.maxRemainingDays": "Maximum Remaining Days",
  "employeePtoForm.activateEmployeePTO": "Activate",
  "employeePtoForm.deactivateEmployeePTO": "Deactivate",
  "employeePtoForm.statusChangedSuccessfully": "Saved Successfully",

  "employeeTeam.list.column.description": "Description",
  "employeeTeam.list.column.createdBy": "Assigned By",
  "employeeTeam.list.column.creationDate": "Assign Date",
  "teamTable.AddTeam": "Add Team",
  "employeeForm.addButton": "Add",
  "employeeTeamForm.addTeam": "Add Team(s)",
  "teams.list.delete.success": "Deleted Successfully",
  "assignTeamsToEmployee.success": "Team(s) Assigned Successfully",
  "employeeTeams.list.search.label": "Search",
  "employeeList.addEmployee": "Add Resource(s)",
  "assignEmployeesToTeam.success": "Resource(s) Assigned Successfully",
  "employees.delete.success": "Deleted Successfully",
  "employee.list.filters.roles": "Roles",
  //tenant requests
  "request.list.column.Employee": "Resource",
  "request.list.column.reason": "Reason",
  "request.list.column.date": "Date(s)",
  "request.list.column.Days": "Days",
  "request.list.column.Reviewer": "Reviewed By",
  "request.list.column.RequestTime": "Absence Time",
  "request.list.column.RequestDate": "Created At",
  "request.list.column.RequestType": "Request Type",
  "request.list.column.RequestApprover": "Request Approver",
  "request.list.column.RequestStatus": "Request Status",
  "request.list.column.clockIn": "Clock In",
  "request.list.column.clockOut": "Clock Out",
  "request.list.column.DateFrom": "Date From",
  "request.list.column.DateTo": "Date To",
  "request.list.column.TimeFrom": "Time From",
  "request.list.column.TimeTo": "Time To",
  "request.list.column.Receiver": "Receiver",
  "request.status.rejected": "Rejected",
  "request.status.cancelled": "Cancelled",
  "request.status.approved": "Approved",
  "request.status.rejectedBy": "Rejected By",
  "request.status.cancelledBy": "Cancelled By",
  "request.status.approvedBy": "Approved By",
  "request.list.column.Date": "Request Date/Time",
  "request.status.sentForApproval": "Sent For Approval",
  "RequestDateTimeHandler.fullDay": "Full Day",
  "request.list.search.label": "Search by Resource",
  "request.offline.list.search.label": "Search by Resource",
  "requests.list.filters.employees": "Resources",
  "requests.list.filters.date": "Date",
  "requests.list.filters.minRequestDate": "Minimum Request Date",
  "requests.list.filters.maxRequestDate": "Maximum Request Date",
  "requests.list.filters.fromDate": "Date From",
  "requests.list.filters.toDate": "Date To",
  "requests.list.filters.requestType": "Request Type",
  "requests.list.filters.ptoType": "Timeoff Type",
  "requests.list.filters.requestStatus": "Request Status",
  "requests.list.filters.requestTime": "Request Time",
  "requests.list.filters.tenants": "Tenants",
  "requests.list.label.attendance": "Add Missed Attendance",
  "requests.label.add.attendance": "Add Missed Attendance Request",
  "requests.label.edit.attendance": "Edit Attendance Request",
  "requests.list.label.leave": "Add Leave Request",
  "non.working.day":
    "Requests for time off cannot be submitted for non-working days.",

  /**Request Tabs */
  "requestTabs.form": "Info",
  "requestsTabs.sent": "My Sent Requests",
  "requestsTabs.received": "Requests Received from my Team",

  /**Request Form */
  "requestForm.save": "Save",
  "requestForm.submit": "Submit",
  "requestForm.Edit": "Edit",
  "requestForm.employee.label": "Resource",
  "requestForm.requestType.label": "Request Type",
  "requestForm.description.label": "Request Reason",
  "requestForm.date.label": "Date",
  "requestForm.dateFrom.label": "Date From",
  "requestForm.dateTo.label": "Date To",
  "requestForm.date.label": "Date",
  "requestForm.timeFrom.label": "Time From",
  "requestForm.timeTo.label": "Time To",
  "requestForm.note.label": "Note",
  "requestForm.attachment.label": "Attachment",
  "requestForm.requestInformation": "Request Information",
  "requestForm.pto.from": "Employee",
  "requestForm.pto.to": "Receiver",
  "requestForm.rejectionReason.label": "Rejection Reason",
  "requestForm.pto.description": "Description",
  "requestForm.pto.attachment": "Attachment",
  "requestForm.uploadFile": "Upload File",
  "requestForm.pto.type": "Request Type",
  "requestForm.pto.requestDate": "Request Date",
  "requestForm.pto.requestType": "Request Type",
  "requestForm.pto.dateFrom": "Day From",
  "requestForm.pto.dateTo": "Day To",
  "requestForm.pto.time": "Time",
  "requestForm.pto.date": "Date",
  "requestForm.pto.note": "Note",
  "requestForm.pto.reason": "Rejection Reason",
  "requestForm.pto.reject.date": "Rejection Date",
  "requestForm.pto.approvedBy": "Approved By",
  "requestForm.pto.approval.date": "Approval Date",
  "requestForm.pto.cancelledBy": "Cancelled By",
  "requestForm.pto.cancellation.date": "Cancellation Date",
  "requestForm.numberOfDays": "Entitlement",
  "requestForm.daysUsed": "Used",
  "requestForm.remaining": "Remaining",
  "requestForm.requestedDays": "Requested",
  "requestForm.Approve": "Approve",
  "requestForm.Reject": "Reject",
  "requestForm.Cancel": "Cancel",
  "requestForm.clockin.label": "Clock In",
  "requestForm.clockout.label": "Clock Out",
  "requestForm.workType.label": "Work Type",
  "requestForm.workType.requiredMessage": "Work Type is required",
  "requestForm.task.label": "Task",
  "requestForm.location.label": "Location",
  "requestForm.location.requiredMessage": "Location is required",
  "requestForm.view.attachment": "View Attachment",
  "requestForm.upload.attachment": "Upload Attachment",
  "requestForm.no.attachment": "No Attachment",
  "requestForm.save.successfully": "Saved Successfully",
  "requestForm.submit.successfully": "Submitted Successfully",
  "requestForm.requestType.requiredMsg": "Request Type is required",
  "requestForm.dateFrom.requiredMsg": "Date From is required",
  "requestForm.reason.requiredMsg": "Rejection Reason is required",
  "requestForm.description.requiredMessage": "Request Reason is required",
  "requestForm.receiver.label": "Receiver",
  "requestForm.status": "Status",
  "requestForm.overusage": "Overusage",
  "requestForm.ApprovedBy": "Approved By",
  "requestForm.ApprovalDate": "Approval Date",
  "requestForm.RejectedBy": "Rejected By",
  "requestForm.RejectionDate": "Rejection Date",
  "requestForm.CanceledBy": "Canceled By",
  "requestForm.CancellationDate": "Cancellation Date",
  "numberOfDays.one.max":
    "The number of days of type 'Usage' should not exceed one day",
  "numberOfDays.one.min": "Number of Days cannot be zero",
  "pto.dialog.confirm.approve":
    "Are you sure you want to approve this request?",
  "pto.dialog.confirm.reject": "Are you sure you want to reject this request?",
  "pto.dialog.confirm.cancel": "Are you sure you want to cancel this request?",
  "pto.dialog.reason": "Please enter the rejection reason",
  "pto.dialog.reason.label": "Reason",
  "requestForm.attachmenet": "Attachment",
  "requestApproval.warningMsg":
    "If you accept this request, this time off will have an overusage. Are you sure you want to approve this overusage? ",
  "requestForm.submitted.successfully":
    "TO Request has been submitted successfully",

  //Days Used
  "addDayUsed.pTO.label": "Time off Type",
  "addDayUsed.pTO.requiredMsg": "Time off Type is required",
  "addDayUsed.description.label": "Description",
  "addDayUsed.description.requiredMsg": "Description is required",
  "addDayUsed.date.label": "Date",
  "addDayUsed.date.requiredMsg": "Date is required",
  "addDayUsed.usage.label": "Usage",
  "addDayUsed.entitlement.label": "Entitlement",
  "addDayUsed.entitlement.requiredMessage": "Entitlement is required",
  "addDayUsed.numberOfDays.label": "Number of Units",
  "addDayUsed.numberOfDays.requiredMsg": "Number of Units is required",
  "addDayUsed.numberOfDays.typeError":
    "Number of Units can have positive or negative value",
  "addDayUsed.numberOfHours.label": "Number of Hours",
  "addDayUsed.numberOfHours.requiredMsg": "Number of Hours is required",
  "addDayUsed.numberOfHours.typeError":
    "Number of Hours can have positive or negative value",
  "addDayUsed.employee.label": "Employee",
  "addDayUsed.employee.requiredMsg": "Employee is required",
  "addDayUsed.cancellation.label": "Cancellation",
  "dayUsedForm.addTitle": "Add Usage/Entitlement",
  "dayUsed.type.fullDay": "Full Day",
  "dayUsed.type.halfDay": "Half Day",
  "dayUsed.type.multiDay": "Multiple Days",
  "dayUsed.type": "Type",
  "addDayUsed.dayFrom.label": "Day From",
  "employeeDaysUsed.addDayUsed": "Add Usage/Entitlement",
  "dayUsedForm.sucessfullyCreated": "Saved Successfully",
  "dayUsed.list.delete.success": "Deleted Successfully",
  "dayUsed.list.search.label": "Search",
  "dayUsed.list.delete.label": "Delete",

  //Profile
  "profileForm.myProfile": "My Profile",
  "profileForm.changePassword": "Change Password",
  "changePassword.Pleasefollowthisguideforastrongpassword":
    "Follow this guide for a strong password",
  "changePassword.first": "One special character",
  "changePassword.second": "Min 6 characters ",
  "changePassword.third": "One number (2 are recommended) ",
  "changePassword.fourth": "Change it often ",

  "profileForm.profileInformation": "Information",
  "profileForm.firstName.label": "FirstName",
  "profileForm.firstName.requiredMessage": "FirstName is required",
  "profileForm.lastName.label": "LastName",
  "profileForm.lastName.requiredMessage": "LastName is required",
  "profileForm.email.label": "Email",
  "profileForm.email.requiredMessage": "Email is required",
  "profileForm.phoneNumber.label": "Phone Number",
  "profileForm.phoneNumber.requiredMessage": "Phone Number is required",
  "profileForm.updatedSuccessfully": "Saved Successfully",
  "profileForm.profilPhoto.label": "Change Picture",
  "profileForm.profilPhoto.name": "Resource's Name",
  "profileForm.newPassword.label": "New Password",
  "profileForm.confirmPassword.label": "Confirm Password",
  "profileForm.newPassword.requiredMessage": "Password is required",
  "profileForm.confirmPassword.requiredMessage": "Confirm Password is required",
  "newPassword.requiredMessage":
    "Password is too short - should be 8 chars minimum.",
  "newPassword.matches":
    "Must Contain 8 Characters, one Uppercase, one Lowercase, one Number and one Special Case Character",
  "confirmPassword.requiredMessage":
    "Password is too short - should be 8 chars minimum.",
  "confirmPassword.matches":
    "Must Contain 8 Characters, one Uppercase, one Lowercase, one Number and one Special Case Character",
  "password.changedSuccessfully": "Password changed successfully",

  "profileTabs.timeOffs": "My Time Off",
  "profileTabs.skills": "My Skills",
  "profileTabs.teams": "My Teams",

  //Task Status
  "taskStatusForm.description.label": "Description",
  "taskStatusForm.description.requiredMessage": "Description is required",
  "taskStatusForm.state.label": "State",
  "taskStatusForm.state.requiredMessage": "State is required",
  "taskStatusForm.createdSuccessfully": "Saved Successfully",
  "taskStatusForm.updatedSuccessfully": "Saved Successfully",
  "taskStatus.list.delete.success": "Deleted Successfully",
  "taskStatus.list.search.label": "Search by Description",
  "taskStatus.list.new.label": "New Status",
  "taskStatus.list.delete.label": "Delete",
  "taskStatus.list.filters.description": "Description",
  "taskStatus.list.filters.state": "State",
  "taskStatus.list.column.State": "State*",
  "taskStatus.list.column.order": "Order*",
  "taskStatus.list.column.Description": "Description*",
  "taskStatus.list.column.Color": "Color*",
  "taskStatusForm.taskStatusInformation": "Task Status Information",

  "projectDashboard.employee": "Resources",
  "projectDashboard.projects": "Projects",
  "projectDashboard.tickets": "Phases",
  "projectDashboard.tasks": "Tasks",
  "projectDashboard.totalTime": "Total Time",
  "projectDashboard.label.DateFrom": "Date From",
  "projectDashboard.label.DateTo": "Date To",
  "projectDashboard.label.Employee": "Resources",
  "projectDashboard.label.Projects": "Projects",
  "projectDashboard.label.Tickets": "Phases",
  "projectDashboard.Search": "Search",
  "projectDetails.noData": "No records to display",
  "dateFilter.today": "Day",
  "dateFilter.last7Days": "Week",
  "dateFilter.last30Days": "Month",
  "dateFilter.custom": "Custom",
  "dateFilter.between2Dates": "Between 2 Dates",
  "dateFilter.label": "Time Period",

  //TicketStatus
  "ticketStatusForm.description.label": "Description",
  "ticketStatusForm.description.requiredMessage": "Description is required",
  "ticketStatusForm.state.label": "State",
  "ticketStatusForm.state.requiredMessage": "State is required",
  "ticketStatusForm.createdSuccessfully": "Saved Successfully",
  "ticketStatusForm.updatedSuccessfully": "Saved Successfully",
  "ticketStatus.list.delete.success": "Deleted Successfully",
  "ticketStatus.list.search.label": "Search by Description",
  "ticketStatus.list.new.label": "New Status",
  "ticketStatus.list.delete.label": "Delete",
  "ticketStatus.list.filters.description": "Desription",
  "ticketStatus.list.filters.state": "State",
  "ticketStatus.list.column.State": "State*",
  "ticketStatus.list.column.Description": "Desription*",
  "ticketStatusForm.ticketStatusInformation": "Phase Status Information",

  //EmployeeReference
  "employeeReference.company.label": "Company",
  "employeeReference.company.requiredMessage": "Company is required",
  "employeeReference.overtimeReference.label": "Overtime Reference",
  "employeeReference.overtimeReference.requiredMessage":
    "Overtime Reference is required",
  "employeeReference.regularPayReference.label": "Regular Reference",
  "employeeReference.regularPayReference.requiredMessage":
    "Regular Reference is required",
  "employeeReference.createdSuccessfully": "Saved Successfully",
  "employeeReference.updatedSuccessfully": "Saved Successfully",
  "employeeReference.list.delete.success": "Deleted Successfully",
  "employeeReference.list.new.label": "New Reference",
  "employeeReference.list.delete.label": "Delete",
  "employeeReference.list.filters.description": "Desription",
  "employeeReference.list.filters.state": "State",
  "employeeReference.list.column.company": "Company",
  "employeeReference.list.column.overtime": "Overtime Reference",
  "employeeReference.list.column.regular": "Regular Reference",
  "employeeReference.list.column.createdBy": "Created By",
  "employeeReference.list.column.creationDate": "Creation Date",
  "employeeReference.list.column.modifiedBy": "Modified By",
  "employeeReference.list.column.modificationDate": "Modification Date",
  "employeeReference.employeeReferenceInformation": "Reference Information",

  "dailyDayLog.UserInfo": "User Information",

  "dashboard.clockIn": "Clock In",
  "dashboard.clockOut": "Clock Out",
  "clockin.location": "Specify where you are working from",
  "clockin.no.location": "No locations available",
  "no.projects.sites": "No Projects/Sites available",
  "clockin.location.office": "Office",
  "clockin.location.home": "Home",
  "clockin.location.site": "Site",
  "clockin.location.project": "Projects",

  //Skills
  "skill.list.search.label": "Search by Description",
  "skill.list.delete.label": "Delete",
  "skillForm.createdSuccessfully": "Saved Successfully",
  "skillForm.updatedSuccessfully": "Saved Successfully",
  "skill.list.delete.success": "Deleted Successfully",
  "skill.list.column.Name": "Description*",
  "skills.list.delete.success": "Deleted Successfully",
  "assignSkillToEmployee.success": "Skills Added Successfully",

  "dashboard.upcomingHolidays": "Holidays",
  "dashboard.timeOff": "Time Off",
  "dashboard.news": "News",
  "dashboard.myTasks": "My Tasks",
  "dashboard.addTimeEntry": "Add TimeEntry",
  "dashboard.viewAll": "Show More",
  "dashboard.runningTasks": "Running Tasks",
  "dashboard.clockinout": "Clock In/Out",
  "dashboard.upcoming": "Upcoming",
  "dashboard.attendance": "Attendance",
  "dashboard.requests": "Pending Requests",
  "dashboard.runningAttendances": "Show Running",
  "dashboard.pendingRequests": "Pending Approval Requests",
  "dashboard.missedClockIns": "Missed Clock In",
  "dashboard.whosOffToday": "are off",
  "dashboard.whosLate": "were late",
  "dashboard.whoLeftEarly": "left early",
  "dashboard.todaysClockins": "Clocked In",
  "dashboard.upcomingHolidays.title": "Upcoming Holidays",
  "dashboard.timeOff.title": "Upcoming Time Off",
  "dashboard.news.title": "News",
  "dashboard.pendingRequests.title": "Pending Approval Requests",
  "dashboard.missedClockIns.title": "Missed Clock In",
  "dashboard.whosOffToday.title": "Who's Off ?",
  "dashboard.whosLate.title": "Who's Late ?",
  "dashboard.whoLeftEarly.title": "Who Left Early?",
  "dashboard.todaysClockins.title": " Clock Ins",
  "dashboard.task.addTimeEntry": "Add Time Entry",
  "dashboard.task.add": "Add Task",
  "dashboard.subtask.add": "Add Subtask",
  "dashboard.task.addNew": "Add New Task",
  "dashboard.subtask.addNew": "Add New Subtask",
  "dashboard.card.employee": "Employee",
  "dashboard.card.date": "Date",
  "dashboard.card.time": "Time",
  "dashboard.card.description": "Description",
  "dashboard.card.reason": "Reason",
  "dashboard.card.actualClockIn": "Actual Clock In",
  "dashboard.card.actualClockOut": "Actual Clock In",
  "dashboard.card.timeDifference": "Time Difference",
  "dashboard.card.dailyTotalHours": "Daily Total Hours",
  "dashboard.card.workShiftClockIn": "Work Shift Clock In",
  "dashboard.card.workShiftClockOut": "Work Shift Clock Out",

  //Employees Availability
  "employeeAvailability.label.skill": "Skills",
  "employeeAvailability.label.DateFrom": "Date From",
  "employeeAvailability.label.DateTo": "Date to",
  "employeeAvailability.Search": "Search",
  "employeeAvailability.task.startDate": "Estimated Start Date",
  "employeeAvailability.task.endDate": "Estimated End Date",
  "employeeAvailability.task": "Task",
  "employeeAvailability.ticket": "Phase",
  "employeeAvailability.project": "Project",
  "employeeAvailability.viewDetails": "View Details",

  //Timesheets //TimeEntry
  "timesheetsTabs.pending": "Pending",
  "timesheetsTabs.approved": "Approved",
  "timesheet.list.column.employee": "Resource",
  "timesheet.list.column.date": "Date",
  "timesheets.tooltip.timesheet":
    "Timesheets need to be approved in order to be synced.",
  "timesheet.list.column.duration": "Duration (h)",
  "tenantForm.timesheetBy.label": "Timesheet",
  "tenantForm.allowTimesheetReopening.label": "Allow Timesheet Reopening",
  "tenantForm.allowTimesheetReopening.requiredMessage":
    "Allow Timesheet Reopening is required",
  "tenantForm.hoursOfWork.label": "Monthly working hours",
  "TenantForm.hoursOfWork.requiredMessage":
    "Number of working hours is required",
  "TenantForm.timesheetBy.requiredMessage": "Timesheet by is required",
  "tenantForm.job.label": "Non Billable Job",
  "TenantForm.job.requiredMessage": "Job is required",
  "tenantForm.mandatoryTimeEntryDescription.label":
    "Time Entry Description Mandatory",
  "TenantForm.mandatoryTimeEntryDescription.requiredMessage":
    "Time Entry Description Mandatory is required",
  "tenantForm.company.label": "Non Billable Job Company",
  "timesheet.btn.remove.label": "Delete Timesheet",
  "TenantForm.company.requiredMessage": "Company is required",
  "tenantForm.allowAttendanceSheetReopening.label":
    "Allow AttendanceSheet Reopening",
  "TenantForm.allowAttendanceSheetReopening.requiredMessage":
    "Allow AttendanceSheet Reopening is required",
  "tenantForm.monthlyAttendanceSheet.label": "AttendanceSheet",
  "TenantForm.monthlyAttendanceSheet.requiredMessage":
    "AttendanceSheet field is required",
  "tenantForm.postUnderAndOverUsageToNonBillableJob.label":
    "Post Under And Over Usage To Non Billable Job",
  "TenantForm.postUnderAndOverUsageToNonBillableJob.requiredMessage":
    "Post Under And Over Usage To Non Billable Job field is required",
  "timesheet.list.column.task": "Task",
  "timesheet.list.column.taskID": "Task ID",
  "timesheet.list.column.project": "Project",
  "timesheet.list.column.synced": "Synced",
  "timesheet.list.delete.success": "Timesheet is deleted successfully",
  "timesheet.list.column.actions": "Actions",
  "timesheet.list.column.description": "Time Entry Description",
  "timesheet.list.column.automaticallyClosed": "Closed by System",
  "monthly.timesheet.list.column.status": "Status",
  "monthly.timesheet.list.column.synced": "Synced",
  "monthly.timesheet.list.column.startDate": "Start Date",
  "monthly.timesheet.list.column.endDate": "End Date",
  "monthly.timesheet.list.column.description": "Description",
  "monthly.timesheet.list.column.createdAt": "Approved At",
  "monthly.timesheet.list.column.createdBy": "Approved By",
  "timesheet.autoComplete.label": "Timesheet",
  "timesheet.list.filters.projects": "Project",
  "timesheet.list.filters.employees": "Resource",
  "automaticcalyClosed.timesheet":
    "Time entry clocked out at midnight by system. You need to review it by editing and adjusting the data, so you can approve the Attendance sheet",
  "automaticcalyClosed.attendance":
    "Time attendance clocked out at midnight by system. You need to review it by editing and adjusting the data, so you can approve the Attendance sheet",
  "timesheet.total.hours": "Total Nbr of Hours: ",
  "timesheet.total.duration": "Total Duration: ",
  "timesheet.list.filters.tasks": "Task",
  "timesheet.list.filters.date": "Date",
  "timesheet.list.filters.dateFrom": "Date From",
  "timesheet.list.filters.dateTo": "Date To",
  "timesheet.list.filters.automaticallyClosed": "Closed by",
  "timeEntryForm.updatedSuccessfully": "Saved Successfully",
  "timeEntryForm.createdSuccessfully": "Saved Successfully",
  "timeEntryForm.save": "Save",
  "timeEntryForm.save.close": "Save & Close",
  "timeEntryForm.employee.label": "Resource",
  "timeEntryForm.employee.requiredMessage": "Resource is required",
  "timeEntryForm.task.label": "Task",
  "timeEntryForm.task.requiredMessage": "Task is required",
  "timeEntryForm.date.label": "Date",
  "timeEntryForm.date.requiredMessage": "Date is required",
  "timeEntryForm.description.label": "Description",
  "timeEntryForm.description.requiredMessage": "Description is required",
  "timeEntryForm.endTime.label": "End Time",
  "timeEntryForm.endTime.requiredMessage": "End Time is required",
  "timeEntryForm.duration.label": "Duration (h)",
  "timeEntryForm.hours.label": "Hours",
  "timeEntryForm.project.label": "Project",
  "timeEntryForm.project.requiredMessage": "Project is required",
  "timeEntryForm.billable.label": "Billable",
  "timeEntryForm.billable.requiredMessage": "Billable is required",
  "timeEntryForm.minutes.label": "Minutes",
  "timeEntryForm.minutes.requiredMessage": "Minutes field is required",
  "timeEntryForm.hours.requiredMessage": "Hours field is required",
  "timeEntryForm.duration.requiredMessage": "Duration is required",
  "timesheet.list.delete.label": "Delete",
  "timesheet.list.search.label": "Search",
  "timesheet.btn.add.label": "Add Time Entry",
  "timesheet.btn.create.label": "Create Timesheet",
  "timesheet.btn.remove.label": "Disregard from this timesheet",
  "timesheet.btn.approve.label": "Approve",
  "timesheet.btn.reopen.label": "Reopen & Approve",
  "timesheet.btn.refresh.label": "Refresh",
  "timesheet.disregarded.success": "Disregarded Successfully",
  "timesheet.approval.success": "Timesheet Approved Successfully",
  "timeEntry.info": "Edit Time Entry",
  "needs.review": "Needs Review",
  "timesheet.drawer.title": "Time Entry",
  "timesheet.drawer.title.edit": "Edit Time Entry",
  "timesheet.drawer.title.create": "Create Time Entry",
  "timesheet.tooltip.import": "Import Time",
  "timesheet.tooltip.project.search":
    "You must select one or more project for the search to function.",

  ///////////LOGS
  "breadcrumbs.logs": "Logs",
  "logs.logID?.list.column.logs": "Log ID",
  "logs.status?.list.column": "Status",
  "logs.tenant?.list.column": "Tenant",
  "logs.type?.list.column": "Type",
  "logs.LogDate?.list.column": "Log Date",
  "logs.EndDate?.list.column": "End Date",
  "logs.list.column.actions": "Actions",
  "logs.list.search.label": "Search by Status",
  "logs.list.filters.tenants": "Tenants",
  "logs.list.filters.types": "Types",
  "logs.list.filters.logDate": "Log Date From",
  "logs.list.filters.logEndDate": "Log Date To",
  "logDetails.filters.status": "Status",
  "logDetails.status?.list.column": "Status",
  "logDetails.description?.list.column": "Description",
  "logDetails.additionalInfo?.list.column": "Additional Info",
  "breadcrumbs.mylogsdetails": "Log Details",
  "breadcrumbs.mylogs": "Logs",
  "breadcrumbs.plan": "Plan",
  "breadcrumbs.tree": "Tree",
  "breadcrumbs.projectMembers": "Members",
  "logs.list.delete.label": "Delete",
  "logs.list.delete.success": "Deleted Successfully",
  "logDetails.timeStamp?.list.column": "Time Stamp",
  "copy.clipboard.success": "Copied to clipboard!",

  "task.stoppedSuccessfully": "Task Stopped Successfully",
  "task.startedSuccessfully": "Task Started Successfully",

  ////////////EMPLOYEES CARDS
  "activate.user.button": "Activate User",
  "delete.user.button": "Delete User",
  "deactivate.user.button": "Deactivate User",

  //Plan
  "plan.label.id": "ID",
  "plan.label.title": "Title",
  "plan.label.state": "Status",
  "plan.label.employee": "Assigned To",
  "plan.label.estimatedStartDate": "Est. Start Date",
  "plan.label.estimatedEndDate": "Est. End Date",
  "plan.label.estimatedTime": "Est. Time (h)",
  "plan.label.actualStartDate": "Act. Start Date",
  "plan.label.actualEndDate": "Act. End Date",
  "plan.label.actualTime": "Act. Time (h)",
  "plan.label.actions": "",
  "plan.new.plan.item": "New Plan Item",
  "email.user.button": "Resend Activation Email",
  "tooltip.performance": "Late",
  "task": "Task",
  "phase": "Phase",
  "subtask": "Subtask",

  //Time Entries
  "timeEntry.list.column.Description": "Description",
  "timeEntry.list.column.Employee": "Resource",
  "timeEntry.list.column.Date": "Date",
  "timeEntry.list.column.StartTime": "StartTime",
  "timeEntry.list.column.Duration": "Duration (h)",
  "timeEntry.list.column.Open": "Open",
  "timeEntry.list.column.Synced": "Synced",
  "timeEntry.list.column.Task": "Task",
  "timeEntry.list.column.Project": "Project",
  "timeEntry.list.column.Workshift": "Workshift",
  "timeEntry.list.column.billable": "Billable",
  "timeEntry.list.column.TimesheetApproved": "Timesheet Approved",
  "timeEntry.list.column.actions": "Actions",
  "timeEntry.list.column.modificationDate": "Modification Date",
  "timeEntry.list.column.modifiedBy": "Modified By",
  "timeEntry.list.column.createdBy": "Created By",
  "timeEntry.list.column.creationDate": "Creation Date",
  "timeEntry.list.search.label": "Search by Description",
  "timeEntry.list.delete.success": "Deleted Successfully",
  "timeEntry.filters.label.employee": "Resource",
  "timeEntry.filters.label.date": "Date",
  "timeEntry.filters.label.synced": "Synced",
  "timeEntry.filters.label.open": "Open",
  "timeEntry.filters.label.billable": "Billable",
  "timeEntry.filters.label.nonBillable": "Non Billable",
  "timeEntry.list.delete.label": "Delete",
  "openTimeEntries.list.search.label": "Search",

  ////TOOLTIP
  "tooltip.clean": "Clean Formatting",
  "tooltip.link": "Insert link using http or https. Select a text to add link",
  "tooltip.copy": "Copy to clipboard",
  "tooltip.copy.additionalInfo": "Copy Additional Info to clipboard",
  "tooltip.show.detail": "Show Details",
  "tooltip.add.new": "Add New",
  "tooltip.maximize": "Maximize Description",
  "tooltip.minimize": "Minimize Description",

  //Report
  "reportTabs.employeeTimesheet": "Resource Timesheet",
  "reportTabs.employeeAttendanceSheet": "Resource Attendance Sheet",
  "reportTabs.attendanceByEmployee": "Attendance By Resource",
  "reportTabs.timeByEmployee": "Time By Resource",
  "reportTabs.timeByProject": "Time By Project",
  "reportTabs.timeByContractor": "Time By Contractor",
  "reportTabs.timeByExternal": "Time By External Reference",
  "reportTabs.exportToExcel": "Export Report",
  "reportTabs.export": "Export",
  "reports.tooltip.employee.search":
    "You must select an employee for the search to function.",
  "reports.tooltip.project.search":
    "You must select a project for the search to function.",
  "reports.tooltip.task.search":
    "You must select one or more task for the search to function.",
  "reports.tooltip.contractor.search":
    "You must select a contractor for the search to function.",
  "reports.tooltip.excel.search":
    "You must select one or more task for the export to function.",
  "reports.label.contractor": "Contractor",
  "reports.label.reference": "Reference",
  "reports.label.projects": "Projects",
  "reports.label.project": "Project",
  "reports.label.employee": "Resources",
  "reports.label.tasks": "Tasks",
  "reports.label.exportOption": "Export Option",
  "reports.label.includeOption": "Include Option",
  "reports.onlyBillable.label": "Only Billable",
  "reports.onlyNonBillable.label": "Only Non Billable",
  "reports.BillableAndNonBillable.label": "Both Billable - Non Billable",
  "reports.ByTask.label": "By Task",
  "reports.ByTaskByTimeEntry.label": "By Task By Time Entry",
  "reports.ByTaskByTimeEntryByResource.label":
    "By Task By Time Entry By Resource",

  //Description Dialog
  "dialog.title": "Description",
  "dialog.file.name": "Please name the file",
  "dialog.task.content":
    "To stop this time entry, please enter your description here.",
  "dialog.day.log.content": "To clock out, please enter your description here.",
  "dialog.user.confirmation": "Changes you made may not be saved.",
  "dialog.user.sheet.approve.sync.time.entry.true.confirmation":
    "Once this Time Sheet is approved, it will be Synced to Business Central Jobs. You will not be able to reopen it. Kindly make sure all the data in it is checked before approving. Are you sure you want to approve this record?",
  "dialog.user.sheet.approve.sync.time.entry.false.allow.sheet.reopening.false.confirmation":
    "Once this Time Sheet is approved, you will not be able to reopen it. Kindly make sure all the data in it is checked before approving. Are you sure you want to approve this record?",
  "dialog.user.sheet.approve.confirmation":
    "Are you sure you want to approve this sheet?",
  "dialog.user.sheet.approve.allow.attendance.sheet.reopening.true.confirmation":
    "Once this Attendance Sheet is approved, you will not be able to reopen it. Kindly make sure all the data in it is checked before approving. Are you sure you want to approve this record?",
  "dialog.user.sheet.approve.allow.attendance.sheet.reopening.false.confirmation":
    "Are you sure you want to approve this sheet?",
  "dialog.user.delete.record.confirmation":
    "Are you sure you want to delete this record?",
  "dialog.user.delete.attachment.confirmation":
    "Are you sure you want to delete this attachment?",
  "dialog.user.delete.comment.confirmation":
    "Are you sure you want to delete this comment?",
  "dialog.user.delete.sheet.confirmation":
    "Are you sure you want to delete this sheet?",
  "dialog.user.reopen.sheet.confirmation":
    "Reopening the timesheet will remove existing data. Do you want to continue?",
  "dialog.user.delete.records.confirmation":
    "Are you sure you want to delete these records?",
  "dialog.official.holiday.content":
    "Copying to next year will overwrite existing data. Do you want to continue?",
  "dialog.add.days.balance": "Do you want to add previous days to the balance?",
  "dialog.description": "Description",
  "dialog.approve": "Approve",
  "dialog.continue": "Continue",
  "dialog.confirm": "Confirm",
  "dialog.warning": "Warning",
  "dialog.delete": "Delete",
  "dialog.reset": "Reset",
  "dialog.cancel": "Cancel",
  "dialog.title.first": "Leave Page?",
  "dialog.title.second": "Continue?",
  "dialog.leave": "Leave",
  "dialog.save": "Save",
  "dialog.yes": "Yes",
  "dialog.no": "No",
  "dialog.refresh": "Refresh",

  //Employee Timesheet
  "employeeTimesheet.task": "Task",
  "employeeTimesheet.project": "Project",
  "employeeTimesheet.total": "Total",
  "employeeTimesheet.no.timeLog": "There is no time logged.",
  "employeeTimesheet.invalid.date": "Invalid Date",

  //System Variables
  "sideNav.SystemVariables": "System Variables",
  "systVar.list.column.description": "Description*",
  "systVar.list.column.Value": "Value*",
  "breadcrumbs.systemVariables": "System Variables",

  ///Notifications
  "notif.list.clicked.success": "Notification Clicked",
  "notif.drawer.title": "Notifications",
  "notif.markAsRead": "Mark as read",
  "notif.delete": "Delete",
  //Time By Employee
  "timeByEmployee.assignTask": "Assign Task",
  "timeByEmployee.name": "Name",
  "timeByEmployee.total": "Total",
  "timeByEmployee.project": "Project",
  "timeByEmployee.task": "Task",
  "timeByEmployee.employee": "Employee",
  "timeByEmployee.billable": "Billable",

  //AREAS
  "sideNav.areas": "Task Areas",
  "areaForm.updatedSuccessfully": "Saved Successfully",
  "areaForm.createdSuccessfully": "Saved Successfully",
  "areas.list.delete.success": "Deleted Successfully",
  "area.list.search.label": "Search by Description",
  "area.list.delete.label": "Delete",
  "area.list.column.description": "Description*",
  "areas.list.column.description": "Description*",
  "systVar.list.column.additionalInfo": "Additional Info",

  //Attendance Sheets
  "attendanceSheetsTabs.pending": "Pending",
  "attendanceSheetsTabs.approved": "Approved",
  "attendanceSheet.autoComplete.label": "Attendance Sheet",
  "attendance.list.column.employeeName": "Resource",
  "attendance.list.column.description": "Description",
  "attendance.list.column.location": "Location",
  "attendance.list.column.workType": "WorkType",
  "attendance.list.column.date": "Date",
  "attendance.list.column.checkInTime": "Check In",
  "attendance.list.column.checkOutTime": "Check Out",
  "attendance.list.column.automaticallyClockedOut": "Clocked Out By System",
  "attendance.list.column.isAutomaticRecord": "AutoClocked in/out",
  "attendance.list.column.isEarly": "Clocked Out Early",
  "attendance.list.column.isLate": "Clocked in Late",
  "attendance.list.column.actions": "Actions",
  "attendance.list.column.createdBy": "Created By",
  "attendance.list.column.modifiedBy": "Modified By",
  "attendance.list.column.approvedBy": "Approved By",
  "attendance.list.column.creationDate": "Creation Date",
  "attendance.list.column.modificationDate": "Modification Date",
  "attendance.list.column.approvalDate": "Approval Date",
  "attendanceSheet.btn.refresh.label": "Refresh",
  "attendanceSheet.btn.approve.label": "Approve",
  "attendanceSheet.btn.add.label": "Add Attendance",
  "attendanceSheet.btn.remove.label": "Delete",
  "attendanceSheet.list.delete.label": "Delete",
  "attendanceSheet.list.filters.employees": "Resources",
  "attendanceSheet.list.filters.workType": "WorkType",
  "attendanceSheet.list.filters.locations": "Locations",
  "attendanceSheet.list.filters.date": "Date",
  "attendanceSheet.list.filters.fromWeb": "From Web",
  "attendanceSheet.list.filters.fromKiosk": "From Kiosk",
  "attendanceSheet.list.filters.isLate": "Clocked in Late",
  "attendanceSheet.list.filters.isEarly": "Clocked Out Early",
  "attendanceSheet.list.filters.isAutomaticRecord": "AutoClocked in/out",
  "employeeAttendanceSheet.employee": "Resource",
  "employeeAttendanceSheet.total": "Total",
  "employeeAttendanceSheet.no.attendance": "No logs.",
  "attendance.list.column.fromWeb": "From Web",
  "attendance.list.column.fromKiosk": "From Kiosk",
  "attendance.list.column.workTime": "Work Time (h)",
  "attendanceSheet.list.filters.automaticallyClockedOut":
    "Automatically ClockedOut",
  "attendanceSheet.approval.success": "Attendance Sheet Approved Successfully",
  "attendanceSheet.list.delete.success": "Deleted Successfully",
  "late": "Came Late",
  "early": "Left Early",
  "holiday": "Holiday",
  "time-off": "Time off",
  "non-working-day": "Non Working Day",
  "dayLog.info": "Edit Attendance",
  "attendanceSheet.drawer.title": "Attendance",
  "dayLogForm.employee.label": "Resource",
  "dayLogForm.employee.requiredMessage": "Resource is required",
  "dayLogForm.date.label": "Date",
  "dayLogForm.date.requiredMessage": "Date is required",
  "dayLogForm.description.label": "Description",
  "dayLogForm.description.requiredMessage": "Description is required",
  "dayLogForm.checkInTime.label": "Check In",
  "dayLogForm.checkInTime.requiredMessage": "Check In Time is required",
  "dayLogForm.checkOutTime.label": "Check Out",
  "dayLogForm.checkOutTime.requiredMessage": "Check Out Time is required",
  "dayLogForm.workType.label": "WorkType",
  "dayLogForm.workType.requiredMessage": "WorkType is required",
  "dayLogForm.location.label": "Location",
  "dayLogForm.location.requiredMessage": "Location is required",
  "dayLogForm.checkin.earlier.checkout":
    "Please ensure that your check-in time is earlier than your check-out time",

  //attendance by employee
  "attendanceByEmployee.name": "Name",
  "attendanceByEmployee.total": "Total",
  "attendanceByEmployee.checkIn": "Check In Time",
  "attendanceByEmployee.checkOut": "Check Out Time",
  "attendanceByEmployee.label.dateFrom": "Date From",
  "attendanceByEmployee.label.dateTo": "Date To",
  "attendanceByEmployee.label.employee": "Resource",
  "attendanceByEmployee.search": "Search",
  "attendanceByEmployee.no.attendance": "No logs.",
  "attendanceByEmployee.invalid.date": "Invalid Date",
  "workType.fromHome": "From Home",

  "teams.list.column.description": "Description*",
  "team.list.search.label": "Search",
  "team.list.assign.view": "Assign/View Resources",
  "team.list.delete.success": "Deleted Successfully",
  "teamForm.createdSuccessfully": "Saved Successfully",
  "teamForm.updatedSuccessfully": "Saved Successfully",

  ///////////////////////////////USE MUTATION
  "useMutation.descriptionRequired":
    "Please don't forget to stop the running task you are working on.",

  //////////////////////////GRID TABLE

  "gridTable.noFilters": "No Filters are applied",
  "gridTable.filtersApplied": "Clear Filters",

  //////USERS LIST
  "users.list.search.label": "Search by Name, Email",
  "users.list.unlock.success": "Unlocked User(s) Successfully",
  "users.list.column.firstName": "Name",
  "users.list.column.email": "Email",
  "users.list.column.isLocked": "Locked",
  "table.Unlock": "Unlock",

  //////////////////////// SYSTEM ERRORS

  "errors.list.column.errorCode": "Code",
  "errors.list.column.errorMessage": "Message",
  "errors.list.column.date": "Date",

  "time.error": "You cannot add a time entry with 0 hours and 0 minutes",

  //MyTimesheet
  "myTimesheet.label.task": "Task",
  "myTimesheet.label.submit": "Submit",
  "myTimesheet.label.submit.view": "Submit & View",
  "myTimesheet.label.date": "Time Entry Date",
  "myTimesheet.column.task": "Task",
  "myTimesheet.column.project": "Project",
  "myTimesheet.column.time": "Time (h)",
  "myTimesheet.column.description": "Time Entry Description",
  "myTimesheet.column.billable": "Billable",
  "myTimesheet.error.time": "Only digits are allowed",
  "myTimesheet.error.description": "Description is required",
  "timesheet.post.success": "Timsheet Posted Succesfully",
  "importTimeSheet": "Import Time Sheet",
  "exportTimeSheet": "Export Time Sheet",
  "excelBcFormat": "Import-Export BC Format",
  "importExportTimeEntries": "Import Time Entries",
  "importExportTimeEntriesByResource": "Import Time Entries By Resource",
  "exportExcelTemplate": "Export Template",
  "importExcelBcFormat": "Apply Data",
  "DataToExport": "Data To Export ",
  "DataToImport": "Data To Import ",
  "excel.tooltip.export":
    "Exports the Excel Template file to be used to import Time entries into Activ365",
  "excel.tooltip.apply":
    "Uploads the Excel file Template filled with Time Entries to be applied into Activ365",
  "excel.tooltip.import":
    "Applies Time Entries displayed in the table below into Activ365",
  "excel.dataAppliedSuccess.message": "Data Applied successfully",
  "excel.importWithouAppload.message":
    "You need to upload data first using the template file from the Export button",
  "excel.errorImportDataFromExcel.message":
    "Errors found while applying data. Check errors in table and fix the data in the excel sheet then import it again",
  "excel.oneOrMoreFieldAreEmpty.message": "one or more fields are empty",

  //Contractor
  "contractors.list.column.name": "Name*",
  "contractor.list.search.label": "Search",
  "contractor.list.delete.label": "Delete",
  "contractorForm.updatedSuccessfully": "Saved Successfully",
  "contractorForm.createdSuccessfully": "Saved Successfully",
  "contractor.list.delete.success": "Deleted Successfully",

  "show.chatBot": "Connect with us",
  "show.help": "Help",

  //Months Names
  "jan": "Jan",
  "feb": "Feb",
  "mar": "Mar",
  "apr": "Apr",
  "may": "May",
  "jun": "Jun",
  "jul": "Jul",
  "aug": "Aug",
  "sep": "Sep",
  "oct": "Oct",
  "nov": "Nov",
  "dec": "dec",

  "add.new": "Add New",

  /////////////////////SUGGESTIONS
  "Enable.chatBot": "Enable Chatbot",
  "Disable.chatBot": "Disable Chatbot",
  "suggestions.box": "Suggestions Box",
  "sugg.form.content":
    "We're always looking to improve. What's your suggestion?",
  "Suggestion.createdSuccessfully": "Saved Successfully",
  "sugg.submit": "Submit",
  "sugg.desc": "Type Here ...",
  "sideNav.suggestions": "Suggestions",
  "breadcrumbs.suggs": "Suggestions",
  "suggs.list.search.label": "Search by Description",
  "suggestions.suggestionID.list.column.suggestions": "ID",
  "suggestions.description.list.column": "Description",
  "suggestions.suggestionStatus?.list.column": "Status",
  "suggestions.CreatedBy?.list.column": "Email",
  "suggestions.CreationDate?.list.column": "Creation Date",
  "sideNav.SuggestionDetails": "Suggestion Details",
  "roleForm.suggestionStatus.label": "Status",
  "suggs.list.filters.creationDate": "Creation Date",
  "suggs.list.filters.suggsStatus": "Status",
  "logs.list.filters.employee": "Created By",
  "suggs.list.delete.success": "Deleted Successfully",
  "suggestions.list.column.Employee": "Author",
  "suggs.addNew": "Add New Suggestion",
  "createdBy.suggs": "Posted By: ",
  "createdat.suggs": "Posted At: ",
  "suggs.list.vote.success": "Voted Successfully",
  "suggs.list.unvote.success": "Removed successfully",
  "suggestions.votes?.list.column": "Votes",
  "SuggestionBox.title": "Suggestions Box",
  "suggestions.Tenant?.list.column": "Tenant",
  "sugg.nbofVoters": "Show Voters",
  "sugg.showVoters.title": "Voters",
  "sugg.showVoters": "Voters",
  "sugg.creator.anonymous": "Anonymous",

  /////////////////////////NEWS
  "sideNav.newsDetails": " News Details",
  "sideNav.news": "News",
  "breadcrumbs.news": "News",
  "news.list.search.label": "Search by description ...",
  "news.list.delete.label": "Delete News",
  "news.form.content": "News",
  "news.addNew": "Add news",
  "breadcrumbs.newsForm": "News",
  "breadcrumbs.newsList": "News List",
  "news.createdSuccessfully": "Saved Successfully",
  "news.updatedSuccessfully": "Saved Successfully",
  "News.news": "News",

  ////////////////////RELEASE NOTES
  "sideNav.release": "Release Notes",
  "sideNav.releaseNotesDetails": "Release Notes Details",
  "breadcrumbs.releases": "Release Notes ",
  "release.list.delete.label": "Delete",
  "release.list.search.label": "Search by Description ...",
  "releaseNotes.addNew": "Add New Release Note",
  "releaseNotes.list.delete.success": "Deleted Successfully",
  "releaseNotes.suggestionID.list.column.releaseNotes": "ID",
  "releaseNotes.description.list.column": "Description",
  "releaseNotes.CreationDate?.list.column": "Creation Date",
  "releases.createdSuccessfully": "Saved Successfully",
  "sideNav.newReleaseNote": "New Release Note",
  "breadcrumbs.newReleaseNote": "New Release Note",
  "releaseNote.form.content": "Release Note",
  "projectDashboard.label.releaseDate": "Release Date",
  "label.releaseDate": "ReleaseDate",
  "dontshowthisagain": "Do not show this again",
  "NewReleaseNotes": "New Release Note",
  "release.close": "close",
  "wtsnew": "What's New?",
  "colorstatus.list.column.color": "Color*",
  "label.version": "Version",
  "releaseNotes.version.list.column": "Version",
  //export to excel
  "exportExcel.list.column.resourceName": "Resource Name",
  "exportExcel.list.column.projectName": "Project Name",
  "exportExcel.list.column.taskTitle": "Task Title",
  "exportExcel.list.column.description": "Description",
  "exportExcel.list.column.date": "Date",
  "exportExcel.list.column.duration": "Duration",
  "exportExcel.list.column.time": "Time",
  "exportExcel.list.column.billable": "Billable",
  "exportExcel.list.column.action": "Action",
  "exportExcel.list.column.error": "Error",
  "report.exportSuccessfully": "Exported Successfully",
  "report.exportToExcel": "Export to Excel",
  "report.exportToExcel.label": "Export",

  ///REPORTS TABS

  "tooltip.PreviousPeriod": "Previous Period",
  "tooltip.NextPeriod": "Next Period",

  "tooltip.QuickAdd": "Quick Add",

  ////LOANED ITEMS
  "employeeRefTabs.loanedItems": "Loaned Items",
  "employeeRefTabs.projects": "Projects",
  "breadcrumbs.projects": "Projects",
  "breadcrumbs.employeeLoanedItems": "Loaned Items",
  "items.list.search.label": "Search by description..",
  "items.list.delete.label": "Delete",
  "loanedItems.list.column.Description": "Description*",
  "loanedItems.list.column.assignDate": "Assign Date*",
  "loanedItems.list.column.returnDate": "Return Date",
  "loanedItems.list.column.resource": "Resource*",
  "loanedItems.list.filters.employees": "Resources",
  "addtheassignDatefieldplease.error": "Add the Assign Date please!",
  "record.createdSuccessfully": "Saved Successfully",
  "record.updatedSuccessfully": "Saved Successfully",
  "assignto.error": "Add the Assign To field please!",
  "date.error": "Assign date cannot be greater than return date",
  "sendEmailTotheemployee": "Send Email",
  "loanedItems.list.email.success": "Email Sent Successfully",
  "breadcrumbs.myProfile.myLoanedItems": "Loaned Items",
  "profileTabs.loanedItems": "My Loaned Items",

  ////ONBOARDING TASKS

  "sideNav.onBoardingTasks": "Onboarding Tasks",
  "breadcrumbs.onBoarding": "Onboarding Tasks",
  "breadcrumbs.newOnBoardingTask": "New Onboarding Task",
  "onboarding.list.column.Description": "Description",
  "onboarding.list.column.type": "Type",
  "OnboardingTask.Information": "Onboarding Task",
  "onboardingForm.type.label": "Type",
  "onboardingForm.type.requiredMessage": "Onboarding Task Type is required",
  "onboardingForm.description.label": "Description",
  "onboardingForm.description.requiredMessage": "Description is required",
  "onboardingForm.emailBody.label": "Email Body",
  "onboardingForm.emailBody.requiredMessage": "Email Body is required",
  "onboardingForm.emailTitle.label": "Email Title",
  "onboardingForm.emailTitle.requiredMessage": "Email Title is required",
  "onboardingForm.status.requiredMessage": "Status is required",
  "employeeRefTabs.onboard": "Onboarding",
  "breadcrumbs.onboardingtasks": "Onboarding tasks",
  "employeeptos.list.column.OnBoardingTask": "On Boarding Task",
  "employeeptos.list.column.status": "Status",
  "employeeptos.list.column.Type": "Type",
  "employeePtoForm.assignonboardingtasks": "Assign Onboarding Tasks",
  "dialog.assign":
    "Are you sure you want to assign these onboarding tasks to this employee?",
  "employeePtoForm.assignonboardingtaskss": "Assign",
  "onboarding.emailTemplate": "Email Template",
  "onboarding.Attachment": "Attachment",
  "AssignEmployeeOnBoardingTasks.success": "Assigned Tasks Successfully",
  "onboardingForm.name.label": "Onboarding Task",
  "onboardingTasks.updatedSuccessfully": "Saved Successfully",

  ///PROJECT INVOICES
  "sideNav.invoices": "Invoices",
  "breadcrumbs.invoices": "Invoices",
  "projectTabs.invoices": "Invoices",
  "invoices.list.column.doc": "Document Number",
  "invoices.list.column.date": "Posting Date",
  "invoices.list.column.task": "Task",
  "invoices.list.column.ref": "Reference",
  "invoices.list.column.rem": "Remaining",
  "invoices.list.column.quan": "Quantity",
  "invoices.list.column.quanUsed": "Qty Applied",
  "invoices.list.column.type": "Type",
  "invoice.list.search.label":
    "Search by Document Number, Task and External Reference.",
  "ts.myprojectTasks": "My Project Tasks",
  "ts.AssignedToMyTeam": "Assigned To My Team",
  "ts.AssignedToMe": "Assigned To Me",
  "ts.tooltip.search.search":
    "You must select a filter for the search to function.",
  "clear.ts": "Clear Filters",
  "ts.addtimesheet": "Add Multiple Time Entries",
  "ts.AddtimeEntry": "Add Single Time Entry",
  "timesheet.btn.add.": "Add Time",
  "table.QuickFilterTitle": "Quick Filters",
  "ts.OnlyTasks": "Tasks",
  "ts.OnlySubtasks": "Subtasks",
  "ts.Doing": "Doing",
  "ts.assignedtometooltip": "Tasks that are assigned directly to me.",
  "ts.taskstooltip": "Unparented tasks.",
  "ts.assignedtomeyteamtooltip":
    "Tasks that are assigned to teams I belong to.",
  "ts.prioritytooltip": "High priority tasks.",
  "ts.myprojectstooltip": "Tasks related to projects I am a member of.",
  "ts.subtaskstooltip": "Subtasks.",
  "ts.tooltip.date": "Time entries submitted will be created on this date",
  "ts.showParent": "Tree View",
  "ts.tooltip.showParent":
    "Maintains the hirerachy when applying filters. (Sorting applies only to parent tasks)",
  "ts.savedFilters": "Saved Filters",
  "ts.showSavedFilters": "Show Saved Filters",
  "ts.hideSavedFilters": "Hide Saved Filters",
  "dialog.ts.title": "Add multiple time entries",
  "invoices.btn.undo":
    "Reapply time entries and credit notes to invoices chronologically",
  "invoices.list.undo.success": "Updated successfully!",
  "breadcrumbs.appliedinvoices": "Applied Invoices",
  "appliedInvoices.list.column.postingDate": "Posting Date",
  "appliedInvoices.list.column.Type": "Type",
  "appliedInvoices.list.column.DocumentNumber": "Document Number",
  "appliedInvoices.list.column.Description": "Time Entry Description",
  "appliedInvoices.list.column.Task": "Task",
  "appliedInvoices.list.column.ResourceName": "Resource",
  "appliedInvoices.list.column.QuantityApplied": "Quantity Applied",
  "invoices.btn.undo.": "Undo",
  "company.list.undo.success": "Updated successfully!",
  "invoice.print.label": "Print",
  "invoice.filter.label": "Filter By:",
  "invoice.invoice": "Invoice",
  "invoice.toInvoice": "To Invoice",
  "invoice.invoiceNo": "Invoice no",
  "invoice.date": "Posting Date",
  "invoice.qty": "Quantity",
  "invoice.monthlyTotal": "Monthly Total",
  "invoice.total": "Total",
  "invoice.remaning": "Remaning",
  "invoice.type": "Type",
  "invoice.description": "Description",
  "invoice.appliedEntries": "Applied Entries",
  "invoice.uninvoicedEntries": "Uninvoiced Entries",
  "invoice.noData": "No Data to display.",

  ///Tasks Backlog
  "tasksBacklog.Title": "Title",
  "tasksBacklog.ID": "ID",
  "tasksBacklog.ParentTask": "Parent Task",
  "tasksBacklog.Phase": "Phase",
  "tasksBacklog.Project": "Project",
  "tasksBacklog.AssignedTo": "Assigned To",
  "tasksBacklog.Status": "Status",
  "tasksBacklog.Area": "Area",
  "tasksBacklog.Priority": "Priority",
  "tasksBacklog.Progress": "Progress",
  "tasksBacklog.EstimatedStartDate": "Estimated Start Date",
  "tasksBacklog.EstimatedEndDate": "Estimated End Date",
  "tasksBacklog.EstimatedTime": "Estimated Time",
  "tasksBacklog.ActualStartDate": "Actual Start Date",
  "tasksBacklog.ActualEndDate": "Actual End Date",
  "tasksBacklog.ActualTime": "Actual Time",
  "tasksBacklog.CreatedBy": "Created By",
  "tasksBacklog.CreatedAt": "Created At",
  "tasksBacklog.ModifiedBy": "Modified By",
  "tasksBacklog.ModifiedAt": "Modified At",
  "tasksBacklog.Billable": "Billable",
  "backlog.tooltip.subtask": "Subtask",
  "backlog.tooltip.task": "Task",
  "tasksBacklog.BehindSchedule": "",
  "tasksBacklog.movedRow": "Are you sure you want to move this record under",

  //Help
  "helpForm.helpInformation.title": "Help",
  "helpForm.title.label": "Page",
  "helpForm.description.label": "Description",

  "tooltip.hide.sideInfo": "Hide Side Info",
  "tooltip.show.sideInfo": "Show Side Info",

  ///Kiosk Devices
  "sideNav.myKioskDevices": "Kiosk Devices",
  "breadcrumbs.kiosk": "Kiosk Devices",
  "breadcrumbs.newKiosk": "New Kiosk Device",
  "kioskDevices.form": "Info",
  "kiosk.list.new.label": "New Kiosk Device",
  "kiosk.list.delete.label": "Delete",
  "kiosk.workType": "Work Type",
  "kiosk.list.column.deviceID": "Device ID",
  "kiosk.list.column.desc": "Description",
  "kiosk.list.column.status": "Status",
  "kiosk.list.column.workType": "Work Type",
  "kiosk.list.column.location": "Location",
  "kiosk.list.column.fingerprint": "Fingerprint",
  "breadcrumbs.newkiosk": "New Kiosk Device",
  "kioskForm.status.label": "Status",
  "kioskForm.status.requiredMessage": "Status is required",
  "kioskForm.description.label": "Description",
  "kioskForm.deviceID.label": "Device ID",
  "kioskForm.deviceID.requiredMessage": "Device ID is required",
  "kioskForm.fingerprint.label": "Fingerprint",
  "kioskForm.fingerprint.requiredMessage": "Fingerprint is required",
  "kioskForm.location.label": "Location",
  "kioskForm.location.requiredMessage": "Location is required",
  "kioskForm.workType.label": "Work Type",
  "kioskForm.workType.requiredMessage": "Work Type is required",
  "kiosk.list.search.label.fromTenant":
    "Search by Description, status, Fingerprint",
  "kiosk.list.search.label": "Search by Description, Status, Fingerprint",
  "kiosk.tooltip.location":
    "You must select a work type first, and then choose a location",
  "kioskError.delete":
    "You cannot delete this device. Some attendance records are linked to it.",

  ///Resources Table
  "employee.list.column.ResourceType": "Type",
  "employee.list.column.Timezone": "Timezone",
  "employee.list.column.Location": "Home Location",
  "employee.list.column.EmploymentDate": "Employment Date",
  "employee.list.column.defaultWorkCompany": "Work Company",
  "employee.list.column.WorkType": "Work Type",
  "employee.list.column.EmploymentStatus": "Employment Status",
  "employee.list.column.HolidayCalendar": "Holiday Calendar",
  "employee.list.filters.timezone": "Timezone",
  "employee.list.filters.location": "Locations",
  "employee.list.filters.date": "Employment Date",
  "employee.list.filters.position": "Position",
  "employee.list.filters.manager": "Direct Manager",
  "projectList.addProject":"Assign Project",
  "projectTable.AssignProject":"Assign Projects",



  //Kanban
  "due": "Due: ",
  "project": "Project: ",
  "actual.time": "Actual Time: ",
  "search.label": "Search",
};

export default translations;
