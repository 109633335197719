import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import { DELETE_KIOSK_DEVICE, GET_ALL_KIOS_DEVICES } from "../../../../graphql/kioskDevices";
import { GET_ALL_LOCATIONS_DROPDOWN } from "../../../../graphql/location";
import { GET_ALL_WORK_TYPES } from "../../../../graphql/workType";
import { GET_LOCATION_OPTIONS } from "../../../../graphql/clockin-out";



const KioskDevicesList = ({ fromTenant = false, myKioskDevices = false }) => {
  const { tID, tName } = useParams();

  const {
    new_tenant_kiosk_device,
    tenant_kiosk_form,
    kiosk_devices_form,
    new_kiosk_device,
  } = urls;

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageParameters } = userRoles;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [workType, setWorkType] = useState(null);

  const { cfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_KIOSK_DEVICE,
    "DeleteKioskDevice"
  );

  const {
    data: workTypesList,
    loading: workTypeLoading,
    tokenChange: workTypesWait,
  } = useQueryAuth(GET_ALL_WORK_TYPES, "GetAllWorkTypes");

  const workType_list = workTypesList &&
    workTypesList.GetAllWorkTypes &&
    workTypesList.GetAllWorkTypes.workTypes
    ? workTypesList.GetAllWorkTypes.workTypes.filter(workType => workType.mnemonic !== "workfromhome")
    : [];

  const { data: locationsList, loading: locationLoading, tokenChange: locationWait } = useQueryAuth(
    GET_LOCATION_OPTIONS,
    "GetLocationsByWorkType",
    {
      variables: {
        model: {
          userID: storeUserGuid,
           workTypeID: workType?.workTypeGuid
        },
      },
       skip: workType == null,
      isWait: true,
    }
  );

  const location_list =
    locationsList &&
      locationsList.GetLocationsByWorkType &&
      locationsList.GetLocationsByWorkType.locations
      ? locationsList.GetLocationsByWorkType.locations
      : [];

  const filtersObject = [
    {
      label: "attendanceSheet.list.filters.workType",
      name: "workTypeIDs",
      type: "autocomplete",
      getOptionLabel: (option) => (option ? option.description : ""),
      options: workType_list,
      access: "workTypeGuid",
      loading: workTypeLoading,
      selectedData: workType,
      setSelectedData: setWorkType,
      fieldsToClear:["locationIDs"]
    },
    {
      label: "attendanceSheet.list.filters.locations",
      name: "locationIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.name : ""),
      options: location_list,
      access: "locationGuid",
      loading: locationLoading,
      disabled: workType == null,

     
    },

  ];

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables["KioskDevices"],
          userID: storeUserGuid,
        },
      },
      skip : !workTypesWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        const col =
          response &&
            response.GetUserTablePreferences &&
            response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) setColumns(parsed.columns);

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: getAllKiosks, loading } = useLazyQueryAuth(
    GET_ALL_KIOS_DEVICES,
    "GetAllKioskDevices"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllKiosks(
        {
          variables: {
            model: {
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyword: search,
              tenantGuid: myKioskDevices
                ? storeUserTenant
                : tID && fromTenant
                  ? tID
                  : undefined,
              filters: filters,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData, re]);

  const rows =
    data &&
      data.GetAllKioskDevices &&
      data.GetAllKioskDevices.kioskDeviceList && data.GetAllKioskDevices.kioskDeviceList.devices
      ? data.GetAllKioskDevices.kioskDeviceList.devices
      : [];
  const numberOfRecords =
    data &&
      data.GetAllKioskDevices &&
      data.GetAllKioskDevices.kioskDeviceList &&
      data.GetAllKioskDevices.kioskDeviceList.totalNumberOfRecords
      ? data.GetAllKioskDevices.kioskDeviceList.totalNumberOfRecords
      : 0;


  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          deviceGuid: selected[0],
        },
      },
      (response) => {
        if (
          response["DeleteKioskDevice"].errorCode !== null &&
          response["DeleteKioskDevice"].errorCode !== ""
        ) {

          enqueueSnackbar(t("kioskError.delete"), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {

          enqueueSnackbar(t("role.list.delete.success"), {
            variant: "success",
          });
          setRe((v) => !v);
          setSelected([]);
          setPage(0);
        }
      },
      
    );
  };


  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    removeFilters: false,
    loading: loading || columnsWait,
    searchText: fromTenant
      ? "kiosk.list.search.label.fromTenant"
      : "kiosk.list.search.label",
    refetch,
    tableMnemonic: Mnemonic.Tables["KioskDevices"],
    filtersObject,
    generateRoute: (row) =>
      myKioskDevices
        ? kiosk_devices_form.replace(
          "/:kName/:kID",
          `/${encodeURIComponent(row?.deviceID)}/${encodeURIComponent(
            row?.kioskDeviceGuid
          )}`
        )
        : tenant_kiosk_form
          .replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          )
          .replace(
            "/:kName/:kID",
            `/${encodeURIComponent(row?.deviceID)}/${encodeURIComponent(
              row?.kioskDeviceGuid
            )}`
          ),
    // newText: "kiosk.list.new.label",
    // newLink: fromTenant
    //   ? new_tenant_kiosk_device.replace("/:tName/:tID", `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`)
    //   : new_kiosk_device,
    deleteText: "kiosk.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    singleSelect: true,
    hideEditColumns: true,
    removeSelectAll: true,
    checkboxDisable: (row) => row.isSystemGenerated === true,
    readOnly: manageParameters !== "M",
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable {...pagination} identifier={"kioskDeviceGuid"} hasCheckbox />
      )}
    </div>
  );
};

export default KioskDevicesList;
