import React, { useEffect } from "react";

//components
import { Grid, Box, TextField, FormControlLabel, Switch } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

//translation
import { useTranslation } from "react-i18next";
//mnemonic values
import Mnemonic from "../../../../../Mnemonics.json";

//hooks
import useAuth from "../../../../../hooks/useAuth";

import { day_month_year } from "../../../../../utils/timezones";

import AutoComplete from "../../../../../components/AutoComplete";

import colors from "../../../../../assets/theme/light/colors";

import "./style.css";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../../graphql/project";
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import { GET_ALL_TASKS_WITH_PROJECT } from "../../../../../graphql/task";
import { Field } from "formik";

import { v4 as uuidv4 } from "uuid";

const Main = ({
  formData,
  employees,
  tasks,
  selectedTask,
  resetFields,
  status,
  tenant,
  manager,
  userID,
  fromTimesheet,
  myTime,
  group,
  handleSetEmployeeGuid,
  fromDashboard,
  setProjectGuid,
  taskBillable,
}) => {
  const { t } = useTranslation();
  const {
    storeUserGuid,
    storeUserTenant,
    storeUserFirstName,
    storeUserLastName,
    userRoles,
    isManager,
    projectPermissions,
    isTimeEntryDescriptionMandatory,
  } = useAuth();
  const { isAdmin, isEntelligence, isAppUser, manageTimeEntry } = userRoles;

  const isTenantUser = !isAdmin && !isEntelligence && !isAppUser;
  const user = {
    employeeGuid: storeUserGuid,
    firstName: storeUserFirstName,
    lastName: storeUserLastName,
  };

  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];
  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    editMode,
    synced,
  } = formData;

  const {
    employee,
    task,
    date,
    description,
    minutes,
    hours,
    project,
    billable,
  } = formField;

  const {
    employee: employeeV,
    task: taskV,
    project: projectV,
    date: dateV,
    minutes: minutesV,
    hours: hoursV,
    billable: billableV,
    description: descriptionV,
  } = values;

  useEffect(() => {
    if (editMode === false) {
      if (
        !isTenantUser ||
        !employees.some((x) => x.employeeGuid === storeUserGuid)
      )
        setFieldValue("employee", null);
      setFieldValue("task", null);
      setFieldValue("project", null);
      setFieldValue("date", new Date());
      setFieldValue("duration", Number(0));
      setFieldValue("description", "");
    }
  }, [resetFields]);

  useEffect(() => {
    if (taskV && !editMode) {
      setFieldValue(billable.name, taskV?.billable);
    } else if(!editMode) {
      setFieldValue(billable.name, taskBillable);
    }
  }, [taskV]);

  useEffect(() => {
    if (
      ((!fromTimesheet && isTenantUser && editMode === false) ||
        editMode === false) &&
      employees.some((x) => x.employeeGuid === storeUserGuid)
    ) {
      setFieldValue(employee.name, user);
      if (handleSetEmployeeGuid != undefined)
        handleSetEmployeeGuid(user?.employeeGuid);
    }
  }, []);

  const {
    data: projectsData,
    loading: projectLoading,
    tokenChange: projectWait,
  } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
      },
    },
  });

  const projectsList =
    projectsData?.GetAllProjects?.projectsList?.projectViews || [];

  const {
    data: tasksList,
    loading: taskLoading,
    tokenChange: tasksWait,
  } = useQueryAuth(GET_ALL_TASKS_WITH_PROJECT, "GetTasks", {
    variables: {
      model: {
        tenantID: storeUserTenant,
        enableFilters: false,
        onGoingTasks: true,
        userID: storeUserGuid,
        projectID: projectV?.projectGuid,
        timeEntriesCreationAllowed: true,
      },
    },
    skip: !projectV || projectWait,
    isWait: true,
  });

  const task_list =
    tasksList && tasksList.GetTasks && tasksList.GetTasks.tasks
      ? tasksList.GetTasks.tasks
      : [];

  useEffect(() => {
    if (selectedTask) {
      setFieldValue(
        task.name,
        selectedTask
      );
      setFieldValue(project.name, selectedTask?.ticket?.project);
      setProjectGuid(selectedTask?.ticket?.project?.projectGuid);
      if(!editMode) setFieldValue(billable.name, selectedTask.billable);
    }
  }, [selectedTask, task_list?.length]);

  const disableResource =
    editMode ||
    synced === true ||
    (!isAdmin && !isManager && manageTimeEntry !== "M") ||
    status === Mnemonic.TimesheetStatuses.Approved ||
    // (manager !== null &&
    //   manager !== undefined &&
    (manager !== storeUserGuid &&
      isManager === true &&
      userID !== undefined &&
      userID !== null &&
      userID !== storeUserGuid &&
      !isAdmin) ||
    (tenant !== null && tenant !== undefined && tenant !== storeUserTenant);

  const mutualDisableConditions =
    synced === true ||
    status === Mnemonic.TimesheetStatuses.Approved ||
    // (manager !== null &&
    //   manager !== undefined &&
    (manager !== storeUserGuid &&
      isManager === true &&
      userID !== undefined &&
      userID !== null &&
      userID !== storeUserGuid &&
      !isAdmin &&
      (projectV !== null
        ? !projectPermissions?.some(
            (x) => x.projectGuid === projectV?.projectGuid
          )
        : false)) ||
    (tenant !== null && tenant !== undefined && tenant !== storeUserTenant);
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="flex_space-between_center"
          ></Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <AutoComplete
              autoFocus={!disableResource}
              options={employees}
              getOptionLabel={(option) =>
                option ? option.firstName + " " + option.lastName : ""
              }
              renderOption={(props, option) => (
                <li {...props} key={uuidv4()}>
                  {option.firstName + " " + option.lastName}
                </li>
              )}
              identifier={"employeeGuid"}
              disabled={disableResource}
              value={employeeV}
              onChange={(e, value) => {
                setFieldValue(employee.name, value);
                if (handleSetEmployeeGuid != undefined)
                  handleSetEmployeeGuid(value?.employeeGuid);
                if (selectedTask !== null)
                  setFieldValue(task.name, selectedTask);
              }}
              label={t(employee.label)}
              name={employee.name}
              formData={formData}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <AutoComplete
              autoFocus={disableResource && (myTime || fromDashboard)}
              options={projectsList}
              getOptionLabel={(option) => (option ? option.title : "")}
              renderOption={(props, option) => (
                <li {...props} key={uuidv4()}>
                  {option.title}
                </li>
              )}
              identifier={"projectGuid"}
              value={projectV}
              onChange={(e, value) => {
                setFieldValue(project.name, value);
                setFieldValue(task.name, null);
              }}
              label={t(project.label)}
              name={project.name}
              formData={formData}
              required
              disabled={
                editMode ||
                (!myTime && !fromDashboard && !fromTimesheet) ||
                mutualDisableConditions
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <AutoComplete
              options={task_list}
              disabled={
                (editMode && selectedTask == null) ||
                (!fromTimesheet && !myTime && !fromDashboard) ||
                mutualDisableConditions
              }
              getOptionLabel={(option) => (option ? option.title : "")}
              renderOption={(props, option) => (
                <li {...props} key={uuidv4()}>
                  {option.title}
                </li>
              )}
              identifier={"taskGuid"}
              value={selectedTask !== null ? selectedTask : taskV}
              onChange={(e, value) => {
                setFieldValue(task.name, value);
              }}
              label={t(task.label)}
              name={task.name}
              formData={formData}
              required
            />
          </Grid>

          <Grid
            className="timesheet-date"
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                fullWidth
                format={
                  day_month_year.includes(userTimezone) === true
                    ? "dd-MM-yyyy"
                    : "MM-dd-yyyy"
                }
                timezone="UTC"
                label={t(date.label)}
                value={dateV}
                disabled={mutualDisableConditions}
                onChange={(newValue) => {
                  setFieldValue(date.name, newValue);
                }}
                slotProps={{
                  desktopPaper: {
                    sx: {
                      boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                    },
                  },
                  dialog: {
                    disablePortal: true,
                    style: { zIndex: 1000000 },
                  },
                  textField: {
                    size: "medium",
                    variant: "standard",
                    sx: {
                      ".Mui-disabled": {
                        color: "#636563",
                        WebkitTextFillColor: "#636563",
                      },
                    },

                    error: touched[date.name] && errors[date.name],
                    helperText:
                      touched[date.name] &&
                      errors[date.name] &&
                      t(errors[date.name]),

                    onBlur: (e) =>
                      setTouched({
                        ...touched,
                        [date.name]: true,
                      }),
                  },
                }}
                sx={{ width: "100% !important", fontFamily: "poppins" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              sx={{
                ".MuiInputBase-input": { fontSize: "14px !important" },
                ".Mui-disabled": { color: "#636563" },
              }}
              variant="standard"
              fullWidth
              required
              disabled={mutualDisableConditions}
              label={t(hours.label)}
              value={hoursV}
              onBlur={(e) => {
                setTouched({ ...touched, [hours.name]: true });
                setFieldValue(hours.name, Number(e.target.value));
              }}
              onChange={(e) => {
                const enteredValue =
                  e.target.value == "" ? "0" : e.target.value;

                const numericValue = parseInt(enteredValue, 10);

                // If the entered value is 24, clear the minutes field
                if (numericValue === 24) {
                  setFieldValue(hours.name, "24");
                  setFieldValue(minutes.name, "");
                } else {
                  // For other values, format the hours field as before
                  const formattedValue = isNaN(numericValue)
                    ? "00"
                    : Math.min(Math.max(0, numericValue), 24)
                        .toString()
                        .padStart(2, "00");

                  setFieldValue(hours.name, numericValue);
                }
              }}
              error={errors[hours.name]}
              helperText={errors[hours.name] && t(errors[hours.name])}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <TextField
              sx={{
                ".MuiInputBase-input": { fontSize: "14px !important" },
                ".Mui-disabled": { color: "#636563" },
              }}
              variant="standard"
              fullWidth
              required
              disabled={mutualDisableConditions}
              label={t(minutes.label)}
              value={minutesV}
              onBlur={(e) => {
                setTouched({ ...touched, [minutes.name]: true });
              }}
              onChange={(e) => {
                const enteredValue = e.target.value;
                const numericValue = parseInt(enteredValue, 10);

                // Check the value of the hours field
                const hoursValue = parseInt(hoursV, 10);

                // If the hours field is 24, disable input for minutes
                if (hoursValue === 24) {
                  setFieldValue(minutes.name, "00");
                } else {
                  // For other values, format the minutes field as before
                  const formattedValue = isNaN(numericValue)
                    ? "00"
                    : Math.min(Math.max(0, numericValue), 60)
                        .toString()
                        .padStart(2, "00");

                  setFieldValue(minutes.name, formattedValue);
                }
              }}
              error={errors[minutes.name]}
              helperText={errors[minutes.name] && t(errors[minutes.name])}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={3}
            display="flex"
            alignItems="end"
            justifyContent="center"
          >
            <Field
              as={FormControlLabel}
              type={billable.type}
              name={billable.name}
              control={
                <Switch
                  size="small"
                  disabled={mutualDisableConditions}
                  checked={billableV}
                  onBlur={(e) =>
                    setTouched({ ...touched, [billable.name]: true })
                  }
                  onChange={(e) => {
                    setFieldValue(billable.name, e.target.checked);
                  }}
                  error={touched[billable.name] && errors[billable.name]}
                />
              }
              label={t(billable.label)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={3}>
            <TextField
              sx={{
                ".MuiInputBase-input": { fontSize: "14px !important" },
                ".Mui-disabled": {
                  color: "#636563",
                  WebkitTextFillColor: "#636563",
                },
              }}
              required={isTimeEntryDescriptionMandatory}
              variant="standard"
              fullWidth
              label={t(description.label)}
              placeholder={t(description.label)}
              value={descriptionV}
              // required={isTimeEntryDescriptionMandatory === true}
              disabled={mutualDisableConditions}
              multiline
              onBlur={(e) =>
                setTouched({ ...touched, [description.name]: true })
              }
              onChange={(e) => {
                setFieldValue(description.name, e.target.value);
              }}
              error={touched[description.name] && errors[description.name]}
              helperText={
                touched[description.name] &&
                errors[description.name] &&
                t(errors[description.name])
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
