import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import useAuth from "../../../../hooks/useAuth";

import { Box, Chip, CircularProgress, Grid, Stack } from "@mui/material";

// formik components
import { Formik, Form } from "formik";

import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import RoundedDatePicker from "../../../../components/DateField/RoundedDatePicker";
import CircleIcon from "@mui/icons-material/AddCircle";

import { POST_TIMESHEET } from "../../../../graphql/timesheet";
import { GET_ALL_TASKS, GET_TASKS_BY_TICKET } from "../../../../graphql/task";

import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

import { useSnackbar } from "notistack";

import { TimesheetTable } from "./_table";
import TaskTabsModal from "../../../../components/Modal/TaskTabsModal";
import TimesheetForm from "../../../../pages/admin-configuration/Timesheets/Timesheets-Form";

import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import urls from "../../../../urls";
import colors from "../../../../assets/theme/light/colors";
import { DeleteIcon } from "../../../../components/Icon";
import { GET_ALL_PROJECTS_DROPDOWN } from "../../../../graphql/project";
import AutoComplete from "../../../../components/AutoComplete";
import Tooltip from "../../../../components/Tooltip";

import { v4 as uuidv4 } from "uuid";

function AttendanceByEmployee({ handleCloseTimesheet, refetchData, handleDate }) {
  const { storeUserTenant, storeUserGuid, isTimeEntryDescriptionMandatory } = useAuth();
  const [timeErrors, setTimeErrors] = useState({});
  const [taskID, setTaskID] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [projectID, setProjectID] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [button1Subitting, setButton1Submitting] = useState(false);
  const [button2Subitting, setButton2Submitting] = useState(false);
  const [assignedToMe, setAssignedToMe] = useState(true);
  const [assignedToMyTeam, setAssignedToMyTeam] = useState(true);
  const [myProjectTasks, setMyProjectTasks] = useState(false);
  const [projects, setProjects] = useState([]);
  const [fetched, setFetched] = useState(false);
  const type = useRef("task");


  const saveAndClose = useRef(false);

  const { t } = useTranslation();

  const validationSchema = yup.object({
    tasks: yup.array().required(t("myTimesheet.label.task")),
    date: yup.date().required(t("myTimesheet.label.date")),
  });

  const { my_time_entries } = urls;

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const initialValues = {
    tasks: [],
    date: new Date(),
  };

  const nowDate = new Date();

  const [selectedDate, setSelectedDate] = useState(nowDate);

  const [list, setList] = useState(null);
  const [re, setRe] = useState(false);
  const [fetchProjects, setFetchProjects] = useState();
  const [projectIDs, setProjectIDs] = useState([]);


  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setRe(v => !v);
        setOpenModal(false);
        setTaskID(null);
        setTaskName(null);
        setProjectID(null);
        setProjectName(null);
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);



  const { cfunction: postTimeSheet, loading: postTimesheetLoading } =
    useMutationAuth(POST_TIMESHEET, "PostExternalTimeSheet");

  const handlePostTimeSheet = (values, actions) => {
    if (saveAndClose.current === false) setButton1Submitting(true)
    else setButton2Submitting(true)
    postTimeSheet(
      {
        variables: {
          model: {
            date: new Date(selectedDate),
            employeeGuid: storeUserGuid,
            timeList: values["tasks"],
          },
        },
      },
      (response) => {
        if (
          response["PostExternalTimeSheet"].errorCode !== null &&
          response["PostExternalTimeSheet"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["PostExternalTimeSheet"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
          setButton1Submitting(false);
          setButton2Submitting(false);
        } else {
          enqueueSnackbar(t("timesheet.post.success"), {
            variant: "success",
          });
          values["tasks"].map(task => task.duration = '0');
          setRe(v => !v);
          setButton1Submitting(false);
          setButton2Submitting(false);


          if (saveAndClose.current === true) {
            let f = document.getElementById("my-timsheet");
            f?.reset();
            navigate(my_time_entries);
            handleCloseTimesheet();
            refetchData()
          }
        }
      },
      (error) => {
        setButton1Submitting(false);
        setButton2Submitting(false);
      }
    );
  };


  const {
    data: projectsData,
    loading: projectLoading,
    tokenChange: projectWait,
  } = useQueryAuth(GET_ALL_PROJECTS_DROPDOWN, "GetAllProjects", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
      },
    },
    skip: !fetchProjects,
    isWait: fetchProjects
  });

  const projectsList =
    projectsData?.GetAllProjects?.projectsList?.projectViews || [];


  const { data: tasksList, loading: tasksLoading, refetch } = useQueryAuth(
    GET_TASKS_BY_TICKET,
    "GetTasksByTicket",
    {
      variables: {
        model: {
          tenantID: storeUserTenant,
          userID: storeUserGuid,
          enableFilters: true,
          onGoingTasks: true,
          myTasks: assignedToMe,
          myTeamsTasks: assignedToMyTeam,
          myProjectsTasks: myProjectTasks,
          filters: { projectIDs },
          orderColumn: "ProjectTask",
          orderDirection: "asc",
        },
      },
    }
  );


  useEffect(() => {
    if (tasksList) {
      const tasks = tasksList?.GetTasksByTicket?.tasksList?.taskViews;
      let timesheetList = [];
      tasks?.forEach((task) => {
        timesheetList.push({
          taskGuid: task?.task?.taskGuid,
          project: task?.task?.ticket?.project.title,
          // projectGuid: task?.task?.ticket?.projectGuid,
          title: task?.task?.title,
          description: "",
          duration: '0',
          billable: true,
        });
        return timesheetList;
      });
      setFetched(true)
      setList(timesheetList);
    }
  }, [tasksList]);

  const handleSubmit = (values, actions) => {
    handlePostTimeSheet(values, actions);
  };


  return (
    <>
      <TaskTabsModal
        showModal={openModal}
        setShowModal={(v) => setOpenModal(v)}
        loading={tasksLoading}
        projectID={projectID}
        projectName={projectName}
        taskID={taskID}
        handleChangeProject={(v) => setProjectID(v)}
        handleChangeTask={(v) => setTaskID(v)}
        handleChangeTaskName={(v) => setTaskName(v)}
        taskName={taskName}
        fromDashboard={false}
        fromProject={true}
        TimesheetForm={TimesheetForm}
        refetch={refetch}
        t={t}
        setType={(v) => type.current=v}
        type={type.current}
        open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        modifyFormFlag={() => sessionStorage.setItem("unsavedChanges", JSON.stringify(false))}
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ContainerCard
          className="projects-card"
          sx={{
            width: "100%",
            padding: "10px 0",
            display: "grid",
            gridTemplateColumn: "1fr",
            backgroundColor: "transparent !important",
            boxShadow: "none !important",
            gap: "20px",

          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3}>
              <AutoComplete
                multiple
                loading={projectLoading}
                options={projectsList}
                getOptionLabel={(option) => (option ? option.title : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.title}
                  </li>
                )}
                identifier={"projectGuid"}
                size="small"
                value={projects}
                onOpen={() => setFetchProjects(true)}
                onChange={(event, value) => {
                  setProjects(value);
                  let tmp = [];
                  value.map((ids) => {
                    tmp.push(ids.projectGuid);
                    return tmp;
                  });
                  setProjectIDs(tmp);
                  refetch()
                }}
                sx={{
                  "& .MuiInputBase-input": { fontSize: "13px !important", height: "16px" },
                }}
                label={t("reports.label.projects")}
                roundedTextField
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Stack direction="row" spacing={1}>
                <Tooltip title={t("ts.assignedtometooltip")}>
                  <Chip
                    label={t("ts.AssignedToMe")}
                    color={assignedToMe ? "primary" : "default"}
                    variant={assignedToMe ? "filled" : "outlined"}
                    sx={{
                      ".MuiButtonBase-root": {
                        border: `1px solid ${colors.chip_border} !important`,
                      },
                      "&.MuiChip-root": {
                        border: `1px solid ${colors.chip_border} !important`,
                      },
                      "& .MuiChip-label": {
                        color: assignedToMe ? "white" : `${colors.chip_label} !important`,
                      },
                    }}
                    onClick={() => {
                      const newAssignedToMe = !assignedToMe;
                      setAssignedToMe(newAssignedToMe);
                      if (newAssignedToMe) {
                        setMyProjectTasks(false);
                      }
                      refetch();
                    }}
                  />
                </Tooltip>
                <Tooltip title={t("ts.assignedtomeyteamtooltip")}>
                  <Chip
                    label={t("ts.AssignedToMyTeam")}
                    color={assignedToMyTeam ? "primary" : "default"}
                    variant={assignedToMyTeam ? "filled" : "outlined"}
                    sx={{
                      ".MuiButtonBase-root": {
                        border: `1px solid ${colors.chip_border} !important`,
                      },
                      "&.MuiChip-root": {
                        border: `1px solid ${colors.chip_border} !important`,
                      },
                      "& .MuiChip-label": {
                        color: assignedToMyTeam ? "white" : `${colors.chip_label} !important`,
                      },
                    }}
                    onClick={() => {
                      const newAssignedToMyTeam = !assignedToMyTeam;
                      setAssignedToMyTeam(newAssignedToMyTeam);
                      if (newAssignedToMyTeam) {
                        setMyProjectTasks(false);
                      }
                      refetch();
                    }}
                  />
                </Tooltip>
                <Tooltip title={t("ts.myprojectstooltip")}>
                  <Chip
                    label={t("ts.myprojectTasks")}
                    color={myProjectTasks ? "primary" : "default"}
                    variant={myProjectTasks ? "filled" : "outlined"}
                    sx={{
                      ".MuiButtonBase-root": {
                        border: `1px solid ${colors.chip_border} !important`,
                      },
                      "&.MuiChip-root": {
                        border: `1px solid ${colors.chip_border} !important`,
                      },
                      "& .MuiChip-label": {
                        color: myProjectTasks ? "white" : `${colors.chip_label} !important`,
                      },
                    }}
                    onClick={() => {
                      const newMyProjectTasks = !myProjectTasks;
                      setMyProjectTasks(newMyProjectTasks);
                      if (newMyProjectTasks) {
                        setAssignedToMe(false);
                        setAssignedToMyTeam(false);
                      }
                      refetch();
                    }}
                  />
                </Tooltip>
                <Chip
                  label={t("clear.ts")}
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setAssignedToMe(false);
                    setAssignedToMyTeam(false);
                    setMyProjectTasks(false);
                    setProjects([]);
                    setProjectIDs([]);
                    refetch();
                  }}
                />
              </Stack>


            </Grid>
            <Tooltip title={t("ts.tooltip.date")}>
              <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <RoundedDatePicker
                  label={t("myTimesheet.label.date")}
                  value={selectedDate}
                  onChange={(newValue) => {
                    if(handleDate) handleDate(newValue);
                    setSelectedDate(newValue);
                  }}
                />
              </Grid></Tooltip>
          </Grid>

          <Formik
            innerRef={formikRef}
            key={`timesheet-external-resource`}
            id="my-timsheet"
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            // enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setTouched,
            }) => {
              formikValuesRef.current = values;
              return (
                <Form id={"timesheet-external-resource"} autoComplete="off" >
                  {tasksLoading && !fetched && (
                    <ContainerCard
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px",
                      }}
                    >
                      <CircularProgress />
                    </ContainerCard>
                  )}
                  {list && list.length !== undefined && <TimesheetTable
                    formData={{
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      setTouched,
                    }}
                    t={t}
                    re={re}
                    list={list}
                    setTimeErrors={setTimeErrors}
                    setTaskID={setTaskID}
                    setTaskName={setTaskName}
                    setProjectID={setProjectID}
                    tasksLoading={tasksLoading}
                    setProjectName={setProjectName}
                    setOpenModal={setOpenModal}
                    timeErrors={timeErrors}
                    isTimeEntryDescriptionMandatory={isTimeEntryDescriptionMandatory}
                  />}


                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    pt={2}
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    <IALoadingButton
                      disabled={
                        button1Subitting || Object.keys(timeErrors).length > 0 || !values["tasks"].some(task => task.duration > 0)
                      }
                      loading={button1Subitting}
                      type="submit"
                      label={t("myTimesheet.label.submit")}
                    />
                    <span style={{ marginLeft: "10px" }}>
                      <IALoadingButton
                        disabled={
                          button2Subitting || Object.keys(timeErrors).length > 0 || !values["tasks"].some(task => task.duration > 0)
                        }
                        loading={button2Subitting}
                        type="submit"
                        onClick={() => { saveAndClose.current = true }}
                        label={t("myTimesheet.label.submit.view")}
                      />
                    </span>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </ContainerCard>
      </Grid>
    </>
  );
}

export default AttendanceByEmployee;
