import LongText from "../../../../components/Text/LongText";
import UTC2Local from "../../../../UTC2Local";

export const getDataTableData = (t) => {

    return {
      columns: [
        {
            id: "errorCode",
            label: "errors.list.column.errorCode",
            align: "left",
            width: "15vw",
            visible: true,
            renderCell: (row) => {
              return row?.errorCode || ""
            },
          },
          {
            id: "errorMessage",
            label: "errors.list.column.errorMessage",
            align: "left",
            width: "35vw",
            visible: true,
            renderCell: (row) => {
              return row?.errorMessage;
            },
          },
          {
            id: "date",
            label: "errors.list.column.date",
            align: "center",
            visible: true,
            renderCell: (row) => {
              return row?.createdAt ? UTC2Local(row.createdAt): null
            },
          },
      ],
    };
  };
  