const form = {
  formID: "Projects",
  formField: {
    title: {
      name: "title",
      label: "projectForm.title.label",
      requiredMessage: "projectForm.title.requiredMessage",
    },
    description: {
      name: "description",
      label: "projectForm.description.label",
      
    },
    synced:{
      name: "synced",
      label: "ticketForm.synced.label",
      requiredMessage: "TenantForm.synced.requiredMessage",
    },
    status: {
      name: "status",
      label: "projectForm.status.label",
      requiredMessage: "projectForm.status.requiredMessage",
    },
    tenant: {
      name: "tenant",
      label: "projectForm.tenant.label",
      requiredMessage:"projectForm.tenant.requiredMessage"
    },
    company: {
      name: "company",
      label: "projectForm.company.label",
      requiredMessage:"projectForm.company.requiredMessage"
    },
    location: {
      name: "location",
      label: "projectForm.location.label",
      requiredMessage:"projectForm.location.requiredMessage"
    },
    client: {
      name: "client",
      label: "projectForm.client.label",
      requiredMessage:"projectForm.client.requiredMessage"
    },
    externalReference: {
      name: "externalReference",
      label: "projectForm.externalReference.label",
      requiredMessage:"projectForm.externalReference.requiredMessage"
    },
    EstimatedStartDate:{
      name: "EstimatedStartDate",
      label: "taskForm.EstimatedStartDate.label",
      requiredMessage: "TenantForm.EstimatedStartDate.requiredMessage",
    },
    EstimatedEndDate:{
      name: "EstimatedEndDate",
      label: "taskForm.EstimatedEndDate.label",
      requiredMessage: "TenantForm.EstimatedEndDate.requiredMessage",
    },
    EstimatedTime:{
      name: "EstimatedTime",
      label: "taskForm.EstimatedTime.label",
      requiredMessage: "TenantForm.EstimatedTime.requiredMessage",
    },
    ActualStartDate:{
      name: "ActualStartDate",
      label: "taskForm.ActualStartDate.label",
      requiredMessage: "TenantForm.ActualStartDate.requiredMessage",
    },
    ActualEndDate:{
      name: "ActualEndDate",
      label: "taskForm.ActualEndDate.label",
      requiredMessage: "TenantForm.ActualEndDate.requiredMessage",
    },
    ActualTime:{
      name: "ActualTime",
      label: "taskForm.ActualTime.label",
      requiredMessage: "TenantForm.ActualTime.requiredMessage",
    },
  },
};

export default form;