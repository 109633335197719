import { useParams, Navigate, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import BasicTabs from "../../../../components/BasicTabs";

import { useTranslation } from "react-i18next";

import urls from "../../../../urls";

function EmployeeTabs({ myEmployees = false }) {
  const { eName, eID, tName, tID, lID, obID, obName } = useParams();

  const {
    employee_form,
    my_employee_form,
    my_employee_reference_list,
    employee_reference,
    employee_ptos,
    my_employee_ptos,
    employee_skills,
    my_employee_skills,
    my_employee_teams,
    employee_teams,
    loaned_items,
    employee_onboarding_tasks,
    employee_projects_list
  } = urls;

  const { t } = useTranslation();

  const location = useLocation();

  if (
    !eName ||
    !eID ||
    eName === "undefined" ||
    eName === "null" ||
    eName === "" ||
    eID === "null" ||
    eID === "undefined" ||
    eID === ""
  )
    return <Navigate to={"/"} state={{ from: location }} replace />;

  const encodedName = encodeURIComponent(eName);
  const encodedID = encodeURIComponent(eID);

  const TabLinks = [
    {
      key: 1,
      Header: t("employeeTabs.form"),
      Link: myEmployees
        ? my_employee_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace("/:eName/:eID", `/${encodedName}/${encodedID}`)
        : employee_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace("/:eName/:eID", `/${encodedName}/${encodedID}`),
    },
    {
      key: 2,
      Header: t("employeeTabs.timeOffs"),
      Link: myEmployees
        ? my_employee_ptos.replace(
            "/:eName/:eID",
            `/${encodedName}/${encodedID}`
          )
        : employee_ptos
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace("/:eName/:eID", `/${encodedName}/${encodedID}`),
    },
    // {
    //   key: 8,
    //   Header: t("employeeRefTabs.projects"),
    //   Link: employee_projects_list
    //   .replace(
    //       "/:tName/:tID",
    //       `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
    //     )
    //     .replace("/:eName/:eID", `/${encodedName}/${encodedID}`),
    // },
    {
      key: 3,
      Header: t("employeeTabs.skills"),
      Link: myEmployees
        ? my_employee_skills.replace(
            "/:eName/:eID",
            `/${encodedName}/${encodedID}`
          )
        : employee_skills
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace("/:eName/:eID", `/${encodedName}/${encodedID}`),
    },
    {
      key: 4,
      Header: t("employeeTabs.teams"),
      Link: myEmployees
        ? my_employee_teams.replace(
          "/:eName/:eID",
          `/${encodedName}/${encodedID}`
        )
        : employee_teams
        .replace(
          "/:tName/:tID",
          `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
        )
        .replace(
              "/:eName/:eID",
              `/${encodedName}/${encodedID}`
            ),
    },
    {
      key: 5,
      Header: t("employeeRefTabs.form"),
      Link: myEmployees
        ? my_employee_reference_list
          .replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          )
          .replace("/:eName/:eID", `/${encodedName}/${encodedID}`)
        : employee_reference
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace("/:eName/:eID", `/${encodedName}/${encodedID}`),
    },
    {
      key: 6,
      Header: t("employeeRefTabs.loanedItems"),
      Link: loaned_items
      .replace(
        "/:tName/:tID",
        `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
      )
      .replace("/:eName/:eID", `/${encodedName}/${encodedID}`),
    },
    {
      key: 7,
      Header: t("employeeRefTabs.onboard"),
      Link: employee_onboarding_tasks
      .replace(
          "/:tName/:tID",
          `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
        )
        .replace("/:eName/:eID", `/${encodedName}/${encodedID}`),
    },
   
  ];

  return (
    <Box>
      <BasicTabs TabHeaders={TabLinks} />
    </Box>
  );
}

export default EmployeeTabs;
