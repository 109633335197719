//components
import TenantList from "./pages/admin-configuration/Tenants/Tenants-list";

//authorization
import Authorization from "./authorization";

import RolePermissions from "./classes/RolePermissions";

//colors
import colors from "./assets/theme/light/colors";

import urls from "./urls";

import {
  ConfigIcon,
  DashboardIcon,
  ErrorsIcon, HelpIcon, LogsIcon,
  ReleasesIcon,
  SuggestionsIcon,
  SysVariablesIcon,
  TenantIcon,
  UsersIcon
} from "./components/Icon";
import CompanyForm from "./pages/admin-configuration/Companies/Companies-form";
import CompanyTabs from "./pages/admin-configuration/Companies/Companies-Tabs";
import CompanyTabRoutes from "./pages/admin-configuration/Companies/Companies-Tabs/routes/CompanyTabRoutes";
import EmployeeReferencesForm from "./pages/admin-configuration/EmployeeReferences/EmployeeReferences-form";
import EmployeeReferencesTabs from "./pages/admin-configuration/EmployeeReferences/EmployeeReferences-tabs";
import EmployeeReferenceTabRoutes from "./pages/admin-configuration/EmployeeReferences/EmployeeReferences-tabs/routes/EmployeeReferenceTabRoutes";
import EmployeeForm from "./pages/admin-configuration/Employees/Employee-form";
import EmployeeTabs from "./pages/admin-configuration/Employees/Employees-tabs";
import EmployeeTabRoutes from "./pages/admin-configuration/Employees/Employees-tabs/routes/EmployeeTabRoutes";
import Help from "./pages/admin-configuration/Help/Help-form";
import LocationForm from "./pages/admin-configuration/Locations/Locations-form";
import LocationTabs from "./pages/admin-configuration/Locations/Locations-tabs";
import LocationsTabRoutes from "./pages/admin-configuration/Locations/Locations-tabs/routes/LocationsTabRoutes";
import LogDetails from "./pages/admin-configuration/Logs/components/log_details";
import LogsList from "./pages/admin-configuration/Logs/Logs-list";
import OfficialHolidayForm from "./pages/admin-configuration/OfficialHolidays/OfficialHolidays-Form";
import OfficialHolidaysTabs from "./pages/admin-configuration/OfficialHolidays/OfficialHolidays-Tabs";
import OfficialHolidayTabRoutes from "./pages/admin-configuration/OfficialHolidays/OfficialHolidays-Tabs/routes/OfficialHolidayTabRoutes";
import ProjectForm from "./pages/admin-configuration/Projects/Projects-form";
import ProjectsList from "./pages/admin-configuration/Projects/Projects-list";
import ProjectTabs from "./pages/admin-configuration/Projects/Projects-tabs";
import ProjectsTabRoutes from "./pages/admin-configuration/Projects/Projects-tabs/routes/ProjectsTabRoutes";
import PTOForm from "./pages/admin-configuration/PTOs/PTOs-form";
import PTOTabs from "./pages/admin-configuration/PTOs/PTOs-tabs";
import PTOTabRoutes from "./pages/admin-configuration/PTOs/PTOs-tabs/routes/PTOTabRoutes";
import ReleaseNotesDetails from "./pages/admin-configuration/ReleaseNotes/release-info";
import ReleaseNotesList from "./pages/admin-configuration/ReleaseNotes/release-list";
import DaysUsedHistory from "./pages/admin-configuration/Requests/DaysUsedHistory";
import RequestList from "./pages/admin-configuration/Requests/Requests-List";
import RequestListTabRoutes from "./pages/admin-configuration/Requests/Requests-List/route/RequestListTabRoutes";
import RequestTabs from "./pages/admin-configuration/Requests/Requests-Tabs";
import RequestTabRoutes from "./pages/admin-configuration/Requests/Requests-Tabs/routes/RequestTabRoutes";
import RoleForm from "./pages/admin-configuration/Roles/Roles-form";
import RoleTabs from "./pages/admin-configuration/Roles/Roles-Tabs";
import RoleTabRoutes from "./pages/admin-configuration/Roles/Roles-Tabs/routes/RoleTabRoutes";
import SuggestionDetails from "./pages/admin-configuration/Suggestions/suggestion-form";
import Suggestions from "./pages/admin-configuration/Suggestions/suggestions-list";
import Errors from "./pages/admin-configuration/SystemErrors/errors-list";
import SystemVariablesList from "./pages/admin-configuration/SystemVariables/systemVariables-List";
import TaskForm from "./pages/admin-configuration/Tasks/Tasks-form";
import TaskTabs from "./pages/admin-configuration/Tasks/Tasks-tabs";
import TasksTabRoutes from "./pages/admin-configuration/Tasks/Tasks-tabs/routes/TasksTabRoutes";
import TenantForm from "./pages/admin-configuration/Tenants/Tenants-form";
import TenantTabs from "./pages/admin-configuration/Tenants/Tenants-Tabs";
import TenantTabRoutes from "./pages/admin-configuration/Tenants/Tenants-Tabs/routes/TenantTabRoutes";
import TicketForm from "./pages/admin-configuration/Tickets/Tickets-form";
import TicketTabs from "./pages/admin-configuration/Tickets/Tickets-tabs";
import TicketsTabRoutes from "./pages/admin-configuration/Tickets/Tickets-tabs/routes/TicketsTabRoutes";
import Users from "./pages/admin-configuration/Users/Users-list";
import WorkShiftForm from "./pages/admin-configuration/WorkShifts/WorkShift-form";
import WorkShiftTabs from "./pages/admin-configuration/WorkShifts/Workshift-Tabs";
import WorkShiftTabRoutes from "./pages/admin-configuration/WorkShifts/Workshift-Tabs/routes/WorkShiftTabRoutes";
import Dashboard from "./pages/dashboard";
import DashboardTabRoutes from "./pages/dashboard/routes/DashboardTabRoutes";
import PasswordForm from "./pages/profile/password-form";
import ProfileTabs from "./pages/profile/profile-Tabs";
import ProfileTabRoutes from "./pages/profile/profile-Tabs/routes/ProfileTabRoutes";

const admin_routes = [
  {
    type: "authentication",
    name: "entelligence-auth",
    path: urls.entelligence,
    isAdmin: true,
    element: <Authorization type={["entelligence"]} />,
    routes_inside: [
      /**Dashboard */
      {
        type: "nested",
        name: "dashboard",
        path: urls.entelligence_dashboard,
        display: true,
        label: "sideNav.Dashboard",
        icon: <DashboardIcon color={colors.pure_white} />,
        element: <Dashboard isEntelligence />,
        routes_inside: [
          {
            type: "route",
            name: "dashboard",
            path: urls.entelligence_dashboard,

            element: <DashboardTabRoutes value={3} isEntelligence />,
          },

          {
            type: "route",
            name: "dashboard",
            path: urls.entelligence_dashboard_project,
            element: <DashboardTabRoutes value={2} isEntelligence />,
          },
        ],
      },

      {
        type: "route",
        name: "admin-profile",
        path: urls.admin_profile_form_days_used,
        element: <DaysUsedHistory fromProfile />,
      },

      {
        type: "nested",
        name: "admin-profile",
        path: urls.admin_profile,
        element: <ProfileTabs fromTenant />,
        routes_inside: [
          {
            type: "route",
            name: "admin-profile",
            path: urls.admin_profile,
            element: <ProfileTabRoutes value={0} fromTenant />,
          },
          {
            type: "route",
            name: "admin-profile",
            path: urls.admin_ptos,
            element: <ProfileTabRoutes value={1} fromTenant />,
          },
          {
            type: "route",
            name: "admin-profile",
            path: urls.admin_skills,
            element: <ProfileTabRoutes value={2} fromTenant />,
          },
          {
            type: "route",
            name: "my-profile",
            path: urls.admin_teams,
            element: <ProfileTabRoutes value={4} fromTenant />,
          },
        ],
      },

      {
        type: "route",
        name: "admin-profile",
        path: urls.admin_change_password,
        element: <PasswordForm />,
      },
      {
        type: "route",
        name: "tenants",
        path: urls.tenant_list,
        element: <TenantList />,
        display: (role) => true,
        label: "sideNav.Tenants",
        icon: <TenantIcon color={colors.pure_white} width="24" height="24" />,
      },
      /**Admin Configurations */
      {
        type: "collapse",
        name: "configurations",
        display: (role) => (role && !role.isEntelligence ? true : false),
        label: "sideNav.AdminConfig",
        icon: <ConfigIcon color={colors.pure_white} />,
        inline_routes: [
          {
            type: "route",
            name: "tenants",
            path: urls.new_tenant,
            element: <TenantForm />,
          },

          {
            type: "nested",
            name: "tenants",
            path: urls.tenant_tabs,
            element: <TenantTabs />,
            routes_inside: [
              {
                type: "route",
                name: "tenants",
                path: urls.tenant_form,
                element: <TenantTabRoutes fromTenant value={0} />,
              },
              {
                type: "authentication",
                name: "entelligence-company-auth",
                path: urls.tenant_licenses,
                display: "manageParameters",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[
                      new RolePermissions("manageParameters", ["M", "R"]),
                    ]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.tenant_licenses,
                    element: <TenantTabRoutes fromTenant value={1} />,
                  },
                ],
              },
              {
                type: "authentication",
                name: "entelligence-company-auth",
                path: urls.tenant_companies,
                display: "manageParameters",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[
                      new RolePermissions("manageParameters", ["M", "R"]),
                    ]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.tenant_companies,
                    element: <TenantTabRoutes fromTenant value={2} />,
                  },
                ],
              },
              {
                type: "authentication",
                name: "entelligence-projects-auth",
                path: urls.employees_list,
                display: "manageUsers",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[new RolePermissions("manageUsers", ["M", "R"])]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.employees_list,
                    element: <TenantTabRoutes fromTenant value={3} />,
                  },
                ],
              },
              {
                type: "route",
                name: "tenants",
                path: urls.tenant_management,
                element: <TenantTabRoutes fromTenant value={4} />,
              },
            ],
          },

          /**Locations */
          {
            type: "authentication",
            name: "entelligence-locations-auth",
            path: urls.tenant_locations,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "authentication",
                name: "entelligence-tenant-locations-auth",
                path: urls.new_tenant_location,
                display: "manageSystemConfigurations",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[
                      new RolePermissions("manageSystemConfigurations", ["M"]),
                    ]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.new_tenant_location,
                    element: <LocationForm fromTenant />,
                  },
                ],
              },

              {
                type: "nested",
                name: "tenants",
                path: urls.tenant_locations_tabs,
                element: <LocationTabs fromTenant />,
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.tenant_locations_form,
                    element: <LocationsTabRoutes value={0} fromTenant />,
                  },
                ],
              },
            ],
          },

          /**Companies */
          {
            type: "authentication",
            name: "entelligence-company-auth",
            path: urls.tenant_companies,
            display: "manageParameters",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageParameters", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "authentication",
                name: "entelligence-tenant-new-company-auth",
                path: urls.tenant_new_company,
                display: "manageParameters",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[new RolePermissions("manageParameters", ["M"])]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.tenant_new_company,
                    element: <CompanyForm />,
                  },
                ],
              },

              {
                type: "nested",
                name: "tenants",
                path: urls.tenant_company_tabs,
                element: <CompanyTabs fromTenant />,
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.tenant_company_form,
                    element: <CompanyTabRoutes value={0} />,
                  },
                ],
              },
            ],
          },

          /**Roles */
          {
            type: "authentication",
            name: "entelligence-roles-auth",
            path: urls.tenant_roles,
            display: "manageParameters",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageParameters", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "authentication",
                name: "entelligence-tenant-roles-auth",
                path: urls.new_tenant_role,
                display: "manageParameters",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[new RolePermissions("manageParameters", ["M"])]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.new_tenant_role,
                    element: <RoleForm fromTenant />,
                  },
                ],
              },
              {
                type: "nested",
                name: "tenants",
                path: urls.tenant_role_tabs,
                element: <RoleTabs fromTenant />,
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.tenant_role_form,
                    element: <RoleTabRoutes value={0} />,
                  },
                ],
              },
            ],
          },

          /**Requests */
          {
            type: "authentication",
            name: "admin-request-auth",
            path: urls.tenant_request,
            display: "manageAttendance",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageAttendance", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "nested",
                name: "requests",
                path: urls.tenant_request_list,
                label: "sideNav.tenantRequests",
                display: true,
                element: <RequestList fromTenant />,
                routes_inside: [
                  {
                    type: "route",
                    name: "requests",
                    path: urls.tenant_request_list,
                    element: <RequestListTabRoutes fromTenant value={0} />,
                  },
                  {
                    type: "route",
                    name: "requests",
                    path: urls.tenant_requests_offline,
                    element: <RequestListTabRoutes fromTenant value={1} />,
                  },
                ],
              },
            ],
          },
          {
            type: "authentication",
            name: "entelligence-requests-auth",
            path: urls.tenant_request_tabs,
            display: "manageAttendance",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageAttendance", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "nested",
                name: "tenants",
                path: urls.tenant_request_tabs,
                element: <RequestTabs fromTenant />,
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.tenant_request_form,
                    element: <RequestTabRoutes value={0} fromTenant />,
                  },
                ],
              },
            ],
          },
          /**Workshift */
          {
            type: "authentication",
            name: "entelligence-workshifts-auth",
            path: urls.tenant_work_shifts,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "authentication",
                name: "entelligence-new-workshifts-auth",
                path: urls.new_tenant_workshift,
                display: "manageSystemConfigurations",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[
                      new RolePermissions("manageSystemConfigurations", ["M"]),
                    ]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.new_tenant_workshift,
                    element: <WorkShiftForm />,
                  },
                ],
              },

              {
                type: "nested",
                name: "tenants",
                path: urls.tenant_workshift_tabs,
                element: <WorkShiftTabs fromTenant />,
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.tenant_workshift_form,
                    element: <WorkShiftTabRoutes value={0} />,
                  },
                ],
              },
            ],
          },

          /**Projects */
          {
            type: "authentication",
            name: "entelligence-projects-auth",
            path: urls.tenant_project,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "projects",
                path: urls.tenant_project,
                label: "sideNav.Projects",
                element: <ProjectsList fromTenant />,
                display: (role) => true,
              },
            ],
          },
          {
            type: "authentication",
            name: "entelligence-projects-auth",
            path: urls.tenant_project,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "authentication",
                name: "entelligence-tenant-projects-auth",
                path: urls.new_tenant_project,
                display: "manageTimeEntry",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[new RolePermissions("manageTimeEntry", ["M"])]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "projects",
                    path: urls.new_tenant_project,
                    element: <ProjectForm fromTenant />,
                  },
                ],
              },

              {
                type: "nested",
                name: "projects",
                path: urls.tenant_project_tabs,
                element: <ProjectTabs fromTenant />,
                routes_inside: [
                  {
                    type: "route",
                    name: "projects",
                    path: urls.tenant_project_form,
                    element: <ProjectsTabRoutes value={0} fromTenant />,
                  },
                  {
                    type: "authentication",
                    name: "entelligence-phase-auth",
                    path: urls.tenant_project_tickets,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "projects",
                        path: urls.tenant_project_tickets,
                        element: <ProjectsTabRoutes value={1} fromTenant />,
                      },
                    ],
                  },
                  {
                    type: "authentication",
                    name: "entelligence-task-auth",
                    path: urls.tenant_project_direct_tasks,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "projects",
                        path: urls.tenant_project_direct_tasks,
                        element: <ProjectsTabRoutes value={2} fromTenant />,
                      },
                    ],
                  },
                ],
              },
            ],
          },

          /**Personal Time Offs */
          {
            type: "authentication",
            name: "entelligence-PTO-auth",
            path: urls.tenant_ptos,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "nested",
                name: "tenants",
                path: urls.tenant_ptos_tabs,
                element: <PTOTabs fromTenant />,
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.tenant_ptos_form,
                    element: <PTOTabRoutes value={0} fromTenant />,
                  },
                ],
              },
              {
                type: "authentication",
                name: "entelligence-tenant-new-role-auth",
                path: urls.new_tenant_pto,
                display: "manageSystemConfigurations",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[
                      new RolePermissions("manageSystemConfigurations", ["M"]),
                    ]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.new_tenant_pto,
                    element: <PTOForm fromTenant />,
                  },
                ],
              },
            ],
          },

          /**Official Holidays */
          {
            type: "authentication",
            name: "entelligence-official-holiday-auth",
            path: urls.tenant_official_holidays,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M", "R"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "nested",
                name: "tenants",
                path: urls.tenant_official_holidays_tabs,
                element: <OfficialHolidaysTabs fromTenant />,
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.tenant_official_holidays_form,
                    element: <OfficialHolidayTabRoutes value={0} fromTenant />,
                  },
                ],
              },
            ],
          },
          {
            type: "authentication",
            name: "entelligence-new-official-holiday-auth",
            path: urls.new_tenant_official_holidays,
            display: "manageSystemConfigurations",
            element: (
              <Authorization
                type={["roles"]}
                roles={[
                  new RolePermissions("manageSystemConfigurations", ["M"]),
                ]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "tenants",
                path: urls.new_tenant_official_holidays,
                element: <OfficialHolidayForm fromTenant />,
              },
            ],
          },

          /**Employees */

          {
            type: "authentication",
            name: "entelligence-auth",
            path: urls.employees,
            display: "manageUsers",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageUsers", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "authentication",
                name: "entelligence-new-employee-auth",
                path: urls.new_employee,
                display: "manageUsers",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[new RolePermissions("manageUsers", ["M"])]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "employees",
                    path: urls.new_employee,
                    element: <EmployeeForm fromTenant />,
                  },
                ],
              },
              {
                type: "route",
                name: "employees",
                path: urls.employee_form_days_used,
                element: <DaysUsedHistory fromTenant />,
              },
              {
                type: "nested",
                name: "employees",
                path: urls.employee_tabs,
                element: <EmployeeTabs fromTenant />,
                routes_inside: [
                  {
                    type: "route",
                    name: "employees",
                    path: urls.employee_form,
                    element: <EmployeeTabRoutes value={0} fromTenant />,
                  },
                  {
                    type: "route",
                    name: "employees",
                    path: urls.employee_reference,
                    element: <EmployeeTabRoutes value={1} fromTenant />,
                  },
                  {
                    type: "route",
                    name: "employees",
                    path: urls.employee_ptos,
                    element: <EmployeeTabRoutes value={2} fromTenant />,
                  },
                  {
                    type: "route",
                    name: "employees",
                    path: urls.employee_skills,
                    element: <EmployeeTabRoutes value={3} fromTenant />,
                  },
                  {
                    type: "route",
                    name: "employees",
                    path: urls.employee_teams,
                    element: <EmployeeTabRoutes value={4} fromTenant />,
                  },
                ],
              },
            ],
          },
          /**Employee Reference */
          {
            type: "authentication",
            name: "entelligence-phase-auth",
            path: urls.employee_reference,
            display: "manageUsers",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageUsers", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "nested",
                name: "phases",
                path: urls.employee_reference_tabs,
                element: <EmployeeReferencesTabs fromTenant />,
                routes_inside: [
                  {
                    type: "route",
                    name: "phases",
                    path: urls.employee_reference_form,
                    element: (
                      <EmployeeReferenceTabRoutes value={0} fromTenant />
                    ),
                  },
                ],
              },
            ],
          },
          {
            type: "authentication",
            name: "entelligence-new-phase-auth",
            path: urls.new_employee_reference,
            display: "manageUsers",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageUsers", ["M"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "phases",
                path: urls.new_employee_reference,
                element: <EmployeeReferencesForm fromTenant />,
              },
            ],
          },
          /**Tickets */ {
            type: "authentication",
            name: "entelligence-phase-auth",
            path: urls.tenant_project_tickets,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "nested",
                name: "phases",
                path: urls.tenant_project_tickets_tabs,
                element: <TicketTabs fromTenant />,
                routes_inside: [
                  {
                    type: "route",
                    name: "phases",
                    path: urls.tenant_project_tickets_form,
                    element: <TicketsTabRoutes value={0} fromTenant />,
                  },
                  {
                    type: "authentication",
                    name: "entelligence-task-auth",
                    path: urls.tenant_project_tasks,
                    display: "manageTimeEntry",
                    element: (
                      <Authorization
                        type={["roles"]}
                        roles={[
                          new RolePermissions("manageTimeEntry", ["M", "R"]),
                        ]}
                      />
                    ),
                    routes_inside: [
                      {
                        type: "route",
                        name: "projects",
                        path: urls.tenant_project_tasks,
                        element: <TicketsTabRoutes value={1} fromTenant />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: "authentication",
            name: "entelligence-new-phase-auth",
            path: urls.tenant_new_project_ticket,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M"])]}
              />
            ),
            routes_inside: [
              {
                type: "route",
                name: "phases",
                path: urls.tenant_new_project_ticket,
                element: <TicketForm fromTenant />,
              },
            ],
          },
          /**Tasks */
          {
            type: "authentication",
            name: "entelligence-task-auth",
            path: urls.tenant_project_tasks,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "nested",
                name: "tenants",
                path: urls.tenant_project_tasks_tabs,
                element: <TaskTabs fromTenant />,
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.tenant_project_tasks_form,
                    element: <TasksTabRoutes value={0} fromTenant />,
                  },
                ],
              },
              {
                type: "authentication",
                name: "entelligence-tenant-new-task-auth",
                path: urls.tenant_new_project_task,
                display: "manageTimeEntry",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[new RolePermissions("manageTimeEntry", ["M"])]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.tenant_new_project_task,
                    element: <TaskForm fromTenant />,
                  },
                ],
              },
              {
                type: "authentication",
                name: "entelligence-tenant-new-task-auth",
                path: urls.tenant_new_project_subtask,
                display: "manageTimeEntry",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[new RolePermissions("manageTimeEntry", ["M"])]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.tenant_new_project_subtask,
                    element: <TaskForm fromTenant subtask />,
                  },
                ],
              },
            ],
          },

          {
            type: "authentication",
            name: "entelligence-task-auth",
            path: urls.tenant_project_direct_tasks,
            display: "manageTimeEntry",
            element: (
              <Authorization
                type={["roles"]}
                roles={[new RolePermissions("manageTimeEntry", ["M", "R"])]}
              />
            ),
            routes_inside: [
              {
                type: "nested",
                name: "tenants",
                path: urls.tenant_project_direct_tasks_tabs,
                element: <TaskTabs fromTenant fromProject />,
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.tenant_project_direct_tasks_form,
                    element: (
                      <TasksTabRoutes value={0} fromTenant fromProject />
                    ),
                  },
                ],
              },
              {
                type: "authentication",
                name: "entelligence-tenant-new-task-auth",
                path: urls.tenant_direct_new_project_task,
                display: "manageTimeEntry",
                element: (
                  <Authorization
                    type={["roles"]}
                    roles={[new RolePermissions("manageTimeEntry", ["M"])]}
                  />
                ),
                routes_inside: [
                  {
                    type: "route",
                    name: "tenants",
                    path: urls.tenant_direct_new_project_task,
                    element: <TaskForm fromTenant fromProject />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: "route",
        name: "users",
        path: urls.all_users,
        display: (role) => (role && role.isEntelligence ? true : false),
        label: "sideNav.users",
        element: <Users />,
        icon: <UsersIcon color={colors.pure_white} />,
      },
      {
        type: "route",
        name: "logs",
        display: (role) => (role && role.isEntelligence ? true : false),
        path: urls.logs_list,
        element: <LogsList fromTenant />,
        label: "sideNav.Logs",
        icon: <LogsIcon color={colors.pure_white} />,
      },
      {
        type: "route",
        name: "suggestions",
        display: (role) => (role && role.isEntelligence ? true : false),
        path: urls.suggestions_list,
        element: <Suggestions fromTenant />,
        label: "sideNav.suggestions",
        icon: <SuggestionsIcon color={colors.pure_white} width="24" height="24" />,
      },
      {
        type: "route",
        name: "releaseNotes",
        display: (role) => (role && role.isEntelligence ? true : false),
        path: urls.releaseNotes_list,
        element: <ReleaseNotesList />,
        label: "sideNav.release",
        icon: <ReleasesIcon color={colors.pure_white} width="24" height="24" />
      },

      {
        type: "route",
        name: "system-variables",
        display: (role) => (role && role.isEntelligence ? true : false),
        path: urls.systemVariables_List,
        element: <SystemVariablesList />,
        label: "sideNav.SystemVariables",
        icon: <SysVariablesIcon color={colors.pure_white} />,
      },
      {
        type: "route",
        name: "errors",
        display: (role) => (role && role.isEntelligence ? true : false),
        path: urls.errors,
        element: <Errors />,
        label: "sideNav.errors",
        icon: <ErrorsIcon color={colors.pure_white} />,
      },
      {
        type: "route",
        name: "help",
        display: (role) => (role && role.isEntelligence ? true : false),
        path: urls.help,
        element: <Help />,
        label: "sideNav.help",
        icon: <HelpIcon color={colors.pure_white} width="24" height="24" />,
      },
      {
        type: "route",
        name: "logDetails",
        display: false,
        path: urls.log_details,
        element: <LogDetails />,
        label: "sideNav.Log-Details",
      },
      {
        type: "route",
        name: "suggestionDetails",
        display: false,
        path: urls.suggestion_details,
        element: <SuggestionDetails fromDetails />,
        label: "sideNav.SuggestionDetails",
      },
      {
        type: "route",
        name: "releaseNotesDetails",
        display: false,
        path: urls.releaseNotes_details,
        element: <ReleaseNotesDetails fromDetails />,
        label: "sideNav.releaseNotesDetails",
      },
      {
        type: "route",
        name: "newReleaseNotes",
        display: false,
        path: urls.new_releaseNote,
        element: <ReleaseNotesDetails fromDetails />,
        label: "sideNav.newReleaseNote",
      },
    ],
  },
];

export default admin_routes;
