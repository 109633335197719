import { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import { DeleteIcon, EditPenIcon } from "../../components/Icon";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import ReactQuill from "react-quill-new";
import 'react-quill-new/dist/quill.snow.css';

function CommentCard({ key, comment, handleDelete, storeUserGuid, modules, formats, UpdateTaskCommentData, loading, t, editMode, setEditMode }) {
  const { taskCommentGuid, employee, description, createdAt, editedAt } =
    comment;
  const [content, setContent] = useState(false);
  // const [editMode, setEditMode] = useState(false);
  // const [newUpdatedComment, setNewUpdatedComment] = useState(description);

  const quillRef = useRef();

  useEffect(() => {
    const commentCard = document.querySelector(".comment-card");
    if(commentCard) {
      const allContainers = commentCard.querySelectorAll(".ql-container");
      const allToolbars = commentCard.querySelectorAll(".ql-toolbar");

      allToolbars.forEach(toolbar => {
        if (toolbar && toolbar.style) {
          toolbar.style.borderTopLeftRadius = "5px";
          toolbar.style.borderTopRightRadius = "5px";
        }
      });

      allContainers.forEach((container) => {
        if (container && container.style) {
          container.style.borderBottomLeftRadius = "5px";
          container.style.borderBottomRightRadius = "5px";
        }
      });
    }
  }, [editMode]);

  useEffect(() => {
    if (description) {
      const mentionRegex =
        /@\[(.*?)\]\(([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\)/g;
      setContent(description.replace(mentionRegex, "@$1"));
    }
  }, [description]);

  const handleQuillChange = (value) => {
    setContent(value);
  };

  const handleCancel = () => {
    setEditMode(null);
    if (description) {
      const mentionRegex =
        /@\[(.*?)\]\(([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\)/g;
      setContent(description.replace(mentionRegex, "@$1"));
      setEditMode(null)
    }
  };
  
  return (
    <div className="comment-card" style={{ width: "100%" }}>
      {editMode === taskCommentGuid ? (
        <>
          <ReactQuill
            ref={quillRef}
            // className="comment-quill"
            bounds={"#root"}
            name="body"
            value={content}
            onChange={handleQuillChange}
            modules={modules}
            formats={formats}
            placeholder={t("taskForm.comment.placeholder")}
          />
          <div className="comment-action-buttons">
            <Button
              variant="text"
              disabled={loading}
              onClick={handleCancel}
              size="small"
            >
              {t("dialog.cancel")}
            </Button>
            <Button
              variant="text"
              disabled={content == "" || loading}
              onClick={() => {
                setEditMode(taskCommentGuid);
                UpdateTaskCommentData(content, taskCommentGuid);
              }}
              size="small"
            >
              {t("dialog.save")}
            </Button>
          </div>
        </>
      ) : (
        <Card
          key={key}
          sx={{
            minWidth: "100%",
            maxHeight: "150px",
            overflowY: "auto",
            border: "1px solid #ccc !important",
            borderRadius: "5px",
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                sx={{ bgcolor: "red", width: "35px", height: "35px" }}
                aria-label="recipe"
              >
                {employee?.firstName?.substring(0, 1)}
              </Avatar>
            }
            action={
              storeUserGuid === employee?.employeeGuid && (
                <>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDelete(taskCommentGuid)}
                  >
                    <DeleteIcon color="red" width="18" height="18" />
                  </IconButton>
                  <IconButton
                    aria-label="edit"
                    onClick={() => setEditMode(taskCommentGuid)}
                  >
                    <EditPenIcon color="blue" width="18" height="18" />
                  </IconButton>
                </>
              )
            }
            title={employee?.firstName + " " + employee?.lastName}
            subheader={
              editedAt
                ? new Date(editedAt).toDateString()
                : new Date(createdAt).toDateString()
            }
            sx={{
              padding: "16px 16px 0 16px !important",
              "& .MuiCardHeader-title": {
                fontSize: "12px !important",
                fontWeight: "bold !important",
              },
              "& .MuiCardHeader-subheader": { fontSize: "11px !important" },
            }}
          />
          <CardContent>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: "13px !important" }}
            >
              {ReactHtmlParser(content)}
            </Typography>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

export default CommentCard;
