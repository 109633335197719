import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import useAuth from "../../../../hooks/useAuth";

import {
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Autocomplete,
} from "@mui/material";

import RoundedDatePicker from "../../../../components/DateField/RoundedDatePicker";
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import AutoComplete from "../../../../components/AutoComplete";
import Calendar from "./_calendar";

import {
  GET_EMPLOYEE_ATTENDANCE,
  GET_SINGLE_EMPLOYEE_ATTENDANCE,
} from "../../../../graphql/attendanceSheet";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";

import useQueryAuth from "../../../../hooks/useQueryAuth";
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";

import { useSnackbar } from "notistack";

import { EmployeeAttendanceSheetTable } from "./_table";

import colors from "../../../../assets/theme/light/colors";
import { CloseIcon, NextIcon, PreviousIcon } from "../../../../components/Icon";
import { handleValidateDates } from "../../../../utils/functions";
import RoundedTextField from "../../../../components/TextFields/RoundedTextField";
import AutoCompletePaper from "../../../../components/Paper/AutoCompletePaper";
import Tooltip from "../../../../components/Tooltip";
import { DATE_FILTER_DATA, handleDateBackward, handleDateForward, handleTimeBetween } from "../../../../utils/period";

import { v4 as uuidv4 } from "uuid";

const getDates = (startDate, endDate) => {
  let currentDate = new Date(startDate);
  const result = [];

  while (currentDate <= endDate) {
    result.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return result;
};

function EmployeeAttendanceSheet() {
  const { storeUserTenant, userRoles, user, storeUserGuid, attendanceEnabled } =
    useAuth();

  const { dark_logo_blue } = colors;

  const { t } = useTranslation();

  const data_filter = DATE_FILTER_DATA(t);

  const { enqueueSnackbar } = useSnackbar();

  const { manageAttendance } = userRoles;

  const [re, setRe] = useState(false);

  const nowDate = new Date();

  const to = React.useRef(nowDate);
  const firstDayOfWeek = new Date(nowDate);
  firstDayOfWeek.setDate(
    nowDate.getDate() - nowDate.getDay() + (nowDate.getDay() === 0 ? -6 : 1)
  );

  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  const [dateTo, setDateTo] = useState(lastDayOfWeek);

  const [dateFrom, setDateFrom] = useState(firstDayOfWeek);

  const [dateVal, setDateVal] = useState(
    data_filter.find((x) => x.mnemonic === "week")
  );

  const from = React.useRef(firstDayOfWeek);

  const modalRef = React.useRef(false);

  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [employeeID, setEmployeeID] = useState(
    user && attendanceEnabled ? user.user?.employeeGuid : null
  );

  const employeeValue = useRef(user && attendanceEnabled ? user.user : null);

  const [fetchEmployees, setFetchEmployees] = useState();

  const [timer, setTimer] = useState(null);
  const [list, setList] = useState(null);
  const [data, setData] = useState(null);

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      employeeValue.current = null;
      setEmployeeID(null);
      setOpenDialog(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const {
    data: employeeData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
        // attendanceDisabled: true,
      },
    },
    skip: !fetchEmployees,
    isWait: fetchEmployees,
  });

  const employeeList =
    employeeData?.GetCompanyUsersList?.usersList?.employees || [];

  const {
    mfunction: getData,
    data: attendanceData,
    loading: dataLoading,
  } = useLazyQueryAuth(GET_EMPLOYEE_ATTENDANCE, "GetEmployeesAttendance");

  const { mfunction: getEmployeeAttendanceData } = useLazyQueryAuth(
    GET_SINGLE_EMPLOYEE_ATTENDANCE,
    "GetSingleEmployeeDayLogs"
  );

  useEffect(() => {
    if (!employeeWait && attendanceData && employeeID === null)
      setList(
        attendanceData?.GetEmployeesAttendance?.employeesAttendance?.employees
      );
  }, [employeeWait, attendanceData]);

  function disableDates(date) {
    const oneDayBefore = new Date(dateFrom);
    oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
    return date < oneDayBefore;
  }

  useEffect(() => {
    const oneDayBefore = new Date(dateFrom);
    oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
    if (!employeeWait && !openDialog && oneDayBefore <= dateTo) {
      getData(
        {
          variables: {
            model: {
              userID: employeeID,
              tenantGuid: storeUserTenant,
              dateFrom: new Date(dateFrom).toDateString(),
              dateTo: new Date(dateTo).toDateString(),
              loggedInUserID: storeUserGuid,
            },
          },
          skip: employeeWait,
          isWait: true,
        },
        (response) => {
          if (response["GetEmployeesAttendance"].employeesAttendance != null) {
            setList(
              response?.GetEmployeesAttendance?.employeesAttendance?.employees
            );
            setData(null);
          } else
            enqueueSnackbar(
              t(response["GetEmployeesAttendance"].errorMessage),
              {
                variant: "error",
                autoHideDuration: 5000,
              }
            );
        }
      );
    }
  }, [employeeWait, re]);

  useEffect(() => {
    if (openDialog && dateFrom <= dateTo) {
      getEmployeeAttendanceData(
        {
          variables: {
            model: {
              userID: employeeID,
              tenantGuid: storeUserTenant,
              dateFrom: new Date(dateFrom).toDateString(),
              dateTo: new Date(dateTo).toDateString(),
              loggedInUserID: storeUserGuid,
            },
          },
          skip: employeeWait,
          isWait: true,
        },
        (response) => {
          if (
            response["GetSingleEmployeeDayLogs"].singleEmployeesAttendance !=
            null
          ) {
            setData(
              response?.GetSingleEmployeeDayLogs?.singleEmployeesAttendance
                ?.dayLogWithIcons
            );
          } else
            enqueueSnackbar(
              t(response["GetSingleEmployeeDayLogs"].errorMessage),
              {
                variant: "error",
                autoHideDuration: 5000,
              }
            );
        }
      );
    }
  }, [openDialog, re, dateFrom, dateTo]);

  // const handleValidateDates = (value) => {
  //   if (timer) {
  //     clearTimeout(timer);
  //     setTimer(null);
  //   }

  //   setTimer(
  //     setTimeout(() => {
  //       const oneDayBefore = new Date(dateFrom);
  //       oneDayBefore.setDate(oneDayBefore?.getDate() - 1);
  //       if (value < oneDayBefore)
  //         enqueueSnackbar(t("employeeTimesheet.invalid.date"), {
  //           variant: "error",
  //           autoHideDuration: 5000
  //         });
  //       else {
  //         setDateTo(value);
  //         to.current = value;
  //       }
  //     }, 500)
  //   );
  // };

  const handleOpenDialog = (id) => {
    modalRef.current = true;
    setEmployeeID(id);
    setOpenDialog(true);
    setDateFrom(from.current);
    setDateTo(to.current);

    getEmployeeAttendanceData(
      {
        variables: {
          model: {
            userID: employeeID,
            tenantGuid: storeUserTenant,
            dateFrom: new Date(from.current).toDateString(),
            dateTo: new Date(to.current).toDateString(),
          },
        },
        skip: employeeWait,
        isWait: true,
      },
      (response) => {
        if (
          response["GetSingleEmployeeDayLogs"].singleEmployeesAttendance != null
        ) {
          setData(
            response?.GetSingleEmployeeDayLogs?.singleEmployeesAttendance
              ?.dayLogWithIcons
          );
        } else
          enqueueSnackbar(
            t(response["GetSingleEmployeeDayLogs"].errorMessage),
            {
              variant: "error",
              autoHideDuration: 5000,
            }
          );
      }
    );
  };

  const handleCloseDialog = () => {
    if (employeeValue.current == null && employeeID != null)
      setEmployeeID(null);
    if (dateFrom != from.current) setDateFrom(from.current);
    if (dateTo != to.current) setDateTo(to.current);
    setOpenDialog(false);
  };

  const handleChangeDates = (start, end) => {
    if (start != dateFrom || end != dateTo) {
      setDateFrom(start);
      setDateTo(end);
    }
  };

  return (
    <>
      <Dialog
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        onClose={() => setOpenDialog(!openDialog)}
        open={openDialog}
      >
        <DialogTitle
          sx={{ display: "flex", justifyContent: "end", padding: "5px" }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon color={"#000000"} width={"20"} stroke={"1"} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "20px" }}>
          {data && (
            <Calendar
              events={data}
              t={t}
              handleChangeDates={handleChangeDates}
              startDate={dateFrom}
            />
          )}
        </DialogContent>
      </Dialog>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ContainerCard
          className="projects-card"
          sx={{
            width: "100%",
            padding: "10px",
            display: "grid",
            gridTemplateColumn: "1fr",
            backgroundColor: "transparent !important",
            boxShadow: "none !important",
            gap: "20px",
          }}
        >
          <Grid container spacing={1}>
            {manageAttendance !== "N" && (
              <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                <AutoComplete
                  loading={employeeLoading}
                  options={employeeList}
                  getOptionLabel={(option) =>
                    option ? option.firstName + " " + option.lastName : ""
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.firstName + " " + option.lastName}
                    </li>
                  )}
                  identifier={"employeeGuid"}
                  size="small"
                  value={employeeValue.current}
                  onOpen={() => setFetchEmployees(true)}
                  onChange={(event, value) => {
                    employeeValue.current = value;
                    setEmployeeID(value?.employeeGuid);
                  }}
                  sx={{
                    "& .MuiInputBase-input": { fontSize: "13px !important" },
                  }}
                  label={t("reports.label.employee")}
                  roundedTextField
                />
              </Grid>
            )}

            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
              <RoundedDatePicker
                className="date_picker_styles"
                label={t("projectDashboard.label.DateFrom")}
                value={dateFrom}
                // disableFuture
                onChange={(newValue) => {
                  setDateFrom(newValue);
                  from.current = newValue;
                  setDateVal(data_filter.find((x) => x.mnemonic === "custom"));
                }}
                onClose={() => setOpen(true)}
                // renderInput={(params) => (
                //   <RoundedTextField removeMinWidth {...params} />
                // )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
              <RoundedDatePicker
                className="date_picker_styles"
                label={t("projectDashboard.label.DateTo")}
                value={dateTo}
                shouldDisableDate={disableDates}
                autoFocus={open}
                open={open}
                onClose={() => setOpen(false)}
                onClick={() => setOpen(!open)}
                onOpen={() => setOpen(!open)}
                onChange={(newValue) => {
                  handleValidateDates(
                    timer,
                    setTimer,
                    dateFrom,
                    newValue,
                    setDateTo,
                    enqueueSnackbar,
                    t
                  );
                  setDateVal(data_filter.find((x) => x.mnemonic === "custom"));
                }}
                // renderInput={(params) => (
                //   <RoundedTextField removeMinWidth {...params} />
                // )}
              />
            </Grid>
            <Tooltip title={t("tooltip.PreviousPeriod")}>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <PreviousIcon
                  color={dateVal.mnemonic === "custom" ? "#73717380" : "#737173"}
                  onClick={() => {
                    if (dateVal.mnemonic !== "custom")
                      handleDateBackward(
                        dateFrom,
                        dateTo,
                        dateVal,
                        setDateFrom,
                        setDateTo
                      );
                  }}
                />
              </Grid>
            </Tooltip>
            <Grid item xs={12} sm={4} md={1.5} lg={1.5} xl={1.5}>
              <Autocomplete
                options={data_filter}
                getOptionLabel={(option) => (option ? t(option.name) : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {t(option.name)}
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  value == "" || option.mnemonic === value.mnemonic
                }
                size="small"
                disableClearable
                value={dateVal}
                onChange={(event, value) => {
                  setDateVal(value);
                  handleTimeBetween(value.mnemonic, setDateFrom, setDateTo);
                }}
                fullWidth
                PaperComponent={(props) => <AutoCompletePaper {...props} />}
                renderInput={(params) => (
                  <RoundedTextField
                    label={t("dateFilter.label")}
                    size="small"
                    removeMinWidth
                    {...params}
                  />
                )}
                sx={{".MuiInputBase-input": {fontSize: "14px !important"}}}
              />
            </Grid>
            <Tooltip title={t("tooltip.NextPeriod")}>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <NextIcon 
                  color={dateVal.mnemonic === "custom" ? "#73717380" : "#737173"}
                  onClick={() => {
                    if (dateVal.mnemonic !== "custom")
                      handleDateForward(
                        dateFrom,
                        dateTo,
                        dateVal,
                        setDateFrom,
                        setDateTo
                      );
                  }}
                />
              </Grid>
            </Tooltip>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <IALoadingButton
                onClick={() => {
                  setRe((val) => !val);
                  if (employeeValue.current == null) setEmployeeID(null);
                  modalRef.current = false;
                }}
                style={{
                  width: "150px",
                  backgroundColor: dark_logo_blue,
                  borderRadius: "50px",
                  height: "36px",
                }}
                label={t("projectDashboard.Search")}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ overflowX: "auto" }}
          >
            {dataLoading && (
              <div
                style={{
                  color: "grey",
                  textAlign: "center",
                  padding: "5px",
                  fontFamily: "poppins  !important",
                }}
              >
                <CircularProgress />
              </div>
            )}
            {!dataLoading && list && list?.length > 0 && (
              <EmployeeAttendanceSheetTable
                data={list}
                dateSummaries={getDates(dateFrom, dateTo)}
                t={t}
                generateProjectRoute={""}
                handleOpenDialog={handleOpenDialog}
              />
            )}
            {!dataLoading && (!list || list?.length === 0) && (
              <div
                style={{
                  color: "grey",
                  textAlign: "center",
                  padding: "5px",
                  fontFamily: "poppins  !important",
                }}
              >
                {t("employeeAttendanceSheet.no.attendance")}
              </div>
            )}
          </Grid>
        </ContainerCard>
      </Grid>
    </>
  );
}

export default EmployeeAttendanceSheet;
