import urls from "./urls";

const breadcrumbs = (isManager, isAdmin) => {
  return {
    default: [],
    login: [],
    subscription: [],
    forgot_password: [],
    reset_password: [],
    verify_login: [],
    entelligence_dashboard: [{ label: "breadcrumbs.dashboard" }],
    logs_list: [{ label: "breadcrumbs.logs" }],
    suggestions_list: [{ label: "breadcrumbs.suggs" }],
    import_time: [
      { label: "breadcrumbs.importExportTime" },
      { label: "breadcrumbs.importTime" },
    ],
    export_time_by_resource: [
      { label: "breadcrumbs.importExportTime" },
      { label: "breadcrumbs.importTimeByResource" },
    ],
    excel_bc_excel: [
      { label: "breadcrumbs.importExportTime" },
      { label: "breadcrumbs.excelBCFormat" },
    ],
    excel_time_entries: [
      { label: "breadcrumbs.importExportTime" },
      { label: "breadcrumbs.importExportTimeEntries" },
    ],
    news_list: [{ label: "breadcrumbs.news" }],
    tenant_news_list: [{ label: "breadcrumbs.news" }],
    new_news: [
      { label: "breadcrumbs.newsList", route: () => urls.news_list },
      { label: "breadcrumbs.newsForm" },
    ],
    releaseNotes_list: [{ label: "breadcrumbs.releases" }],
    releaseNotes_details: [
      { label: "breadcrumbs.releases", route: () => urls.releaseNotes_list },
      {
        label: "{{rName}}",
      },
    ],
    user_releaseNotes_details: [
      {
        label: "breadcrumbs.releases",
      },
    ],
    new_releaseNote: [
      { label: "breadcrumbs.releases", route: () => urls.releaseNotes_list },
      { label: "breadcrumbs.newReleaseNote" },
    ],
    tenant_suggestions_list: [{ label: "breadcrumbs.suggs" }],

    project_backlog: [{ label: "breadcrumbs.backlog" }],
    project_backlog_new_task: [
      { label: "breadcrumbs.backlog", route: () => urls.project_backlog },
      { label: "breadcrumbs.newTask" },
    ],
    project_backlog_new_subtask: [
      { label: "breadcrumbs.backlog", route: () => urls.project_backlog },
      { label: "breadcrumbs.newSubTask" },
    ],
    project_backlog_tasks: [
      { label: "breadcrumbs.backlog", route: () => urls.project_backlog },
      { label: "{{tskName}}" },
    ],
    log_details: [
      { label: "breadcrumbs.logs", route: () => urls.logs_list },
      {
        label: "{{lName}}",
      },
    ],
    suggestion_details: [
      { label: "breadcrumbs.news", route: () => urls.news_list },
      {
        label: "{{sName}}",
      },
    ],
    news_details: [
      { label: "breadcrumbs.news", route: () => urls.news_list },
      {
        label: "{{nName}}",
      },
    ],

    tenant_suggestion_details: [
      { label: "breadcrumbs.suggs", route: () => urls.tenant_suggestions_list },
      {
        label: "{{sName}}",
      },
    ],
    my_logs_list: [{ label: "breadcrumbs.mylogs" }],
    systemVariables_List: [{ label: "breadcrumbs.systemVariables" }],
    errors: [{ label: "breadcrumbs.errors" }],
    help: [{ label: "breadcrumbs.help" }],
    areas: [{ label: "breadcrumbs.areas" }],

    my_log_details: [
      { label: "breadcrumbs.mylogsdetails", route: () => urls.my_logs_list },
      {
        label: "{{lName}}",
      },
    ],
    entelligence_dashboard_project: [
      {
        label: "breadcrumbs.dashboard",
        route: () => urls.entelligence_dashboard,
      },
      {
        label: "breadcrumbs.projectAnalytics",
      },
    ],
    tenant_list: [{ label: "breadcrumbs.tenants" }],
    tenant_tabs: [],
    tenant_form: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      { label: "{{tName}}" },
    ],
    tenant_management: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.tenantManagement" },
    ],
    tenant_companies: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.tenantCompanies" },
    ],
    tenant_licenses: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.tenantLicenses" },
    ],
    tenant_new_company: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantCompanies",
        route: ({ tName, tID }) =>
          urls.tenant_companies.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.newTenantCompany" },
    ],
    new_tenant: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      { label: "breadcrumbs.newTenant" },
    ],

    tenant_company_tabs: [],
    tenant_company_form: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantCompanies",
        route: ({ tName, tID }) =>
          urls.tenant_companies.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "{{cName}}" },
    ],
    roles_list: [{ label: "breadcrumbs.roles" }],
    tenant_roles: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.tenantRoles" },
    ],
    new_role: [
      { label: "breadcrumbs.roles", route: () => urls.roles_list },
      { label: "breadcrumbs.newRole" },
    ],
    new_tenant_role: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantRoles",
        route: ({ tName, tID }) =>
          urls.tenant_roles.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.newTenantRoles" },
    ],
    tenant_role_tabs: [],
    tenant_role_form: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantRoles",
        route: ({ tName, tID }) =>
          urls.tenant_roles.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "{{rName}}" },
    ],

    //kiosk devices
    kiosk_devices_list: [{ label: "breadcrumbs.kiosk" }],
    tenant_kiosk_devices: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.kiosk" },
    ],
    new_kiosk_device: [
      { label: "breadcrumbs.kiosk", route: () => urls.kiosk_devices_list },
      { label: "breadcrumbs.newKiosk" },
    ],
    new_tenant_kiosk_device: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.kiosk",
        route: ({ tName, tID }) =>
          urls.tenant_kiosk_devices.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.newkiosk" },
    ],
    tenant_kiosk_devices_tabs: [],
    tenant_kiosk_devices_form: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.kiosk",
        route: ({ tName, tID }) =>
          urls.tenant_kiosk_devices.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "{{kName}}" },
    ],

    tenant_ptos: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantPTOs",
      },
    ],
    tenant_ptos_tabs: [],
    tenant_ptos_form: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantPTOs",
        route: ({ tName, tID }) =>
          urls.tenant_ptos.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(
              encodeURIComponent(tName)
            )}/${encodeURIComponent(encodeURIComponent(tID))}`
          ),
      },
      {
        label: "{{ptoName}}",
      },
    ],
    new_tenant_pto: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantPTOs",
        route: ({ tName, tID }) =>
          urls.tenant_ptos.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(
              encodeURIComponent(tName)
            )}/${encodeURIComponent(encodeURIComponent(tID))}`
          ),
      },
      {
        label: "breadcrumbs.newTenantPTO",
      },
    ],

    tenant_work_shifts: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.tenantWorkShifts" },
    ],
    tenant_workshift_tabs: [],
    tenant_workshift_form: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantWorkShifts",
        route: ({ tName, tID }) =>
          urls.tenant_work_shifts.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "{{wsName}}" },
    ],

    departments_list: [{ label: "breadcrumbs.departments" }],
    tenant_departments: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.tenantDepartments" },
    ],
    new_department: [
      { label: "breadcrumbs.departments", route: () => urls.departments_list },
      { label: "breadcrumbs.newDepartment" },
    ],
    new_tenant_department: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantDepartments",
        route: ({ tName, tID }) =>
          urls.tenant_departments.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.newTenantDepartments" },
    ],
    tenant_department_tabs: [],
    tenant_departments_form: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantDepartments",
        route: ({ tName, tID }) =>
          urls.tenant_departments.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "{{dName}}" },
    ],
    new_tenant_workshift: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantWorkShifts",
        route: ({ tName, tID }) =>
          urls.tenant_work_shifts.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.newTenantWorkShift" },
    ],

    tenant_locations: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.tenantLocations" },
    ],
    tenant_locations_tabs: [],
    tenant_locations_form: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantLocations",
        route: ({ tName, tID }) =>
          urls.tenant_locations.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "{{lName}}" },
    ],
    new_tenant_location: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantLocations",
        route: ({ tName, tID }) =>
          urls.tenant_locations.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.newLocations" },
    ],

    tenant_project: [{ label: "breadcrumbs.tenantProjects" }],
    tenant_project_tabs: [],
    tenant_project_form: [
      {
        label: "breadcrumbs.tenantProjects",
        route: () => urls.tenant_project,
      },
      { label: "{{pName}}" },
    ],
    new_tenant_project: [
      {
        label: "breadcrumbs.tenantProjects",
        route: () => urls.tenant_project,
      },
      { label: "breadcrumbs.newProject" },
    ],
    tenant_project_tickets: [
      {
        label: "breadcrumbs.tenantProjects",
        route: () => urls.tenant_project,
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.tenant_project_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      { label: "breadcrumbs.tenantTickets" },
    ],
    tenant_project_tickets_tabs: [],
    tenant_project_tickets_form: [
      {
        label: "breadcrumbs.tenantProjects",
        route: () => urls.tenant_project,
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.tenant_project_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantTickets",
        route: ({ pName, pID }) =>
          urls.tenant_project_tickets.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      { label: "{{tkName}}" },
    ],
    tenant_new_project_ticket: [
      {
        label: "breadcrumbs.tenantProjects",
        route: () => urls.tenant_project,
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.tenant_project_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantTickets",
        route: ({ pName, pID }) =>
          urls.tenant_project_tickets.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      { label: "breadcrumbs.newTicket" },
    ],
    tenant_project_direct_tasks: [
      {
        label: "breadcrumbs.tenantProjects",
        route: () => urls.tenant_project,
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.tenant_project_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      { label: "breadcrumbs.tenantTasks" },
    ],
    tenant_project_direct_tasks_tabs: [],
    tenant_project_direct_tasks_form: [
      {
        label: "breadcrumbs.tenantProjects",
        route: () => urls.tenant_project,
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.tenant_project_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantTasks",
        route: ({ pName, pID }) =>
          urls.tenant_project_direct_tasks.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      { label: "{{tskName}}" },
    ],
    tenant_direct_new_project_task: [
      {
        label: "breadcrumbs.tenantProjects",
        route: () => urls.tenant_project,
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.tenant_project_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      { label: "breadcrumbs.newTask" },
    ],
    tenant_direct_new_project_subtask: [
      {
        label: "breadcrumbs.tenantProjects",
        route: () => urls.tenant_project,
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.tenant_project_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      { label: "breadcrumbs.newSubTask" },
    ],
    tenant_project_tasks: [
      {
        label: "breadcrumbs.tenantProjects",
        route: () => urls.tenant_project,
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.tenant_project_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantTickets",
        route: ({ pName, pID }) =>
          urls.tenant_project_tickets.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{tkName}}",
        route: ({ pName, pID, tkName, tkID }) =>
          urls.tenant_project_tickets_form
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            ),
      },
      { label: "breadcrumbs.tenantTasks" },
    ],
    tenant_new_project_task: [
      {
        label: "breadcrumbs.tenantProjects",
        route: () => urls.tenant_project,
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.tenant_project_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantTickets",
        route: ({ pName, pID }) =>
          urls.tenant_project_tickets.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{tkName}}",
        route: ({ pName, pID, tkName, tkID }) =>
          urls.tenant_project_tickets_form
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            ),
      },
      {
        label: "breadcrumbs.tenantTasks",
        route: ({ pName, pID, tkName, tkID }) =>
          urls.tenant_project_tasks
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            ),
      },
      { label: "breadcrumbs.newTask" },
    ],

    tenant_new_project_subtask: [
      {
        label: "breadcrumbs.tenantProjects",
        route: () => urls.tenant_project,
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.tenant_project_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantTickets",
        route: ({ pName, pID }) =>
          urls.tenant_project_tickets.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{tkName}}",
        route: ({ pName, pID, tkName, tkID }) =>
          urls.tenant_project_tickets_form
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            ),
      },
      {
        label: "breadcrumbs.tenantTasks",
        route: ({ pName, pID, tkName, tkID }) =>
          urls.tenant_project_tasks
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            ),
      },
      { label: "breadcrumbs.newSubTask" },
    ],

    tenant_project_tasks_form: [
      {
        label: "breadcrumbs.tenantProjects",
        route: () => urls.tenant_project,
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.tenant_project_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantTickets",
        route: ({ pName, pID }) =>
          urls.tenant_project_tickets.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{tkName}}",
        route: ({ pName, pID, tkName, tkID }) =>
          urls.tenant_project_tickets_form
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            ),
      },
      {
        label: "breadcrumbs.tenantTasks",
        route: ({ pName, pID, tkName, tkID }) =>
          urls.tenant_project_tasks
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            ),
      },
      { label: "{{tskName}}" },
    ],
    tenant_official_holidays: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantOfficialHolidays",
      },
    ],
    tenant_official_holidays_tabs: [],
    tenant_official_holidays_form: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantOfficialHolidays",
        route: ({ tName, tID }) =>
          urls.tenant_official_holidays.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "{{ohName}}",
      },
    ],
    new_tenant_official_holidays: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantOfficialHolidays",
        route: ({ tName, tID }) =>
          urls.tenant_official_holidays.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.newTenantOfficialHolidays",
      },
    ],
    users: [{ label: "breadcrumbs.users", route: () => urls.users }],
    all_users: [{ label: "breadcrumbs.users", route: () => urls.all_users }],
    employees_list: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.employees" },
    ],
    new_employee: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.employees",
        route: ({ tName, tID }) =>
          urls.employees_list.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.newemployee" },
    ],
    employee_form: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.employees",
        route: ({ tName, tID }) =>
          urls.employees_list.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "{{eName}}",
      },
    ],
    employee_form_days_used: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.employees",
        route: ({ tName, tID }) =>
          urls.employees_list.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "{{eName}}",
        route: ({ eName, eID, tName, tID }) =>
          urls.employee_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:eName/:eID",
              `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
            ),
      },
      {
        label: "breadcrumbs.employeeTimeOffs",
        route: ({ eName, eID, tName, tID }) =>
          urls.employee_ptos
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:eName/:eID",
              `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
            ),
      },
      {
        label: "{{duName}}",
      },
    ],
    my_companies_list: [
      {
        label: "breadcrumbs.myCompanies",
      },
    ],
    my_new_company: [
      {
        label: "breadcrumbs.myCompanies",
        route: () => urls.my_companies_list,
      },
      {
        label: "breadcrumbs.newCompany",
      },
    ],
    my_company_tabs: [],
    my_company_form: [
      {
        label: "breadcrumbs.myCompanies",
        route: () => urls.my_companies_list,
      },
      {
        label: "{{cName}}",
      },
    ],

    my_roles_list: [
      {
        label: "breadcrumbs.roles",
      },
    ],
    my_new_role: [
      {
        label: "breadcrumbs.roles",
        route: () => urls.my_roles_list,
      },
      {
        label: "breadcrumbs.newRole",
      },
    ],
    my_roles_tabs: [],
    my_roles_form: [
      {
        label: "breadcrumbs.roles",
        route: () => urls.my_roles_list,
      },
      {
        label: "{{rName}}",
      },
    ],

    //kiosk devices
    kiosk_devices_list: [
      {
        label: "breadcrumbs.kiosk",
      },
    ],
    new_kiosk_device: [
      {
        label: "breadcrumbs.kiosk",
        route: () => urls.kiosk_devices_list,
      },
      {
        label: "breadcrumbs.newkiosk",
      },
    ],
    kiosk_devices_tabs: [],
    kiosk_devices_form: [
      {
        label: "breadcrumbs.kiosk",
        route: () => urls.kiosk_devices_list,
      },
      {
        label: "{{kName}}",
      },
    ],

    my_departments_list: [
      {
        label: "breadcrumbs.departments",
      },
    ],
    my_new_department: [
      {
        label: "breadcrumbs.departments",
        route: () => urls.my_departments_list,
      },
      {
        label: "breadcrumbs.newDepartment",
      },
    ],
    my_departments_tabs: [],
    my_departments_form: [
      {
        label: "breadcrumbs.departments",
        route: () => urls.my_departments_list,
      },
      { label: "{{dName}}" },
    ],

    my_workshifts_list: [
      {
        label: "breadcrumbs.workShifts",
      },
    ],
    my_new_workshift: [
      {
        label: "breadcrumbs.workShifts",
        route: () => urls.my_workshifts_list,
      },
      {
        label: "breadcrumbs.newWorkShift",
      },
    ],
    my_workshifts_tabs: [],
    my_workshifts_form: [
      {
        label: "breadcrumbs.workShifts",
        route: () => urls.my_workshifts_list,
      },
      {
        label: "{{wsName}}",
      },
    ],

    my_employees_list: [
      {
        label: "breadcrumbs.myEmployees",
      },
    ],
    my_employee_tabs: [],
    my_employee_form: [
      {
        label: "breadcrumbs.myEmployees",
        route: () => urls.my_employees_list,
      },
      {
        label: "{{eName}}",
      },
    ],
    my_employee_form_days_used: [
      {
        label: "breadcrumbs.myEmployees",
        route: () => urls.my_employees_list,
      },
      {
        label: "{{eName}}",
        route: ({ eName, eID }) =>
          urls.my_employee_form.replace(
            "/:eName/:eID",
            `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
          ),
      },
      {
        label: "breadcrumbs.employeeTimeOffs",
        route: ({ eName, eID }) =>
          urls.my_employee_ptos.replace(
            "/:eName/:eID",
            `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
          ),
      },
      {
        label: "{{duName}}",
      },
    ],
    my_new_employee: [
      {
        label: "breadcrumbs.myEmployees",
        route: () => urls.my_employees_list,
      },
      {
        label: "breadcrumbs.myNewEmployees",
      },
    ],
    my_tenant: [
      {
        label: "breadcrumbs.myTenant",
      },
      {
        label: "{{tName}}",
      },
    ],
    my_tenant_licenses: [
      {
        label: "breadcrumbs.myTenant",
      },
      {
        label: "{{tName}}",
      },
      { label: "breadcrumbs.tenantLicenses" },
    ],
    my_profile: [
      {
        label: "breadcrumbs.myProfile",
      },
      {
        label: "{{profileName}}",
      },
    ],
    change_password: [
      {
        label: "breadcrumbs.myProfile",
        route: ({ profileID, profileName }) =>
          urls.my_profile
            .replace("/:profileName", `/${encodeURIComponent(profileName)}`)
            .replace("/:profileID", `/${encodeURIComponent(profileID)}`),
      },
      {
        label: "{{profileName}}",
        route: ({ profileID, profileName }) =>
          urls.my_profile
            .replace("/:profileName", `/${encodeURIComponent(profileName)}`)
            .replace("/:profileID", `/${encodeURIComponent(profileID)}`),
      },
      {
        label: "breadcrumbs.changePassword",
      },
    ],
    admin_profile: [
      {
        label: "breadcrumbs.myProfile",
      },
      {
        label: "{{profileName}}",
      },
    ],

    admin_ptos: [
      {
        label: "breadcrumbs.myProfile",
      },
      {
        label: "{{profileName}}",
      },
      {
        label: "breadcrumbs.myProfile.adminMyPTOs",
      },
    ],

    admin_profile_form_days_used: [
      {
        label: "breadcrumbs.myProfile",
      },
      {
        label: "{{profileName}}",
      },
      {
        label: "breadcrumbs.myProfile.adminMyPTOs",
        route: ({ profileName, profileID, tName, tID }) =>
          urls.admin_ptos.replace(
            "/:profileName/:profileID",
            `/${encodeURIComponent(profileName)}/${encodeURIComponent(profileID)}`
          ),
      },
      {
        label: "{{duName}}",
      },
    ],
    admin_skills: [
      {
        label: "breadcrumbs.myProfile",
      },
      {
        label: "{{profileName}}",
      },
      {
        label: "breadcrumbs.myProfile.adminMySkills",
      },
    ],

    admin_teams: [
      {
        label: "breadcrumbs.myProfile",
      },
      {
        label: "{{profileName}}",
      },
      {
        label: "breadcrumbs.myProfile.adminMyTeams",
      },
    ],

    admin_change_password: [
      {
        label: "breadcrumbs.myProfile",
        route: ({ profileID, profileName }) =>
          urls.my_profile
            .replace("/:profileName", `/${encodeURIComponent(profileName)}`)
            .replace("/:profileID", `/${encodeURIComponent(profileID)}`),
      },
      {
        label: "{{profileName}}",
        route: ({ profileID, profileName }) =>
          urls.my_profile
            .replace("/:profileName", `/${encodeURIComponent(profileName)}`)
            .replace("/:profileID", `/${encodeURIComponent(profileID)}`),
      },
      {
        label: "breadcrumbs.changePassword",
      },
    ],
    //for my profile "My Time Offs" and "My Skills"
    my_ptos2: [
      {
        label: "breadcrumbs.myProfile",
      },
      {
        label: "{{profileName}}",
      },
      {
        label: "breadcrumbs.myProfile.myPtos2",
      },
    ],
    my_profile_employee_form_days_used: [
      {
        label: "breadcrumbs.myProfile",
      },
      {
        label: "{{profileName}}",
      },
      {
        label: "breadcrumbs.myProfile.myPtos2",
        route: ({ profileName, profileID, tName, tID }) =>
          urls.my_ptos2.replace(
            "/:profileName/:profileID",
            `/${encodeURIComponent(profileName)}/${encodeURIComponent(profileID)}`
          ),
      },
      {
        label: "{{duName}}",
      },
    ],
    my_skills2: [
      {
        label: "breadcrumbs.myProfile",
      },
      {
        label: "{{profileName}}",
      },
      {
        label: "breadcrumbs.myProfile.mySkills2",
      },
    ],
    my_loaned_items: [
      {
        label: "breadcrumbs.myProfile",
      },
      {
        label: "{{profileName}}",
      },
      {
        label: "breadcrumbs.myProfile.myLoanedItems",
      },
    ],
    my_teams_profile: [
      {
        label: "breadcrumbs.myProfile",
      },
      {
        label: "{{profileName}}",
      },
      {
        label: "breadcrumbs.myProfile.myTeams",
      },
    ],

    my_locations_list: [
      {
        label: "breadcrumbs.myLocations",
      },
    ],
    my_locations_tabs: [],
    my_locations_form: [
      {
        label: "breadcrumbs.myLocations",
        route: () => urls.my_locations_list,
      },
      {
        label: "{{lName}}",
      },
    ],
    my_locations_wifi: [
      {
        label: "breadcrumbs.myLocations",
        route: () => urls.my_locations_list,
      },
      {
        label: "{{lName}}",
      },
    ],
    my_new_location: [
      {
        label: "breadcrumbs.myLocations",
        route: () => urls.my_locations_list,
      },
      {
        label: "breadcrumbs.myNewLocation",
      },
    ],
    my_projects_list: [
      {
        label: "breadcrumbs.myProjects",
      },
    ],
    favorite_projects: [
      {
        label: "breadcrumbs.favprolist",
      },
    ],

    my_project_tabs: [],
    my_project_form: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      { label: "{{eName}}" },
    ],
    my_new_project: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.myNewProject",
      },
    ],
    my_new_favorite_project: [
      {
        label: "breadcrumbs.favprolist",
        route: ({ pName, pID }) =>
          urls.favorite_projects.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.myNewProject",
      },
    ],
    my_favorite_projects_form: [
      {
        label: "breadcrumbs.favprolist",
        route: ({ pName, pID }) =>
          urls.favorite_projects.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
      },
    ],
    my_projects_tabs: [],
    my_favorite_projects_tabs: [],
    my_projects_form: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
      },
    ],
    my_project_tickets: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}
            /${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.myTickets",
      },
    ],
    my_new_project_ticket: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.myTickets",
        route: ({ pName, pID, tkName, tkID }) =>
          urls.my_project_tickets_list
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            ),
      },
      {
        label: "breadcrumbs.myNewTicket",
      },
    ],
    my_project_tickets_tabs: [],
    my_project_tickets_form: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{tkName}}",
      },
    ],
    my_project_tickets_list: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.myTickets",
      },
    ],
    my_project_tasks: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{tkName}}",
        route: ({ pName, pID, tkName, tkID }) =>
          urls.my_project_tickets_form
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            ),
      },
      {
        label: "breadcrumbs.myTasks",
      },
    ],
    my_new_project_task: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{tkName}}",
        route: ({ pName, pID, tkName, tkID }) =>
          urls.my_project_tickets_form
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            ),
      },
      {
        label: "breadcrumbs.myNewTask",
      },
    ],
    my_new_project_subtask: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{tkName}}",
        route: ({ pName, pID, tkName, tkID }) =>
          urls.my_project_tickets_form
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            ),
      },
      {
        label: "breadcrumbs.myNewSubTask",
      },
    ],
    my_project_tasks_form: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{tkName}}",
        route: ({ pName, pID, tkName, tkID }) =>
          urls.my_project_tickets_form
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            ),
      },
      {
        label: "{{tskName}}",
      },
    ],
    my_project_tasks_time_entries: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{tkName}}",
        route: ({ pName, pID, tkName, tkID }) =>
          urls.my_project_tickets_form
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            ),
      },
      {
        label: "{{tskName}}",
        route: ({ pName, pID, tkName, tkID, tskName, tskID }) =>
          urls.my_project_tasks_form
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:tkName/:tkID",
              `/${encodeURIComponent(tkName)}/${encodeURIComponent(tkID)}`
            )
            .replace(
              "/:tskName/:tskID",
              `/${encodeURIComponent(tskName)}/${encodeURIComponent(tskID)}`
            ),
      },
      {
        label: "breadcrumbs.timeEntries",
      },
    ],
    my_project_direct_tasks: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      { label: "breadcrumbs.backlog" },
    ],

    my_favorite_project_direct_tasks: [
      {
        label: "breadcrumbs.favprolist",
        route: ({ pName, pID }) =>
          urls.favorite_projects.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_favorite_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      { label: "breadcrumbs.backlog" },
    ],
    my_project_direct_tasks_tabs: [],
    my_project_direct_tasks_form: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      // {
      //   label: "breadcrumbs.myTasks",
      //   route: ({ pName, pID }) =>
      //     urls.my_project_direct_tasks.replace(
      //       "/:pName/:pID",
      //       `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
      //     ),
      // },
      { label: "{{tskName}}" },
    ],
    my_direct_new_project_task: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.myTasks",
        route: ({ pName, pID }) =>
          urls.my_project_direct_tasks.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      { label: "breadcrumbs.newTask" },
    ],
    my_direct_new_project_subtask: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.myTasks",
        route: ({ pName, pID }) =>
          urls.my_project_direct_tasks.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      { label: "breadcrumbs.newSubTask" },
    ],
    my_project_direct_tasks_time_entries: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{tskName}}",
        route: ({ pName, pID, tskName, tskID }) =>
          urls.my_project_direct_tasks_form
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:tskName/:tskID",
              `/${encodeURIComponent(tskName)}/${encodeURIComponent(tskID)}`
            ),
      },
      {
        label: "breadcrumbs.timeEntries",
      },
    ],
    project_plan: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.plan",
      },
    ],
    favorite_project_plan: [
      {
        label: "breadcrumbs.favprolist",
        route: ({ pName, pID }) =>
          urls.favorite_projects.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_favorite_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.plan",
      },
    ],
    project_area: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.areas",
      },
    ],
    invoices: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.invoices",
      },
    ],
    appliedInvoices: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },

      {
        label: "breadcrumbs.invoices",
        route: ({ iName, iID, pName, pID }) =>
          urls.invoices
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:iName/:iID",
              `/${encodeURIComponent(iName)}/${encodeURIComponent(iID)}`
            ),
      },
      {
        label: "breadcrumbs.appliedinvoices",
      },
    ],
    favorite_appliedInvoices: [
      {
        label: "breadcrumbs.favprolist",
        route: ({ pName, pID }) =>
          urls.favorite_projects.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_favorite_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },

      {
        label: "breadcrumbs.invoices",
        route: ({ iName, iID, pName, pID }) =>
          urls.favorite_invoices
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:iName/:iID",
              `/${encodeURIComponent(iName)}/${encodeURIComponent(iID)}`
            ),
      },
      {
        label: "breadcrumbs.appliedinvoices",
      },
    ],
    appliedInvoices: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },

      {
        label: "breadcrumbs.invoices",
        route: ({ iName, iID, pName, pID }) =>
          urls.invoices
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:iName/:iID",
              `/${encodeURIComponent(iName)}/${encodeURIComponent(iID)}`
            ),
      },
      {
        label: "breadcrumbs.appliedinvoices",
      },
    ],
    favorite_appliedInvoices: [
      {
        label: "breadcrumbs.favprolist",
        route: ({ pName, pID }) =>
          urls.favorite_projects.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_favorite_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },

      {
        label: "breadcrumbs.invoices",
        route: ({ iName, iID, pName, pID }) =>
          urls.favorite_invoices
            .replace(
              "/:pName/:pID",
              `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
            )
            .replace(
              "/:iName/:iID",
              `/${encodeURIComponent(iName)}/${encodeURIComponent(iID)}`
            ),
      },
      {
        label: "breadcrumbs.appliedinvoices",
      },
    ],
    printable_invoices: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.invoices",
        route: ({ pName, pID }) =>
          urls.invoices.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.printView",
      },
    ],
    favorite_project_area: [
      {
        label: "breadcrumbs.favprolist",
        route: ({ pName, pID }) =>
          urls.favorite_projects.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_favorite_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.areas",
      },
    ],
    favorite_invoices: [
      {
        label: "breadcrumbs.favprolist",
        route: ({ pName, pID }) =>
          urls.favorite_projects.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_favorite_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.invoices",
      },
    ],
    favorite_printable_invoices: [
      {
        label: "breadcrumbs.favprolist",
        route: ({ pName, pID }) =>
          urls.favorite_projects.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_favorite_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.invoices",
        route: ({ pName, pID }) =>
          urls.favorite_invoices.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.printView",
      },
    ],
    project_tree: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.tree",
      },
    ],
    project_members: [
      {
        label: "breadcrumbs.myProjects",
        route: ({ pName, pID }) =>
          urls.my_projects_list.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.projectMembers",
      },
    ],
    favorite_project_members: [
      {
        label: "breadcrumbs.favprolist",
        route: ({ pName, pID }) =>
          urls.favorite_projects.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "{{pName}}",
        route: ({ pName, pID }) =>
          urls.my_favorite_projects_form.replace(
            "/:pName/:pID",
            `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
          ),
      },
      {
        label: "breadcrumbs.projectMembers",
      },
    ],
    my_official_holidays: [{ label: "breadcrumbs.myOfficialHolidays" }],
    my_official_holidays_tabs: [],
    my_official_holidays_form: [
      {
        label: "breadcrumbs.myOfficialHolidays",
        route: () => urls.my_official_holidays,
      },
      { label: "{{ohName}}" },
    ],

    new_my_official_holidays: [
      {
        label: "breadcrumbs.myOfficialHolidays",
        route: () => urls.my_official_holidays,
      },
      {
        label: "breadcrumbs.newOfficialHoliday",
      },
    ],
    my_dashboard: [{ label: "breadcrumbs.myDashboard" }],

    my_dashboard_projects: [
      {
        label: "breadcrumbs.myDashboard",
        route: () => urls.my_dashboard,
      },
      {
        label: "breadcrumbs.projectAnalytics",
      },
    ],

    my_ptos: [
      {
        label: "breadcrumbs.myPTOs",
      },
    ],
    my_ptos_tabs: [],
    my_ptos_form: [
      {
        label: "breadcrumbs.myPTOs",
        route: () => urls.my_ptos,
      },
      {
        label: "{{ptoName}}",
      },
    ],
    new_my_pto: [
      {
        label: "breadcrumbs.myPTOs",
        route: () => urls.my_ptos,
      },
      {
        label: "breadcrumbs.newPTO",
      },
    ],
    onBoarding_tasks: [
      {
        label: "breadcrumbs.onBoarding",
      },
    ],
    onBoarding_task_form: [
      {
        label: "breadcrumbs.onBoarding",
        route: () => urls.onBoarding_tasks,
      },
      {
        label: "{{ptoName}}",
      },
    ],
    new_onBoarding_task: [
      {
        label: "breadcrumbs.onBoarding",
        route: () => urls.onBoarding_tasks,
      },
      {
        label: "breadcrumbs.newOnBoardingTask",
      },
    ],

    onBoarding_task_form: [
      {
        label: "breadcrumbs.onBoarding",
        route: () => urls.onBoarding_tasks,
      },

      {
        label: "{{obName}}",
      },
    ],

    my_skills_list: [
      {
        label: "breadcrumbs.mySkills",
      },
    ],

    my_teams_list: [
      {
        label: "breadcrumbs.myTeams",
      },
    ],

    my_teams_employees: [
      {
        label: "breadcrumbs.myTeams",
        route: () => urls.my_teams_list,
      },
      {
        label: "{{tName}}",
      },
    ],

    tenant_request_list: [
      {
        label: "breadcrumbs.request",
      },
    ],
    tenant_requests_offline: [
      {
        label: "breadcrumbs.request",
      },
    ],
    tenant_request_tabs: [],
    tenant_request_form: [
      {
        label: "breadcrumbs.request",
        route: ({ tName, tID }) =>
          urls.tenant_request_list.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "{{reqName}}",
      },
    ],

    running_tasks: [
      {
        label: "breadcrumbs.runningTasks",
      },
    ],

    import_output_time: [
      {
        label: "breadcrumbs.importExportTime",
      },
    ],

    running_attendances: [
      {
        label: "breadcrumbs.runningAttendances",
      },
    ],

    my_time_entries: [{ label: "breadcrumbs.timeEntries" }],
    my_attendances: [{ label: "breadcrumbs.attendances" }],
    my_timesheet: [{ label: "breadcrumbs.myTimesheet" }],
    my_contractors_list: [{ label: "breadcrumbs.myContractors" }],

    my_requests: [
      {
        label: "breadcrumbs.requests",
      },
    ],
    my_received_requests: [
      {
        label: "breadcrumbs.requests",
      },
    ],
    my_requests_offline: [
      {
        label: "breadcrumbs.requests",
      },
    ],
    my_requests_tabs: [],
    my_requests_form: [
      {
        label: "breadcrumbs.requests",
        route: () =>
        (isAdmin || isManager) &&
          window.location.href.includes("received")
            ? urls.my_received_requests
            : urls.my_requests,
      },
      {
        label: "{{reqName}}",
      },
    ],

    my_new_request: [
      {
        label: "breadcrumbs.requests",
        route: () => urls.my_requests,
      },
      {
        label: "breadcrumbs.newRequest",
      },
    ],
    my_attendance_requests_form: [
      {
        label: "breadcrumbs.requests",
        route: () =>
        (isAdmin || isManager) &&
          window.location.href.includes("received")
            ? urls.my_received_requests
            : urls.my_requests,
      },
      {
        label: "breadcrumbs.attendanceRequest",
        route: () => 
        (isAdmin || isManager) &&
          window.location.href.includes("received")
            ? urls.my_received_requests
            : urls.my_requests,
      },
      {
        label: "{{reqName}}",
      },
    ],
    my_new_attendance_request: [
      {
        label: "breadcrumbs.requests",
        route: () => urls.my_requests,
      },
      {
        label: "breadcrumbs.attendanceRequest",
        route: () => urls.my_requests,
      },
      {
        label: "breadcrumbs.newAttendanceRequest",
      },
    ],
    my_task_status_list: [
      {
        label: "breadcrumbs.myTaskStatus",
      },
    ],
    my_task_status: [
      {
        label: "breadcrumbs.myTaskStatus",
      },
    ],
    my_task_status_tabs: [],
    my_task_status_form: [
      {
        label: "breadcrumbs.myTaskStatus",
        route: () => urls.my_task_status_list,
      },
      {
        label: "{{sName}}",
      },
    ],
    my_new_task_status: [
      {
        label: "breadcrumbs.myTaskStatus",
        route: () => urls.my_task_status_list,
      },
      {
        label: "breadcrumbs.myNewTaskStatus",
      },
    ],
    my_ticket_status_list: [
      {
        label: "breadcrumbs.myTicketStatus",
      },
    ],
    my_ticket_status: [
      {
        label: "breadcrumbs.myTicketStatus",
      },
    ],
    my_ticket_status_tabs: [],
    my_ticket_status_form: [
      {
        label: "breadcrumbs.myTicketStatus",
        route: () => urls.my_ticket_status_list,
      },
      {
        label: "{{sName}}",
      },
    ],
    my_new_ticket_status: [
      {
        label: "breadcrumbs.myTicketStatus",
        route: () => urls.my_ticket_status_list,
      },
      {
        label: "breadcrumbs.myNewTicketStatus",
      },
    ],
    tenant_task_status: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "breadcrumbs.tenantTaskStatus",
      },
    ],
    tenant_task_status_list: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantTaskStatus",
      },
    ],
    tenant_task_status_form: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantTaskStatus",
        route: ({ tID, tName }) =>
          urls.tenant_task_status_list.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "{{sName}}",
      },
    ],
    tenant_new_task_status: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantTaskStatus",
        route: ({ tID, tName }) =>
          urls.tenant_task_status_list.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantNewTaskStatus",
      },
    ],
    tenant_ticket_status: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "breadcrumbs.tenantTicketStatus",
      },
    ],
    tenant_ticket_status_list: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantTicketStatus",
      },
    ],
    tenant_ticket_status_form: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantTicketStatus",
        route: ({ tID, tName }) =>
          urls.tenant_ticket_status_list.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "{{sName}}",
      },
    ],
    tenant_new_ticket_status: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantTicketStatus",
        route: ({ tID, tName }) =>
          urls.tenant_ticket_status_list.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.tenantNewTicketStatus",
      },
    ],

    tenant_skills_list: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.skills",
      },
    ],

    employee_reference: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.employees",
        route: ({ tName, tID }) =>
          urls.employees_list.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "{{eName}}",
        route: ({ eName, eID, tName, tID }) =>
          urls.employee_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:eName/:eID",
              `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
            ),
      },
      {
        label: "breadcrumbs.employeeReference",
      },
    ],
    employee_ptos: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.employees",
        route: ({ tName, tID }) =>
          urls.employees_list.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "{{eName}}",
        route: ({ eName, eID, tName, tID }) =>
          urls.employee_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:eName/:eID",
              `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
            ),
      },
      {
        label: "breadcrumbs.employeeTimeOffs",
      },
    ],

    employee_skills: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.employees",
        route: ({ tName, tID }) =>
          urls.employees_list.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "{{eName}}",
        route: ({ eName, eID, tName, tID }) =>
          urls.employee_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:eName/:eID",
              `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
            ),
      },
      {
        label: "breadcrumbs.employeeSkills",
      },
    ],

    employee_teams: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.employees",
        route: ({ tName, tID }) =>
          urls.employees_list.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "{{eName}}",
        route: ({ eName, eID, tName, tID }) =>
          urls.employee_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:eName/:eID",
              `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
            ),
      },
      {
        label: "breadcrumbs.employeeTeams",
      },
    ],
    employee_reference_form: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.employees",
        route: ({ tName, tID }) =>
          urls.employees_list.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "{{eName}}",
        route: ({ eName, eID, tName, tID }) =>
          urls.employee_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:eName/:eID",
              `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
            ),
      },
      {
        label: "breadcrumbs.employeeReference",
        route: ({ eName, eID, tName, tID }) =>
          urls.employee_reference
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:eName/:eID",
              `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
            ),
      },
      {
        label: "breadcrumbs.referenceInformation",
      },
    ],
    new_employee_reference: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "breadcrumbs.employees",
        route: ({ tName, tID }) =>
          urls.employees_list.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      {
        label: "{{eName}}",
        route: ({ eName, eID, tName, tID }) =>
          urls.employee_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:eName/:eID",
              `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
            ),
      },
      {
        label: "breadcrumbs.newEmployeeReference",
      },
    ],
    my_employee_reference_list: [
      { label: "breadcrumbs.employees", route: () => urls.my_employees_list },
      {
        label: "{{eName}}",
        route: ({ eName, eID }) =>
          urls.my_employee_form.replace(
            "/:eName/:eID",
            `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
          ),
      },
      {
        label: "breadcrumbs.employeeReference",
      },
    ],
    loaned_items: [
      { label: "breadcrumbs.employees", route: () => urls.my_employees_list },
      {
        label: "{{eName}}",
        route: ({ eName, eID }) =>
          urls.my_employee_form.replace(
            "/:eName/:eID",
            `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
          ),
      },
      {
        label: "breadcrumbs.employeeLoanedItems",
      },
    ],
    employee_onboarding_tasks: [
      { label: "breadcrumbs.employees", route: () => urls.my_employees_list },
      {
        label: "{{eName}}",
        route: ({ eName, eID }) =>
          urls.my_employee_form.replace(
            "/:eName/:eID",
            `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
          ),
      },
      {
        label: "breadcrumbs.onboardingtasks",
      },
    ],
    employee_projects_list: [
      { label: "breadcrumbs.employees", route: () => urls.my_employees_list },
      {
        label: "{{eName}}",
        route: ({ eName, eID }) =>
          urls.my_employee_form.replace(
            "/:eName/:eID",
            `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
          ),
      },
      {
        label: "breadcrumbs.projects",
      },
    ],
    my_employee_ptos: [
      { label: "breadcrumbs.employees", route: () => urls.my_employees_list },
      {
        label: "{{eName}}",
        route: ({ eName, eID }) =>
          urls.my_employee_form.replace(
            "/:eName/:eID",
            `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
          ),
      },
      {
        label: "breadcrumbs.employeeTimeOffs",
      },
    ],
    my_employee_skills: [
      { label: "breadcrumbs.employees", route: () => urls.my_employees_list },
      {
        label: "{{eName}}",
        route: ({ eName, eID }) =>
          urls.my_employee_form.replace(
            "/:eName/:eID",
            `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
          ),
      },
      {
        label: "breadcrumbs.employeeSkills",
      },
    ],
    my_employee_teams: [
      { label: "breadcrumbs.employees", route: () => urls.my_employees_list },
      {
        label: "{{eName}}",
        route: ({ eName, eID }) =>
          urls.my_employee_form.replace(
            "/:eName/:eID",
            `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
          ),
      },
      {
        label: "breadcrumbs.employeeTeams",
      },
    ],
    my_employee_reference_form: [
      { label: "breadcrumbs.employees", route: () => urls.my_employees_list },
      {
        label: "{{eName}}",
        route: ({ eName, eID, tName, tID }) =>
          urls.my_employee_form.replace(
            "/:eName/:eID",
            `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
          ),
      },
      {
        label: "breadcrumbs.employeeReference",
        route: ({ eName, eID, tName, tID }) =>
          urls.my_employee_reference_list
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:eName/:eID",
              `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
            ),
      },
      {
        label: "breadcrumbs.referenceInformation",
      },
    ],
    my_new_employee_reference: [
      { label: "breadcrumbs.employees", route: () => urls.my_employees_list },
      {
        label: "{{eName}}",
        route: ({ eName, eID, tName, tID }) =>
          urls.my_employee_form.replace(
            "/:eName/:eID",
            `/${encodeURIComponent(eName)}/${encodeURIComponent(eID)}`
          ),
      },
      {
        label: "breadcrumbs.newEmployeeReference",
      },
    ],
    my_employees_availability: [{ label: "breadcrumbs.employeesAvailability" }],
    admin_employees_availability: [
      { label: "breadcrumbs.tenants", route: () => urls.tenant_list },
      {
        label: "{{tName}}",
        route: ({ tName, tID }) =>
          urls.tenant_form.replace(
            "/:tName/:tID",
            `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
          ),
      },
      { label: "breadcrumbs.employeesAvailability" },
    ],
    my_timesheets_list: [{ label: "breadcrumbs.timesheets" }],
    my_approved_timesheets: [{ label: "breadcrumbs.timesheets" }],
    my_created_timesheets: [
      {
        label: "breadcrumbs.timesheets",
        route: () => urls.my_approved_timesheets,
      },
      { label: "breadcrumbs.newTimesheet" },
    ],
    my_approved_timesheet_form: [
      {
        label: "breadcrumbs.timesheets",
        route: () => urls.my_approved_timesheets,
      },
      { label: "{{tName}}" },
    ],
    my_timesheets_list_pending: [
      { label: "breadcrumbs.timesheets" },
      { label: "breadcrumbs.timesheetsPending" },
    ],
    my_timesheets_list_approved: [
      { label: "breadcrumbs.timesheets" },
      { label: "breadcrumbs.timesheetsApproved" },
    ],
    my_attendance_sheets_list_pending: [
      { label: "breadcrumbs.attendanceSheets" },
      { label: "breadcrumbs.attendanceSheetsPending" },
    ],
    my_attendance_sheets_list_approved: [
      { label: "breadcrumbs.attendanceSheets" },
      { label: "breadcrumbs.attendanceSheetsApproved" },
    ],
    reports_employee_timesheet: [{ label: "breadcrumbs.employeeTimesheet" }],
    reports_employee_attendance_sheet: [
      { label: "breadcrumbs.employeeAttendanceSheet" },
    ],
    reports_attendance_by_employee: [
      { label: "breadcrumbs.attendanceByEmployee" },
    ],
    reports_time_by_employee: [{ label: "breadcrumbs.timeByEmployee" }],
    reports_time_by_project: [{ label: "breadcrumbs.timeByProject" }],
    reports_time_by_contractor: [{ label: "breadcrumbs.timeByContractor" }],
    reports_time_by_external: [{ label: "breadcrumbs.timeByExternal" }],
    reports_excel: [{ label: "breadcrumbs.exportReport" }],
  }
};

export default breadcrumbs;
