import React, { useState, useEffect, useCallback } from "react";

//routing
import { useNavigate, useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import { GET_ALL_TO_REQUESTS } from "../../../../graphql/toRequest";
import {
  GET_ALL_REQUESTS,
  GET_ALL_REQUEST_STATUSES,
  GET_ALL_REQUEST_TYPES,
} from "../../../../graphql/requests";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress, ListItemIcon, Menu, MenuItem } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local, { UTC2Time } from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import colors from "../../../../assets/theme/light/colors";
import { getWorkType, TimeManagementIcon } from "../../../../components/Icon";

const PTOsList = ({ myRequests = false }) => {
  const { tID, tName, reqName, reqID } = useParams();

  const {
    my_new_request,
    my_requests_form,
    my_new_attendance_request,
    my_attendance_requests_form,
  } = urls;

  const { storeUserGuid, storeUserTenant, userRoles, isManager } = useAuth();

  const { manageAttendance, isAdmin } = userRoles;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [filterState, setFilterState] = useState([]);
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [fetchResourcesList, setFetchResourcesList] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [fetchRequestTypesList, setFetchRequestTypesList] = useState(false);
  const [requestStatusList, setRequestStatusList] = useState([]);
  const [fetchRequestStatusesList, setFetchRequestStatusesList] =
    useState(false);
  const [requestTypeList, setRequestTypeList] = useState([]);
  const [menuItems, setMenuItems] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenuItems = Boolean(menuItems);
  const handleClickMenu = (event) => {
    setMenuItems(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuItems(null);
  };

  const {
    data: employeesData,
    loading: employeeLoading,
    tokenChange: usersWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        userID: storeUserGuid,
      },
    },
    skip: !fetchResourcesList,
    wait: fetchResourcesList,
  });

  // useEffect(() => {
  //   if (myRequests) {
  //     const objectIndexEmployeeIDs = filterState.findIndex(
  //       (obj) => obj.property === "employeeIDs"
  //     );

  //     if (objectIndexEmployeeIDs < 0) {
  //       setDefaultFilters({
  //         ...defaultFilters,
  //         employeeIDs: [storeUserGuid],
  //       });
  //       setFilterState([
  //         ...filterState,
  //         { value: [storeUserGuid], property: "employeeIDs" },
  //       ]);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (employeesData)
      setEmployeeList(
        employeesData?.GetCompanyUsersList?.usersList?.employees || []
      );
  }, [employeesData]);

  const {
    data: requestStatusData,
    loading: requestStatusLoading,
    tokenChange: requestStatusWait,
  } = useQueryAuth(GET_ALL_REQUEST_STATUSES, "GetAllRequestStatuses", {
    skip: !fetchRequestStatusesList,
    isWait: fetchRequestStatusesList,
  });

  useEffect(() => {
    if (requestStatusData)
      setRequestStatusList(
        requestStatusData?.GetAllRequestStatuses?.requestStatus || []
      );
  }, [requestStatusData]);

  const {
    data: requestTypeData,
    loading: requestTypeLoading,
    tokenChange: requestWait,
  } = useQueryAuth(GET_ALL_REQUEST_TYPES, "GetAllRequestTypes", {
    skip: !fetchRequestTypesList,
    isWait: fetchRequestTypesList,
  });

  useEffect(() => {
    if (requestTypeData)
      setRequestTypeList(
        requestTypeData?.GetAllRequestTypes?.requestTypes || []
      );
  }, [requestTypeData]);

  const filtersObject = myRequests
    ? [
        {
          type: "date",
          label: "requests.list.filters.date",
          name: "date",
        },
        // {
        //   label: "requests.list.filters.requestType",
        //   name: "requestType",
        //   type: "multiple",
        //   getOptionLabel: (option) => (option ? option.description : ""),
        //   options: requestTypeList,
        //   access: "mnemonic",
        //   loading: requestTypeLoading,
        //   fetchData: setFetchRequestTypesList,
        // },
        {
          label: "requests.list.filters.requestStatus",
          name: "statusGuid",
          type: "multiple",
          getOptionLabel: (option) => (option ? option.description : ""),
          options: requestStatusList,
          access: "requestStatusGuid",
          loading: requestStatusLoading,
          fetchData: setFetchRequestStatusesList,
        },
      ]
    : [
        {
          label: "requests.list.filters.employees",
          name: "employeeIDs",
          type: "multiple",
          getOptionLabel: (option) =>
            option ? option.firstName + " " + option.lastName : "",
          options: employeeList,
          access: "employeeGuid",
          loading: employeeLoading,
          disabled: !isManager && !isAdmin ? true : false,
          disableClear: !isManager && !isAdmin ? true : false,
          fetchData: setFetchResourcesList,
        },
        {
          type: "date",
          label: "requests.list.filters.date",
          name: "date",
        },
        // {
        //   label: "requests.list.filters.requestType",
        //   name: "requestType",
        //   type: "multiple",
        //   getOptionLabel: (option) => (option ? option.description : ""),
        //   options: requestTypeList,
        //   access: "mnemonic",
        //   loading: requestTypeLoading,
        //   fetchData: setFetchRequestTypesList,
        // },
        {
          label: "requests.list.filters.requestStatus",
          name: "statusGuid",
          type: "multiple",
          getOptionLabel: (option) => (option ? option.description : ""),
          options: requestStatusList,
          access: "requestStatusGuid",
          loading: requestStatusLoading,
          fetchData: setFetchRequestStatusesList,
        },
      ];

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.PTO_Requests,
          userID: storeUserGuid,
        },
      },
    },
    {
      onCompleted: (response) => {
        const col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay, UTC2Time)
          );

          if (parsed.columns) {
            const cols = parsed.columns;
            if (myRequests === true) {
              cols.map((col) => {
                if (col.id === "Employee") col.visible = false;
                if (col.id === "Receiver") col.visible = true;
                return col;
              });
            } else {
              cols.map((col) => {
                if (col.id === "Employee") col.visible = true;
                if (col.id === "Receiver") col.visible = false;
                return col;
              });
            }
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: getAllRequests, loading } = useLazyQueryAuth(
    GET_ALL_REQUESTS,
    "GetAllRequestsList"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllRequests(
        {
          variables: {
            model: {
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyword: search,
              userID: storeUserGuid,
              filters: filters,
              myRequests: myRequests,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData, re]);

  const rows =
    data &&
    data.GetAllRequestsList &&
    data.GetAllRequestsList.requestsList &&
    data.GetAllRequestsList.requestsList.requests
      ? data.GetAllRequestsList.requestsList.requests
      : [];
  const numberOfRecords =
    data &&
    data.GetAllRequestsList &&
    data.GetAllRequestsList.requestsList &&
    data.GetAllRequestsList.requestsList.totalNumberOfRecords
      ? data.GetAllRequestsList.requestsList.totalNumberOfRecords
      : 0;

  const customButtons = [
    {
      label: "",
      // handleClick: () => navigate(my_new_request),
      handleClick: (e) => handleClickMenu(e),
      condition: selected?.length === 0 && myRequests,
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color: "var(--color--light-logo-blue)",
            fontSize: "28px !important",
          }}
        />
      ),
    },
  ];

  const renderAddTime = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        MenuListProps={{ sx: { py: 0, width: "230px" } }}
        open={openMenuItems}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        style={{ textAlign: "left" }}
        slotProps={{
          paper: {
            sx: {
              top: "12rem !important",
              backgroundColor: "white !important",
              boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
            },
          },
        }}
      >
        <div
          style={{
            textAlign: "left",
            backgroundColor: `${colors.grey_background} !important`,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <MenuItem
              className="user-menu-item-hover"
              style={{ paddingTop: "10px", paddingBottom: "10px !important" }}
              onClick={() => navigate(my_new_attendance_request)}
            >
              <ListItemIcon>
                <TimeManagementIcon color={"#2c3e50"} />
              </ListItemIcon>
              <span
                style={{
                  fontSize: "13px",
                  fontFamily: "Poppins",
                  marginTop: "5px",
                }}
              >
                {t("requests.list.label.attendance")}
              </span>
            </MenuItem>
            <MenuItem
              className="user-menu-item-hover"
              style={{ paddingTop: "10px", paddingBottom: "10px !important" }}
              onClick={() => navigate(my_new_request)}
            >
              <ListItemIcon>
                {getWorkType(Mnemonic.WorkTypes.timeoff, "22", "22")}
              </ListItemIcon>
              <span style={{ fontSize: "13px", fontFamily: "Poppins" }}>
                {t("requests.list.label.leave")}
              </span>
            </MenuItem>
          </div>
        </div>
      </Menu>
    );
  };

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "request.list.search.label",
    refetch,
    tableMnemonic: Mnemonic.Tables.PTO_Requests,
    filtersObject,
    generateRoute: (row) =>
      row?.attendanceGuid !== null
        ? my_attendance_requests_form.replace(
            "/:reqName/:reqID",
            `/${encodeURIComponent(
              row?.employee?.firstName +
                " " +
                row?.employee?.lastName
            )}/${encodeURIComponent(row?.requestGuid)}?${
              myRequests ? "sent" : "received"
            }`
          )
        : my_requests_form.replace(
            "/:reqName/:reqID",
            `/${encodeURIComponent(
              row?.employeePTO?.employee?.firstName +
                " " +
                row?.employeePTO?.employee?.lastName
            )}/${encodeURIComponent(row?.requestGuid)}?${
              myRequests ? "sent" : "received"
            }`
          ),
    hideEditColumns: false,
    readOnly: manageAttendance !== "M",
    customButtons: customButtons,
    heightClassName: "table-request-height",
  };

  return (
    <div>
      {renderAddTime()}
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable {...pagination} identifier={"toRequestGuid"} />
      )}
    </div>
  );
};

export default PTOsList;
