import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";

//components
import GridTable from "../../../../components/Tables/GridTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import {
  GET_TASK_STATUSES_LIST,
  DELETE_TASK_STATUS,
  UPDATE_TASK_STATUS,
  CREATE_TASK_STATUS,
  UPDATE_TASK_STATUSES_LIST,
  GET_STATUS_COLOR,
} from "../../../../graphql/taskStatus";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";
import "./style.css";

//external components
import { CircularProgress, MenuItem, Select } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import { CloseIcon, SaveIcon } from "../../../../components/Icon";
import { GET_ALL_TASK_STATES } from "../../../../graphql/taskSate";

const TaskStatusList = ({ fromTenant = false }) => {
  const { tID, pID } = useParams();

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const DEFAULT_STATUS = {
    taskStatusGuid: "",
    description: "",
    state: "",
    ordered: 0,
    tenantGuid: fromTenant ? tID : storeUserTenant,
  };

  const { manageSystemConfigurations } = userRoles;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const [selectedStatusColorGuid, setSelectedStatusColorGuid] = useState(null);
  const [selectedStateGuid, setSelectedStateGuid] = useState(null);
  const [rowsUpdated, setRowsUpdated] = useState(false);
  const editMode = React.useRef(false);
  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rows, setRows] = useState([]);
  const [max, setMax] = useState(0);
  const clickedRow = React.useRef(null);
  const clickedRowGuid = React.useRef("");

  const [newObj, setNewObj] = useState({
    taskStatusGuid: "",
    description: "",
    state: "",
    order: 0,
    tenantGuid: fromTenant ? tID : storeUserTenant,
  });
  const requiredFields = ["taskStatusGuid", "description", "State", "order"];

  const setCreate = React.useRef(false);
  const [changeOrder, setChangeOrder] = useState(false);

  const { mfunction: deleteRecord } = useMutationAuth(
    DELETE_TASK_STATUS,
    "DeleteTaskStatus"
  );

  const { data: statusColor, tokenChange: statusWait } = useQueryAuth(
    GET_STATUS_COLOR,
    "GetStatusColors"
  );

  const statusColorsList =
    statusColor &&
    statusColor.GetStatusColors &&
    statusColor.GetStatusColors.statusColors
      ? statusColor.GetStatusColors.statusColors
      : [];

  const { data: taskStates, tokenChange: taskStatesWait } = useQueryAuth(
    GET_ALL_TASK_STATES,
    "GetAllTaskStates",
    {
      skip: statusWait,
      isWait: true,
    }
  );

  const taskStatesList =
    taskStates &&
    taskStates.GetAllTaskStates &&
    taskStates.GetAllTaskStates.tasksState
      ? taskStates.GetAllTaskStates.tasksState
      : [];

  const { loading: getColumnsLoading, tokenChange: columnsWait } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables["PhaseStatuses"],
          userID: storeUserGuid,
        },
      },
      skip: statusWait || taskStatesWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        const col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) {
            let cols = parsed.columns;
            cols.push({
              field: "State",
              headerName: t("taskStatus.list.column.State"),
              headerAlign: "center",
              align: "center",
              order: 2,
              flex: 0.2,
              renderCell: (params) => {
                const disabled = editMode.current === true ||
                  (editMode.current === false && clickedRow.current === null) ||
                  (clickedRow.current !== "" &&
                    clickedRow.current !== undefined &&
                    clickedRow.current !== null &&
                    clickedRow.current.taskStatusGuid !== params.id) ||
                  (clickedRowGuid.current !== "" &&
                    clickedRowGuid.current !== undefined &&
                    clickedRowGuid.current !== null &&
                    clickedRowGuid.current !== params.id);
                return (
                  <div>
                    {taskStatesList.length > 0 && (
                      <Select
                        value={
                          params.row?.state?.taskStateGuid
                            ? params.row?.state?.taskStateGuid
                            : taskStatesList[0]?.taskStateGuid
                        }
                        onChange={(event) =>
                          handleStateChange(event, params.row, rows)
                        }
                        disabled={disabled}
                      >
                        {taskStatesList.map((stateObj) => (
                          <MenuItem
                            key={stateObj?.taskStateGuid}
                            value={stateObj?.taskStateGuid}
                          >
                            {stateObj.description}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </div>
                );
              },
            });
            cols.push({
              field: "Color",
              headerName: t("taskStatus.list.column.Color"),
              headerAlign: "center",
              align: "center",
              flex: 0.2,
              order: 4,
              renderCell: (params) => {
                const disabled =
                  (editMode.current === false && clickedRow.current === null) ||
                  (clickedRow.current !== "" &&
                    clickedRow.current !== undefined &&
                    clickedRow.current !== null &&
                    clickedRow.current.taskStatusGuid !== params.id) ||
                  (clickedRowGuid.current !== "" &&
                    clickedRowGuid.current !== undefined &&
                    clickedRowGuid.current !== null &&
                    clickedRowGuid.current !== params.id);
                return (
                  <div>
                    {statusColorsList.length > 0 && (
                      <Select
                        value={
                          params.row?.statusColor?.statusColorGuid
                            ? params.row?.statusColor?.statusColorGuid
                            : statusColorsList[0]?.statusColorGuid
                        }
                        onChange={(event) => {
                          handleStatusColorChange(event, params.row, rows);
                        }}
                        disabled={disabled}
                      >
                        {statusColorsList.map((colorObj) => (
                          <MenuItem
                            key={colorObj?.statusColorGuid}
                            value={colorObj?.statusColorGuid}
                          >
                            <div
                              style={{
                                backgroundColor: colorObj.colorCode,
                                width: "20px",
                                height: "20px",
                                marginRight: "8px",
                              }}
                            ></div>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </div>
                );
              },
            });

            cols.sort((a, b) => {
              return a.order - b.order;
            });
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const handleStatusColorChange = (event, id, r) => {
    const selectedColorGuid = event.target.value;
    setRows((prevRows) => {
      const updatedRows = [...prevRows];

      // Find index of the row with the given taskStatusGuid
      const index = updatedRows.findIndex(
        (x) => x.taskStatusGuid === id.taskStatusGuid
      );

      if (index !== -1) {
        updatedRows[index].statusColor = { statusColorGuid: selectedColorGuid };
      } else {
        const newStatus = {
          ...DEFAULT_STATUS,
          taskStatusGuid: id.taskStatusGuid,
          statusColor: {
            statusColorGuid: selectedColorGuid,
          },
          order: max,
          isNew: true,
        };
        updatedRows.unshift(newStatus);
      }

      // Return the updated rows
      return updatedRows;
    });
    setRowsUpdated(!rowsUpdated);
    setSelectedStatusColorGuid(selectedColorGuid);
  };

  const handleStateChange = (event, id, r) => {
    const selectedStateValue = event.target.value;
    setRows((prevRows) => {
      const updatedRows = [...prevRows];

      // Find index of the row with the given taskStatusGuid
      const index = updatedRows.findIndex(
        (x) => x.taskStatusGuid === id.taskStatusGuid
      );

      if (index !== -1) {
        updatedRows[index].state = { taskStateGuid: selectedStateValue };
      } else {
        const newStatus = {
          ...DEFAULT_STATUS,
          taskStatusGuid: id.taskStatusGuid,
          state: {
            taskStateGuid: selectedStateValue,
          },
          order: max,
          isNew: true,
        };
        updatedRows.unshift(newStatus);
      }

      // Return the updated rows
      return updatedRows;
    });
    setRowsUpdated(!rowsUpdated);
    setSelectedStateGuid(selectedStateValue);
  };

  useEffect(() => {
    if (statusColorsList.length > 0)
      setSelectedStatusColorGuid(statusColorsList[0]?.statusColorGuid);
  }, [statusColorsList.length]);

  useEffect(() => {
    if (rows.length > 0) {
      let cols = columns;
      cols.map((col) => {
        if (col.field === "Color") {
          col.renderCell = (params) => {
            const disabled =
              (editMode.current === false && clickedRow.current === null) ||
              (clickedRow.current !== "" &&
                clickedRow.current !== undefined &&
                clickedRow.current !== null &&
                clickedRow.current.taskStatusGuid !== params.id) ||
              (clickedRowGuid.current !== "" &&
                clickedRowGuid.current !== undefined &&
                clickedRowGuid.current !== null &&
                clickedRowGuid.current !== params.id);
            return (
              <div>
                {statusColorsList.length > 0 && (
                  <Select
                    value={
                      params.row?.statusColor?.statusColorGuid
                        ? params.row?.statusColor?.statusColorGuid
                        : statusColorsList[0]?.statusColorGuid
                    }
                    onChange={(event) => {
                      handleStatusColorChange(event, params.row, rows);
                    }}
                    disabled={disabled}
                  >
                    {statusColorsList.map((colorObj) => (
                      <MenuItem
                        key={colorObj?.statusColorGuid}
                        value={colorObj?.statusColorGuid}
                      >
                        <div
                          style={{
                            backgroundColor: colorObj.colorCode,
                            width: "20px",
                            height: "20px",
                            marginRight: "8px",
                          }}
                        ></div>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
            );
          };
        } else if (col.field === "State") {
          col.renderCell = (params) => {
            const disabled = editMode.current === true ||
              (editMode.current === false && clickedRow.current === null) ||
              (clickedRow.current !== "" &&
                clickedRow.current !== undefined &&
                clickedRow.current !== null &&
                clickedRow.current.taskStatusGuid !== params.id) ||
              (clickedRowGuid.current !== "" &&
                clickedRowGuid.current !== undefined &&
                clickedRowGuid.current !== null &&
                clickedRowGuid.current !== params.id);
            return (
              <div>
                {taskStatesList.length > 0 && (
                  <Select
                    value={
                      params.row?.state?.taskStateGuid
                        ? params.row?.state?.taskStateGuid
                        : taskStatesList[0]?.taskStateGuid
                    }
                    onChange={(event) =>
                      handleStateChange(event, params.row, rows)
                    }
                    disabled={disabled}
                  >
                    {taskStatesList.map((stateObj) => (
                      <MenuItem
                        key={stateObj?.taskStateGuid}
                        value={stateObj?.taskStateGuid}
                      >
                        {stateObj.description}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
            );
          };
        }
        return col;
      });

      cols.sort((a, b) => {
        return a.order - b.order;
      });

      setColumns(cols);
    }
  }, [
    rowsUpdated,
    clickedRow.current,
    clickedRowGuid.current,
    editMode.current,
  ]);

  const {
    mfunction: getAllTaskStatuses,
    loading,
    refetch: refetchData,
  } = useLazyQueryAuth(GET_TASK_STATUSES_LIST, "GetAllTaskStatuses");

  const fetchTableData = useCallback((page = 0, pageSize = 0, search = "") => {
    getAllTaskStatuses(
      {
        variables: {
          model: {
            enableFilters: true,
            keys: {
              page: page + 1,
              pageSize: pageSize,
            },
            orderColumn: orderBy,
            orderDirection: order,
            keyWord: search,
            userID: storeUserGuid,
            tenantID: fromTenant ? tID : storeUserTenant,
            projectID: pID,
          },
        },
        skip: columnsWait,
      },
      (response) => {
        setData(response);
        var myData =
          response?.GetAllTaskStatuses?.tasksStatusList?.taskStatusList;
        myData.forEach((status) => {
          // status.disabledSorting = true;
        });
        setRows(myData);
        setRowsUpdated(!rowsUpdated);
        setMax(response?.GetAllTaskStatuses?.tasksStatusList?.maxOrder);
        setNewObj({
          ...newObj,
          order: response?.GetAllTaskStatuses?.tasksStatusList?.maxOrder,
        });
      }
    );
  }, []);

  useEffect(() => {
    if (!columnsWait && columns.length > 0 && setCreate.current == false)
      fetchTableData(page, rowsPerPage, search);
  }, [columnsWait, columns.length, fetchTableData, re]);

  // const records =
  //   data &&
  //   data.GetAllTaskStatuses &&
  //   data.GetAllTaskStatuses.tasksStatusList &&
  //   data.GetAllTaskStatuses.tasksStatusList.taskStatusList
  //     ? data.GetAllTaskStatuses.tasksStatusList.taskStatusList
  //     : [];
  const numberOfRecords =
    data &&
    data.GetAllTaskStatuses &&
    data.GetAllTaskStatuses.tasksStatusList &&
    data.GetAllTaskStatuses.tasksStatusList.totalNumberOfRecords
      ? data.GetAllTaskStatuses.tasksStatusList.totalNumberOfRecords
      : 0;

  const handleDelete = (id) => {
    deleteRecord(
      {
        variables: { id: id },
      },
      (response) => {
        enqueueSnackbar(t("taskStatus.list.delete.success"), {
          variant: "success",
        });
        fetchTableData(page, rowsPerPage, search);
        setRe((v) => !v);
        setPage(0);
      }
    );
  };

  const { cfunction: editTaskStatusData } = useMutationAuth(
    UPDATE_TASK_STATUS,
    "EditTaskStatus"
  );

  const { cfunction: updateTaskStatusesData } = useMutationAuth(
    UPDATE_TASK_STATUSES_LIST,
    "ChangeTaskStatusesOrder"
  );

  const { cfunction: createTaskStatusData } = useMutationAuth(
    CREATE_TASK_STATUS,
    "CreateTaskStatus"
  );

  function CreateTaskStatus(newRow) {
    setIsSubmitting(true);
    createTaskStatusData(
      {
        variables: {
          model: {
            description: newRow.description,
            taskStateGuid: selectedStateGuid || taskStatesList[0]?.taskStateGuid,
            tenantId: fromTenant ? tID : storeUserTenant,
            // order: max,
            statusColorGuid: selectedStatusColorGuid,
          },
        },
      },
      (response) => {
        if (
          response["CreateTaskStatus"].errorCode !== null &&
          response["CreateTaskStatus"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateTaskStatus"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          setRows(
            rows.filter((r) => r.taskStatusGuid !== newObj.taskStatusGuid)
          );
          setRowsUpdated(!rowsUpdated);
          setNewObj(DEFAULT_STATUS);
        } else {
          enqueueSnackbar(t("taskStatusForm.createdSuccessfully"), {
            variant: "success",
          });
          editMode.current = false;
          clickedRow.current = null;
          clickedRowGuid.current = "";
          fetchTableData(page, rowsPerPage, search);
          setRowsUpdated(!rowsUpdated);
          setNewObj(DEFAULT_STATUS);
          refetchData();
        }
        setSelectedStatusColorGuid(statusColorsList[0]?.statusColorGuid);
        setTimeout(() => {
          setIsSubmitting(false);
        }, 500);
      },
      (error) => {
        setRows(rows.filter((r) => r.taskStatusGuid !== newObj.taskStatusGuid));
        setRowsUpdated(!rowsUpdated);
        setNewObj(DEFAULT_STATUS);
        setTimeout(() => {
          setIsSubmitting(false);
        }, 500);
      }
    );
  }

  function EditTaskStatus(updatedRow) {
    editTaskStatusData(
      {
        variables: {
          model: {
            taskStatusGuid: updatedRow.taskStatusGuid,
            description: updatedRow.description,
            taskStateGuid: updatedRow.state?.taskStateGuid,
            tenantId: fromTenant ? tID : storeUserTenant,
            order: updatedRow.order,
            statusColorGuid: updatedRow?.statusColor?.statusColorGuid
              ? updatedRow?.statusColor?.statusColorGuid
              : selectedStatusColorGuid,
          },
        },
      },
      (response) => {
        //  const data = response?.EditTaskStatus?.TaskStatus || {};
        if (
          response["EditTaskStatus"].errorCode !== null &&
          response["EditTaskStatus"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["EditTaskStatus"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_STATUS);
        } else {
          enqueueSnackbar(t("taskStatusForm.updatedSuccessfully"), {
            variant: "success",
          });
          editMode.current = false;
          clickedRow.current = null;
          clickedRowGuid.current = "";
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_STATUS);
        }
        setSelectedStatusColorGuid(statusColorsList[0]?.statusColorGuid);
        setRowsUpdated(!rowsUpdated);
        refetchData();
      },
      (error) => {
        fetchTableData(page, rowsPerPage, search);
        setNewObj(DEFAULT_STATUS);
      }
    );
  }

  function UpdateTaskStatusList(updatedRows) {
    updateTaskStatusesData(
      {
        variables: {
          model: {
            taskStatuses: updatedRows,
          },
        },
      },
      (response) => {
        if (
          response["ChangeTaskStatusesOrder"].errorCode !== null &&
          response["ChangeTaskStatusesOrder"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["ChangeTaskStatusesOrder"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          fetchTableData(page, rowsPerPage, search);
        } else {
          setChangeOrder(false);
          enqueueSnackbar(t("taskStatusForm.updatedSuccessfully"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
        }
      },
      (error) => {
        fetchTableData(page, rowsPerPage, search);
      }
    );
  }

  const handleChangeOrder = () => {
    const updatedRows = rows;
    updatedRows.map((row) => {
      row.statusColorGuid = row.statusColor?.statusColorGuid;
      return row;
    });
    updatedRows.map((row) => {
      row.taskStateGuid = row.state?.taskStateGuid;
      return row;
    });
    updatedRows.map((row) => delete row.statusColor);
    updatedRows.map((row) => delete row.state);
    UpdateTaskStatusList(updatedRows);
  };

  const customButtons = [
    {
      label: "table.ChangeOrder",
      handleClick: () => {
        setChangeOrder(!changeOrder);
        editMode.current = false;
      },

      condition: !changeOrder,
      icon: true,
      iconButton: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-arrows-up-down"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="1"
          stroke="#000000"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M7 3l0 18" />
          <path d="M10 6l-3 -3l-3 3" />
          <path d="M20 18l-3 3l-3 -3" />
          <path d="M17 21l0 -18" />
        </svg>
      ),
    },
    {
      label: "table.Save",
      handleClick: () => handleChangeOrder(),
      condition: changeOrder,
      icon: true,
      iconButton: <SaveIcon color={"var(--color-enabled-color)"} />,
    },
    {
      label: "table.Cancel",
      handleClick: () => {
        setChangeOrder(!changeOrder);
      },
      condition: changeOrder,
      icon: true,
      iconButton: <CloseIcon color={"#000000"} width={"24"} stroke={"1"} />,
    },
  ];

  const handleMoveUp = (row) => {
    if (row != null && row?.row?.order > 1 && row?.row?.order < max) {
      const newData = [...rows];
      const index = newData.findIndex((x) => x.taskStatusGuid == row.id);
      const tempOrder = newData[index].order;
      newData[index].order = newData[index - 1].order;
      newData[index - 1].order = tempOrder;
      setRows(newData.sort((a, b) => a.order - b.order));
      setRowsUpdated(!rowsUpdated);
    }
  };
  const handleMoveDown = (row) => {
    if (row != null && row?.row?.order > 1 && row?.row?.order < max) {
      const newData = [...rows];
      const index = newData.findIndex((x) => x.taskStatusGuid == row.id);
      const tempOrder = newData[index].order;
      newData[index].order = newData[index + 1].order;
      newData[index + 1].order = tempOrder;
      setRows(newData.sort((a, b) => a.order - b.order));
      setRowsUpdated(!rowsUpdated);
    }
  };

  const pagination = {
    rows,
    setRows,
    newObj,
    setNewObj,
    setCreate,
    columns,
    selected,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "taskStatus.list.search.label",
    // refetch,
    deleteText: "taskStatus.list.delete.label",
    handleDelete: handleDelete,
    readOnly: manageSystemConfigurations !== "M",
    changeOrder,
    customButtons,
    handleMoveUp,
    removeFilters: true,
    handleMoveDown,
    max,
    hideAddButton: changeOrder,
    hideDeleteButton: true,
    isSubmitting,
    requiredFields,
    enqueueSnackbar,
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        columns && (
          <GridTable
            {...pagination}
            identifier="taskStatusGuid"
            hasCheckbox={false}
            editMode={editMode}
            changeOrder={changeOrder}
            handleCreate={(taskStatus) => CreateTaskStatus(taskStatus)}
            handleUpdate={(taskStatus) => EditTaskStatus(taskStatus)}
            handleDelete={(id) => handleDelete(id)}
            handleEditMode={(v) => (editMode.current = v)}
            handleClickedRowGuid={(v) => {
              clickedRowGuid.current = v;
            }}
            handleClickedRow={(v) => {
              clickedRow.current = v;
            }}
          />
        )
      )}
    </div>
  );
};

export default TaskStatusList;
