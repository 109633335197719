import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import {
  GET_ALL_COMPANIES,
  GET_COMPANY_CREATED_BY_USERS,
  DELETE_COMPANY,
} from "../../../../graphql/company";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";

const CompanyList = ({ fromTenant = false, myCompany = false }) => {
  const { tID, tName } = useParams();

  const {
    company_form,
    new_company,
    tenant_new_company,
    tenant_company_form,
    my_company_form,
    my_new_company,
  } = urls;

  const { storeUserGuid, userRoles, storeUserTenant } = useAuth();

  const { manageParameters } = userRoles;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  const [createdByList, setCreatedByList] = useState([]);

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_COMPANY,
    "DeleteCompaniesList"
  );

  //call to get list of created by users
  const { loading: createdByLoading, tokenChange: createdByWait } =
    useQueryAuth(
      GET_COMPANY_CREATED_BY_USERS,
      "GetCompanyCreatedByUsers",
      {
        variables: {
          userID: storeUserGuid,
        },
      },
      {
        onCompleted: (response) => {
          const data = response.GetCompanyCreatedByUsers
            ? response.GetCompanyCreatedByUsers.createdByUsers
            : [];
          setCreatedByList(data);
        },
      }
    );

  const filtersObject = [
    {
      type: "text",
      label: "company.list.filters.name",
      name: "name",
    },
    {
      type: "text",
      label: "company.list.filters.tenant",
      name: "tenant",
      skip: fromTenant || myCompany,
    },
    {
      type: "date",
      label: "company.list.filters.creationDateFrom",
      name: "creationDateFrom",
    },
    {
      type: "date",
      label: "company.list.filters.creationDateTo",
      name: "creationDateTo",
    },
    {
      label: "company.list.filters.createdBy",
      name: "createdByUsers",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.email : ""),
      options: createdByList,
      access: "employeeGuid",
      loading: createdByLoading,
    },
  ];

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table:
            Mnemonic.Tables[
              fromTenant || myCompany ? "Tenant_Companies" : "Companies"
            ],
          userID: storeUserGuid,
        },
      },
      skip: createdByWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        const col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) setColumns(parsed.columns);

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: getAllCompanies, loading } = useLazyQueryAuth(
    GET_ALL_COMPANIES,
    "GetAllCompanies"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllCompanies(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyWord: search,
              tenantID: tID && fromTenant ? tID : storeUserTenant,
              userID: storeUserGuid,
              filters: filters,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData, re]);

  const rows =
    data &&
    data.GetAllCompanies &&
    data.GetAllCompanies.compList &&
    data.GetAllCompanies.compList.companies
      ? data.GetAllCompanies.compList.companies
      : [];
  const numberOfRecords =
    data &&
    data.GetAllCompanies &&
    data.GetAllCompanies.compList &&
    data.GetAllCompanies.compList.totalNumberOfRecords
      ? data.GetAllCompanies.compList.totalNumberOfRecords
      : 0;

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            companyIDs: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("company.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  };

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText:
      fromTenant || myCompany
        ? "company.list.search.label.fromTenant"
        : "company.list.search.label",
    refetch,
    removeFilters:true,
    tableMnemonic:
      Mnemonic.Tables[
        fromTenant || myCompany ? "Tenant_Companies" : "Companies"
      ],
    filtersObject,
    generateRoute: (row) =>
      !fromTenant && !myCompany
        ? company_form.replace(
            "/:cName/:cID",
            `/${encodeURIComponent(row?.name)}/${encodeURIComponent(
              row?.companyGuid
            )}`
          )
        : !myCompany
        ? tenant_company_form
            .replace("/:tName/:tID", `/${tName}/${tID}`)
            .replace(
              "/:cName/:cID",
              `/${encodeURIComponent(row?.name)}/${encodeURIComponent(
                row?.companyGuid
              )}`
            )
        : my_company_form.replace(
            "/:cName/:cID",
            `/${encodeURIComponent(row?.name)}/${encodeURIComponent(
              row?.companyGuid
            )}`
          ),
    newText: "company.list.new.label",
    newLink: fromTenant
      ? tenant_new_company.replace("/:tName/:tID", `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`)
      : myCompany
      ? my_new_company
      : new_company,
    deleteText: "company.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    readOnly: manageParameters !== "M",
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable {...pagination} identifier={"companyGuid"} hasCheckbox />
      )}
    </div>
  );
};

export default CompanyList;
