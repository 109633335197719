import React, { useEffect, useState, useCallback, useRef } from "react";

//routing
import { useParams } from "react-router-dom";

//graphql
import { GET_ALL_TICKETS, DELETE_TICKET } from "../../../../graphql/ticket";
import { DELETE_TASK } from "../../../../graphql/task";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//component
import CollapsibleTable from "../../../../components/Tables/CollapsibleTable";
import ContainerCard from "../../../../components/Cards/ContainerCard";
import TaskTabs from "../../Tasks/Tasks-tabs";
import TicketForm from "../../Tickets/Tickets-form";
import DeleteRecord from "../Projects-form/components/DeleteRecord";

import { Drawer } from "antd";
//urls
import urls from "../../../../urls";

import {
  CircularProgress,
  IconButton,
  Paper,
  Grid,
} from "@mui/material";


//uuid generator
import { v4 as uuidv4 } from "uuid";

//editors
import { Parse } from "../../../../JSON.editor";
import UTC2Local, { UTC2Time, formatDateTimeToString } from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

import { useTranslation } from "react-i18next";

import { useSnackbar } from "notistack";
import { CloseIcon } from "../../../../components/Icon";
import ConfirmationPopup from "../../../../components/Popup";

import ProjectTree from "../Project-tree";
import TaskTabsModal from "../../../../components/Modal/TaskTabsModal";

function ProjectPlan() {
  const { pID, pName } = useParams();

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const { my_project_direct_tasks_form, my_project_tickets_form } = urls;

  const { storeUserGuid, userRoles, storeUserTenant } = useAuth();

  const { manageTimeEntry } = userRoles;

  const miniSidenav = JSON.parse(localStorage.getItem("miniSidenav"));

  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState(null);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  // const [modalType, setModalType] = useState(null);
  const modalType = useRef("task");
  const edit = useRef(false);
  const [taskID, setTaskID] = useState(null);
  const [ticketID, setTicketID] = useState(null);
  const [taskName, setTaskName] = useState(null);
  const [ticketName, setTicketName] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [resetFields, setResetFields] = useState(false);

  const handleAgree = () => {
    sessionStorage.removeItem("unsavedChanges");
    setResetFields(true);
    setOpenDialog(false);
    refetch();
    setOpenModal(false);
    setTaskID(null);
    setTicketID(null);
  }

  const { mfunction: deleteTicket, loading: deleteTicketLoading } =
    useMutationAuth(DELETE_TICKET, "DeleteTicket");

  const handleDeleteTicket = (ticketGuid) => {
    deleteTicket(
      {
        variables: { id: ticketGuid },
      },
      (response) => {
        enqueueSnackbar(t("ticket.list.delete.success"), {
          variant: "success",
        });
        refetchData();
      }
    );
  };

  const { mfunction: deleteTask, loading: deleteTaskLoading } = useMutationAuth(
    DELETE_TASK,
    "DeleteTask"
  );

  const handleDeleteTask = (taskGuid) => {
    deleteTask(
      {
        variables: { id: taskGuid },
      },
      (response) => {
        enqueueSnackbar(t("ticket.list.delete.success"), {
          variant: "success",
        });
        refetchData();
      }
    );
  };

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.Plan,
          userID: storeUserGuid,
        },
      },
      isWait: true,
    },
    {
      onCompleted: (response) => {
        const col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        const actions = ({ compProps }) => {
          let props = compProps;
          return (
            <DeleteRecord
              {...props}
              handleClick={(id) =>
                props.tasks != null
                  ? handleDeleteTicket(id)
                  : handleDeleteTask(id)
              }
            />
          );
        };

        if (col) {
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay, UTC2Time, actions, null, formatDateTimeToString)
          );

          if (parsed.columns) { 
            let cols = parsed.columns;
            cols.forEach(c => {
              if(c.id === "BillableTasksPercentage") c.visible = false;
              return c;
            })
            setColumns(parsed.columns);
          }
          
          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const {
    mfunction: getAllTickets,
    loading,
    refetch: refetchData,
  } = useLazyQueryAuth(GET_ALL_TICKETS, "GetAllTickets");

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllTickets(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyWord: search,
              tenantID: storeUserTenant,
              userID: storeUserGuid,
              filters: filters,
              projectID: pID,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );
  const rows =
    data &&
    data.GetAllTickets &&
    data.GetAllTickets.ticketsList &&
    data.GetAllTickets.ticketsList.tickets
      ? data.GetAllTickets.ticketsList.tickets
      : [];
  const numberRecords =
    data &&
    data.GetAllTickets &&
    data.GetAllTickets.ticketsList &&
    data.GetAllTickets.ticketsList.totalNumberOfRecords
      ? data.GetAllTickets.ticketsList.totalNumberOfRecords
      : 0;

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData, ]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setOpenModal(false);
        setTicketID(null);
        setTaskID(null);
        refetchData();
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const pagination = {
    rows,
    columns,
    page,
    setPage,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    search,
    defaultFilters,
    rowsPerPage,
    setRowsPerPage,
    numberRecords,
    fetchTableData,
    loading,
    refetch,
    tableMnemonic: Mnemonic.Tables.Plan,
    setOpenModal,
    modalType,
    setTaskID,
    setTaskName,
    setTicketID,
    setTicketName,
    edit,
    readOnly: manageTimeEntry !== "M",
    generateTaskRoute: (row) =>
      my_project_direct_tasks_form
        .replace(
          "/:pName/:pID",
          `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
        )
        .replace(
          "/:tskName/:tskID",
          `/${encodeURIComponent(row?.title)}/${encodeURIComponent(
            row?.taskGuid
          )}`
        ),
    generateTicketRoute: (row) =>
      my_project_tickets_form
        .replace(
          "/:pName/:pID",
          `/${encodeURIComponent(pName)}/${encodeURIComponent(pID)}`
        )
        .replace(
          "/:tkName/:tkID",
          `/${encodeURIComponent(row?.title)}/${encodeURIComponent(
            row?.ticketGuid
          )}`
        ),
  };
  
  return (
    <>
      <div style={{ marginLeft: miniSidenav === true ? "76px" : "256px"}}>
        <ConfirmationPopup
          openDialog={openDialog}
          handleOpenDialog={(v) => setOpenDialog(v)}
          handleAgree={handleAgree}
        />
        <TaskTabsModal 
          // key={JSON.stringify(taskID + ' '+ pID)}
          showModal={openModal && modalType.current !== "phase"}
          setShowModal={(v) => setOpenModal(v)}
          fromProject={true}
          fromPlan={true}
          projectID={pID}
          projectName={pName}
          taskID={taskID}
          editMode={edit.current}
          // handleChangeTask={(v) => setTaskID(v)}
          // handleChangeTaskName={(v) => setTaskName(v)}
          taskName={taskName}
          refetch={refetchData}
          resetFields={resetFields}
          type={modalType.current}
          setType={(v) => modalType.current = v}
          open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
          modifyFormFlag={() => sessionStorage.setItem("unsavedChanges", JSON.stringify(false))}
        />
        <Drawer
          placement={"right"}
          width={miniSidenav === true ? "calc(100vw - 76px)" : "calc(100vw - 256px)"}
          // onClose={() => {
          //   if(JSON.parse(sessionStorage.getItem("unsavedChanges")) === true) {
          //     setOpenDialog(true) }
          //   else
          //   {
          //     // refetchData();
          //     setResetFields(true);
          //     setOpenModal(false);
          //     setTicketID(null);
          //   }
          //   setResetFields(false);
          // }}
          headerStyle={{ display: "none" }}
          bodyStyle={{ padding: 0, marginTop: "5px" }}
          open={openModal && modalType.current === "phase"}
          zIndex={1400}
          closeIcon={null}
          keyboard={true}
        >
          <>
            {/* {modalType !== "phase" && (
              <Paper
                sx={{
                  padding: "0px 50px 0px 50px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  overflowY: "auto",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TaskTabs
                      key={JSON.stringify(taskID + ' '+ pID)}
                      showModal={openModal}
                      fromProject={true}
                      fromPlan={true}
                      projectID={pID}
                      projectName={pName}
                      taskID={taskID}
                      handleChangeTask={(v) => setTaskID(v)}
                      handleChangeTaskName={(v) => setTaskName(v)}
                      taskName={taskName}
                      refetchData={refetchData}
                      resetFields={resetFields}
                      handleCloseModal={(open) => {
                        if(formFlag === true && (open === null || open === undefined)) {
                          setOpenDialog(true) }
                        else
                        {
                          // refetchData();
                          setResetFields(true);
                          setOpenModal(false);
                        }
                        setResetFields(false);
                      }}
                      type={modalType}
                    />
                  </Grid>
                </Grid>
              </Paper>
            )}
            {modalType === "phase" && ( */}
              <Paper
                sx={{
                  padding: "5px 50px 0px 50px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  overflowY: "auto",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        if(JSON.parse(sessionStorage.getItem("unsavedChanges")) === true) {
                          setOpenDialog(true) 
                        }
                        else
                        {
                          setResetFields(true);
                          setOpenModal(false);
                          refetchData();
                        }
                        setResetFields(false);
                        setTicketID(null);
                      }}
                    >
                    <CloseIcon color={"#9e9e9e"} width={'24'} stroke={'1.5'} />
                    </IconButton>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TicketForm
                      key={ticketID ? JSON.stringify(ticketID + " " + pID) : uuidv4()}
                      fromProject={true}
                      projectID={pID}
                      projectName={pName}
                      ticketID={ticketID}
                      ticketName={ticketName}
                      refetchData={refetchData}
                      handleChangeTicket={(v) => setTicketID(v)}
                      handleChangeTicketName={(v) => setTicketName(v)}
                      handleCloseModal={() => {
                        setTicketID(null);
                        setTicketName(null);
                        setOpenModal(false);
                      }}
                      showCloseButton={true}
                      modal={true}
                      openModal={openModal && modalType.current === "phase"}
                      resetFields={resetFields}
                    />
                  </Grid>
                </Grid>
              </Paper>
            {/* )} */}
          </>
        </Drawer>
      </div>
      {getColumnsLoading || loading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <CollapsibleTable {...pagination} />
      )}
    </>
  );
}

export default ProjectPlan;
