import React, { useEffect, useState, useRef } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";

//graphql
import {
  CREATE_OFFICIAL_HOLIDAY,
  GET_OFFICIAL_HOLIDAY_BY_ID,
  UPDATE_OFFICIAL_HOLIDAY,
} from "../../../../graphql/officialHolidays";

//urls
import urls from "../../../../urls";

import ContainerCard from "../../../../components/Cards/ContainerCard";

import { formatDateTimeZone } from "../../../../UTC2Local";

const OfficialHolidayForm = ({
  fromModal = false,
  handleCloseModal,
  resetFields = false,
}) => {
  const { tID, tName, ohName, ohID } = useParams();

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const createMode = !ohName && !ohID;

  const { tenant_official_holidays, my_official_holidays } = urls;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;

  const [disableCopy, setDisableCopy] = useState(false);
  
  const [ unTouchedForm, setUnTouchedForm ] = useState(true);

  const { description, holidays } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (resetFields === true) {
      let f = document.getElementById(form.formID);
      f?.reset();
    }
  }, [resetFields]);

  const { loading: getDataByIDLoading, tokenChange: wait } = useQueryAuth(
    GET_OFFICIAL_HOLIDAY_BY_ID,
    "GetOfficialHolidayByID",
    {
      variables: {
        model: {
          officialHolidayID: ohID,
          year: selectedYear.toString(),
          userID: storeUserGuid,
        },
      },
      skip: createMode,
    },
    {
      onCompleted: (response) => {
        const data = response?.GetOfficialHolidayByID?.officialHoliday || {};

        const newArray = data.officialHolidayDays || [];

        const tmpArray = newArray.map((val, index) => {
          return {
            officialHolidayDayGuid: val.officialHolidayDayGuid,
            description: val.description,
            date: new Date(formatDateTimeZone(val.date, false)),
          };
        });

        setFormData({
          [description.name]: data.description || "",
          [holidays.name]: tmpArray || [],
        });
      },
    }
  );

  const { cfunction: editOfficialHolidays } = useMutationAuth(
    UPDATE_OFFICIAL_HOLIDAY,
    "UpdateOfficialHoliday"
  );

  const { cfunction: createOfficialHolidays } = useMutationAuth(
    CREATE_OFFICIAL_HOLIDAY,
    "CreateOfficialHoliday"
  );

  function compareDates(a, b) {
    // Display date in order for sorting
    if (new Date(a.date) < new Date(b.date)) {
      return -1;
    }
    if (new Date(a.date) > new Date(b.date)) {
      return 1;
    }
    return 0;
  }

  const hasDuplicateDates = (officialHolidaysArray) => {
    const uniqueDates = new Set();

    for (const holiday of officialHolidaysArray) {
      const holidayDate = new Date(holiday.date).toISOString().split("T")[0];

      if (uniqueDates.has(holidayDate)) {
        // Duplicate date found
        return true;
      }

      uniqueDates.add(holidayDate);
    }

    // No duplicate dates found
    return false;
  };

  function createOfficialHoliday(values, actions) {
    const daysArray = structuredClone(values[holidays.name] || []);

    daysArray.sort(compareDates);

    const holidayArray = daysArray.map((holiday, index) => {
      const date = new Date(holiday.date);
      const formattedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];

      return {
        description: holiday.description,
        date: formattedDate,
      };
    });

    if (hasDuplicateDates(holidayArray)) {
      enqueueSnackbar(t("duplicate.dates.error"), {
        variant: "error",
      });
      actions.setSubmitting(false);
    } else {
      createOfficialHolidays(
        {
          variables: {
            model: {
              description: values[description.name],
              tenantGuid: tID ? tID : storeUserTenant,
              officialHolidayDaysCreateRequests: holidayArray,
            },
          },
        },
        (response) => {
          if (
            response["CreateOfficialHoliday"].errorCode !== null &&
            response["CreateOfficialHoliday"].errorCode !== ""
          ) {
            enqueueSnackbar(t(response["CreateOfficialHoliday"].errorMessage), {
              variant: "error",
              autoHideDuration: 5000,
            });
          } else {
            enqueueSnackbar(t("officialHolidayForm.createdSuccessfully"), {
              variant: "success",
            });
            sessionStorage.removeItem("unsavedChanges");
            if (fromModal === true && handleCloseModal !== undefined)
              handleCloseModal(false);
            else
              navigate(
                tID
                  ? my_official_holidays
                  : tenant_official_holidays.replace(
                      "/:tName/:tID",
                      `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
                    )
              );
          }
          actions.setSubmitting(false);
        },
        (error) => {
          actions.setSubmitting(false);
        }
      );
    }
  }

  function editOfficialHoliday(values, actions) {
    const daysArray = structuredClone(values[holidays.name] || []);

    daysArray.sort(compareDates);

    const holidayArray = daysArray.map((holiday, index) => {
      const date = new Date(holiday.date);
      const formattedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];

      return {
        description: holiday.description,
        date: formattedDate,
      };
    });

    if (hasDuplicateDates(holidayArray)) {
      enqueueSnackbar(t("duplicate.dates.error"), {
        variant: "error",
      });
      actions.setSubmitting(false);
    } else {
      editOfficialHolidays(
        {
          variables: {
            model: {
              description: values[description.name],
              officialHolidayGuid: ohID,
              officialHolidayDayUpdateRequests: holidayArray,
              year: parseInt(selectedYear),
            },
          },
        },
        (response) => {
          if (
            response["UpdateOfficialHoliday"].errorCode !== null &&
            response["UpdateOfficialHoliday"].errorCode !== ""
          ) {
            enqueueSnackbar(t(response["UpdateOfficialHoliday"].errorMessage), {
              variant: "error",
              autoHideDuration: 5000,
            });
          } else {
            enqueueSnackbar(t("officialHolidayForm.updatedSuccessfully"), {
              variant: "success",
            });
            sessionStorage.removeItem("unsavedChanges");
            setDisableCopy(false);
          }
          actions.setSubmitting(false);
        },
        (error) => {
          actions.setSubmitting(false);
        }
      );
    }
  }

  useEffect(() => {
    if(performance.navigation.type === performance.navigation.TYPE_NAVIGATE && unTouchedForm && !fromModal) {
      sessionStorage.clear();
    }
  }, [])

  const handleSubmit = (values, actions) => {
    if (createMode) {
      createOfficialHoliday(values, actions);
    } else {
      editOfficialHoliday(values, actions);
    }
  };

  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard sx={{ height: "50vh" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="left"
            alignItems="stretch"
            sx={{ height: "100%", mt: 2 }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={createMode ? 12 : 9}>
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${
                    createMode ? "creation" : "edit"
                  }`}
                  initialValues={formData}
                  validationSchema={validation[0]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                  }) =>  {
                    formikValuesRef.current = values;
                    return (
                    <Form
                      onChange={() => {
                        if (touched){
                          sessionStorage.setItem(
                            "unsavedChanges",
                            JSON.stringify(true)
                          );
                        setUnTouchedForm(false)}
                      }}
                      id={form.formID}
                      autoComplete="off"
                    >
                      <Box p={2}>
                        <Box>
                          <Main
                            formData={{
                              values,
                              touched,
                              formField: form.formField,
                              errors,
                              setFieldValue,
                              setTouched,
                              createMode,
                              wait,
                              readOnly: manageSystemConfigurations === "R",
                            }}
                            holidayGuid={ohID}
                            selectedYear={selectedYear}
                            enqueueSnackbar={enqueueSnackbar}
                            setSelectedYear={(year) => setSelectedYear(year)}
                            handleDisableCopyToNextYear={(copy) =>
                              setDisableCopy(copy)
                            }
                            disableCopy={disableCopy}
                          />
                        </Box>
                        <Box
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <IALoadingButton
                            disabled={
                              isSubmitting || manageSystemConfigurations !== "M"
                            }
                            loading={isSubmitting}
                            type="submit"
                            label={t("officialHolidayForm.save")}
                          />
                        </Box>
                      </Box>
                    </Form>
                  )}}
                </Formik>
              </ContainerCard>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default OfficialHolidayForm;
