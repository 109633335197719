import { gql } from "@apollo/client";

export const GET_ALL_EMPLOYEE_REFERENCES = gql`
query($model: GetAllEmployeeReferencesRequestModelInput!) {
    GetAllEmployeeReferences(model: $model) {
        employeeReferencesOutput {
            employeeReferenceList {
              employeeReferenceGuid
              employee {
                firstName
                lastName
              } 
              company {
                name
              }
              regularPayExternalResource {
                name
                code
              }
              overtimeExternalResource {
                name
                code
              }
              createdBy {
                email
              }
              creationDate
              modifiedBy {
                email
              }
              modificationDate
            }
            totalNumberOfRecords
          }
          errorCode
          errorMessage
          errorVariables
    }
  }
`;

export const GET_EMPLOYEE_REFERENCE_BY_ID = gql`
query ($id: UUID!){
    GetEmployeeReference(id: $id) {
      employeeReferenceOutput {
        employeeReferenceGuid
        employee {
          firstName
          lastName
        } 
        company {
          externalReference
          companyGuid
          name
        }
        regularPayExternalResource {
          resourceGuid
          name
          code
        }
        overtimeExternalResource {
          resourceGuid
          name
          code
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }`;

export const CREATE_EMPLOYEE_REFERENCE = gql`
mutation($model: CreateEmployeeReferenceRequestInput!){
    CreateEmployeeReference(model: $model) {
      employeeReferenceOutput {
        employeeReferenceGuid
      }
    errorCode
    errorMessage
    errorVariables
    }
  }
`;

export const EDIT_EMPLOYEE_REFERENCE = gql`
mutation($model: EditEmployeeReferenceRequestInput!){
    EditEmployeeReference(model: $model) {
      employeeReferenceOutput {
        employeeReferenceGuid
      }
    errorCode
    errorMessage
    errorVariables
    }
  }
`;

export const DELETE_EMPLOYEE_REFERENCE = gql`
mutation($id: UUID!){
    DeleteEmployeeReference(id: $id) {
        crudEmployeeReference 
        errorCode
        errorMessage
        errorVariables
    }
  }
`;

export const DELETE_EMPLOYEE_REFERENCES_LIST = gql`
mutation($model: DeleteEmployeeReferenceListRequestInput!){
    DeleteListOfEmployeeReferences(model: $model) {
        crudEmployeeReference 
        errorCode
        errorMessage
        errorVariables
    }
  }
`;