import React, { useState, useCallback, useEffect } from "react";

//routing
import { useParams } from "react-router-dom";

//material UI component

//translation
import { useTranslation } from "react-i18next";

//component
import GridTable from "../../../../components/Tables/GridTable";
import ContainerCard from "../../../../components/Cards/ContainerCard";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//gql
import {
  GET_ALL_DEPARTMENTS,
  DELETE_DEPARTMENTS,
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
} from "../../../../graphql/department";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//external components
import { CircularProgress } from "@mui/material";

import { useSnackbar } from "notistack";

function DepartmentsList({ fromTenant = false, myDepartments = false, fromModal = false, handleCloseModal  }) {
  const { tID } = useParams();

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const DEFAULT_DEP = {
    departmentGuid: "",
    name: "",
    tenantGuid: tID ? tID : storeUserTenant,
  };

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [re, setRe] = useState(false);
  const [newObj, setNewObj] = useState({
    departmentGuid: "",
    name: "",
    tenantGuid: tID ? tID : storeUserTenant,
  });
  const requiredFields=["departmentGuid", "name"];

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setCreate = React.useRef(false);
  const [data, setData] = useState(null);

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_DEPARTMENTS,
    "DeleteDepartmentsList"
  );

  const { mfunction: getAllDepartments, loading } = useLazyQueryAuth(
    GET_ALL_DEPARTMENTS,
    "GetAllDepartments"
  );

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.SkillsGrid,
          userID: storeUserGuid,
        },
      },
      isWait: true,
    },
    {
      onCompleted: (response) => {
        const col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) setColumns(parsed.columns);
        }
      },
    }
  );

  const fetchTableData = useCallback((page = 0, pageSize = 0, search = "") => {
    getAllDepartments(
      {
        variables: {
          model: {
            enableFilters: true,
            keys: {
              page: page + 1,
              pageSize: pageSize,
            },
            keyWord: search,
            tenantID: tID ? tID : storeUserTenant,
            // tenantID: myDepartments
            //   ? storeUserTenant
            //   : tID && fromTenant
            //   ? tID
            //   : undefined,
            userID: storeUserGuid,
          },
        },
      },
      (response) => {
        setData(response);
        setRows(response?.GetAllDepartments?.depList?.departments);
      }
    );
  }, []);

  const records =
    data &&
    data.GetAllDepartments &&
    data.GetAllDepartments.depList &&
    data.GetAllDepartments.depList.departments
      ? data.GetAllDepartments.depList.departments
      : [];
  const numberRecords =
    data &&
    data.GetAllDepartments &&
    data.GetAllDepartments.depList &&
    data.GetAllDepartments.depList.departments &&
    data.GetAllDepartments.depList.totalNumberOfRecords
      ? data.GetAllDepartments.depList.totalNumberOfRecords
      : 0;

  useEffect(() => {
    if (!columnsWait && setCreate.current === false)
      fetchTableData(page, rowsPerPage, search);
  }, [columnsWait, fetchTableData, re]);

  const handleDelete = (selectedItems) => {
    deleteRecords(
      {
        variables: {
          model: {
            departmentIDs: selectedItems,
          },
        },
      },
      (response) => {
        if (response["DeleteDepartmentsList"].crudDepartment === true) {
          enqueueSnackbar(t("department.list.delete.success"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
          setRe((v) => !v);
          setSelected([]);
          setPage(0);
        } else {
        }
      }
    );
  };

  const { cfunction: createDepartmentData } = useMutationAuth(
    CREATE_DEPARTMENT,
    "CreateDepartment"
  );

  const { cfunction: updateDepartmentData } = useMutationAuth(
    UPDATE_DEPARTMENT,
    "EditDepartment"
  );

  function CreateDepartmentData(newRow) {
    setIsSubmitting(true);
    if (newRow != null && newRow.departmentGuid != null) {
      createDepartmentData(
        {
          variables: {
            model: {
              name: newRow.name,
              tenantGuid: newRow.tenantGuid,
            },
          },
        },
        (response) => {
          if (
            response["CreateDepartment"].errorCode !== null &&
            response["CreateDepartment"].errorCode !== ""
          ) {
            enqueueSnackbar(t(response["CreateDepartment"].errorMessage), {
              variant: "error",
              autoHideDuration: 5000
            });
            setRows(
              rows.filter((r) => r.departmentGuid !== newObj.departmentGuid)
            );
            setNewObj(DEFAULT_DEP);
          } else {
            enqueueSnackbar(t("departmentForm.createdSuccessfully"), {
              variant: "success",
            });
            fetchTableData(page, rowsPerPage, search);
            setNewObj(DEFAULT_DEP);
            if(fromModal === true && handleCloseModal !== undefined) handleCloseModal(true);
          }
          setTimeout(() => {
            setIsSubmitting(false)
          }, 500)
        },
        (error) => {
          setRows(
            rows.filter((r) => r.departmentGuid !== newObj.departmentGuid)
          );
          setNewObj(DEFAULT_DEP);
          setTimeout(() => {
            setIsSubmitting(false)
          }, 500)
        }
      );
    }
  }

  function EditDepartmentData(updatedRow) {
    updateDepartmentData(
      {
        variables: {
          model: {
            departmentID: updatedRow.departmentGuid,
            name: updatedRow.name,
            tenantGuid: updatedRow.tenant.tenantGuid,
          },
        },
      },
      (response) => {
        if (
          response["EditDepartment"].errorCode !== null &&
          response["EditDepartment"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["EditDepartment"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_DEP);
        } else {
          enqueueSnackbar(t("departmentForm.updatedSuccessfully"), {
            variant: "success",
          });
          fetchTableData(page, rowsPerPage, search);
          setNewObj(DEFAULT_DEP);
          if(fromModal === true && handleCloseModal !== undefined) handleCloseModal(true);
        }
      },
      (error) => {
        fetchTableData(page, rowsPerPage, search);
        setNewObj(DEFAULT_DEP);
      }
    );
  }

  const pagination = {
    rows: rows.length === 0 ? records : rows,
    setRows,
    newObj,
    setNewObj,
    setCreate,
    columns,
    selected,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords,
    removeFilters:true,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || getColumnsLoading,
    searchText: "department.list.search.label",
    // refetch,
    tableMnemonic: Mnemonic.Tables["Departments"],
    deleteText: "department.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    readOnly: manageSystemConfigurations !== "M",
    isSubmitting,
    requiredFields,
    enqueueSnackbar
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        columns && (
          <GridTable
            {...pagination}
            identifier="departmentGuid"
            hasCheckbox={true}
            handleCreate={(department) => CreateDepartmentData(department)}
            handleUpdate={(department) => EditDepartmentData(department)}
            handleDelete={(departments) => handleDelete(departments)}
          />
        )
      )}
    </div>
  );
}

export default DepartmentsList;
