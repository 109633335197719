import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";
import InfoCard from "../../../../components/Cards/InfoCard";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import {
  GET_ALL_EMPLOYEES,
  DELETE_EMPLOYEES,
  RESEND_ACTIVATION_EMAIL,
  CHANGE_EMPLOYEE_STATUS,
  GET_ALL_EMPLOYEES_DROPDOWN,
} from "../../../../graphql/employee";
import {
  GET_USER_TABLE_PREFERENCES,
  SAVE_USER_TABLE_PREFERENCES,
} from "../../../../graphql/userTablePreferences";
import { GET_ALL_EMPLOYMENT_TYPES } from "../../../../graphql/employmentType";
import { GET_ALL_EMPLOYMENT_STATUS } from "../../../../graphql/employmentStatus";
import { GET_ALL_WORKSHIFTS } from "../../../../graphql/workshifts";
import { GET_ALL_WORK_TYPES } from "../../../../graphql/workType";
import { GET_ALL_DEPARTMENTS_DROPDOWN } from "../../../../graphql/department";
import { GET_ALL_COMPANIES_DROPDOWN } from "../../../../graphql/company";
import { GET_ALL_OFFICIAL_HOLIDAYS } from "../../../../graphql/officialHolidays";
import { GET_ALL_CONTRACTORS } from "../../../../graphql/contractor";
import { GET_ALL_EMPLOYMENT_STATUSES } from "../../../../graphql/employmentStatuses";

//editors
import { Parse, Stringify } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";
import Mnemonics from "../../../../Mnemonics.json";
import { useTranslation } from "react-i18next";
import { GET_ALL_ROLES_DROPDOWN } from "../../../../graphql/role";
import {
  BacklogIcon,
  DeactivateUserIcon,
  EmailIcon,
} from "../../../../components/Icon";
import { GET_ALL_TIMEZONES } from "../../../../graphql/timezone";
import { GET_ALL_LOCATIONS_DROPDOWN } from "../../../../graphql/location";

const EmployeesList = ({ fromTenant = false, myEmployees = false }) => {
  const { tID, tName } = useParams();

  const { employee_form, new_employee, my_employee_form, my_new_employee } =
    urls;

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageUsers, isAdmin } = userRoles;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const [filterState, setFilterState] = useState([]);

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [showAsTile, setShowAsTile] = useState(false);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [fetchEmploymentTypes, setFetchEmploymentTypes] = useState(false);
  const [fetchWorkShifts, setFetchWorkShifts] = useState(false);
  const [fetchWorkTypes, setFetchWorkTypes] = useState(false);
  const [fetchDepartments, setFetchDepartments] = useState(false);
  const [fetchCompanies, setFetchCompanies] = useState(false);
  const [fetchEmployeeStatuses, setFetchEmployeeStatuses] = useState(false);
  const [fetchOfficialHolidays, setFetchOfficialHolidays] = useState(false);
  const [fetchContractors, setFetchContractors] = useState(false);
  const [fetchEmploymentStatuses, setFetchEmploymentStatuses] = useState(false);
  const [fetchManagers, setFetchManagers] = useState(false);
  const [fetchRoles, setFetchRoles] = useState(false);
  const [fetchTimezone, setFetchTimezone] = useState(false);
  const [fetchLocations, setFetchLocations] = useState(false);
  const [employeeStatusesList, setEmployeeStatusesList] = useState([]);
  const [employmentStatusesList, setEmploymentStatusesList] = useState([]);
  const [employmentStatusList, setEmploymentStatusList] = useState([]);
  const [holidaysList, setHolidaysList] = useState([]);
  const [workTypesList, setWorkTypesList] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [timezonesList, setTimezonesList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [managersList, setManagersList] = useState([]);
  const [employmentTypesList, setEmploymentTypesList] = useState([]);
  const [workShiftsList, setWorkShiftsList] = useState([]);
  const [contractorsList, setContractorsList] = useState([]);
  const [departmentsList, setDepartmentsList] = useState([]);
  const [selectedRowID, setSelectedRowID] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_EMPLOYEES,
    "DeleteUsers"
  );

  const { mfunction: saveUserPreferences } = useMutationAuth(
    SAVE_USER_TABLE_PREFERENCES,
    "SaveUserTablePreferences"
  );

  const {
    data: workShiftsData,
    loading: workShiftLoading,
    tokenChange: workShiftsWait,
  } = useQueryAuth(GET_ALL_WORKSHIFTS, "GetAllWorkShifts", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: fromTenant ? tID : storeUserTenant,
      },
    },
    skip: !fetchWorkShifts,
    isWait: fetchWorkShifts,
  });

  const {
    data: employeesData,
    loading: managerLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: fromTenant ? tID : storeUserTenant,
        userID: storeUserGuid,
        isManager: true,
      },
    },
    skip: !fetchManagers,
    isWait: fetchManagers,
  });

  const {
    data: employmentTypesData,
    loading: employmentTypeLoading,
    tokenChange: employmentTypesWait,
  } = useQueryAuth(GET_ALL_EMPLOYMENT_TYPES, "GetAllEmploymentTypes", {
    variables: {},
    skip: !fetchEmploymentTypes,
    isWait: fetchEmploymentTypes,
  });

  const {
    data: timezonesData,
    loading: timezonesLoading,
    tokenChange: timezoneWait,
  } = useQueryAuth(GET_ALL_TIMEZONES, "GetTimezones", {
    skip: !fetchTimezone,
    isWait: fetchTimezone,
  });

  const {
    data: locationData,
    loading: locationLoading,
    tokenChange: locationWait,
    refetch: refetchLocations,
  } = useQueryAuth(GET_ALL_LOCATIONS_DROPDOWN, "GetAllLocations", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: storeUserTenant,
        isNotOnsite: true,
      },
    },
    skip: !fetchLocations,
    isWait: fetchLocations,
  });

  const {
    data: departmentsData,
    loading: departmentLoading,
    tokenChange: departmentsWait,
  } = useQueryAuth(GET_ALL_DEPARTMENTS_DROPDOWN, "GetAllDepartments", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: fromTenant ? tID : storeUserTenant,
      },
    },
    skip: !fetchDepartments,
    isWait: fetchDepartments,
  });

  const {
    data: companiesData,
    loading: companyLoading,
    tokenChange: companyWait,
  } = useQueryAuth(GET_ALL_COMPANIES_DROPDOWN, "GetAllCompanies", {
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: fromTenant ? tID : storeUserTenant,
      },
    },
    skip: !fetchCompanies,
    isWait: fetchCompanies,
  });

  const {
    data: getRolesData,
    loading: roleLoading,
    refetch: refetchRoles,
  } = useQueryAuth(GET_ALL_ROLES_DROPDOWN, "GetAllRoles", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: fromTenant ? tID : storeUserTenant,
      },
    },
    skip: !fetchRoles,
    isWait: fetchRoles,
  });

  const {
    data: workTypesData,
    loading: workTypeLoading,
    tokenChange: workTypesWait,
  } = useQueryAuth(GET_ALL_WORK_TYPES, "GetAllWorkTypes", {
    variables: {},
    skip: !fetchWorkTypes,
    isWait: fetchWorkTypes,
  });

  const {
    data: holidaysData,
    loading: holidayLoading,
    tokenChange: getHolidaysWait,
  } = useQueryAuth(GET_ALL_OFFICIAL_HOLIDAYS, "GetOfficialHolidaysList", {
    variables: {
      model: {
        enableFilters: false,
        tenantGuid: fromTenant ? tID : storeUserTenant,
      },
    },
    skip: !fetchOfficialHolidays,
    isWait: fetchOfficialHolidays,
  });

  const {
    data: employmentStatusData,
    loading: employmentStatusLoading,
    tokenChange: employmentStatusWait,
  } = useQueryAuth(GET_ALL_EMPLOYMENT_STATUS, "GetAllEmployeeStatuses");

  useEffect(() => {
    if (employmentStatusData)
      setEmployeeStatusesList(
        employmentStatusData?.GetAllEmployeeStatuses?.employeeStatusesOutput ||
          []
      );
  }, [employmentStatusData]);

  const {
    data: employmentStatusesData,
    loading: employmentStatusesLoading,
    tokenChange: employmentStatusesWait,
  } = useQueryAuth(GET_ALL_EMPLOYMENT_STATUSES, "GetAllEmploymentStatuses", {
    skip: employmentStatusWait,
    isWait: true,
  });

  useEffect(() => {
    if (employmentStatusesData) {
      setEmploymentStatusesList(
        employmentStatusesData?.GetAllEmploymentStatuses
          ?.employmentStatusesOutput || []
      );
      setEmploymentStatusList(
        employmentStatusesData?.GetAllEmploymentStatuses
          ?.employeeStatusesOutput || []
      );
    }
  }, [employmentStatusesData]);

  const {
    data: contractorsData,
    loading: contractorsLoading,
    tokenChange: contractorsWait,
  } = useQueryAuth(GET_ALL_CONTRACTORS, "GetContractorsList", {
    variables: {
      model: {
        tenantGuid: fromTenant ? tID : storeUserTenant,
      },
    },
    skip: !fetchContractors,
    isWait: fetchContractors,
  });

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables["Employees"],
          userID: storeUserGuid,
        },
      },
      skip: employmentStatusWait || employmentStatusesWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        const col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) setColumns(parsed.columns);
          if (parsed.tile) setShowAsTile(parsed.tile);

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  useEffect(() => {
    if (holidaysData)
      setHolidaysList(
        holidaysData?.GetOfficialHolidaysList?.officialHolidayListModel
          ?.officialHolidays || []
      );
  }, [holidaysData]);

  useEffect(() => {
    if (workTypesData)
      setWorkTypesList(workTypesData?.GetAllWorkTypes?.workTypes || []);
  }, [workTypesData]);

  useEffect(() => {
    if (companiesData)
      setCompaniesList(
        companiesData?.GetAllCompanies?.compList?.companies || []
      );
  }, [companiesData]);

  useEffect(() => {
    if (locationData)
      setLocationsList(
        locationData?.GetAllLocations?.locationsList?.locations || []
      );
  }, [locationData]);

  useEffect(() => {
    if (timezonesData)
      setTimezonesList(timezonesData?.GetTimezones?.timezones || []);
  }, [timezonesData]);

  useEffect(() => {
    if (employeesData)
      setManagersList(
        employeesData?.GetCompanyUsersList?.usersList?.employees || []
      );
  }, [employeesData]);

  useEffect(() => {
    if (departmentsData)
      setDepartmentsList(
        departmentsData?.GetAllDepartments?.depList?.departments || []
      );
  }, [departmentsData]);

  useEffect(() => {
    if (getRolesData)
      setRolesList(getRolesData?.GetAllRoles?.rolesList?.roles || []);
  }, [getRolesData]);

  useEffect(() => {
    if (employmentTypesData)
      setEmploymentTypesList(
        employmentTypesData?.GetAllEmploymentTypes?.employmentTypeList
          ?.employmentTypes || []
      );
  }, [employmentTypesData]);

  useEffect(() => {
    if (workShiftsData)
      setWorkShiftsList(
        workShiftsData?.GetAllWorkShifts?.workShiftsList?.workShifts || []
      );
  }, [workShiftsData]);

  useEffect(() => {
    if (contractorsData)
      setContractorsList(
        contractorsData?.GetContractorsList?.contractorsList?.contractors || []
      );
  }, [contractorsData]);

  const resourceTypeList = [
    { label: t("table.filters.all"), value: "" },
    {
      label: Mnemonics.ResourceTypes.Employee,
      value: Mnemonics.ResourceTypes.Employee,
    },
    {
      label: Mnemonics.ResourceTypes.External,
      value: Mnemonics.ResourceTypes.External,
    },
  ];

  const filtersObject = [
    {
      type: "text",
      label: "employee.list.filters.name",
      name: "name",
    },
    {
      type: "text",
      label: "employee.list.filters.email",
      name: "email",
    },
    {
      label: "employee.list.filters.employmentTypes",
      name: "employmentTypeIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.name : ""),
      options: employmentTypesList,
      access: "employmentTypeGuid",
      loading: employmentTypeLoading,
      fetchData: setFetchEmploymentTypes,
    },
    {
      label: "employee.list.filters.workShifts",
      name: "workShiftIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.description : ""),
      options: workShiftsList,
      access: "workShiftGuid",
      loading: workShiftLoading,
      fetchData: setFetchWorkShifts,
    },
    {
      label: "employee.list.filters.managers",
      name: "managerIDs",
      type: "multiple",
      getOptionLabel: (option) =>
        option ? option.firstName + " " + option.lastName : "",
      options: managersList,
      access: "employeeGuid",
      loading: managerLoading,
      fetchData: setFetchManagers,
    },
    {
      label: "employee.list.filters.company",
      name: "companyIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.name : ""),
      options: companiesList,
      access: "companyGuid",
      loading: companyLoading,
      fetchData: setFetchCompanies,
    },
    {
      label: "employee.list.filters.departments",
      name: "departmentIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.name : ""),
      options: departmentsList,
      access: "departmentGuid",
      loading: departmentLoading,
      fetchData: setFetchDepartments,
    },
    {
      label: "employee.list.filters.workTypes",
      name: "workTypeIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.description : ""),
      options: workTypesList,
      access: "workTypeGuid",
      loading: workTypeLoading,
      fetchData: setFetchWorkTypes,
    },

    {
      label: "employee.list.filters.holidays",
      name: "holidayIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.description : ""),
      options: holidaysList,
      access: "officialHolidayGuid",
      loading: holidayLoading,
      fetchData: setFetchOfficialHolidays,
    },
    {
      label: "employee.list.filters.Status",
      name: "profileStatusIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.description : ""),
      options: employeeStatusesList,
      access: "employeeStatusGuid",
      loading: employmentStatusLoading,
    },
    {
      label: "employee.list.filters.employmentStatus",
      name: "employmentStatusIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.description : ""),
      options: employmentStatusesList,
      access: "employementStatusGuid",
      loading: employmentStatusesLoading,
      fetchData: setFetchEmploymentStatuses,
    },
    {
      label: "employee.list.filters.contractor",
      name: "contractorsIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.name : ""),
      options: contractorsList,
      access: "contractorGuid",
      loading: contractorsLoading,
      fetchData: setFetchContractors,
    },
    {
      label: "employee.list.filters.resourceType",
      name: "resourceType",
      type: "dropdown",
      options: resourceTypeList,
    },
    {
      label: "employee.list.filters.roles",
      name: "rolesIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.name : ""),
      options: rolesList,
      access: "roleGuid",
      loading: roleLoading,
      fetchData: setFetchRoles,
    },
    {
      label: "employee.list.filters.timezone",
      name: "timeZoneIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.value : ""),
      options: timezonesList,
      access: "timezoneGuid",
      loading: timezonesLoading,
      fetchData: setFetchTimezone,
    },
    {
      label: "employee.list.filters.location",
      name: "locationIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.name : ""),
      options: locationsList,
      access: "locationGuid",
      loading: locationLoading,
      fetchData: setFetchLocations,
    },
    {
      label: "employee.list.filters.manager",
      name: "directManagerIDs",
      type: "multiple",
      getOptionLabel: (option) =>
        option ? option.firstName + " " + option.lastName : "",
      options: managersList,
      access: "employeeGuid",
      loading: managerLoading,
      fetchData: setFetchManagers,
    },

    {
      type: "date",
      label: "employee.list.filters.date",
      name: "employmentDate",
    },
    {
      type: "text",
      label: "employee.list.filters.position",
      name: "position",
    },
    {
      type: "check",
      label: "employee.list.filters.isManager",
      name: "isManager",
      access: "isManager",
    },
    {
      type: "check",
      label: "employee.list.filters.isAppUser",
      name: "isAppUser",
      access: "isAppUser",
    },
    {
      type: "check",
      label: "employee.list.filters.isAutoClockin",
      name: "isAutoClockin",
      access: "isAutoClockin",
    },
  ];

  const {
    mfunction: getAllEmployees,
    loading,
    refetch: refetchData,
  } = useLazyQueryAuth(GET_ALL_EMPLOYEES, "GetCompanyUsersList");

  const { mfunction: deleteUser } = useMutationAuth(
    DELETE_EMPLOYEES,
    "DeleteUsers"
  );
  const { mfunction: changeStatus } = useMutationAuth(
    CHANGE_EMPLOYEE_STATUS,
    "ChangeUserStatus"
  );

  const { mfunction: resendActivationEmail } = useMutationAuth(
    RESEND_ACTIVATION_EMAIL,
    "ResetPasswordForNewUser"
  );

  function deactivateUser(id) {
    changeStatus(
      {
        variables: {
          model: {
            activate: false,
            userID: id,
          },
        },
      },
      (response) => {
        refetchData();
        enqueueSnackbar(t("employeeForm.deactivatedSuccessfully"), {
          variant: "success",
        });
        setSelected([]);
      }
    );
  }

  const handleResendActivationEmail = (email) => {
    resendActivationEmail(
      {
        variables: {
          model: {
            email: email,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("employeeForm.resentSuccessfully"), {
          variant: "success",
        });
        setSelected([]);
      }
    );
  };

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllEmployees(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyWord: search,
              tenantID: myEmployees
                ? storeUserTenant
                : tID && fromTenant
                ? tID
                : undefined,
              userID: storeUserGuid,
              currentUserID: storeUserGuid,
              filters: filters,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );
  // useEffect(() => {
  //   if (!columnsWait) refetchData();
  // }, []);

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData, re]);

  const rows =
    data &&
    data.GetCompanyUsersList &&
    data.GetCompanyUsersList &&
    data.GetCompanyUsersList.usersList
      ? data.GetCompanyUsersList.usersList.employees
      : [];
  const numberOfRecords =
    data &&
    data.GetCompanyUsersList &&
    data.GetCompanyUsersList &&
    data.GetCompanyUsersList.usersList.totalNumberOfRecords
      ? data.GetCompanyUsersList.usersList.totalNumberOfRecords
      : 0;

  function activateUser(id) {
    changeStatus(
      {
        variables: {
          model: {
            activate: true,
            userID: id,
          },
        },
      },
      (response) => {
        refetchData();
        enqueueSnackbar(t("employeeForm.activatedSuccessfully"), {
          variant: "success",
        });
      }
    );
  }

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            users: selected,
          },
        },
      },
      () => {
        enqueueSnackbar(t("employee.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  };

  const handleSave = () => {
    const json = {
      columns,
      defaultSort: orderBy,
      defaultSortDirection: order,
      tile: !showAsTile,
    };
    const stringified = JSON.stringify(json, Stringify);
    saveUserPreferences(
      {
        variables: {
          model: {
            columns: stringified,
            defaultSort: orderBy,
            defaultSortDirection: order,
            tablePreference: Mnemonic.Tables["Employees"],
            userID: storeUserGuid,
          },
        },
      },
      () => {
        refetch();
        setShowAsTile(!showAsTile);
      }
    );
  };

  // useEffect(() => {
  //   const objectEmploymentStatus = filterState.findIndex(
  //     (obj) => obj.property === "employmentStatusIDs"
  //   );

  //   const employmentGuidsArray = employmentStatusesList
  //     ?.filter((stat) => stat.mnemonic === Mnemonics.EmploymentStatuses.Employed || stat.mnemonic === Mnemonics.EmploymentStatuses.Probation)
  //     .map((stat) => stat.employementStatusGuid);

  //   if (employmentStatusesList.length > 0) {
  //     if (objectEmploymentStatus < 0) {
  //       setDefaultFilters({ ...defaultFilters, employmentStatusIDs: employmentGuidsArray });
  //       const employmentIDsObj = { value: employmentGuidsArray, property: "employmentStatusIDs" };
  //       setFilterState([...filterState, employmentIDsObj]);
  //     } else {
  //       const val =
  //         filterState[objectEmploymentStatus].value == null
  //           ? null
  //           : [filterState[objectEmploymentStatus].value];
  //       const employmentIDsObj = { value: val, property: "employmentStatusIDs" };
  //       setDefaultFilters({ ...defaultFilters, employmentStatusIDs: val });
  //       setFilterState([...filterState, employmentIDsObj]);
  //     }
  //   }

  // }, [employmentStatusesList.length]);

  // useEffect(() => {
  //   const objectStatus = filterState.findIndex(
  //     (obj) => obj.property === "profileStatusIDs"
  //   );

  //   const employeeStatusGuidsArr = employeeStatusesList
  //     ?.filter((emp) => emp.mnemonic === Mnemonics.EmployeeStatuses.new || emp.mnemonic === Mnemonics.EmployeeStatuses.active)
  //     .map((emp) => emp.employeeStatusGuid);

  //   if (employeeStatusesList.length > 0) {
  //     if (objectStatus < 0) {
  //       setDefaultFilters({ ...defaultFilters, profileStatusIDs: employeeStatusGuidsArr });
  //       const employeeStatusObj = { value: employeeStatusGuidsArr, property: "profileStatusIDs" };
  //       setFilterState([...filterState, employeeStatusObj]);
  //     } else {
  //       const val =
  //         filterState[objectStatus].value == null
  //           ? null
  //           : [filterState[objectStatus].value];
  //       const employeeStatusObj = { value: val, property: "profileStatusIDs" };
  //       setDefaultFilters({ ...defaultFilters, profileStatusIDs: val });

  //       setFilterState([...filterState, employeeStatusObj]);
  //     }
  //   }
  // }, [employeeStatusesList.length]);

  //COMMENTED TO INTRODUCE THE SAVED FILTERS
  // // // // // useEffect(() => {
  // // // // //   const objectEmploymentStatus = filterState.findIndex(
  // // // // //     (obj) => obj.property === "employmentStatusIDs"
  // // // // //   );
  // // // // //   const objectStatus = filterState.findIndex(
  // // // // //     (obj) => obj.property === "profileStatusIDs"
  // // // // //   );
  // // // // //   const objectResourceType = filterState.findIndex(
  // // // // //     (obj) => obj.property === "resourceType"
  // // // // //   );

  // // // // //   const employeeStatusGuidsArr = employeeStatusesList
  // // // // //     ?.filter(
  // // // // //       (emp) =>
  // // // // //         emp.mnemonic === Mnemonics.EmployeeStatuses.new ||
  // // // // //         emp.mnemonic === Mnemonics.EmployeeStatuses.active
  // // // // //     )
  // // // // //     .map((emp) => emp.employeeStatusGuid);

  // // // // //   const employmentGuidsArray = employmentStatusesList
  // // // // //     ?.filter(
  // // // // //       (stat) =>
  // // // // //         stat.mnemonic === Mnemonics.EmploymentStatuses.Employed ||
  // // // // //         stat.mnemonic === Mnemonics.EmploymentStatuses.Probation
  // // // // //     )
  // // // // //     .map((stat) => stat.employementStatusGuid);

  // // // // //   const employmentIDsObj = {
  // // // // //     value: employmentGuidsArray,
  // // // // //     property: "employmentStatusIDs",
  // // // // //   };
  // // // // //   const employeeStatusObj = {
  // // // // //     value: employeeStatusGuidsArr,
  // // // // //     property: "profileStatusIDs",
  // // // // //   };
  // // // // //   const resourceTypeObj = { value: "Employee", property: "resourceType" };

  // // // // //   if (employmentStatusesList.length > 0 && employeeStatusesList.length > 0) {
  // // // // //     if (
  // // // // //       objectEmploymentStatus < 0 &&
  // // // // //       objectStatus < 0 &&
  // // // // //       objectResourceType < 0
  // // // // //     ) {
  // // // // //       setDefaultFilters({
  // // // // //         ...defaultFilters,
  // // // // //         employmentStatusIDs: employmentGuidsArray,
  // // // // //         profileStatusIDs: employeeStatusGuidsArr,
  // // // // //         resourceType: "Employee",
  // // // // //       });
  // // // // //       setFilterState([
  // // // // //         ...filterState,
  // // // // //         employmentIDsObj,
  // // // // //         employeeStatusObj,
  // // // // //         resourceTypeObj,
  // // // // //       ]);
  // // // // //     } else if (objectEmploymentStatus < 0 && objectStatus < 0) {
  // // // // //       setDefaultFilters({
  // // // // //         ...defaultFilters,
  // // // // //         employmentStatusIDs: employmentGuidsArray,
  // // // // //         profileStatusIDs: employeeStatusGuidsArr,
  // // // // //       });
  // // // // //       setFilterState([...filterState, employmentIDsObj, employeeStatusObj]);
  // // // // //     } else if (objectEmploymentStatus < 0 && objectResourceType < 0) {
  // // // // //       setDefaultFilters({
  // // // // //         ...defaultFilters,
  // // // // //         employmentStatusIDs: employmentGuidsArray,
  // // // // //         resourceType: "Employee",
  // // // // //       });
  // // // // //       setFilterState([...filterState, employmentIDsObj, resourceTypeObj]);
  // // // // //     } else if (objectStatus < 0 && objectResourceType < 0) {
  // // // // //       setDefaultFilters({
  // // // // //         ...defaultFilters,
  // // // // //         profileStatusIDs: employeeStatusGuidsArr,
  // // // // //         resourceType: "Employee",
  // // // // //       });
  // // // // //       setFilterState([...filterState, employmentIDsObj]);
  // // // // //     } else if (objectEmploymentStatus < 0) {
  // // // // //       setDefaultFilters({
  // // // // //         ...defaultFilters,
  // // // // //         employmentStatusIDs: employmentGuidsArray,
  // // // // //       });
  // // // // //       setFilterState([...filterState, employmentIDsObj]);
  // // // // //     } else if (objectStatus < 0) {
  // // // // //       setDefaultFilters({
  // // // // //         ...defaultFilters,
  // // // // //         profileStatusIDs: employeeStatusGuidsArr,
  // // // // //       });
  // // // // //       setFilterState([...filterState, employeeStatusObj]);
  // // // // //     }
  // // // // //   } else if (employmentStatusesList.length > 0) {
  // // // // //     if (objectEmploymentStatus < 0) {
  // // // // //       setDefaultFilters({
  // // // // //         ...defaultFilters,
  // // // // //         employmentStatusIDs: employmentGuidsArray,
  // // // // //       });
  // // // // //       setFilterState([...filterState, employmentIDsObj]);
  // // // // //     }
  // // // // //   } else if (employeeStatusesList.length > 0) {
  // // // // //     if (objectStatus < 0) {
  // // // // //       setDefaultFilters({
  // // // // //         ...defaultFilters,
  // // // // //         profileStatusIDs: employeeStatusGuidsArr,
  // // // // //       });
  // // // // //       setFilterState([...filterState, employeeStatusObj]);
  // // // // //     }
  // // // // //   }
  // // // // // }, [employeeStatusesList, employmentStatusesList]);

  const customButtons = [
    {
      label: "table.squares",
      handleClick: () => handleSave(),
      condition: true,
      icon: true,
      iconButton: <BacklogIcon color="#000000" />,
    },
    {
      label: "deactivate.user.button",
      handleClick: () => deactivateUser(selected[0]),
      condition:
        !showAsTile &&
        selected &&
        selected.length > 0 &&
        rows.filter((x) => x.employeeGuid === selected[0])[0]?.employeeStatus
          ?.mnemonic === "active",
      icon: true,
      iconButton: <DeactivateUserIcon color="#000000" />,
    },
    {
      label: "email.user.button",
      handleClick: () =>
        handleResendActivationEmail(
          rows.filter((x) => x.employeeGuid === selected[0])[0]?.email
        ),
      condition:
        !showAsTile &&
        selected &&
        selected.length > 0 &&
        rows.filter((x) => x.employeeGuid === selected[0])[0]?.employeeStatus
          ?.mnemonic === "new",
      icon: true,
      iconButton: <EmailIcon color="#000000" />,
    },
  ];

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: fromTenant
      ? "employee.list.search.label.fromTenant"
      : "employee.list.search.label",
    refetch,
    tableMnemonic: Mnemonic.Tables["Employees"],
    filterState,
    filtersObject,
    generateRoute: (row) =>
      myEmployees
        ? !fromTenant
          ? my_employee_form.replace(
              "/:eName/:eID",
              `/${
                encodeURIComponent(row?.firstName) +
                " " +
                encodeURIComponent(row?.lastName)
              }/${encodeURIComponent(row?.employeeGuid)}`
            )
          : my_employee_form.replace(
              "/:eName/:eID",
              `/${
                encodeURIComponent(row?.firstName) +
                " " +
                encodeURIComponent(row?.lastName)
              }/${encodeURIComponent(row?.employeeGuid)}`
            )
        : !fromTenant
        ? employee_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:eName/:eID",
              `/${
                encodeURIComponent(row?.firstName) +
                " " +
                encodeURIComponent(row?.lastName)
              }/${encodeURIComponent(row?.employeeGuid)}`
            )
        : employee_form
            .replace(
              "/:tName/:tID",
              `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            )
            .replace(
              "/:eName/:eID",
              `/${
                encodeURIComponent(row?.firstName) +
                " " +
                encodeURIComponent(row?.lastName)
              }/${encodeURIComponent(row?.employeeGuid)}`
            ),
    ...(manageUsers === "M"
      ? {
          newText: "employee.list.new.label",
          newLink: myEmployees
            ? my_new_employee
            : new_employee.replace(
                "/:tName/:tID",
                `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
              ),
        }
      : {}),
    deleteText:
      rows.filter((x) => x.employeeGuid === selected[0])[0]?.employeeStatus
        ?.mnemonic === "new"
        ? "employee.list.delete.label"
        : "",
    handleDelete: handleDelete,
    deleteLoading,
    readOnly: manageUsers !== "M",
    customButtons,
    singleSelect: true,
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : showAsTile === false ? (
        <DataTable {...pagination} identifier={"employeeGuid"} hasCheckbox />
      ) : (
        <InfoCard
          {...pagination}
          activateUser={activateUser}
          deleteUser={deleteUser}
          refetchData={refetchData}
          deactivateUser={deactivateUser}
          handleResendActivationEmail={handleResendActivationEmail}
          disableAction={(row) => !isAdmin && row?.role?.isAdmin}
        />
      )}
    </div>
  );
};

export default EmployeesList;
