import React, { useEffect, useState, useRef } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Modal, Grid, CircularProgress, Typography } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";
import { Title } from "../../../../components/Titles/FormTitles";

//pages
import Main from "./components/main";
import SideInfo from "./components/sideInfo";
import ProfilePhoto from "./components/profilePhoto";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";

//redux
import { useDispatch } from "react-redux";
import { getEmployee } from "../../../../app/reducers/employeeSlice";

//graphql
import {
  GET_EMPLOYEE_BY_ID,
  UPDATE_EMPLOYEE,
  CREATE_EMPLOYEE,
  CREATE_APP_USER,
} from "../../../../graphql/employee";

//urls
import urls from "../../../../urls";

import ContainerCard from "../../../../components/Cards/ContainerCard";

import UTC2Local, { formatDateTimeZone } from "../../../../UTC2Local";

import Mnemonic from "../../../../Mnemonics.json";
import ConfirmationModal from "../../../../components/Modal/ConfirmationModal";
import CustomButton from "../../../../components/CustomButton";
import colors from "../../../../assets/theme/light/colors";

const EmployeeForm = ({
  myEmployees = false,
  fromTenant = false,
  fromProfile = false,
}) => {
  const { eName, eID, tID, tName, profileID } = useParams();

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageUsers } = userRoles;

  const createMode = !eName && !eID && !profileID;

  const [linkedUsers, setLinkedUsers] = useState([]);

  const [unTouchedForm, setUnTouchedForm] = useState(true);

  const {
    employee_form,
    employees_list,
    my_employees_list,
    my_employee_form,
    my_profile,
  } = urls;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { formField } = form;

  const [sideInfoData, setSideInfoData] = useState({});
  const [employeeGuid, setEmployeeGuid] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    department,
    directManager,
    defaultWorkCompany,
    isAutoClockIn,
    isAppUser,
    isOffice365User,
    workShift,
    workType,
    preferEmail,
    preferSMS,
    employmentType,
    role,
    homeLocation,
    employmentDate,
    tenant,
    position,
    isManager,
    profilePicture,
    employeeStatuses,
    probation,
    attendance,
    timeEntry,
    officialHoliday,
    resignationDate,
    inactiveDate,
    timeZone,
    resourceType,
    contractor,
    canCreateKiosk
  } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);
  const [isAppUserState, setIsAppUserState] = useState(false);

  const [PTOs, setPTOs] = useState([]);
  const [displayTable, setDisplayTable] = useState(false);
  const [profilePictureState, setProfilePictureState] = useState(null);
  const [employeeStatus, setEmployeeStatus] = useState(null);
  const [employeePTOs, setEmployeePTOs] = useState([]);
  const [oldEmploymentDate, setOldEmploymentDate] = useState(null);

  const formikValuesRef = useRef(null);
  const formikActionsRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const {
    loading: getDataByIDLoading,
    tokenChange: wait,
    reset,
  } = useQueryAuth(
    GET_EMPLOYEE_BY_ID,
    "GetUserByID",
    {
      variables: { model: { userId: eID ? eID : profileID } },
      skip: createMode,
    },
    {
      onCompleted: (response) => {
        dispatch(getEmployee(response.GetUserByID.userView.user));
        const data = response?.GetUserByID?.userView.user || {};
        setEmployeePTOs(response?.GetUserByID?.userView?.user?.employeePTOs)
        setOldEmploymentDate(response?.GetUserByID?.userView?.user?.employmentDate)

        
        setFormData({
          [firstName.name]: data.firstName || "",
          [lastName.name]: data.lastName || "",
          [email.name]: data.email || "",
          [phoneNumber.name]: data.phoneNumber || "",
          [department.name]: data.department || "",
          [defaultWorkCompany.name]: data.defaultWorkCompany || "",
          [tenant.name]: data?.defaultWorkCompany?.tenant || null,
          [isAutoClockIn.name]: data.isAutoClockIn || false,
          [isAppUser.name]: data.role?.isAppUser || false,
          [isManager.name]: data.isManager || false,
          [isOffice365User.name]: data.isOffice365User || false,
          [preferEmail.name]: data.preferEmail || false,
          [preferSMS.name]: data.preferSMS || false,
          [attendance.name]: data.attendance || false,
          [timeEntry.name]: data.timeEntry || false,
          [workShift.name]: data.workShift || "",
          [workType.name]: data.workType || "",
          [employmentType.name]: data.employmentType || "",
          [role.name]: data.role || "",
          [directManager.name]: data.manager || null,
          [homeLocation.name]: data.location || null,
          [timeZone.name]: data.timezone || null,
          [employmentDate.name]:
            new Date(formatDateTimeZone(data.employmentDate)) || null,
          [position.name]: data.position || "",
          [profilePicture.name]: data.profilePictureURL || "",
          // [regularPayExternalReference.name]:
          //   data.regularPayExternalReference || "",
          // [overtimeExternalReference.name]:
          //   data.overtimeExternalReference || "",
          [officialHoliday.name]: data.officialHoliday || null,
          [employeeStatuses.name]: data.employmentStatus || null,
          [probation.name]: data.probation || null,
          // [overtimeExternalReference.name]: data?.overtimeExternalResource || null,
          // [regularPayExternalReference.name]: data?.regularPayExternalResource || null
          [resignationDate.name]: data.resignationDate
            ? new Date(formatDateTimeZone(data.resignationDate))
            : null,
          [inactiveDate.name]: data.inactiveDate
            ? new Date(formatDateTimeZone(data.inactiveDate))
            : null,
          [resourceType.name]: data.resourceType || "",
          [contractor.name]: data.contractor || "",
          [canCreateKiosk.name]:data.canCreateKiosk
        });

        // setRegularPayExternalReferenceCode(data?.regularPayExternalReference);
        // setOvertimeExternalReferenceCode(data?.overtimeExternalReference);
        setEmployeeStatus(data?.employeeStatus || null);
        setEmployeeGuid(data.employeeGuid);
        setIsAppUserState(data.role.isAppUser);
        setProfilePictureState(data.profilePictureURL);

        if (data.createdByUser) {
          sideInfoData["employeeForm.createdBy"] = data.createdByUser.email;
        } else {
          sideInfoData["employeeForm.createdBy"] = "";
        }

        sideInfoData["employeeForm.creationDate"] = UTC2Local(
          data.creationDate
        );
        if (data.modifiedByUser) {
          sideInfoData["employeeForm.modifiedBy"] = data.modifiedByUser.email;
        } else {
          sideInfoData["employeeForm.modifiedBy"] = "";
        }

        sideInfoData["employeeForm.modificationDate"] = UTC2Local(
          data.modificationDate
        );

        if (data.employeeStatus.description === "Inactive") {
          sideInfoData["employeeForm.deactivationDate"] = UTC2Local(
            data.inactiveDate
          );
        }

        setSideInfoData(sideInfoData);
      },
    }
  );

 

  // useEffect(() => {
  //   if (!getDataByIDLoading && !createMode)
  //     refetchData();
  // }, []);

  const { cfunction: editUserData } = useMutationAuth(
    UPDATE_EMPLOYEE,
    "EditUser"
  );

  const { cfunction: createUserAppData } = useMutationAuth(
    CREATE_APP_USER,
    "CreateAppUser"
  );

  const { cfunction: createUserData } = useMutationAuth(
    CREATE_EMPLOYEE,
    "CreateUser"
  );

  function createAppUser(values, actions) {
    // const newPTOList = PTOs
    //   ? PTOs.map((val) => {
    //       return val.ptoGuid;
    //     })
    //   : [];

    createUserAppData(
      {
        variables: {
          model: {
            createdByID: storeUserGuid,
            firstName: values[firstName.name],
            lastName: values[lastName.name],
            email: values[email.name] ? values[email.name] : null,
            phoneNumber: values[phoneNumber.name],
            employmentTypeID: values[employmentType.name]?.employmentTypeGuid,
            departmentGuid: values[department.name]?.departmentGuid,
            isAutoClockIn: values[isAutoClockIn.name],
            preferEmail: values[preferEmail.name],
            preferSMS: values[preferSMS.name],
            attendance: values[attendance.name],
            timeEntry: values[timeEntry.name],
            isManager: values[isManager.name],
            defaultWorkCompanyGuid:
              values[defaultWorkCompany.name]?.companyGuid,
            isAppUser: isAppUserState,
            roleID: !isAppUserState ? values[role.name]?.roleGuid : null,
            workShiftGuid: values[workShift.name]?.workShiftGuid,
            workTypeID: values[workType.name]?.workTypeGuid,
            directManagerID: values[directManager.name]?.employeeGuid,
            tenantID: fromTenant ? tID : storeUserTenant,
            homeLocationID: values[homeLocation.name]
              ? values[homeLocation.name].locationGuid
              : null,
            timeZoneGuid: values[timeZone.name]?.timezoneGuid,
            employmentDate: new Date(values[employmentDate.name]).toISOString(),
            // listOfPTOGuid: newPTOList,
            position: values[position.name],
            officialHoliday: values[officialHoliday.name]?.officialHolidayGuid,
            contractorGuid: values[contractor.name]?.contractorGuid,
            employmentStatusGuid:
              values[employeeStatuses.name]?.employementStatusGuid,
            probation:
              values[probation.name] &&
                values[employeeStatuses.name].mnemonic ===
                Mnemonic.EmploymentStatuses.Probation
                ? parseInt(values[probation.name])
                : null,
            resourceType: values[resourceType.name],
            canCreateKiosk: values[canCreateKiosk.name],
            resignationDate:
              values[resignationDate.name] &&
                values[employeeStatuses.name].mnemonic ===
                Mnemonic.EmploymentStatuses.Resigned
                ? new Date(values[resignationDate.name]).toISOString()
                : null,
          },
        },
      },
      (response) => {
        if (
          response["CreateAppUser"].errorCode !== null &&
          response["CreateAppUser"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateAppUser"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("employeeForm.createdSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
          navigate(
            myEmployees
              ? my_employees_list.replace(
                "/:tName/:tID",
                `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
              )
              : employees_list.replace(
                "/:tName/:tID",
                `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
              )
          );
        }
        actions.setSubmitting(false);
      },
      () => {
        actions.setSubmitting(false);
      }
    );
  }

  function createUser(values, actions) {
    // const newPTOList = PTOs
    //   ? PTOs.map((val) => {
    //       return val.ptoGuid;
    //     })
    //   : [];

    createUserData(
      {
        variables: {
          model: {
            createdByID: storeUserGuid,
            firstName: values[firstName.name],
            lastName: values[lastName.name],
            email: values[email.name],
            phoneNumber: values[phoneNumber.name],
            isOffice365User: values[isOffice365User.name],
            preferEmail: values[preferEmail.name],
            preferSMS: values[preferSMS.name],
            attendance: values[attendance.name],
            timeEntry: values[timeEntry.name],
            employmentTypeID: values[employmentType.name]?.employmentTypeGuid,
            departmentGuid: values[department.name]?.departmentGuid,
            isAutoClockIn: values[isAutoClockIn.name],
            isManager: values[isManager.name],
            defaultWorkCompanyGuid:
              values[defaultWorkCompany.name]?.companyGuid,
            workShiftGuid: values[workShift.name]?.workShiftGuid,
            workTypeID: values[workType.name]?.workTypeGuid,
            directManagerID: values[directManager.name]?.employeeGuid,
            roleID: values[role.name]?.roleGuid,
            tenantGuid: fromTenant ? tID : !fromTenant ? storeUserTenant : null,
            homeLocationID: values[homeLocation.name]
              ? values[homeLocation.name]?.locationGuid
              : null,
            timeZoneGuid: values[timeZone.name]?.timezoneGuid,
            employmentDate: new Date(oldEmploymentDate).toISOString(),
            // listOfPTOGuid: newPTOList,
            position: values[position.name],
            contractorGuid: values[contractor.name]?.contractorGuid,
            officialHoliday: values[officialHoliday.name]?.officialHolidayGuid,
            employmentStatusGuid:
              values[employeeStatuses.name]?.employementStatusGuid,
            canCreateKiosk: values[canCreateKiosk.name],
            probation:
              values[probation.name] &&
                values[employeeStatuses.name]?.mnemonic ===
                Mnemonic.EmploymentStatuses.Probation
                ? parseInt(values[probation.name])
                : null,
            resourceType: values[resourceType.name],
            resignationDate:
              values[resignationDate.name] &&
                values[employeeStatuses.name].mnemonic ===
                Mnemonic.EmploymentStatuses.Resigned
                ? new Date(values[resignationDate.name]).toISOString()
                : null,
          },
        },
      },
      (response) => {
        if (
          response["CreateUser"].errorCode !== null &&
          response["CreateUser"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateUser"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("employeeForm.createdSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
          navigate(
            myEmployees
              ? my_employees_list.replace(
                "/:tName/:tID",
                `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
              )
              : employees_list.replace(
                "/:tName/:tID",
                `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
              )
          );
        }
        actions.setSubmitting(false);
      },
      () => {
        actions.setSubmitting(false);
      }
    );
  }

  function editUser(values, actions, type) {

   
    editUserData(
      {
        variables: {
          model: {
            userID: storeUserGuid,
            employeeGuid: employeeGuid,
            firstName: values[firstName.name] || null,
            lastName: values[lastName.name],
            email: values[email.name],
            phoneNumber: values[phoneNumber.name],
            isOffice365User: values[isOffice365User.name],
            attendance: values[attendance.name],
            timeEntry: values[timeEntry.name],
            preferEmail: values[preferEmail.name],
            preferSMS: values[preferSMS.name],
            employmentTypeID: values[employmentType.name]?.employmentTypeGuid,
            departmentGuid: values[department.name]?.departmentGuid,
            isAutoClockIn: values[isAutoClockIn.name],
            isAppUser: isAppUserState,
            defaultWorkCompanyGuid:
              values[defaultWorkCompany.name]?.companyGuid,
            roleID: !isAppUserState ? values[role.name]?.roleGuid : null,
            workShiftGuid: values[workShift.name]?.workShiftGuid,
            workTypeID: values[workType.name]?.workTypeGuid,
            directManagerID: values[directManager.name]?.employeeGuid,
            canCreateKiosk: values[canCreateKiosk.name],
            homeLocationID: values[homeLocation.name]
              ? values[homeLocation.name]?.locationGuid
              : null,
            timeZoneGuid: values[timeZone.name]?.timezoneGuid,
            employmentDate: type === "proceed" ? new Date(values[employmentDate.name]).toISOString() : new Date(oldEmploymentDate).toISOString(),
            position: values[position.name],
            isManager: values[isManager.name],
            officialHoliday: values[officialHoliday.name]?.officialHolidayGuid,
            contractorGuid: values[contractor.name]?.contractorGuid,
            employmentStatusGuid:
              values[employeeStatuses.name]?.employementStatusGuid,
            probation:
              values[probation.name] &&
                values[employeeStatuses.name]?.mnemonic ===
                Mnemonic.EmploymentStatuses.Probation
                ? parseInt(values[probation.name])
                : null,
            resourceType: values[resourceType.name],
            resignationDate:
              values[resignationDate.name] &&
                values[employeeStatuses.name]?.mnemonic ===
                Mnemonic.EmploymentStatuses.Resigned
                ? new Date(values[resignationDate.name]).toISOString()
                : null,
            tenantGuid: fromTenant ? tID : !fromTenant ? storeUserTenant : null,
          },
        },
      },
      (response) => {

        
        const linkU = response?.EditUser?.userView?.linkedUsers || [];
        if (
          response["EditUser"].errorCode !== null &&
          response["EditUser"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["EditUser"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else if (linkU && linkU.length > 0) {
          setLinkedUsers(linkU);
        } else {
          enqueueSnackbar(t("companyForm.updatedSuccessfully"), {
            variant: "success",
          });
          setOldEmploymentDate(values[employmentDate.name]);
          sessionStorage.removeItem("unsavedChanges");
          navigate(
            fromProfile
              ? my_profile.replace(
                "/:profileName/:profileID",
                `/${encodeURIComponent(
                  values[firstName.name] + " " + values[lastName.name]
                )}/${encodeURIComponent(profileID)}`
              )
              : myEmployees
                ? my_employee_form.replace(
                  "/:eName/:eID",
                  `/${encodeURIComponent(
                    values[firstName.name] + " " + values[lastName.name]
                  )}/${encodeURIComponent(eID)}`
                )
                : employee_form
                  .replace(
                    "/:tName/:tID",
                    `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
                  )
                  .replace(
                    "/:eName/:eID",
                    `/${encodeURIComponent(
                      values[firstName.name] + " " + values[lastName.name]
                    )}/${encodeURIComponent(eID)}`
                  )
          );
        }
        actions?.setSubmitting(false);
      },
      () => {
        actions?.setSubmitting(false);
      }
    );
  }

  useEffect(() => {
    if (performance.navigation.type === performance.navigation.TYPE_NAVIGATE && unTouchedForm) {
      sessionStorage.clear();
    }
  }, [])




  const handleSubmit = (values, actions) => {
  
    const originalEmploymentDate = values[employmentDate.name];

    if (UTC2Local(oldEmploymentDate, true) !== UTC2Local(originalEmploymentDate, true) && originalEmploymentDate !== null && !createMode) {
      if (employeePTOs.length > 0) {
        formikValuesRef.current = values;
        formikActionsRef.current = actions;
        handleOpenDialog();
    } 
    }
    else {
      if (createMode) {
        isAppUserState ? createAppUser(values, actions) : createUser(values, actions);
      } else {
        editUser(values, actions, null);
      }
    }
  };



  const handleChangeFlag = (touched) => {
    if (!fromProfile && touched) {
      sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
      setUnTouchedForm(false);
    }
  };


  //confirmationPopup

  const handleOpenDialog = () => setOpenDialog(true);

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  //   setOldEmploymentDate(null);
  //   formikActionsRef.current?.setSubmitting(false);
  //   sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
  // };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    if (formikActionsRef.current) {
      formikActionsRef.current.setFieldValue(employmentDate.name, new Date(formatDateTimeZone(oldEmploymentDate)));
    }
    formikActionsRef.current?.setSubmitting(false);
    sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
  };
  
  
  const handleProceed = () => {
    editUser(formikValuesRef.current, null, "proceed")
    setOpenDialog(false);
    formikActionsRef.current?.setSubmitting(false);

  };


  const customModalButtons = () => (
    <div>
      <CustomButton onClick={handleProceed} label={t("dialog.proceed")} />
    
        <CustomButton color={colors.error_light}  onClick={handleCloseDialog} label={t("dialog.dontproceed")} />
      
    </div>
  );


  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard
          sx={{
            height: "50vh",
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <>
          <ConfirmationModal
            contentText={t("dialog.user.change.date.confirmation")}
            title={t("dialog.warning")}
            icon={"warning"}
            openDialog={openDialog}
            handleClose={handleCloseDialog}
            customButtons={customModalButtons}
            t={t}
            justifyButtons={"space-around"}
          />
          <Box mb={5}>
            <Modal
              open={linkedUsers && linkedUsers.length > 0}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ContainerCard
                sx={{
                  padding: "30px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
                className="linked-employee-card"
              >
                <div style={{ maxHeight: "500px", overFlowY: "auto" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Title style={{ padding: "10px 0" }}>
                        {t("employeeForm.linkedUsersTitle")}
                      </Title>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="p" component="p">
                        {t("employeeForm.linkedUsersText")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ul style={{ listStyleType: "none" }}>
                        {linkedUsers.map((user, index) => {
                          return (
                            <li key={index} style={{ marginBottom: "10px" }}>
                              {user.firstName} {user.lastName} ({user.email})
                            </li>
                          );
                        })}
                      </ul>
                    </Grid>
                  </Grid>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IALoadingButton
                    onClick={() => setLinkedUsers([])}
                    label={t("employeeForm.linkedUsersButton")}
                  />
                </div>
              </ContainerCard>
            </Modal>
            <Grid
              container
              justifyContent="center"
              alignItems="stretch"
              sx={{ height: "100%", mt: 2 }}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={createMode ? 12 : 9}
                xl={createMode ? 12 : 9}
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                <ContainerCard style={fromProfile ? { boxShadow: "none" } : {}}>
                  <Formik
                    innerRef={formikRef}
                    key={`${form.formID}-form-${createMode ? "creation" : "edit"
                      }`}
                    initialValues={formData}
                    validationSchema={
                      isAppUserState ? validation[1] : validation[0]
                    }
                    onSubmit={handleSubmit}
                    enableReinitialize
                  >
                    {({
                      values,
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                      setTouched,
                    }) => {
                      formikValuesRef.current = values;
                      return (
                        <Form
                          id={form?.formID}
                          autoComplete="off"
                          onChange={() => {
                            handleChangeFlag(touched);
                          }}
                        >
                          <Box p={2}>
                            <Box
                              //make text in my profile darker
                              sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                            >
                              <Main
                                formData={{
                                  values,
                                  touched,
                                  formField: form.formField,
                                  errors,
                                  setFieldValue,
                                  setTouched,
                                  createMode,
                                  wait,
                                  readOnly: manageUsers === "R",
                                  PTOs,
                                  setPTOs,
                                  displayTable,
                                  setDisplayTable,
                                  employeeStatus,
                                  setEmployeeStatus,
                                  fromProfile,
                                }}
                                isAppUserFlag={(isAppUser) =>
                                  setIsAppUserState(isAppUser)
                                }
                                fromTenant={fromTenant}
                                formFlag={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
                                handleChangeFlag={handleChangeFlag}
                              />
                            </Box>
                            <Box
                              mt={2}
                              width="100%"
                              display="flex"
                              justifyContent="flex-end"
                            >
                              <IALoadingButton
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                type="submit"
                                label={t("employeeForm.save")}
                              />
                            </Box>
                          </Box>
                        </Form>
                      )
                    }}
                  </Formik>
                </ContainerCard>
              </Grid>
              {!createMode ? (
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <ContainerCard
                    sx={{ boxShadow: "0px 0px 10px rgba(24,117,214,255)" }}
                  >
                    <Box p={3}>
                      <ProfilePhoto
                        myEmployees={myEmployees}
                        fromProfile={fromProfile}
                        profileData={profilePictureState}
                        setData={(profile) => setProfilePictureState(profile)}
                        refetchData={reset}
                        wait={wait}
                      />
                    </Box>
                  </ContainerCard>
                  <ContainerCard
                    sx={{
                      boxShadow: "0px 0px 10px rgba(24,117,214,255)",
                      marginTop: "20px",
                    }}
                  >
                    <Box p={3}>
                      <SideInfo data={Object.entries(sideInfoData)} />
                    </Box>
                  </ContainerCard>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default EmployeeForm;
