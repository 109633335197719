import React, { useState, useEffect, useCallback } from "react";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
//graphql
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";
import { GET_MIDDLEWARE_JOBS } from "../../../../graphql/middlewareJobs";
//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";

import { GET_ALL_LOGS_LIST, DELETE_LOGS } from "../../../../graphql/logs";
import { GET_ALL_TENANTS } from "../../../../graphql/tenant";

import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

const LogsList = ({ refetchTable = false, fromTenant = false }) => {
  const { log_details, my_log_details } = urls;

  const { storeUserGuid, userRoles } = useAuth();

  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [fetchMiddlewareJobsList, setFetchMiddlewareJobsList] = useState(null);
  const [typesList, setTypesList] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  
  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_LOGS,
    "DeleteLogsList"
  );

  const {
    data: tenantsData,
    loading: tenantsLoading,
    tokenChange: tenantsWait,
  } = useQueryAuth(GET_ALL_TENANTS, "GetAllTenants", {
    variables: {
      model: {
        enableFilters: false
      }
    },
    skip: !fromTenant,
    isWait: fromTenant
  });

  const tenantsList =
    tenantsData?.GetAllTenants?.tenantsList?.tenants;

  const {
    data: typesData,
    loading: typesLoading,
    tokenChange: typesWait,
  } = useQueryAuth(GET_MIDDLEWARE_JOBS, "GetMiddlewareJobsList", {
    skip: !fetchMiddlewareJobsList,
    isWait: fetchMiddlewareJobsList
  });

    useEffect(() => {
      if(typesData) setTypesList(typesData?.GetMiddlewareJobsList?.middlewareJobsList || [])
    }, [typesData])

  const filtersObject = [

    {
      label: "logs.list.filters.types",
      name: "middlewareJobs",
      type: "multiple",
      getOptionLabel: (option) =>
        option ? option.name : "",
      options: typesList,
      access: "middlewareJobGuid",
      loading: typesLoading,
      fetchData: setFetchMiddlewareJobsList,
    },
    {
      type: "date",
      label: "logs.list.filters.logDate",
      name: "logDateFrom",
    },
    {
      type: "date",
      label: "logs.list.filters.logEndDate",
      name: "logDateTo",
    },



  ]

  if (userRoles?.name !== "TenantAdmin") {
    filtersObject.splice(0, 0, {
      label: "logs.list.filters.tenants",
      name: "tenantIDs",
      type: "multiple",
      getOptionLabel: (option) =>
        option ? option.name : "",
      options: tenantsList,
      access: "tenantGuid",
      loading: tenantsLoading,
    },);
  }

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.LogsList,
          userID: storeUserGuid,
        },
      },
      skip: tenantsWait || typesWait,
      isWait: true
    },
    {
      onCompleted: (response) => {
        const col =
          response &&
            response.GetUserTablePreferences &&
            response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          const cols = parsed.columns;
          if (cols) {
            cols.map(col => {
              if(col.id == 'Tenant' && !fromTenant) col.visible = false
            });
            setColumns(cols);
          }

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }

      },
    }
  );

  const { mfunction: GetLogsList, loading, refetch: refetchData } = useLazyQueryAuth(
    GET_ALL_LOGS_LIST,
    "GetLogsList");

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      GetLogsList(
        {
          variables: {
            model: {
              userID: storeUserGuid,
              enablefilters: true,
              filters: filters,
              tenantID: userRoles?.name === "TenantAdmin" ? userRoles?.tenant?.tenantGuid : null,
              keyword: search,

              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
            }


          }
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData]);

  useEffect(() => {
    if (!columnsWait)
      refetchData();
  }, [refetchTable]);

  

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            logs: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("logs.list.delete.success"), {
          variant: "success",
        });
        setSelected([]);
        setPage(0);
        fetchTableData(0, rowsPerPage, order, orderBy, search, defaultFilters);
      }
    );
  };

  const rows =
    data &&
      data.GetLogsList &&
      data.GetLogsList.logsListView &&
      data.GetLogsList.logsListView.logs
      ? data.GetLogsList.logsListView.logs
      : [];
  const numberOfRecords =
    data &&
      data.GetLogsList &&
      data.GetLogsList.logsListView &&
      data.GetLogsList.logsListView.totalNumberOfRecords
      ? data.GetLogsList.logsListView.totalNumberOfRecords
      : 0;

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    defaultFilters,
    setDefaultFilters,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    filtersObject,
    loading: loading || columnsWait,
    searchText: "logs.list.search.label",
    refetch,
    handleDelete,
    deleteText: "logs.list.delete.label",
    tableMnemonic: Mnemonic.Tables.LogsList,
    generateRoute: userRoles?.name === "TenantAdmin" ? (row) => my_log_details.replace(
      "/:lName/:lID",
      `/${encodeURIComponent(row?.log?.logID)}/${encodeURIComponent(
        row?.log?.logGuid
      )}`
    ) : (row) => log_details.replace(
      "/:lName/:lID",
      `/${encodeURIComponent(row?.log?.logID)}/${encodeURIComponent(
        row?.log?.logGuid
      )}`
    )


  };




  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable
          {...pagination}
          identifier={"log.logGuid"}
          hasCheckbox
        />
      )}
    </div>
  );
};

export default LogsList;



