import { gql } from "@apollo/client";

export const GET_ALL_LOANED_ITEMS_LIST = gql`
query ($model: LoanedItemsListGetRequestInput!) {
    GetAllLoanedItemsList(model: $model) {
      getAllLoanedItemsList {
        loanedItemsList {
          loanedItemGuid
          loanedItemID
          description
          assignDate
          returnDate
           employeeGuid
           assignedTo {
             firstName
             lastName
           }
        }
        totalNumberOfRecords
      }
  
      errorCode
      errorMessage
      errorVariables
    }
  }
`

export const GET_LOANED_ITEM_BY_ID = gql`
query ($id: UUID!) {
    GetLoanedItemByID(loanedItemID: $id) {
      loanedItem {
        loanedItemGuid
        loanedItemID
        description
        returnDate
        assignDate
        employeeGuid
        assignedTo {
          employeeGuid
        }
        tenant {
          tenantGuid
        }
      }
      errorCode
      errorMessage
      errorVariables
    }
  }  
`

export const CREATE_LOANED_ITEM=gql`
mutation($model : LoanedItemCreateRequestInput!){
    CreateLoanedItem(model: $model){
       loanedItem {
         loanedItemGuid
         loanedItemID
       }
       errorCode
       errorMessage
       errorVariables
    }
  }
  `

  export const UPDATE_LOANED_ITEM=gql`
  mutation($model : LoanedItemUpdateRequestInput!){
    UpdateLoanedItem (model: $model){
     loanedItem {
       loanedItemGuid
       assignDate
     } 
       errorCode
       errorMessage
       errorVariables
    }
  }
  `

  export const DELETE_LOANED_ITEM=gql`
  mutation($model : LoanedItemDeleteRequestInput!){
    DeleteLoanedItems(model: $model){
      deleteLoanedItemsResponse
       errorCode
       errorMessage
       errorVariables
    }
  }
  `

  export const SEND_EMAIL_TO_EMPLOYEE=gql`
  mutation($ids:[UUID!]!){
    SendAssignedItemEmail(itemsIDs: $ids) {
      sendEmailResponse
      errorCode
      errorMessage
      errorVariables
    }
  }
  
  `