const urls = {
  default: "/",
  login: "/login",
  forgot_password: "/forgot-password",
  reset_password: "/reset-password",
  verify_login: "/verify-login",
  entelligence: "/entelligence",
  subscription: "/subscription",
  subscription_success: "/subscription-success",
  admin: "/user",
  entelligence_dashboard: "/entelligence/dashboard",
  entelligence_dashboard_project: "/entelligence/dashboard/project-analytics",
  tenants: "/tenants",
  tenant_list: "/entelligence/tenants/tenants-list",
  tenant_tabs: "/entelligence/tenants/tenants-list/tenant-information",
  tenant_form:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID",
  tenant_management:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/tenant-management",
  tenant_companies:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/companies",
  tenant_new_company:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/companies/new-company",
  tenant_licenses:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/licenses",
  new_tenant: "/entelligence/tenants/tenants-list/new-tenant",
  tenant_company_tabs:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/companies/company-information",
  tenant_company_form:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/companies/company-information/:cName/:cID",
  roles_list: "/entelligence/roles/roles-list",
  tenant_roles:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/roles",
  new_role: "/entelligence/roles/roles-list/new-role",
  new_tenant_role:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/roles/new-role",
  tenant_role_tabs:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/roles/role-information",
  tenant_role_form:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/roles/role-information/:rName/:rID",

  ///kiosk devices routes
  kiosk_devices_list: "/entelligence/kiosk-devices/kiosk-devices-list",
  tenant_kiosk_devices:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/kiosk-devices",
  // new_kiosk_device: "/entelligence/kiosk-devices/kiosk-devices-list/new-kiosk-device",
  new_tenant_kiosk_device:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/kiosk-devices/new-kiosk-device",
  tenant_kiosk_devices_tabs:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/kiosk-devices/kiosk-information",

  tenant_kiosk_form:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/kiosks-devices/kiosk-device-information/:kName/:kID",

  departments_list: "/entelligence/departments/departments-list",
  tenant_departments:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/departments",
  new_department: "/entelligence/departments/departments-list/new-department",
  new_tenant_department:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/departments/new-department",
  tenant_department_tabs:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/departments/department-information",
  tenant_work_shifts:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/work-shifts",
  tenant_workshift_tabs:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/work-shifts/workshift-information",
  tenant_workshift_form:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/work-shifts/workshift-information/:wsName/:wsID",
  tenant_departments_form:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/departments/department-information/:dName/:dID",
  new_tenant_workshift:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/work-shifts/new-workshift",

  tenant_ptos:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/PTOs",
  tenant_ptos_tabs:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/PTOs/pto-information",
  tenant_ptos_form:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/PTOs/pto-information/:ptoName/:ptoID",
  new_tenant_pto:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/PTOs/new-pto",

  tenant_skills_form:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/Skills/skill-information/:skillName/:skillID",
  new_tenant_skill:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/Skills/new-skill",

  tenant_locations:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/locations",
  tenant_locations_tabs:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/locations/location-information",
  tenant_locations_form:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/locations/location-information/:lName/:lID",
  new_tenant_location:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/locations/new-location",

  tenant_official_holidays:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/official-holidays",
  tenant_official_holidays_tabs:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/official-holidays",
  tenant_official_holidays_form:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/official-holidays/official-holidays-information/:ohName/:ohID",
  new_tenant_official_holidays:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/official-holidays/new-official-holidays",

  tenant_project: "/entelligence/projects",
  tenant_project_tabs: "/entelligence/projects/project-information",
  tenant_project_form: "/entelligence/projects/project-information/:pName/:pID",
  new_tenant_project: "/entelligence/projects/new-project",

  tenant_project_tickets:
    "/entelligence/projects/project-information/:pName/:pID/phases",
  tenant_project_tickets_list:
    "/entelligence/projects/project-information/:pName/:pID/phases/phases_list",
  tenant_project_tickets_tabs:
    "/entelligence/projects/project-information/:pName/:pID/phases/phase_information",
  tenant_project_tickets_form:
    "/entelligence/projects/project-information/:pName/:pID/phases/phase_information/:tkName/:tkID",
  tenant_new_project_ticket:
    "/entelligence/projects/project-information/:pName/:pID/phases/new-phase",
  tenant_project_tasks:
    "/entelligence/projects/project-information/:pName/:pID/phases/phase_information/:tkName/:tkID/tasks",
  tenant_project_tasks_list:
    "/entelligence/projects/project-information/:pName/:pID/phases/phase_information/:tkName/:tkID/tasks/tasks_list",
  tenant_project_tasks_tabs:
    "/entelligence/projects/project-information/:pName/:pID/phases/phase_information/:tkName/:tkID/tasks/task_information",
  tenant_project_tasks_form:
    "/entelligence/projects/project-information/:pName/:pID/phases/phase_information/:tkName/:tkID/tasks/task_information/:tskName/:tskID",
  tenant_new_project_task:
    "/entelligence/projects/project-information/:pName/:pID/phases/phase_information/:tkName/:tkID/tasks/new-task",
  tenant_new_project_subtask:
    "/entelligence/projects/project-information/:pName/:pID/phases/phase_information/:tkName/:tkID/tasks/new-subtask",
  tenant_project_direct_tasks:
    "/entelligence/projects/project-information/:pName/:pID/tasks",
  tenant_project_direct_tasks_list:
    "/entelligence/projects/project-information/:pName/:pID/tasks/tasks_list",
  tenant_project_direct_tasks_tabs:
    "/entelligence/projects/project-information/:pName/:pID/tasks/task_information",
  tenant_project_direct_tasks_form:
    "/entelligence/projects/project-information/:pName/:pID/tasks/task_information/:tskName/:tskID",
  tenant_direct_new_project_task:
    "/entelligence/projects/project-information/:pName/:pID/tasks/new-task",
  tenant_direct_new_project_subtask:
    "/entelligence/projects/project-information/:pName/:pID/tasks/new-subtask",

  tenant_request: "/entelligence/requests",
  tenant_request_list: "/entelligence/requests/requests-list",
  tenant_request_tabs: "/entelligence/requests/request-information",
  tenant_request_form:
    "/entelligence/requests/request-information/:reqName/:reqID",

  tenant_requests_offline: "/entelligence/requests/requests-list/offline",
  tenant_requests_offline_tabs:
    "/entelligence/requests/requests-list/offline/request-information",
  tenant_requests_offline_form:
    "/entelligence/requests/requests-list/offline/request-information/:reqName/:reqID",

  tenant_task_status:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/task-statuses",
  tenant_task_status_list:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/task-statuses/task-statuses-list",
  tenant_task_status_tabs:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/task-statuses/task-status-information",
  tenant_task_status_form:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/task-statuses/task-status-information/:sName/:sID",
  tenant_new_task_status:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/task-statuses/new-task-status",

  tenant_ticket_status:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/phase-statuses",
  tenant_ticket_status_list:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/phase-statuses/phase-statuses-list",
  tenant_ticket_status_tabs:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/phase-statuses/phase-status-information",
  tenant_ticket_status_form:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/phase-statuses/phase-status-information/:sName/:sID",
  tenant_new_ticket_status:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/phase-statuses/new-phase-status",

  tenant_skills:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/skills",
  tenant_skills_list:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/skills/skills-list",

  logs_list: "/entelligence/logs/logs-list",
  log_details: "/entelligence/logs/logs-list/:lName/:lID",
  suggestions_list: "/entelligence/suggestions/suggestions-list",
  suggestion_details: "/entelligence/suggestions/suggestions-list/:sName/:sID",
  releaseNotes_list: "/entelligence/releaseNotes/releaseNotes-list",
  releaseNotes_details:
    "/entelligence/releaseNotes/releaseNotes-list/:rName/:rID",
  new_releaseNote: "/entelligence/releaseNotes/new-releaseNote",
  user_releaseNotes_details: "/user/releaseNotes/releaseNotes-list",
  news_list: "/user/news/news-list",
  news_details: "/user/news/news-list/:nName/:nID",

  new_news: "/user/news/news-list/new-news",

  tenant_new_news: "/entelligence/news/news-list/new-news",

  all_users: "/entelligence/users/users-list",
  systemVariables_List: "/entelligence/system-variables/system-variables-list",
  help: "/entelligence/help",
  errors: "/entelligence/errors/errors-list",
  employees:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/employees",
  employees_list:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/employees/employees-list",
  employee_tabs:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/employees/employees-list/employee-information",
  employee_form:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/employees/employees-list/employee-information/:eName/:eID",
  employee_ptos:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/employees/employees-list/employee-information/:eName/:eID/time-offs",
  employee_form_days_used:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/employees/employees-list/employee-information/:eName/:eID/days-used/time-offs/:duName/:duID",
  admin_profile_form_days_used:
    "/entelligence/my-profile/:profileName/:profileID/time-offs/days-used/:duName/:duID",
  my_profile_employee_form_days_used:
    "/user/my-profile/:profileName/:profileID/time-offs/days-used/:duName/:duID",
  employee_skills:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/employees/employees-list/employee-information/:eName/:eID/skills",

  employee_teams:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/employees/employees-list/employee-information/:eName/:eID/teams",
  new_employee:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/employees/employees-list/new-employee",
  employee_reference:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/employees/employees-list/employee-information/:eName/:eID/employee-references",
  employee_reference_form:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/employees/employees-list/employee-information/:eName/:eID/employee-references/reference-info/:rID",
  new_employee_reference:
    "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/employees/employees-list/employee-information/:eName/:eID/employee-references/new-reference",

    employee_projects_list: "/user/my-employees/employees-list/employee-information/:eName/:eID/projects/projects-list",
    employee_projects_tabs: "/user/my-employees/employees-list/employee-information/:eName/:eID/projects/projects-list/project-information",
    employee_projects_form:
      "/user/my-employees/employees-list/employee-information/:eName/:eID/projects/projects-list/project-information/:pName/:pID",
      employee_new_project: "/user/my-employees/employees-list/employee-information/:eName/:eID/projects/projects-list/new-project",

  admin_employees_availability: "/entelligence/tenants/tenants-list/tenant-information/:tName/:tID/check-employees-availability",

  projects: "/entelligence/projects",
  projects_list: "/entelligence/projects/projects-list",
  projects_tabs: "/entelligence/projects/projects-list/project-information",
  projects_form:
    "/entelligence/projects/projects-list/project-information/:pName/:pID",
  new_project: "/entelligence/projects/projects-list/new-project",

  admin_profile_tabs: "/entelligence/my-profile",
  admin_profile: "/entelligence/my-profile/:profileName/:profileID",
  admin_change_password:
    "/entelligence/my-profile/:profileName/:profileID/change-password",

  admin_ptos: "/entelligence/my-profile/:profileName/:profileID/time-offs",
  admin_skills: "/entelligence/my-profile/:profileName/:profileID/skills",
  admin_teams: "/entelligence/my-profile/:profileName/:profileID/teams",

  my_dashboard: "/user/dashboard",
  my_dashboard_projects: "/user/dashboard/project-analytics",

  my_company: "/user/my-company",
  my_companies_list: "/user/my-company/companies-list",
  my_new_company: "/user/my-company/companies-list/new-company",
  my_company_tabs: "/user/my-company/companies-list/company-information",
  my_company_form:
    "/user/my-company/companies-list/company-information/:cName/:cID",

  my_company_projects:
    "/user/my-company/companies-list/company-information/:cName/:cID/projects",
  my_company_project_tabs:
    "/user/my-company/companies-list/company-information/:cName/:cID/projects/project-information",
  my_company_project_form:
    "/user/my-company/companies-list/company-information/:cName/:cID/projects/project-information/:lName/:lID",
  my_new_company_project:
    "/user/my-company/companies-list/company-information/:cName/:cID/projects/new-project",

  my_roles: "/user/my-roles",
  my_roles_list: "/user/my-roles/roles-list",
  my_new_role: "/user/my-roles/roles-list/new-role",
  my_roles_tabs: "/user/my-roles/roles-list/role-information",
  my_roles_form: "/user/my-roles/roles-list/role-information/:rName/:rID",

  ////kiosk devices
  kiosk_devices: "/user/kiosk-devices",
  kiosk_devices_list: "/user/kiosk-devices/kiosk-devices-list",
  new_kiosk_device: "/user/kiosk-devices/kiosk-devices-list/new-kiosk-device",
  kiosk_devices_tabs:
    "/user/kiosk-devices/kiosk-devices-list/kiosk-device-information",
  kiosk_devices_form:
    "/user/kiosk-devices/kiosk-devices-list/kiosk-device-information/:kName/:kID",

  my_departments: "/user/my-departments",
  my_departments_list: "/user/my-departments/departments-list",
  my_new_department: "/user/my-departments/departments-list/new-department",
  my_departments_tabs:
    "/user/my-departments/departments-list/department-information",
  my_departments_form:
    "/user/my-departments/departments-list/department-information/:dName/:dID",

  my_workshifts: "/user/my-workshifts",
  my_workshifts_list: "/user/my-workshifts/workshifts-list",
  my_new_workshift: "/user/my-workshifts/workshifts-list/new-workshift",
  my_workshifts_tabs:
    "/user/my-workshifts/workshifts-list/workshift-information",
  my_workshifts_form:
    "/user/my-workshifts/workshifts-list/workshift-information/:wsName/:wsID",

  my_employees: "/user/my-employees",
  my_employees_list: "/user/my-employees/employees-list",
  my_employee_tabs: "/user/my-employees/employees-list/employee-information",
  my_employee_form:
    "/user/my-employees/employees-list/employee-information/:eName/:eID",
  my_employee_ptos:
    "/user/my-employees/employees-list/employee-information/:eName/:eID/time-offs",
  my_employee_form_days_used:
    "/user/my-employees/employees-list/employee-information/:eName/:eID/days-used/time-offs/:duName/:duID",
  my_employee_skills:
    "/user/my-employees/employees-list/employee-information/:eName/:eID/skills",
  my_employee_reference:
    "/user/my-employees/employees-list/employee-information/:eName/:eID/employee-references",
  my_employee_reference_list:
    "/user/my-employees/employees-list/employee-information/:eName/:eID/employee-references/employee-references-list",
  my_employee_reference_tabs:
    "/user/my-employees/employees-list/employee-information/:eName/:eID/employee-references/reference-info",
  my_employee_reference_form:
    "/user/my-employees/employees-list/employee-information/:eName/:eID/employee-references/reference-info/:rID",
  loaned_items:
    "/user/my-employees/employees-list/employee-information/:eName/:eID/loaned-items",
  loaned_items_tabs:
    "/user/my-employees/employees-list/employee-information/:eName/:eID/loaned-items/loanedItems-info",
  loaned_items_details:
    "/user/my-employees/employees-list/employee-information/:eName/:eID/loaned-items/loanedItems-info/:lID",
  my_new_employee_reference:
    "/user/my-employees/employees-list/employee-information/:eName/:eID/employee-references/new-reference",
  my_employee_teams:
    "/user/my-employees/employees-list/employee-information/:eName/:eID/teams",
  my_new_employee: "/user/my-employees/employees-list/new-employee",
  employee_onboarding_tasks:
    "/user/my-employees/employees-list/employee-information/:eName/:eID/onboarding-tasks",
  my_tenant_tabs: "/user/my-tenant",
  my_tenant: "/user/my-tenant/:tName/:tID",
  my_tenant_licenses: "/user/my-tenant/:tName/:tID/licenses",
  my_logs_list: "/user/my-logs/my-logs-list",
  my_log_details: "/user/my-logs/my-logs-list/:lName/:lID",
  my_locations: "/user/my-locations",
  my_locations_list: "/user/my-locations/locations-list",
  my_locations_tabs: "/user/my-locations/locations-list/location-information",
  my_locations_form:
    "/user/my-locations/locations-list/location-information/:lName/:lID",
  my_locations_wifi:
    "/user/my-locations/locations-list/location-information/:lName/:lID/access-points",
  my_new_location: "/user/my-locations/locations-list/new-location",

  my_official_holidays: "/user/my-official-holidays/official-holidays-list",
  my_official_holidays_tabs:
    "/user/my-official-holidays/official-holidays-list/official-holidays-information",
  my_official_holidays_form:
    "/user/my-official-holidays/official-holidays-list/official-holidays-information/:ohName/:ohID",
  new_my_official_holidays:
    "/user/my-official-holidays/official-holidays-list/new-official-holidays",

  my_projects: "/user/my-projects",
  my_projects_list: "/user/my-projects/projects-list",
  favorite_projects: "/user/favorite-projects/my-favorite-projects-list",
  my_projects_tabs: "/user/my-projects/projects-list/project-information",
  my_favorite_projects_tabs:
    "/user/favorite-projects/my-favorite-projects-list/project-information",
  my_projects_form:
    "/user/my-projects/projects-list/project-information/:pName/:pID",
  my_favorite_projects_form:
    "/user/favorite-projects/my-favorite-projects-list/project-information/:pName/:pID",
  my_new_project: "/user/my-projects/projects-list/new-project",
  my_new_favorite_project:
    "/user/favorite-projects/my-favorite-projects-list/new-project",
  my_project_tickets:
    "/user/my-projects/projects-list/project-information/:pName/:pID/phases",
  my_project_tickets_list:
    "/user/my-projects/projects-list/project-information/:pName/:pID/phases/phases_list",
  my_project_tickets_tabs:
    "/user/my-projects/projects-list/project-information/:pName/:pID/phases/phase_information",
  my_project_tickets_form:
    "/user/my-projects/projects-list/project-information/:pName/:pID/phases/phase_information/:tkName/:tkID",
  my_new_project_ticket:
    "/user/my-projects/projects-list/project-information/:pName/:pID/phases/new-phase",
  my_project_tasks:
    "/user/my-projects/projects-list/project-information/:pName/:pID/phases/phase_information/:tkName/:tkID/tasks",
  my_project_tasks_list:
    "/user/my-projects/projects-list/project-information/:pName/:pID/phases/phase_information/:tkName/:tkID/tasks/tasks_list",
  my_project_tasks_tabs:
    "/user/my-projects/projects-list/project-information/:pName/:pID/phases/phase_information/:tkName/:tkID/tasks/task_information",
  my_project_tasks_form:
    "/user/my-projects/projects-list/project-information/:pName/:pID/phases/phase_information/:tkName/:tkID/tasks/task_information/:tskName/:tskID",
  my_new_project_task:
    "/user/my-projects/projects-list/project-information/:pName/:pID/phases/phase_information/:tkName/:tkID/tasks/new-task",
  my_new_project_subtask:
    "/user/my-projects/projects-list/project-information/:pName/:pID/phases/phase_information/:tkName/:tkID/tasks/new-subtask",
  my_project_direct_tasks:
    "/user/my-projects/projects-list/project-information/:pName/:pID/backlog",
  my_project_direct_tasks_list:
    "/user/my-projects/projects-list/project-information/:pName/:pID/backlog/tasks_list",
  my_project_direct_tasks_tabs:
    "/user/my-projects/projects-list/project-information/:pName/:pID/backlog/task_information",
  my_project_direct_tasks_form:
    "/user/my-projects/projects-list/project-information/:pName/:pID/backlog/task_information/:tskName/:tskID",
  my_direct_new_project_task:
    "/user/my-projects/projects-list/project-information/:pName/:pID/backlog/new-task",
  my_direct_new_project_subtask:
    "/user/my-projects/projects-list/project-information/:pName/:pID/backlog/new-subtask",
  my_project_tasks_time_entries:
    "/user/my-projects/projects-list/project-information/:pName/:pID/phases/phase_information/:tkName/:tkID/tasks/task_information/:tskName/:tskID/time-entries",
  my_project_direct_tasks_time_entries:
    "/user/my-projects/projects-list/project-information/:pName/:pID/backlog/task_information/:tskName/:tskID/time-entries",
  my_favorite_project_direct_tasks:
    "/user/favorite-projects/my-favorite-projects-list/project-information/:pName/:pID/backlog",
  project_members:
    "/user/my-projects/projects-list/project-information/:pName/:pID/members",
  favorite_project_members:
    "/user/favorite-projects/my-favorite-projects-list/project-information/:pName/:pID/members",
  project_members_list:
    "/user/my-projects/projects-list/project-information/:pName/:pID/members-list",
  project_plan:
    "/user/my-projects/projects-list/project-information/:pName/:pID/plan",
  favorite_project_plan:
    "/user/favorite-projects/my-favorite-projects-list/project-information/:pName/:pID/plan",
  project_area:
    "/user/my-projects/projects-list/project-information/:pName/:pID/area",
  invoices:
    "/user/my-projects/projects-list/project-information/:pName/:pID/invoices",
  printable_invoices:
    "/user/my-projects/projects-list/project-information/:pName/:pID/invoices/print",
  favorite_project_area:
    "/user/favorite-projects/my-favorite-projects-list/project-information/:pName/:pID/area",
  favorite_invoices:
    "/user/favorite-projects/my-favorite-projects-list/project-information/:pName/:pID/invoices",
  appliedInvoices:
    "/user/my-projects/projects-list/project-information/:pName/:pID/invoices/:iName/:iID/appliedInvoices",
  favorite_appliedInvoices:
    "/user/favorite-projects/my-favorite-projects-list/project-information/:pName/:pID/invoices/:iName/:iID/appliedInvoices",
  favorite_printable_invoices:
    "/user/favorite-projects/my-favorite-projects-list/project-information/:pName/:pID/invoices/print",
  project_tree:
    "/user/my-projects/projects-list/project-information/:pName/:pID/tree",
  project_backlog: "/user/backlog",
  project_backlog_new_task: "/user/backlog/new-task",
  project_backlog_new_subtask: "/user/backlog/new-subtask",
  project_backlog_tasks: "/user/backlog/tasks/task_information/:tskName/:tskID",
  my_ptos: "/user/my-time-offs/time-off-list",
  my_ptos_tabs: "/user/my-time-offs/time-off-list/pto-information",
  my_ptos_form:
    "/user/my-time-offs/time-off-list/pto-information/:ptoName/:ptoID",
  new_my_pto: "/user/my-time-offs/onboarding-tasks-list/new-onboarding-task",
  onBoarding_tasks: "/user/onboarding-tasks/onboarding-tasks-list",
  onBoarding_task_form:
    "/user/onboarding-tasks/onboarding-tasks-list/onboarding-task-information/:obName/:obID",
  new_onBoarding_task:
    "/user/onboarding-tasks/onboarding-tasks-list/new-onboarding-task",
  my_skills_form:
    "/user/my-skills/skills-list/skill-information/:skillName/:skillID",
  new_my_skill: "/user/my-skills/skill-list/new-skill",

  my_requests_list: "/user/my-requests",
  my_requests: "/user/my-requests/requests-list",
  my_sent_requests: "/user/my-requests/sent-requests-list",
  my_received_requests: "/user/my-requests/received-requests-list",
  my_requests_tabs: "/user/my-requests/requests-list",
  my_requests_form:
    "/user/my-requests/requests-list/request-information/:reqName/:reqID",
  my_new_request:
    "/user/my-requests/requests-list/request-information/new-request",
  my_attendance_requests_form:
    "/user/my-requests/requests-list/attendance-request-information/:reqName/:reqID",
  my_new_attendance_request:
    "/user/my-requests/requests-list/attendance-request-information/new-attendance-request",

  my_requests_offline: "/user/my-requests/requests-list/offline",
  my_requests_offline_tabs:
    "/user/my-requests/requests-list/offline/request-information",
  my_requests_offline_form:
    "/user/my-requests/requests-list/offline/request-information/:reqName/:reqID",

  my_task_status: "/user/my-task-statuses",
  my_task_status_list: "/user/my-task-statuses/my-task-statuses-list",
  my_task_status_tabs: "/user/my-task-statuses/task-status-information",
  my_task_status_form:
    "/user/my-task-statuses/task-status-information/:sName/:sID",
  my_new_task_status: "/user/my-task-statuses/new-task-status",

  my_ticket_status: "/user/my-phase-statuses",
  my_ticket_status_list: "/user/my-phase-statuses/my-phase-statuses-list",
  my_ticket_status_tabs: "/user/my-phase-statuses/phase-status-information",
  my_ticket_status_form:
    "/user/my-phase-statuses/phase-status-information/:sName/:sID",
  my_new_ticket_status: "/user/my-phase-statuses/new-phase-status",

  my_skills: "/user/my-skills",
  my_skills_list: "/user/my-skills/my-skills-list",
  my_teams: "/user/my-teams",
  my_teams_list: "/user/my-teams/my-teams-list",
  my_teams_employees: "/user/my-teams/my-teams-list/:tName/:tID",
  // areas: "/user/areas/areas-list",
  users: "/user/users/users-list",
  my_profile_tabs: "/user/my-profile",
  my_profile: "/user/my-profile/:profileName/:profileID",
  my_employees_availability: "/user/check-employees-availability",
  change_password: "/user/my-profile/:profileName/:profileID/change-password",

  //for my profile "My Time Offs" and "My Skills"
  my_ptos2: "/user/my-profile/:profileName/:profileID/time-offs",
  my_skills2: "/user/my-profile/:profileName/:profileID/skills",
  my_teams_profile: "/user/my-profile/:profileName/:profileID/teams",
  my_form_days_used:
    "/user/my-profilee/:profileName/:profileID/time-offs/:eName/:eID/days-used/:duName/:duID",
  my_timesheets: "/user/my-timesheets",
  my_approved_timesheets: "/user/my-timesheets/my-approved-timesheets",
  my_approved_timesheet_form:
    "/user/my-timesheets/my-approved-timesheets/:tName/:tID",
  my_created_timesheets: "/user/my-timesheets/new-timesheet",
  my_timesheets_list: "/user/my-timesheets/my-timesheets-list",
  my_timesheets_list_pending: "/user/my-timesheets/my-timesheets-list/pending",
  my_timesheets_list_approved:
    "/user/my-timesheets/my-timesheets-list/approved",

  my_attendance_sheets: "/user/my-attendance-sheets",
  my_attendance_sheets_list:
    "/user/my-attendance-sheets/my-attendance-sheets-list",
  my_attendance_sheets_list_pending:
    "/user/my-attendance-sheets/my-attendance-sheets-list/pending",
  my_attendance_sheets_list_approved:
    "/user/my-attendance-sheets/my-attendance-sheets-list/approved",
  my_loaned_items: "/user/my-profile/:profileName/:profileID/loaned-items",
  reportsTabs: "/user/reports",
  reports: "/user/reports/report",
  reports_employee_timesheet: "/user/reports/report/employee-timesheet",
  reports_employee_attendance_sheet:
    "/user/reports/report/employee-attendance-sheet",
  reports_attendance_by_employee: "/user/reports/report/attendance-by-employee",
  reports_time_by_employee: "/user/reports/report/time-by-employee",
  reports_time_by_project: "/user/reports/report/time-by-project",
  reports_time_by_project_print:
    "/user/reports/report/time-by-project/print-preview",

  reports_time_by_contractor: "/user/reports/report/time-by-contractor",
  reports_time_by_external: "/user/reports/report/time-by-external-reference",
  reports_excel: "/user/reports/report/export-to-excel",
  running_tasks: "/user/running-tasks",
  running_attendances: "/user/running-attendances",
  my_time_entries: "/user/time-entries-list",
  my_attendances: "/user/attendance-list",
  my_timesheet: "/user/time-sheet",
  my_contractors: "/user/my-contractors",
  my_contractors_list: "/user/my-contractors/my-contractors-list",
  import_output_time: "/user/import-output-time",
  import_output_time_list: "/user/import-output-time/import-output-time-list",
  import_time: "/user/import-output-time/import-output-time-list/import-time",
  export_time_by_resource:
    "/user/import-output-time/import-output-time-list/export-time",
  excel_time_entries:
    "/user/import-output-time/import-output-time-list/excel-bc-format",
};

export default urls;
