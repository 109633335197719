import React, { useEffect, useState } from "react";

//react router dom
import { useParams } from "react-router-dom";
//components
import {
  Grid,
  Box,
  Switch,
  FormControlLabel,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Button,
  Popper,
} from "@mui/material";

import { Field } from "formik";

import Tooltip from "../../../../../components/Tooltip";
import BadgeDot from "../../../../../components/StatusCell/BadgeDot";
import { Subtitle, Title } from "../../../../../components/Titles/FormTitles";
import IALoadingButton from "../../../../../components/IAButtons/IALoadingButton";
import AutoComplete from "../../../../../components/AutoComplete";
import TextField from "../../../../../components/TextFields/TextField";

import CompanyForm from "../../../Companies/Companies-form";
import WorkShiftForm from "../../../WorkShifts/WorkShift-form";
import RoleForm from "../../../Roles/Roles-form";
import OfficialHolidayForm from "../../../OfficialHolidays/OfficialHolidays-Form";
import LocationForm from "../../../Locations/Locations-form";
import ContractorsList from "../../../Contractors/Contractors-list";
import DepartmentsList from "../../../Departments/Departments-list";

import colors from "../../../../../assets/theme/light/colors";

//graphql
import { GET_ALL_COMPANIES_DROPDOWN } from "../../../../../graphql/company";
import { GET_ALL_DEPARTMENTS_DROPDOWN } from "../../../../../graphql/department";
import { GET_ALL_EMPLOYMENT_TYPES } from "../../../../../graphql/employmentType";
import { GET_ALL_WORK_TYPES } from "../../../../../graphql/workType";
import { GET_ALL_WORKSHIFTS } from "../../../../../graphql/workshifts";
import { GET_ALL_ROLES_DROPDOWN } from "../../../../../graphql/role";
import { GET_ALL_LOCATIONS_DROPDOWN } from "../../../../../graphql/location";
import { ASSIGN_PTOS_TO_EMPLOYEES } from "../../../../../graphql/pto";
import { GET_ALL_OFFICIAL_HOLIDAYS } from "../../../../../graphql/officialHolidays";
import { GET_ALL_EMPLOYMENT_STATUSES } from "../../../../../graphql/employmentStatuses";
import { GET_ALL_TIMEZONES } from "../../../../../graphql/timezone";
import { GET_ALL_CONTRACTORS } from "../../../../../graphql/contractor";
import {
  GET_ALL_EMPLOYEES_DROPDOWN,
  CHANGE_EMPLOYEE_STATUS,
  RESEND_ACTIVATION_EMAIL,
} from "../../../../../graphql/employee";

import PTOTable from "./PTOTable";
import EmployeePTOTable from "./EmployeePTOTable";

//hooks
import useAuth from "../../../../../hooks/useAuth";
import useQueryAuth from "../../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../../hooks/useMutationAuth";
import useLazyQueryAuth from "../../../../../hooks/useLazyQueryAuth";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

//translation
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import Mnemonics from "../../../../../Mnemonics.json";
import urls from "../../../../../urls";

import "./style.css";
import { day_month_year } from "../../../../../utils/timezones";
import FormModal from "../../../../../components/Modal/FormModal";
import { AddIconOutlined } from "../../../../../components/Icon";

import { v4 as uuidv4 } from "uuid";

const Main = ({ formData, isAppUserFlag, fromTenant, formFlag, handleChangeFlag }) => {
  const { tID, eID } = useParams();

  const {
    my_departments_list,
    my_contractors_list,
    my_workshifts_form,
    my_official_holidays_form,
    my_roles_form,
    my_employee_form,
    my_company_form,
    my_locations_form,
  } = urls;

  const userTimezone = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.split("/")[0];

  const { t } = useTranslation();

  const {
    storeUserGuid,
    storeUserTenant,
    userRoles,
    isAttendance,
    isTimeEntry,
    isEmployee,
  } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const [isAppUserFlagState, setIsAppUserState] = useState(
    formData.values.isAppUser
  );
  const [workshiftList, setWorkshiftList] = useState([]);

  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [openWorkShiftModal, setOpenWorkShiftModal] = useState(false);
  const [openOfficialHolidayModal, setOpenOfficialHolidayModal] =
    useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [openDepartmentModal, setOpenDepartmentModal] = useState(false);
  const [openContractorModal, setOpenContractorModal] = useState(false);

  const [fetchEmploymentTypes, setFetchEmploymentTypes] = useState(false);
  const [fetchWorkShifts, setFetchWorkShifts] = useState(false);
  const [fetchWorkTypes, setFetchWorkTypes] = useState(false);
  const [fetchDepartments, setFetchDepartments] = useState(false);
  const [fetchCompanies, setFetchCompanies] = useState(false);
  const [fetchOfficialHolidays, setFetchOfficialHolidays] = useState(false);
  const [fetchContractors, setFetchContractors] = useState(false);
  const [fetchEmploymentStatuses, setFetchEmploymentStatuses] = useState(false);
  const [fetchManagers, setFetchManagers] = useState(false);
  const [fetchTimezones, setFetchTimezones] = useState(false);
  const [fetchLocations, setFetchLocations] = useState(false);
  const [fetchRoles, setFetchRoles] = useState(false);

  const tenantIDState = fromTenant ? tID : storeUserTenant;

  const { mfunction: changeStatus, loading: changeStatusLoading } =
    useMutationAuth(CHANGE_EMPLOYEE_STATUS, "ChangeUserStatus");

  const { mfunction: resendActivationEmail, loading: resendLoading } =
    useMutationAuth(RESEND_ACTIVATION_EMAIL, "ResetPasswordForNewUser");

  const {
    mfunction: getWorkshiftsList,
    loading: workShiftLoading,
    refetch: refetchWorkShifts,
  } = useLazyQueryAuth(GET_ALL_WORKSHIFTS, "GetAllWorkShifts", {
    skip: !fetchWorkShifts,
    isWait: fetchWorkShifts,
  });

  const {
    values,
    touched,
    formField,
    errors,
    setFieldValue,
    setTouched,
    createMode,
    wait,
    readOnly,
    PTOs,
    setPTOs,
    displayTable,
    setDisplayTable,
    employeeStatus,
    setEmployeeStatus,
    fromProfile,
  } = formData;

  const { mfunction: assingPTOtoEmployee } = useMutationAuth(
    ASSIGN_PTOS_TO_EMPLOYEES,
    "AssignPtoToEmployee"
  );

  const {
    manageUsers,
    isEntelligence,
    manageSystemConfigurations,
    manageParameters,
    isAdmin,
  } = userRoles;

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    role,
    employmentType,
    workShift,
    workType,
    isOffice365User,
    directManager,
    isAppUser,
    isAutoClockIn,
    department,
    defaultWorkCompany,
    homeLocation,
    timeZone,
    employmentDate,
    position,
    isManager,
    employeeStatuses,
    probation,
    preferSMS,
    preferEmail,
    attendance,
    timeEntry,
    officialHoliday,
    resignationDate,
    inactiveDate,
    resourceType,
    contractor,
    canCreateKiosk,
  } = formField;

  const {
    firstName: firstNameV,
    lastName: lastNameV,
    email: emailV,
    phoneNumber: phoneNumberV,
    role: roleV,
    employmentType: employmentTypeV,
    workShift: workShiftV,
    workType: workTypeV,
    isOffice365User: isOffice365UserV,
    isAppUser: isAppUserV,
    isAutoClockIn: isAutoClockInV,
    department: departmentV,
    defaultWorkCompany: defaultWorkCompanyV,
    directManager: directManagerV,
    tenant: tenantV,
    homeLocation: homeLocationV,
    timeZone: timeZoneV,
    employmentDate: employmentDateV,
    position: positionV,
    isManager: isManagerV,
    employeeStatuses: employeeStatusesV,
    probation: probationV,
    attendance: attendanceV,
    preferEmail: preferEmailV,
    preferSMS: preferSMSV,
    timeEntry: timeEntryV,
    officialHoliday: officialHolidayV,
    resignationDate: resignationDateV,
    inactiveDate: inactiveDateV,
    resourceType: resourceTypeV,
    contractor: contractorV,
    canCreateKiosk: canCreateKioskV,
  } = values;

  const disableFirstSection = !isAdmin && roleV?.isAdmin;

  const resetFields = () => {
    setIsAppUserState(false);
    isAppUserFlag(false);
    setFieldValue(isAppUser.name, false);
    setFieldValue(preferEmail.name, false);
    setFieldValue(preferSMS.name, false);
    setFieldValue(isOffice365User.name, false);
    setFieldValue(isAutoClockIn.name, false);
    setFieldValue(attendance.name, false);
    setFieldValue(isAppUser.name, null);
    setFieldValue(isAppUser.name, null);
    setFieldValue(officialHoliday.name, null);
    setFieldValue(contractor.name, null);
    setFieldValue(employmentType.name, null);
    setFieldValue(workShift.name, null);
    setFieldValue(workType.name, null);
    setFieldValue(department.name, null);
    setFieldValue(defaultWorkCompany.name, null);
    setFieldValue(directManager.name, null);
    setFieldValue(homeLocation.name, null);
    setFieldValue(timeZone.name, null);
    setFieldValue(employmentDate.name, null);
    setFieldValue(position.name, "");
    setFieldValue(resignationDate.name, null);
    setFieldValue(inactiveDate.name, null);
  };

  useEffect(() => {
    if (resourceTypeV === Mnemonics.ResourceTypes.External) {
      setFieldValue(isAppUser.name, true);
      setFieldValue(timeEntry.name, true);
      setFetchWorkShifts(true);
      setIsAppUserState(true);
      isAppUserFlag(true);
    }
    if (fetchWorkShifts)
      getWorkshiftsList(
        {
          variables: {
            model: {
              enableFilters: false,
              tenantID: tenantIDState ? tenantIDState : tenantV.tenantGuid,
              showSystemGenerated:
                resourceTypeV === Mnemonics.ResourceTypes.External,
            },
          },
        },
        (response) => {
          const workshift_list =
            response?.GetAllWorkShifts?.workShiftsList?.workShifts || [];
          setWorkshiftList(workshift_list);
          if (resourceTypeV === Mnemonics.ResourceTypes.External) {
            const defaultWorkshift = workshift_list.filter(
              (workshift) => workshift.systemGenerated === true
            )[0];
            setFieldValue(workShift.name, defaultWorkshift);
          }
        }
      );
  }, [resourceTypeV, fetchWorkShifts]);

  const {
    data: holidaysList,
    loading: holidayLoading,
    tokenChange: getHolidaysWait,
    refetch: refetchOfficialHolidays,
  } = useQueryAuth(GET_ALL_OFFICIAL_HOLIDAYS, "GetOfficialHolidaysList", {
    variables: {
      model: {
        enableFilters: false,
        tenantGuid: fromTenant ? tID : storeUserTenant,
      },
    },
    skip: !fetchOfficialHolidays,
    isWait: fetchOfficialHolidays,
  });
  const holidays_list =
    holidaysList &&
    holidaysList.GetOfficialHolidaysList &&
    holidaysList.GetOfficialHolidaysList.officialHolidayListModel &&
    holidaysList.GetOfficialHolidaysList.officialHolidayListModel
      .officialHolidays
      ? holidaysList.GetOfficialHolidaysList.officialHolidayListModel
          .officialHolidays
      : [];

  const {
    data: emplTypesList,
    loading: emplTypeLoading,
    tokenChange: empTypeWait,
  } = useQueryAuth(GET_ALL_EMPLOYMENT_TYPES, "GetAllEmploymentTypes", {
    skip: !fetchEmploymentTypes,
    isWait: fetchEmploymentTypes,
  });
  const emplType_list =
    emplTypesList &&
    emplTypesList.GetAllEmploymentTypes &&
    emplTypesList.GetAllEmploymentTypes.employmentTypeList &&
    emplTypesList.GetAllEmploymentTypes.employmentTypeList.employmentTypes
      ? emplTypesList.GetAllEmploymentTypes.employmentTypeList.employmentTypes
      : [];

  const {
    data: workTypesList,
    loading: workTypeLoading,
    tokenChange: workTypesWait,
  } = useQueryAuth(GET_ALL_WORK_TYPES, "GetAllWorkTypes", {
    skip: !fetchWorkTypes,
    isWait: fetchWorkTypes,
  });
  const workType_list =
    workTypesList &&
    workTypesList.GetAllWorkTypes &&
    workTypesList.GetAllWorkTypes.workTypes
      ? workTypesList.GetAllWorkTypes.workTypes
      : [];

  const {
    data: employmentStatusesData,
    loading: employmentStatusLoading,
    tokenChange: employmentStatusWait,
  } = useQueryAuth(GET_ALL_EMPLOYMENT_STATUSES, "GetAllEmploymentStatuses", {
    skip: !fetchEmploymentStatuses,
    isWait: fetchEmploymentStatuses,
  });
  const employmentStatusList =
    employmentStatusesData?.GetAllEmploymentStatuses
      ?.employmentStatusesOutput || [];

  const {
    data: timezonesData,
    loading: timezonesLoading,
    tokenChange: timezoneWait,
  } = useQueryAuth(GET_ALL_TIMEZONES, "GetTimezones", {
    skip: !fetchTimezones,
    isWait: fetchTimezones,
  });

  const timezonesList = timezonesData?.GetTimezones?.timezones || [];

  const {
    data: contractorsData,
    loading: contractorsLoading,
    tokenChange: contractorsWait,
    refetch: refetchContractors,
  } = useQueryAuth(GET_ALL_CONTRACTORS, "GetContractorsList", {
    variables: {
      model: {
        tenantGuid: fromTenant ? tID : storeUserTenant,
      },
    },
    skip: !fetchContractors,
    isWait: fetchContractors,
  });

  const contractorsList =
    contractorsData?.GetContractorsList?.contractorsList?.contractors || [];

  const {
    data: departmentsData,
    loading: departmentsLoading,
    tokenChange: departmentsWait,
    refetch: refetchDepartments,
  } = useQueryAuth(GET_ALL_DEPARTMENTS_DROPDOWN, "GetAllDepartments", {
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: tenantIDState ? tenantIDState : tenantV.tenantGuid,
      },
    },
    skip: !fetchDepartments,
    isWait: fetchDepartments,
  });
  const departments_list =
    departmentsData?.GetAllDepartments?.depList?.departments || [];

  const {
    data: locationData,
    loading: locationLoading,
    tokenChange: locationWait,
    refetch: refetchLocations,
  } = useQueryAuth(GET_ALL_LOCATIONS_DROPDOWN, "GetAllLocations", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: tenantIDState ? tenantIDState : tenantV.tenantGuid,
        isNotOnsite: true,
      },
    },
    skip: !fetchLocations,
    isWait: fetchLocations,
  });
  const locations_list =
    locationData?.GetAllLocations?.locationsList?.locations || [];

  const {
    data: companiesData,
    loading: companyLoading,
    tokenChange: companyWait,
    refetch: refetchCompanies,
  } = useQueryAuth(GET_ALL_COMPANIES_DROPDOWN, "GetAllCompanies", {
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: tenantIDState ? tenantIDState : tenantV.tenantGuid,
      },
    },
    skip: !fetchCompanies,
    isWait: fetchCompanies,
  });
  const company_list =
    companiesData?.GetAllCompanies?.compList?.companies || [];

  const {
    data: managersData,
    loading: managerLoading,
    tokenChange: managerWait,
    refetch: refetchManagers,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: tenantIDState ? tenantIDState : tenantV.tenantGuid,
        userID: storeUserGuid,
        isManager: true,
      },
    },
    skip: !fetchManagers,
    isWait: fetchManagers,
  });
  const manager_list =
    managersData?.GetCompanyUsersList?.usersList?.employees || [];
    
  const {
    data: getRolesData,
    loading: roleLoading,
    refetch: refetchRoles,
  } = useQueryAuth(GET_ALL_ROLES_DROPDOWN, "GetAllRoles", {
    variables: {
      model: {
        enableFilters: false,
        tenantID: tenantIDState ? tenantIDState : tenantV.tenantGuid,
        showAdminRole: isAdmin || isEntelligence
      },
    },
    skip: !fetchRoles,
    isWait: fetchRoles,
  });

  const role_list = getRolesData?.GetAllRoles?.rolesList?.roles || [];

  function handleAddPTO(array) {
    if (createMode) {
      setPTOs(array);
      setDisplayTable(false);
    } else {
      const ptoGUIDs = array
        ? array.map((val, index) => {
            return val.ptoGuid;
          })
        : [];

      assingPTOtoEmployee(
        {
          variables: {
            model: {
              listPTOID: ptoGUIDs,
              employeeID: eID,
            },
          },
        },
        (data) => {
          enqueueSnackbar(t("assignPtoToEmployee.success"), {
            variant: "success",
          });
          setDisplayTable(false);
        }
      );
    }
  }

  function returnStatusColor(status) {
    if (!status) return "error";

    switch (status.mnemonic) {
      case Mnemonics.EmployeeStatuses.new:
        return "info";
      case Mnemonics.EmployeeStatuses.active:
        return "success";
      case Mnemonics.EmployeeStatuses.inactive:
        return "error";
      default:
        return "error";
    }
  }

  function activateUser() {
    changeStatus(
      {
        variables: {
          model: {
            activate: true,
            userID: eID,
          },
        },
      },
      (response) => {
        setEmployeeStatus(response?.ChangeUserStatus?.employeeStatus || null);

        enqueueSnackbar(t("employeeForm.activatedSuccessfully"), {
          variant: "success",
        });
      }
    );
  }

  function deactivateUser() {
    changeStatus(
      {
        variables: {
          model: {
            activate: false,
            userID: eID,
          },
        },
      },
      (response) => {
        setEmployeeStatus(response?.ChangeUserStatus?.employeeStatus || null);
        setFieldValue(inactiveDate.name, new Date()); // set as current date since in backend we are setting it as current date anyways
        enqueueSnackbar(t("employeeForm.deactivatedSuccessfully"), {
          variant: "success",
        });
      }
    );
  }

  const handleResendActivationEmail = () => {
    resendActivationEmail(
      {
        variables: {
          model: {
            email: emailV,
            resend: true,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("employeeForm.resentSuccessfully"), {
          variant: "success",
        });
      }
    );
  };
  
  function displayUserStatusButton(status) {
    if (!status) return null;

    switch (status.mnemonic) {
      case Mnemonics.EmployeeStatuses.new:
        return (
          <IALoadingButton
            disabled={readOnly || disableFirstSection}
            loading={resendLoading}
            onClick={handleResendActivationEmail}
            label={t("employeeForm.userActivation")}
          />
        );
      case Mnemonics.EmployeeStatuses.active:
        return (
          <IALoadingButton
            disabled={readOnly || manageUsers !== "M" || disableFirstSection}
            error
            onClick={deactivateUser}
            loading={changeStatusLoading}
            label={t("employeeForm.deactivate")}
          />
        );
      case Mnemonics.EmployeeStatuses.inactive:
        return (
          <>
          <IALoadingButton
            disabled={readOnly || disableFirstSection}
            loading={changeStatusLoading}
            onClick={activateUser}
            label={t("employeeForm.activate")}
          />
           <IALoadingButton
            disabled={readOnly || disableFirstSection}
            loading={resendLoading}
            onClick={handleResendActivationEmail}
            label={t("employeeForm.userActivation")}
          />
          </>
        );
      default:
        return null;
    }
  }

  const resourceTypes = ["Employee", "External"];

  const handleOpenCompanyModal = () => {
    setTimeout(() => {
      setOpenCompanyModal(true);
    }, 800);
  };
  const handleOpenRoleModal = () => {
    setTimeout(() => {
      setOpenRoleModal(true);
    }, 800);
  };
  const handleOpenWorkShiftModal = () => {
    setTimeout(() => {
      setOpenWorkShiftModal(true);
    }, 800);
  };
  const handleOpenOfficialHolidayModal = () => {
    setTimeout(() => {
      setOpenOfficialHolidayModal(true);
    }, 800);
  };
  const handleOpenLocationModal = () => {
    setTimeout(() => {
      setOpenLocationModal(true);
    }, 800);
  };
  const handleOpenDepartmentModal = () => {
    setTimeout(() => {
      setOpenDepartmentModal(true);
    }, 800);
  };
  const handleOpenContractorModal = () => {
    setTimeout(() => {
      setOpenContractorModal(true);
    }, 800);
  };

  return (
    <Box>
      <FormModal
        showModal={openCompanyModal}
        setShowModal={(v) => setOpenCompanyModal(v)}
        loading={false}
        refetch={refetchCompanies}
        // open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        // modifyFormFlag={() => sessionStorage.setItem("unsavedChanges", JSON.stringify(false))}
        Child={CompanyForm}
      />
      <FormModal
        showModal={openRoleModal}
        setShowModal={(v) => setOpenRoleModal(v)}
        loading={false}
        refetch={refetchRoles}
        // open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        // modifyFormFlag={() => sessionStorage.setItem("unsavedChanges", JSON.stringify(false))}
        Child={RoleForm}
      />
      <FormModal
        showModal={openWorkShiftModal}
        setShowModal={(v) => setOpenWorkShiftModal(v)}
        loading={false}
        refetch={refetchWorkShifts}
        // open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        // modifyFormFlag={() => sessionStorage.setItem("unsavedChanges", JSON.stringify(false))}
        Child={WorkShiftForm}
        disabledFields={fromProfile}
        object={workShiftV}
      />
      <FormModal
        showModal={openOfficialHolidayModal}
        setShowModal={(v) => setOpenOfficialHolidayModal(v)}
        loading={false}
        refetch={refetchOfficialHolidays}
        // open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        // modifyFormFlag={() => sessionStorage.setItem("unsavedChanges", JSON.stringify(false))}
        Child={OfficialHolidayForm}
      />
      <FormModal
        showModal={openLocationModal}
        setShowModal={(v) => setOpenLocationModal(v)}
        loading={false}
        refetch={refetchLocations}
        // open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        // modifyFormFlag={() => sessionStorage.setItem("unsavedChanges", JSON.stringify(false))}
        Child={LocationForm}
      />
      <FormModal
        showModal={openDepartmentModal}
        setShowModal={(v) => setOpenDepartmentModal(v)}
        loading={false}
        refetch={refetchDepartments}
        // open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        // modifyFormFlag={() => sessionStorage.setItem("unsavedChanges", JSON.stringify(false))}
        Child={DepartmentsList}
      />
      <FormModal
        showModal={openContractorModal}
        setShowModal={(v) => setOpenContractorModal(v)}
        loading={false}
        refetch={refetchContractors}
        // open={JSON.parse(sessionStorage.getItem("unsavedChanges"))}
        // modifyFormFlag={() => sessionStorage.setItem("unsavedChanges", JSON.stringify(false))}
        Child={ContractorsList}
      />
      <PTOTable
        display={displayTable}
        tenant={
          isEntelligence
            ? tenantV
              ? tenantV.tenantGuid
              : tID
            : storeUserTenant
        }
        initialVal={PTOs}
        createMode={createMode}
        handleClick={handleAddPTO}
        setDisplay={setDisplayTable}
      />

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={
            fromProfile
              ? {
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: "10px",
                  paddingTop: "23px !important",
                  paddingBottom: "8px !important",
                }
              : {
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: "10px",
                }
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <Title id="statusBadgeDot">
              {t("employeeForm.employeeInformation")}
            </Title>
            {employeeStatus && (
              <BadgeDot
                color={returnStatusColor(employeeStatus)}
                text={employeeStatus.description}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            {!readOnly && !createMode && !fromProfile && (
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {displayUserStatusButton(employeeStatus)}
              </span>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <TextField
            autoFocus
            fullWidth
            value={firstNameV}
            onChange={(e) => {
              setFieldValue(firstName.name, e.target.value);
            }}
            label={t(firstName.label)}
            name={firstName.name}
            formData={formData}
            required
            disabled={disableFirstSection}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <TextField
            fullWidth
            value={lastNameV}
            onChange={(e) => {
              setFieldValue(lastName.name, e.target.value);
            }}
            label={t(lastName.label)}
            name={lastName.name}
            formData={formData}
            required
            disabled={disableFirstSection}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <TextField
            fullWidth
            value={phoneNumberV}
            onChange={(e) => {
              setFieldValue(phoneNumber.name, e.target.value);
            }}
            label={t(phoneNumber.label)}
            name={phoneNumber.name}
            formData={formData}
            required
            disabled={
              readOnly ||
              manageUsers === "R" ||
              (tenantIDState === null && fromTenant) ||
              fromProfile ||
              disableFirstSection
            }
            Tooltip={Tooltip}
            tootlipTitle={t("phoneNumber.format")}
          />
        </Grid>

        {
          <Grid container spacing={2} ml={0.1} pb={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={4}>
              <Subtitle>{t("employeeForm.userInformation")}</Subtitle>
            </Grid>

            <Grid item sm={12} md={12} lg={4} xl={4}>
              <FormControl
                variant="standard"
                fullWidth
                required
                disabled={
                  readOnly ||
                  manageUsers === "R" ||
                  (tenantIDState === null && fromTenant) ||
                  fromProfile ||
                  disableFirstSection

                }
                sx={{
                  ".MuiInputBase-input": { fontSize: "14px !important" },
                }}
              >
                <InputLabel id={resourceType.name}>
                  {t(resourceType.label)}
                </InputLabel>
                <Select
                  labelId={resourceType.name}
                  id={resourceType.name}
                  value={resourceTypeV}
                  label={t(resourceType.label)}
                  sx={{
                    "& .MuiFormLabel-root": {
                      fontSize: "14px !important",
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "14px !important",
                    },
                  }}
                  onBlur={() => setTouched({...touched, [resourceType.name]: true})}
                  onChange={(event, value) => {
                    resetFields();
                    setFieldValue(resourceType.name, value.props.children);
                    handleChangeFlag(touched);
                    if (
                      value.props.children === Mnemonics.ResourceTypes.External
                    ) {
                      const defaultEmploymentStatus =
                        employmentStatusList.filter(
                          (status) =>
                            status.mnemonic ===
                            Mnemonics.EmploymentStatuses.Employed
                        )[0];
                      setFieldValue(
                        employeeStatuses.name,
                        defaultEmploymentStatus
                      );
                    } else setFieldValue(workShift.name, null);
                  }}
                >
                  {resourceTypes.map((type, index) => (
                    <MenuItem key={index} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Tooltip title={t("employee.tooltip.selfservice")}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                sx={{ marginTop: "16px", display: "flex", alignItems: "end" }}
              >
                <Field
                  as={FormControlLabel}
                  type={isAppUser.type}
                  name={isAppUser.name}
                  control={
                    <Switch
                      sx={{
                        ".MuiTypography-root": { fontSize: "1rem !important" },
                      }}
                      size="small"
                      checked={isAppUserV}
                      disabled={
                        readOnly ||
                        manageUsers === "R" ||
                        fromProfile ||
                        resourceTypeV === Mnemonics.ResourceTypes.External ||
                        disableFirstSection
                      }
                      onBlur={(e) =>
                        setTouched({ ...touched, [isAppUser.name]: true })
                      }
                      onChange={(e) => {
                        setFieldValue(isAppUser.name, e.target.checked);
                        setIsAppUserState(e.target.checked);
                        isAppUserFlag(e.target.checked);
                      }}
                      error={touched[isAppUser.name] && errors[isAppUser.name]}
                    />
                  }
                  label={t(isAppUser.label)}
                />
              </Grid>
            </Tooltip>

            <Tooltip title={t("employee.tooltip.timeEntry")}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                sx={{ marginTop: "16px", display: "flex", alignItems: "end" }}
              >
                <Field
                  as={FormControlLabel}
                  type={timeEntry.type}
                  name={timeEntry.name}
                  control={
                    <Switch
                      size="small"
                      checked={isTimeEntry === false ? false : timeEntryV}
                      disabled={
                        readOnly ||
                        manageUsers === "R" ||
                        fromProfile ||
                        isTimeEntry === false ||
                        disableFirstSection
                      }
                      onBlur={(e) =>
                        setTouched({ ...touched, [timeEntry.name]: true })
                      }
                      onChange={(e) => {
                        setFieldValue(timeEntry.name, e.target.checked);
                      }}
                      error={touched[timeEntry.name] && errors[timeEntry.name]}
                    />
                  }
                  label={t(timeEntry.label)}
                />
              </Grid>
            </Tooltip>

            <Tooltip title={t("employee.tooltip.attendance")}>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Field
                  as={FormControlLabel}
                  type={attendance.type}
                  name={attendance.name}
                  control={
                    <Switch
                      size="small"
                      checked={isAttendance === false ? false : attendanceV}
                      disabled={
                        readOnly ||
                        manageUsers === "R" ||
                        fromProfile ||
                        isAttendance === false ||
                        resourceTypeV === Mnemonics.ResourceTypes.External ||
                        disableFirstSection
                      }
                      onBlur={(e) =>
                        setTouched({ ...touched, [attendance.name]: true })
                      }
                      onChange={(e) => {
                        setFieldValue(attendance.name, e.target.checked);
                      }}
                      error={
                        touched[attendance.name] && errors[attendance.name]
                      }
                    />
                  }
                  label={t(attendance.label)}
                />
              </Grid>
            </Tooltip>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ paddingLeft: "5px" }}
            >
              <Field
                as={FormControlLabel}
                type={isAutoClockIn.type}
                name={isAutoClockIn.name}
                control={
                  <Switch
                    size="small"
                    disabled={
                      readOnly ||
                      manageUsers === "R" ||
                      fromProfile ||
                      resourceTypeV === Mnemonics.ResourceTypes.External ||
                      disableFirstSection
                    }
                    checked={isAutoClockInV}
                    onBlur={(e) =>
                      setTouched({ ...touched, [isAutoClockIn.name]: true })
                    }
                    onChange={(e) => {
                      setFieldValue(isAutoClockIn.name, e.target.checked);
                    }}
                    error={
                      touched[isAutoClockIn.name] && errors[isAutoClockIn.name]
                    }
                  />
                }
                label={t(isAutoClockIn.label)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Field
                as={FormControlLabel}
                type={isOffice365User.type}
                name={isOffice365User.name}
                control={
                  <Switch
                    size="small"
                    checked={isOffice365UserV}
                    disabled={readOnly || manageUsers === "R" || fromProfile || disableFirstSection}
                    onBlur={(e) =>
                      setTouched({ ...touched, [isOffice365User.name]: true })
                    }
                    onChange={(e) => {
                      setFieldValue(isOffice365User.name, e.target.checked);
                    }}
                    error={
                      touched[isOffice365User.name] &&
                      errors[isOffice365User.name]
                    }
                  />
                }
                label={t(isOffice365User.label)}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ paddingLeft: "5px" }}
            >
              <Field
                as={FormControlLabel}
                type={preferEmail.type}
                name={preferEmail.name}
                control={
                  <Switch
                    size="small"
                    disabled={readOnly || manageUsers === "R" || fromProfile || disableFirstSection}
                    checked={preferEmailV}
                    onBlur={(e) =>
                      setTouched({ ...touched, [preferEmail.name]: true })
                    }
                    onChange={(e) => {
                      setFieldValue(preferEmail.name, e.target.checked);
                    }}
                    error={
                      touched[preferEmail.name] && errors[preferEmail.name]
                    }
                  />
                }
                label={t(preferEmail.label)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ paddingLeft: "5px" }}
            >
              <Field
                as={FormControlLabel}
                type={preferSMS.type}
                name={preferSMS.name}
                control={
                  <Switch
                    size="small"
                    disabled={readOnly || manageUsers === "R" || fromProfile || disableFirstSection}
                    checked={preferSMSV}
                    onBlur={(e) =>
                      setTouched({ ...touched, [preferSMS.name]: true })
                    }
                    onChange={(e) => {
                      setFieldValue(preferSMS.name, e.target.checked);
                    }}
                    error={touched[preferSMS.name] && errors[preferSMS.name]}
                  />
                }
                label={t(preferSMS.label)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              sx={{ paddingLeft: "5px" }}
            >
              <Field
                as={FormControlLabel}
                type={canCreateKiosk.type}
                name={canCreateKiosk.name}
                control={
                  <Switch
                    size="small"
                    disabled={readOnly || manageUsers === "R" || fromProfile || disableFirstSection}
                    checked={canCreateKioskV}
                    onBlur={(e) =>
                      setTouched({ ...touched, [canCreateKiosk.name]: true })
                    }
                    onChange={(e) => {
                      setFieldValue(canCreateKiosk.name, e.target.checked);
                    }}
                    error={touched[canCreateKiosk.name] && errors[canCreateKiosk.name]}
                  />
                }
                label={t(canCreateKiosk.label)}
              />
            </Grid>

            <Tooltip title={t("employee.tooltip.email")}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                sx={{ display: "flex", alignItems: "baseline" }}
              >
                <TextField
                  fullWidth
                  value={emailV}
                  onChange={(e) => {
                    setFieldValue(email.name, e.target.value);
                  }}
                  label={t(email.label)}
                  name={email.name}
                  formData={formData}
                  required={isAppUserFlagState === false}
                  disabled={readOnly || fromProfile || disableFirstSection}
                />
              </Grid>
            </Tooltip>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <AutoComplete
                options={timezonesList}
                fullWidth
                getOptionLabel={(option) => (option ? option.value : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.value}
                  </li>
                )}
                identifier={"timezoneGuid"}
                value={timeZoneV}
                onOpen={() => setFetchTimezones(true)}
                onChange={(e, value) => {
                  setFieldValue(timeZone.name, value);
                }}
                loading={timezonesLoading}
                label={t(timeZone.label)}
                name={timeZone.name}
                formData={formData}
                required
                disabled={disableFirstSection}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <AutoComplete
                options={locations_list}
                getOptionLabel={(option) => (option ? option.name : "")}
                renderOption={(props, option) => (
                  <li {...props} key={uuidv4()}>
                    {option.name}
                  </li>
                )}
                identifier={"locationGuid"}
                value={homeLocationV}
                disabled={
                  readOnly ||
                  (tenantIDState === null && fromTenant) ||
                  fromProfile ||
                  disableFirstSection
                }
                onOpen={() => setFetchLocations(true)}
                onChange={(e, value) => {
                  setFieldValue(homeLocation.name, value);
                }}
                loading={locationLoading}
                label={t(homeLocation.label)}
                name={homeLocation.name}
                formData={formData}
                showDisplayView={
                  isEmployee === Mnemonics.ResourceTypes.Employee &&
                  manageSystemConfigurations !== "N" &&
                  homeLocationV &&
                  !fromProfile &&
                  !isEntelligence
                }
                handleClickView={() =>
                  window.open(
                    my_locations_form.replace(
                      "/:lName/:lID",
                      `/${encodeURIComponent(
                        homeLocationV?.name
                      )}/${encodeURIComponent(homeLocationV?.locationGuid)}`
                    )
                  )
                }
                showDisplayAdd={
                  isEmployee === Mnemonics.ResourceTypes.Employee &&
                  manageSystemConfigurations === "M" &&
                  (homeLocationV === null ||
                    homeLocationV === undefined ||
                    homeLocationV === "") &&
                  !fromProfile &&
                  !isEntelligence
                }
                handleClickAdd={() => handleOpenLocationModal()}
              />
            </Grid>

            {!isAppUserFlagState && (
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <AutoComplete
                  options={role_list}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.name}
                    </li>
                  )}
                  identifier={"roleGuid"}
                  value={roleV}
                  disabled={
                    readOnly ||
                    manageUsers === "R" ||
                    (tenantIDState === null && fromTenant) ||
                    fromProfile ||
                    disableFirstSection
                  }
                  onOpen={() => setFetchRoles(true)}
                  onChange={(e, value) => {
                    setFieldValue(role.name, value);
                  }}
                  loading={roleLoading}
                  label={t(role.label)}
                  name={role.name}
                  formData={formData}
                  required={resourceTypeV !== Mnemonics.ResourceTypes.External}
                  showDisplayView={
                    isEmployee === Mnemonics.ResourceTypes.Employee &&
                    manageParameters !== "N" &&
                    roleV &&
                    !fromProfile &&
                    !isEntelligence
                  }
                  disabledViewIcon={
                    roleV === null || roleV === undefined || roleV === ""
                  }
                  handleClickView={() =>
                    window.open(
                      my_roles_form.replace(
                        "/:rName/:rID",
                        `/${encodeURIComponent(
                          roleV?.name
                        )}/${encodeURIComponent(roleV?.roleGuid)}`
                      )
                    )
                  }
                  showDisplayAdd={
                    isEmployee === Mnemonics.ResourceTypes.Employee &&
                    manageSystemConfigurations === "M" &&
                    (roleV === null || roleV === undefined || roleV === "") &&
                    !fromProfile &&
                    !isEntelligence
                  }
                  handleClickAdd={() => handleOpenRoleModal()}
                />
              </Grid>
            )}

            {resourceTypeV === Mnemonics.ResourceTypes.External && (
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <AutoComplete
                  options={contractorsList}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.name}
                    </li>
                  )}
                  identifier={"contractorGuid"}
                  value={contractorV}
                  disabled={
                    readOnly ||
                    manageUsers === "R" ||
                    fromProfile ||
                    resourceTypeV !== Mnemonics.ResourceTypes.External
                  }
                  onOpen={() => setFetchContractors(true)}
                  onChange={(e, value) => {
                    setFieldValue(contractor.name, value);
                  }}
                  loading={contractorsLoading}
                  label={t(contractor.label)}
                  name={contractor.name}
                  formData={formData}
                  required={resourceTypeV === Mnemonics.ResourceTypes.External}
                  showDisplayAdd={
                    isEmployee === Mnemonics.ResourceTypes.Employee &&
                    manageSystemConfigurations !== "N" &&
                    (contractorV === null ||
                      contractorV === undefined ||
                      contractorV === "") &&
                    !fromProfile &&
                    !isEntelligence
                  }
                  handleClickAdd={() => handleOpenContractorModal()}
                />
              </Grid>
            )}
          </Grid>
        }
        {
          <Grid container spacing={2} ml={0.1}>
            {resourceTypeV !== Mnemonics.ResourceTypes.External && (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={4}>
                  <Subtitle>{t("employeeForm.employmentInformation")}</Subtitle>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ display: "flex", alignItems: "baseline" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      sx={{
                        width: "100%",
                        "& .MuiFormLabel-root": {
                          fontSize: "14px !important",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "14px !important",
                        },
                      }}
                      format={
                        day_month_year.includes(userTimezone) === true
                          ? "dd-MM-yyyy"
                          : "MM-dd-yyyy"
                      }
                      label={t(employmentDate.label)}
                      fullWidth
                      value={employmentDateV}
                      onChange={(newValue) => {
                        setFieldValue(employmentDate.name, newValue);
                      }}
                      disabled={fromProfile}
                      onBlur={(e) =>
                        setTouched({ ...touched, [employmentType.name]: true })
                      }
                      slotProps={{
                        desktopPaper: {
                          sx: {
                            boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                          },
                        },
                        textField: {
                          size: "small",
                          variant: "standard",
                          required:
                            resourceTypeV === Mnemonics.ResourceTypes.Employee,
                          error:
                            touched[employmentDate.name] &&
                            errors[employmentDate.name],
                          helperText:
                            touched[employmentDate.name] &&
                            errors[employmentDate.name] &&
                            t(errors[employmentDate.name]),

                          onBlur: (e) =>
                            setTouched({
                              ...touched,
                              [employmentDate.name]: true,
                            }),
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <AutoComplete
                    options={emplType_list}
                    getOptionLabel={(option) => (option ? option.name : "")}
                    renderOption={(props, option) => (
                      <li {...props} key={uuidv4()}>
                        {option.name}
                      </li>
                    )}
                    identifier={"employmentTypeGuid"}
                    value={employmentTypeV}
                    disabled={readOnly || manageUsers === "R" || fromProfile}
                    onOpen={() => setFetchEmploymentTypes(true)}
                    onChange={(e, value) => {
                      setFieldValue(employmentType.name, value);
                    }}
                    loading={emplTypeLoading}
                    label={t(employmentType.label)}
                    name={employmentType.name}
                    formData={formData}
                    required={
                      resourceTypeV === Mnemonics.ResourceTypes.Employee
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <AutoComplete
                    options={company_list}
                    getOptionLabel={(option) => (option ? option.name : "")}
                    renderOption={(props, option) => (
                      <li {...props} key={uuidv4()}>
                        {option.name}
                      </li>
                    )}
                    identifier={"companyGuid"}
                    disabled={
                      readOnly ||
                      manageUsers === "R" ||
                      (tenantIDState === null && fromTenant && createMode) ||
                      fromProfile
                    }
                    value={defaultWorkCompanyV}
                    onOpen={() => setFetchCompanies(true)}
                    onChange={(e, value) => {
                      setFieldValue(defaultWorkCompany.name, value);
                    }}
                    loading={companyLoading}
                    label={t(defaultWorkCompany.label)}
                    name={defaultWorkCompany.name}
                    formData={formData}
                    required={
                      resourceTypeV === Mnemonics.ResourceTypes.Employee
                    }
                    showDisplayView={
                      isEmployee === Mnemonics.ResourceTypes.Employee &&
                      manageParameters !== "N" &&
                      defaultWorkCompanyV &&
                      !fromProfile &&
                      !isEntelligence
                    }
                    disabledViewIcon={
                      defaultWorkCompanyV === null ||
                      defaultWorkCompanyV === undefined ||
                      defaultWorkCompanyV === ""
                    }
                    handleClickView={() =>
                      window.open(
                        my_company_form.replace(
                          "/:cName/:cID",
                          `/${encodeURIComponent(
                            defaultWorkCompanyV?.name
                          )}/${encodeURIComponent(
                            defaultWorkCompanyV?.companyGuid
                          )}`
                        )
                      )
                    }
                    showDisplayAdd={
                      isEmployee === Mnemonics.ResourceTypes.Employee &&
                      manageParameters === "M" &&
                      (defaultWorkCompanyV === null ||
                        defaultWorkCompanyV === undefined ||
                        defaultWorkCompanyV === "") &&
                      !fromProfile &&
                      !isEntelligence
                    }
                    handleClickAdd={() => handleOpenCompanyModal()}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <AutoComplete
                    options={departments_list}
                    getOptionLabel={(option) => (option ? option.name : "")}
                    renderOption={(props, option) => (
                      <li {...props} key={uuidv4()}>
                        {option.name}
                      </li>
                    )}
                    identifier={"departmentGuid"}
                    disabled={
                      readOnly ||
                      manageUsers === "R" ||
                      (tenantIDState === null && fromTenant && createMode) ||
                      fromProfile
                    }
                    value={departmentV}
                    onOpen={() => setFetchDepartments(true)}
                    onChange={(e, value) => {
                      setFieldValue(department.name, value);
                    }}
                    loading={departmentsLoading}
                    label={t(department.label)}
                    name={department.name}
                    formData={formData}
                    required={
                      resourceTypeV === Mnemonics.ResourceTypes.Employee
                    }
                    showDisplayAdd={
                      isEmployee === Mnemonics.ResourceTypes.Employee &&
                      manageSystemConfigurations !== "N" &&
                      (departmentV === null ||
                        departmentV === undefined ||
                        departmentV === "") &&
                      !fromProfile &&
                      !isEntelligence
                    }
                    handleClickAdd={() => handleOpenDepartmentModal()}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <AutoComplete
                    options={workType_list}
                    getOptionLabel={(option) =>
                      option ? option.description : ""
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={uuidv4()}>
                        {option.description}
                      </li>
                    )}
                    identifier={"workTypeGuid"}
                    disabled={readOnly || manageUsers === "R" || fromProfile}
                    value={workTypeV}
                    onOpen={() => setFetchWorkTypes(true)}
                    onChange={(e, value) => {
                      setFieldValue(workType.name, value);
                    }}
                    loading={workTypeLoading}
                    label={t(workType.label)}
                    name={workType.name}
                    formData={formData}
                    required={
                      resourceTypeV === Mnemonics.ResourceTypes.Employee
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sx={{ display: "flex", alignItems: "baseline" }}
                >
                  <TextField
                    fullWidth
                    value={positionV}
                    onChange={(e) => {
                      setFieldValue(position.name, e.target.value);
                    }}
                    label={t(position.label)}
                    name={position.name}
                    formData={formData}
                    required={
                      resourceTypeV === Mnemonics.ResourceTypes.Employee
                    }
                    disabled={readOnly || fromProfile}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <AutoComplete
                    options={manager_list}
                    getOptionLabel={(option) =>
                      option ? option.firstName + " " + option.lastName : ""
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={uuidv4()}>
                        {option.firstName + " " + option.lastName}
                      </li>
                    )}
                    identifier={"employeeGuid"}
                    value={directManagerV}
                    disabled={
                      readOnly ||
                      manageUsers === "R" ||
                      (tenantIDState === null && fromTenant && createMode) ||
                      fromProfile
                    }
                    onOpen={() => setFetchManagers(true)}
                    onChange={(e, value) => {
                      setFieldValue(directManager.name, value);
                    }}
                    loading={managerLoading}
                    label={t(directManager.label)}
                    name={directManager.name}
                    formData={formData}
                    showDisplayView={
                      isEmployee === Mnemonics.ResourceTypes.Employee &&
                      manageUsers !== "N" &&
                      directManagerV &&
                      !fromProfile &&
                      !isEntelligence
                    }
                    disabledViewIcon={
                      directManagerV === null ||
                      directManagerV === undefined ||
                      directManagerV === ""
                    }
                    handleClickView={() =>
                      window.open(
                        my_employee_form.replace(
                          "/:eName/:eID",
                          `/${encodeURIComponent(
                            directManagerV?.firstName +
                              " " +
                              directManagerV?.lastName
                          )}/${encodeURIComponent(
                            directManagerV?.employeeGuid
                          )}`
                        )
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <AutoComplete
                    options={employmentStatusList}
                    getOptionLabel={(option) =>
                      option ? option.description : ""
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={uuidv4()}>
                        {option.description}
                      </li>
                    )}
                    identifier={"employementStatusGuid"}
                    value={employeeStatusesV}
                    disabled={readOnly || manageUsers === "R" || fromProfile}
                    onOpen={() => setFetchEmploymentStatuses(true)}
                    onChange={(e, value) => {
                      setFieldValue(employeeStatuses.name, value);
                    }}
                    loading={employmentStatusLoading}
                    label={t(employeeStatuses.label)}
                    name={employeeStatuses.name}
                    formData={formData}
                    required={
                      resourceTypeV === Mnemonics.ResourceTypes.Employee
                    }
                  />
                </Grid>
                {employeeStatusesV &&
                  employeeStatusesV.mnemonic ===
                    Mnemonics.EmploymentStatuses.Probation && (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <TextField
                        fullWidth
                        value={probationV}
                        onChange={(e) => {
                          setFieldValue(probation.name, e.target.value);
                        }}
                        label={t(probation.label)}
                        name={probation.name}
                        formData={formData}
                        required
                        variant="standard"
                        disabled={
                          readOnly || manageUsers === "R" || fromProfile
                        }
                        type="number"
                        InputProps={{ inputProps: { min: 0, step: 1 } }}
                        onKeyPress={(e) => {
                          if (
                            e.nativeEvent.key === "-" ||
                            e.nativeEvent.key === "e"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                  )}

                {employeeStatusesV &&
                  employeeStatusesV.mnemonic ===
                    Mnemonics.EmploymentStatuses.Resigned && (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          format={
                            day_month_year.includes(userTimezone) === true
                              ? "dd-MM-yyyy"
                              : "MM-dd-yyyy"
                          }
                          label={t(resignationDate.label)}
                          value={resignationDateV}
                          onChange={(newValue) => {
                            setFieldValue(resignationDate.name, newValue);
                          }}
                          slotProps={{
                            desktopPaper: {
                              sx: {
                                boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                              },
                            },
                            textField: {
                              size: "medium",
                              variant: "standard",
                              required: true,
                              error:
                                touched[resignationDate.name] &&
                                errors[resignationDate.name],
                              helperText:
                                touched[resignationDate.name] &&
                                errors[resignationDate.name] &&
                                t(errors[resignationDate.name]),

                              onBlur: (e) =>
                                setTouched({
                                  ...touched,
                                  [resignationDate.name]: true,
                                }),
                            },
                          }}
                          sx={{ width: "100%", fontFamily: "poppins" }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  )}

                {employeeStatusesV &&
                  employeeStatusesV.mnemonic ===
                    Mnemonics.EmployeeStatuses.inactive && (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          format={
                            day_month_year.includes(userTimezone) === true
                              ? "dd-MM-yyyy"
                              : "MM-dd-yyyy"
                          }
                          label={t(inactiveDate.label)}
                          value={inactiveDateV}
                          disabled
                          slotProps={{
                            desktopPaper: {
                              sx: {
                                boxShadow: `${colors.box_shadow_color} 1.95px 1.95px 2.6px !important`,
                              },
                            },
                            textField: {
                              size: "medium",
                              variant: "standard",
                              disabled: true,
                            },
                          }}
                          sx={{ width: "100%", fontFamily: "poppins" }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  )}
              </>
            )}
            <Grid container spacing={2} ml={0.1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={4}>
                <Subtitle>{t("employeeForm.workInformation")}</Subtitle>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <AutoComplete
                  options={workshiftList}
                  getOptionLabel={(option) =>
                    option ? option.description : ""
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.description}
                    </li>
                  )}
                  identifier={"workShiftGuid"}
                  disabled={
                    readOnly ||
                    manageUsers === "R" ||
                    (tenantIDState === null && fromTenant && createMode) ||
                    fromProfile ||
                    resourceTypeV === Mnemonics.ResourceTypes.External
                  }
                  value={workShiftV}
                  onOpen={() => setFetchWorkShifts(true)}
                  onChange={(e, value) => {
                    setFieldValue(workShift.name, value);
                  }}
                  loading={workShiftLoading}
                  label={t(workShift.label)}
                  name={workShift.name}
                  formData={formData}
                  required
                  showDisplayView={
                    isEmployee === Mnemonics.ResourceTypes.Employee &&
                    manageSystemConfigurations !== "N" &&
                    workShiftV &&
                    !isEntelligence
                  }
                  disabledViewIcon={
                    workShiftV === null ||
                    workShiftV === undefined ||
                    workShiftV === ""
                  }
                  handleClickView={() =>
                    !fromProfile ? window.open(
                      my_workshifts_form.replace(
                        "/:wsName/:wsID",
                        `/${encodeURIComponent(
                          workShiftV?.description
                        )}/${encodeURIComponent(workShiftV?.workShiftGuid)}`
                      )
                    ) : handleOpenWorkShiftModal()
                  }
                  showDisplayAdd={
                    isEmployee === Mnemonics.ResourceTypes.Employee &&
                    manageSystemConfigurations === "M" &&
                    (workShiftV === null ||
                      workShiftV === undefined ||
                      workShiftV === "") &&
                    !fromProfile &&
                    !isEntelligence
                  }
                  handleClickAdd={() => handleOpenWorkShiftModal()}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <AutoComplete
                  options={holidays_list}
                  getOptionLabel={(option) =>
                    option ? option.description : ""
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={uuidv4()}>
                      {option.description}
                    </li>
                  )}
                  identifier={"officialHolidayGuid"}
                  value={officialHolidayV}
                  disabled={
                    readOnly ||
                    manageUsers === "R" ||
                    (tenantIDState === null && fromTenant && createMode) ||
                    fromProfile
                  }
                  onOpen={() => setFetchOfficialHolidays(true)}
                  onChange={(e, value) => {
                    setFieldValue(officialHoliday.name, value);
                  }}
                  loading={holidayLoading}
                  label={t(officialHoliday.label)}
                  name={officialHoliday.name}
                  formData={formData}
                  required
                  showDisplayView={
                    isEmployee === Mnemonics.ResourceTypes.Employee &&
                    manageSystemConfigurations !== "N" &&
                    officialHolidayV &&
                    !fromProfile &&
                    !isEntelligence
                  }
                  disabledViewIcon={
                    officialHolidayV === null ||
                    officialHolidayV === undefined ||
                    officialHolidayV === ""
                  }
                  handleClickView={() =>
                    window.open(
                      my_official_holidays_form.replace(
                        "/:ohName/:ohID",
                        `/${encodeURIComponent(
                          officialHolidayV?.description
                        )}/${encodeURIComponent(
                          officialHolidayV?.officialHolidayGuid
                        )}`
                      )
                    )
                  }
                  showDisplayAdd={
                    isEmployee === Mnemonics.ResourceTypes.Employee &&
                    manageSystemConfigurations === "M" &&
                    (officialHolidayV === null ||
                      officialHolidayV === undefined ||
                      officialHolidayV === "") &&
                    !fromProfile &&
                    !isEntelligence
                  }
                  handleClickAdd={() => handleOpenOfficialHolidayModal()}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                mt={3}
                className={"d-flex align-items-end"}
              >
                <Field
                  as={FormControlLabel}
                  type={isManager.type}
                  name={isManager.name}
                  inputProps={{}}
                  control={
                    <Switch
                      size="small"
                      InputProps={{}}
                      checked={isManagerV}
                      disabled={readOnly || manageUsers === "R" || fromProfile}
                      onBlur={(e) =>
                        setTouched({ ...touched, [isManager.name]: true })
                      }
                      onChange={(e) => {
                        setFieldValue(isManager.name, e.target.checked);
                      }}
                      error={touched[isManager.name] && errors[isManager.name]}
                    />
                  }
                  label={t(isManager.label)}
                />
              </Grid>
            </Grid>

            {createMode &&
              resourceTypeV === Mnemonics.ResourceTypes.Employee &&
              isAdmin && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <Subtitle>{t("employeeForm.employeePTOs")}</Subtitle>
                    {!readOnly && (
                      <Tooltip title={t("employeeForm.addPTO")}>
                        <IconButton onClick={() => setDisplayTable(true)}>
                          <AddIconOutlined color="#000000" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <EmployeePTOTable data={PTOs} setData={setPTOs} />
                  </Grid>
                </>
              )}
          </Grid>
        }
      </Grid>
    </Box>
  );
};

export default Main;
