import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

//components
import { Box, CircularProgress, Grid } from "@mui/material";
import { TreeView } from "../../../../components/TreeView";
import { ExpandLeftIcon } from "../../../../components/Icon";
import Tooltip from "../../../../components/Tooltip";
import TaskForm from "../../Tasks/Tasks-form";

//hooks
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";

//gql
import { LOGOUT_USER } from "../../../../graphql/user";
import { GET_ALL_TASK_STATUSES } from "../../../../graphql/taskStatus";

import colors from "../../../../assets/theme/light/colors";

import ConfirmationPopup from "../../../../components/Popup";
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import { parseJwt } from "../../../../utils/parseJwt";

//urls
import urls from "../../../../urls";
import apiCall from "../../../../hooks/useRest";
import { saveToken } from "../../../../app/reducers/tokenSlice";
import { useDispatch } from "react-redux";

const backendURL = process.env.REACT_APP_REST_URL;

function ProjectTree(props) {
  
  const navigate = useNavigate();
  const { pID, tskID } = useParams();
  const {
    jwtToken,
    logout,
    storeUserTenant,
    storeUserGuid,
    storeUserTeams,
    projectPermissions,
    userRoles,
  } = useAuth();

  const dispatch = useDispatch();

  const { project_backlog_tasks, project_backlog_new_subtask } = urls;

  const { isAdmin, manageTimeEntry } = userRoles;
  const [treeData, setTreeData] = useState(null);
  const [fetched, setFetched] = useState(true);
  const [loading, setLoading] = useState(props?.loading);
  const [openTimeEntry, setOpenTimeEntry] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  // const [active, setActive = useState(null);
  const projectGuid = useRef(null);
  const [tempTask, setTempTask] = useState(null);
  const [parent, setParent] = useState(
    props?.parent !== null && props?.parent !== undefined ? props?.parent : null
  );
  const [ticket, setTicket] = useState(null);
  const subtaskInURL = window.location.href.includes("subtask") ? "subtask" : "task";

  const projectID = pID
    ? pID
    : props?.projectID !== null && props?.projectID !== undefined
      ? props?.projectID
      : null;
  const [type, setType] = useState(
    props?.type !== null && props?.type !== undefined ? props?.type : subtaskInURL
  );
  const [display, setDisplay] = useState(
    props?.fromTree === true ? "maximized" : projectID ? "expanded" : "none"
  );
  const [disableSelectTask, setDisableSelectTask] = useState(false);
  const [reset, setReset] = useState(false);
  const [fetchTree, setFetchTree] = useState(false);
  const [functionNo, setFunctionNo] = useState(0);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const getPermission = (task) => {
    // if (task?.parentTaskGuid) return true;
    // else {
    if (isAdmin) return true;
    else if (
      task?.assignedToGuid === storeUserGuid ||
      storeUserTeams.some((x) => x.team?.teamGuid === task?.assignedToGuid) ||
      projectPermissions?.some((x) => x.projectGuid === treeData?.project?.guid)
    )
      return true;
    else return false;
  };

  const { mfunction: logsOutUser } = useMutationAuth(LOGOUT_USER, "LogoutUser");

  const {
    data: statusesList,
    loading: statusesLoading,
    tokenChange: statusWait,
  } = useQueryAuth(GET_ALL_TASK_STATUSES, "GetTaskStatuses", {
    variables: {
      model: {
        tenantID: storeUserTenant,
        projectID: projectID,
      },
    },
    skip: !props?.showModal,
    isWait: props?.showModal,
    // skip: props.fromList === true ? props.taskStatusList !== undefined && props.taskStatusList !== null : (projectID === null || projectID === undefined),
    // isWait: props.fromList,
  });

  const ticket_status_list =
    statusesList &&
      statusesList.GetTaskStatuses &&
      statusesList.GetTaskStatuses.tasksStatus
      ? statusesList.GetTaskStatuses.tasksStatus
      : [];


  const updateToken = (updatedToken) => {
    if(updatedToken) dispatch(saveToken(updatedToken))
  }  

  async function getTreeData(id, token = null) {
    // const parsedToken = parseJwt(jwtToken);
    const response = await apiCall(
      backendURL + `/Task/GetTasksTree/${id}`,
      "GET",
      null,
      logsOutUser,
      logout,
      updateToken,
      enqueueSnackbar,
      t
    );

    if (response) {
      setTreeData(response?.data);
      setFetched(false);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (
      props?.taskID !== null &&
      props?.taskID !== undefined &&
      props?.taskID !== "" &&
      !selectedTask &&
      !disableSelectTask
    ) {
      parent
        ? setSelectedTask({
          taskGuid: props?.taskID,
          title: props?.taskName,
          parentTaskGuid: parent?.taskGuid || parent?.guid,
        })
        : setSelectedTask({ taskGuid: props?.taskID, title: props?.taskName });
    }
  }, [props?.taskID, parent]);

  // useEffect(() => {
  //   if (props?.resetFields === true) setSelectedTask(null);
  // }, [props?.resetFields]);

  useEffect(() => {
    if (props?.type !== null && props?.type !== undefined) setType(props?.type);
  }, [props?.type]);

  // useEffect(() => {
  //   if (pID && !statusWait && fetchTree === true) getTreeData(pID);
  // }, [pID, statusWait, fetchTree]);

  // useEffect(() => {
  //   if (props?.projectID && !pID && !statusWait && fetchTree === true) getTreeData(props?.projectID);
  // }, [props?.projectID, statusWait, fetchTree]);

  // useEffect(() => {
  //   if (!props?.projectID && !pID && !projectID && selectedTask && selectedTask?.ticket?.project?.projectGuid) getTreeData(selectedTask?.ticket?.project?.projectGuid);
  // }, [selectedTask])

  useEffect(() => {
    if (props?.formChanged === true) {
      if (props?.handleFormChanged !== undefined)
        props?.handleFormChanged(false);
      handleAgree();
    }
  }, [props?.formChanged]);

  const handleAddSubtask = (task) => {
    if (JSON.parse(sessionStorage.getItem("unsavedChanges")) === true) {
      if (props?.handleOpenDialog) props?.handleOpenDialog();
      setTempTask(task);
      setFunctionNo(2);
    } else if (formChanged) {
      setTempTask(task);
      setOpenDialog(true);
      setFunctionNo(2);
    } else addSubtaskFct(task);
  };

  const addSubtaskFct = (task) => {
    setParent({
      taskGuid: task?.taskGuid,
      title: task?.title,
      ticketGuid: task?.ticketGuid,
      area: task?.area,
      externalReference: task?.externalReference,
    });
    // setSelectedTask(null);
    setType("subtask");
    setDisableSelectTask(true);
    setDisplay("expanded");
    setReset(true);
    if (tskID) navigate(project_backlog_new_subtask)
  };

  const handleAddTask = () => {
    if (JSON.parse(sessionStorage.getItem("unsavedChanges")) === true) {
      if (props?.handleOpenDialog) props?.handleOpenDialog();
      setFunctionNo(2);
    } else if (formChanged) {
      setFunctionNo(1);
      setOpenDialog(true);
    } else addTaskFct();
  };

  const addTaskFct = () => {
    setParent(null);
    setReset(true);
    setSelectedTask(null);
    setType("task");
    setDisplay("expanded");
    setDisableSelectTask(true);
  };
  const handleAddTimeEntry = (task) => {
    setSelectedTask({ taskGuid: task?.guid, title: task?.title });
    setOpenTimeEntry(true);
  };

  const handleRowClick = (task) => {
    if (getPermission(task) === true) {
      if (JSON.parse(sessionStorage.getItem("unsavedChanges")) === true) {
        if (props?.handleOpenDialog) props?.handleOpenDialog();
        setTempTask(task);
      } else if (formChanged) {
        setTempTask(task);
        setOpenDialog(true);
      } else rowClickFct(task);
    }
  };

  const rowClickFct = (task) => {
    if (tskID) navigate(project_backlog_tasks.replace(
      "/:tskName/:tskID",
      `/${encodeURIComponent(
        task?.title
      )}/${encodeURIComponent(task?.guid)}`
    ));
    setOpenDialog(false);
    setSelectedTask({ taskGuid: task?.guid, title: task?.title });
    setType(task?.parentTaskGuid ? "subtask" : "task");
    if (!props?.open) sessionStorage.removeItem("unsavedChanges");;
    setDisplay("expanded");
    setDisableSelectTask(false);
    setReset(false);
    setFormChanged(false);
  };

  const handleAgree = () => {
    setFormChanged(false);
    switch (functionNo) {
      case 0:
        rowClickFct(tempTask);
        break;
      case 1:
        addTaskFct();
        break;
      case 2:
        addSubtaskFct(tempTask);
        break;
    }
    setFunctionNo(0);
    setReset(false);
    if (!props?.open) sessionStorage.removeItem("unsavedChanges");;
    setOpenDialog(false);
  };

  // useEffect(() => {
  //   if (props?.changePage === true) {
  //     handleAgree()
  //   }
  // }, [props?.changePage]);

  const handleSelectFirstTask = () => {
    const task = treeData?.thisTaskViews[0]?.task;
    setSelectedTask({ taskGuid: task?.guid, title: task?.title });
  };

  return (
    <Grid
      container
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        marginTop: "10px !important",
        width: "100%",
      }}
    >
      {props?.fromTree && (
        <ConfirmationPopup
          openDialog={openDialog}
          handleOpenDialog={(v) => setOpenDialog(v)}
          handleAgree={handleAgree}
        />
      )}
      {/* <Grid
        item
        xs={display === "none" ? 0.5 : 12}
        sm={display === "none" ? 0.5 : 12}
        md={display === "none" ? 0.5 : display === "expanded" ? 2.5 : 12}
        lg={display === "none" ? 0.5 : display === "expanded" ? 2.5 : 12}
        xl={display === "none" ? 0.5 : display === "expanded" ? 2.5 : 12}
        sx={{
          display: "flex",
          justifyContent: display === "none" ? "center" : "start",
        }}
      >
        {display !== "none" ? (
          <TreeView
            data={treeData?.thisTaskViews}
            project={treeData?.project}
            onRowClick={handleRowClick}
            handleAddTimeEntry={handleAddTimeEntry}
            handleAddTask={handleAddTask}
            handleAddSubtask={handleAddSubtask}
            selectedTask={selectedTask}
            display={display}
            setDisplay={setDisplay}
            fromTree={props?.fromTree}
            loading={loading || statusesLoading}
            parent={parent}
            fieldsName={{
              guid: "guid",
              title: "title",
              ticketGuid: "ticketGuid",
              externalReference: "externalReference",
            }}
          />
        ) : (
          <Tooltip title={t("task.tooltip.expandTree")}>
            <span
              style={{
                width: "20px",
                marginTop: "20px",
                cursor: "pointer",
                height: "fit-content",
              }}
              onClick={() => {
                if (treeData) setDisplay("expanded");
              }}
            >
              <ExpandLeftIcon color={colors.icon_button_grey} />
            </span>
          </Tooltip>
        )}
      </Grid> */}
      {
        // display !== "maximized" &&
        !statusesLoading &&
        // !ticketLoading &&
        !loading && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            // xs={display === "none" ? 12 : 9}
            // sm={display === "none" ? 12 : 9}
            // md={display === "expanded" ? 9 : 0}
            // lg={display === "expanded" ? 9 : 0}
            // xl={display === "expanded" ? 9 : 0}
            sx={!props?.showModal ? {
              height: "calc(100vh - 130px) !important",
              paddingBottom: "20px !important"

            } : {
              height: "100% !important",
            }}
          >
            <TaskForm
              fromTree={true}
              taskID={disableSelectTask === true ? null : selectedTask?.taskGuid}
              parent={parent}
              type={type}
              openTimeEntry={props?.openTimeEntry}
              handleChangeOpenTimeEntry={(v) => props?.setOpenTimeEntry(v)}
              refetchFct={(v) => getTreeData(v)}
              ticket={ticket}
              // key={props?.key}
              showModal={props?.showModal}
              fromProject={props?.fromProject}
              fromDashboard={props?.fromDashboard}
              projectID={projectID || selectedTask?.ticket?.project?.projectGuid}
              projectName={props?.projectName ||  selectedTask?.ticket?.project?.title}

              handleChangeProject={(v) => (projectGuid.current = v)}
              handleChangeTask={(v) => {
                if (props?.handleChangeTask !== undefined)
                  return props?.handleChangeTask();
                else return setSelectedTask({ taskGuid: v, title: "" });
              }}
              handleChangeTaskName={props?.handleChangeTaskName}
              handleChangeTaskObject={(v) => setSelectedTask(v)}
              taskName={props?.taskName}
              refetchData={props?.refetchData}
              handleEnableRefetch={props?.handleEnableRefetch}
              resetFields={
                props?.resetFields === true
                  ? props?.resetFields
                  : reset === true
                    ? reset
                    : false
              }
              handleReset={(v) => setReset(v)}
              handleCloseModal={props?.handleCloseModal}
              handleSetParent={(v) => setParent(v)}
              ticket_status_list={ticket_status_list || props.taskStatusList}
              // tickets_list={ticketsList}
              // ticketLoading={ticketLoading}
              selectedTaskObject={disableSelectTask === true ? null : selectedTask}
              handleFormChanged={(v) => setFormChanged(v)}
              // disabledFields={false}
              disabledFields={!getPermission(selectedTask)}
              handleAddSubtask={handleAddSubtask}
              // fetchTickets={(v) => fetchTickets(v)}
              wait={statusWait || loading}
              handleFetchTree={(v) => setFetchTree(v)}
              handleSelectFirstTask={handleSelectFirstTask}
              data={props?.data}
              updateData={props?.updateData}
              quickAdd={props?.quickAdd}
            />
          </Grid>
        )}
    </Grid>
  );
}

export default ProjectTree;
