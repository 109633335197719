import React, { useEffect, useState, useRef } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";
import SideInfo from "./components/sideInfo";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";

//graphql
import {
  GET_LOCATION_BY_ID,
  CREATE_LOCATION,
  UPDATE_LOCATION,
} from "../../../../graphql/location";

//urls
import urls from "../../../../urls";

import ContainerCard from "../../../../components/Cards/ContainerCard";

import UTC2Local from "../../../../UTC2Local";
const LocationForm = ({
  fromTenant = false,
  myTenant = false,
  fromCompany = false,
  fromProject = false,
  onSuccess = () => {},
  handleClose = null,
  handleCloseModal = () => {},
  fromModal = false,
}) => {
  const { lName, lID, tID, tName } = useParams();

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const createMode = !lName && !lID;

  const { my_locations_list, tenant_locations } = urls;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { formField } = form;

  const [sideInfoData, setSideInfoData] = useState({});

  const [ unTouchedForm, setUnTouchedForm ] = useState(true);
  
  const {
    name,
    address,
    latitude,
    longitude,
    radius,
    UnitOfMeasure,
    country,
    isOnSite,
  } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const { loading: getDataByIDLoading, tokenChange: wait } = useQueryAuth(
    GET_LOCATION_BY_ID,
    "GetLocationByID",
    {
      variables: { id: lID },
      skip: createMode,
    },
    {
      onCompleted: (response) => {
        const data = response.GetLocationByID.location || {};

        setFormData({
          [name.name]: data.name || "",
          [address.name]: data.address || "",
          [latitude.name]: data.latitude || "",
          [longitude.name]: data.longitude || "",
          [radius.name]: data.radius || "",
          [UnitOfMeasure.name]: data.unitOfMeasure || null,
          [country.name]: data.country || null,
          [isOnSite.name]: !data.isOnSite || false,
        });

        setSideInfoData({
          "locationForm.createdBy": data.creatorEmpolyee
            ? data.creatorEmpolyee.email
            : "",
          "locationForm.creationDate": UTC2Local(data.creationDate) || "",
          "locationForm.modifiedBy": data.modifiedEmployee
            ? data.modifiedEmployee.email
            : "",
          "locationForm.modificationDate":
            UTC2Local(data.modificationDate) || "",
        });
      },
    }
  );

  const { cfunction: editLocationData } = useMutationAuth(
    UPDATE_LOCATION,
    "UpdateLocation"
  );

  const { cfunction: createLocationData } = useMutationAuth(
    CREATE_LOCATION,
    "CreateLocation"
  );

  function createLocation(values, actions) {
    createLocationData(
      {
        variables: {
          model: {
            name: values[name.name],
            latitude: values[latitude.name],
            longitude: values[longitude.name],
            address: values[address.name],
            radius: values[radius.name],
            isOnSite: fromProject ? true : !values[isOnSite.name],
            unitOfMeasureID: values[UnitOfMeasure.name].unitOfMeasureGuid,
            countryID: values[country.name].countryGuid,
            tenantID: tID ? tID : storeUserTenant,
            createdBy: storeUserGuid,
          },
        },
      },
      (response) => {
        if (
          response["CreateLocation"].errorCode !== null &&
          response["CreateLocation"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateLocation"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("locationForm.createdSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");

          if (fromModal) handleCloseModal(false);
          else if (fromProject) {
            onSuccess(response);
          } else {
            navigate(
              myTenant
                ? my_locations_list
                : tenant_locations.replace(
                    "/:tName/:tID",
                    `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
                  )
            );
          }
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  function editLocation(values, actions) {
    editLocationData(
      {
        variables: {
          model: {
            locationGuid: lID,
            name: values[name.name],
            latitude: values[latitude.name],
            longitude: values[longitude.name],
            address: values[address.name],
            radius: values[radius.name],
            isOnSite: !values[isOnSite.name],
            unitOfMeasureID: values[UnitOfMeasure.name].unitOfMeasureGuid,
            modifiedBy: storeUserGuid,
          },
        },
      },
      (response) => {
        const data = response?.UpdateLocation?.location || {};
        if (
          response["UpdateLocation"].errorCode !== null &&
          response["UpdateLocation"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateLocation"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(t("locationForm.updatedSuccessfully"), {
            variant: "success",
          });
          setSideInfoData((old) => ({
            ...old,
            "locationForm.modifiedBy": data.modifiedEmployee
              ? data.modifiedEmployee.email
              : "",
            "locationForm.modificationDate":
              UTC2Local(data.modificationDate) || "",
          }));
          sessionStorage.removeItem("unsavedChanges");
        }
        actions.setSubmitting(false);
      },
      (error) => {
        actions.setSubmitting(false);
      }
    );
  }

  useEffect(() => {
    if(performance.navigation.type === performance.navigation.TYPE_NAVIGATE && unTouchedForm && !fromModal) {
      sessionStorage.clear();
    }
  }, [])

  const handleSubmit = (values, actions) => {
    if (createMode) {
      createLocation(values, actions);
    } else {
      editLocation(values, actions);
    }
  };

  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard sx={{ height: "50vh" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="center"
            alignItems="stretch"
            sx={{ height: "100%", mt: 2 }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={createMode ? 12 : 9}>
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${
                    createMode ? "creation" : "edit"
                  }`}
                  initialValues={formData}
                  validationSchema={validation[0]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                  }) =>  {
                    formikValuesRef.current = values;
                    return (
                    <Form
                      id={form.formID}
                      autoComplete="off"
                      onChange={() => {
                        if (touched) {
                          sessionStorage.setItem(
                            "unsavedChanges",
                            JSON.stringify(true)
                          );
                        setUnTouchedForm(false)}
                      }}
                    >
                      <Box p={2}>
                        <Box>
                          <Main
                            formData={{
                              values,
                              touched,
                              formField: form.formField,
                              errors,
                              setFieldValue,
                              setTouched,
                              createMode,
                              wait,
                              fromTenant,
                              myTenant,
                              fromCompany,
                              readOnly: manageSystemConfigurations === "R",
                              handleClose,
                              fromProject,
                            }}
                          />
                        </Box>
                        <Box
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <IALoadingButton
                            disabled={
                              isSubmitting || manageSystemConfigurations !== "M"
                            }
                            loading={isSubmitting}
                            type="submit"
                            label={t("companyForm.save")}
                          />
                        </Box>
                      </Box>
                    </Form>
                  )}}
                </Formik>
              </ContainerCard>
            </Grid>
            {!createMode ? (
              <Grid item xs={12} sm={12} md={12} lg={3}>
                <ContainerCard
                  sx={{ boxShadow: "0px 0px 10px rgba(24,117,214,255)" }}
                >
                  <Box p={3}>
                    <SideInfo data={Object.entries(sideInfoData)} />
                  </Box>
                </ContainerCard>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default LocationForm;
