import React, { useEffect, useRef, useState } from "react";

//routing
import { useParams, useNavigate } from "react-router-dom";
import { formatDateTimeToString } from "../../../../UTC2Local";

//schemas
import form from "./schema/form";
import validation from "./schema/validation";
import initialValues from "./schema/initialValues";

//external components
import { Box, Grid, CircularProgress } from "@mui/material";

//components
import IALoadingButton from "../../../../components/IAButtons/IALoadingButton";

//pages
import Main from "./components/main";

// formik components
import { Formik, Form } from "formik";

//translation
import { useTranslation } from "react-i18next";

//hooks
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import { useSnackbar } from "notistack";
import useAuth from "../../../../hooks/useAuth";

//urls
import urls from "../../../../urls";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import {
  GET_PROJECT_BY_ID,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  UPDATE_PROJECT_QUICK,
} from "../../../../graphql/project";
import hasEmptyTags from "../../../../utils/hasEmptyTags";
import { day_month_year } from "../../../../utils/timezones";

const ProjectForm = ({ fromFavorites = false }) => {
  const { tName, tID, pName, pID } = useParams();

  const [mediaList, setMediaList] = useState([]);
  const [unTouchedForm, setUnTouchedForm] = useState(true);
  const [favorite, setFavorite] = useState(false);
  const createMode = !pName && !pID;
  const projectID = useRef("");
  const projectName = useRef("");
  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageTimeEntry } = userRoles;

  const { tenant_project, my_projects_form, my_favorite_projects_form } = urls;

  const navigate = useNavigate();

  const { t } = useTranslation();

  const userTimezone = Intl.DateTimeFormat() .resolvedOptions() .timeZone.split("/")[0];

  const dateFormat = day_month_year.includes(userTimezone) === true ? "DD-MM-YYYY" : "MM-DD-YYYY";

  const { formField } = form;

  const {
    title,
    description,
    status,
    tenant,
    company,
    location,
    client,
    externalReference,
    synced,
    EstimatedStartDate,
    EstimatedEndDate,
    EstimatedTime,
    ActualStartDate,
    ActualEndDate,
    ActualTime,
  } = formField;

  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState(initialValues);

  const formikValuesRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Ctrl + S
      if (event.key === 's' && event.ctrlKey) {
        event.preventDefault(); // Prevent the default behavior
        formikRef.current?.handleSubmit(formikValuesRef.current); // Submit the form
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const { loading: getDataByIDLoading, refetch: reftechProject } = useQueryAuth(
    GET_PROJECT_BY_ID,
    "GetProjectById",
    {
      variables: { id: pID, userID: storeUserGuid },
      skip: createMode,
    },
    {
      onCompleted: (response) => {
        const data = response?.GetProjectById?.project || {};

        setFavorite(data?.favoriteProjects?.length > 0 ? true : false)
        setFormData({
          [title.name]: data.title || "",
          [description.name]: data.description || "",
          [status.name]: data.projectStatus || null,
          [company.name]: data.company || null,
          [location.name]: data.location || null,
          [client.name]: data.client || null,
          [externalReference.name]: data.externalReference || "",
          [tenant.name]: data.company?.tenant || null,
          [synced.name]: data?.synced || false,
          [EstimatedStartDate.name]:
            data.estimatedStartDate == null
              ? null
              : formatDateTimeToString(data.estimatedStartDate, false, false, dateFormat),
          [EstimatedEndDate.name]:
            data.estimatedEndDate == null
              ? null
              : formatDateTimeToString(data.estimatedEndDate, false, false, dateFormat),
          [ActualStartDate.name]:
            data.actualStartDate == null
              ? null
              : formatDateTimeToString(data.actualStartDate, false, false, dateFormat),
          [ActualEndDate.name]:
            data.actualEndDate == null
              ? null
              : formatDateTimeToString(data.actualEndDate, false, false, dateFormat),
          [EstimatedTime.name]:
            data?.estimatedTime !== null
              ? parseFloat(data?.estimatedTime).toFixed(2)
              : null,
          [ActualTime.name]:
            data?.actualTime !== null
              ? parseFloat(data?.actualTime).toFixed(2)
              : null,
        });
      },
    }
  );
  
  const { cfunction: updateProjectQuickData } = useMutationAuth(UPDATE_PROJECT_QUICK, "UpdateProjectQuick");

  const updateProjectQuick = (status) => {
    updateProjectQuickData(
      {
        variables: {
          model: {
            projectGuid: pID,
            statusGuid: status,
          }
        }
      },
      (response) => {
        if (
          response["UpdateProjectQuick"].errorCode !== null &&
          response["UpdateProjectQuick"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["UpdateProjectQuick"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000,
          });
          if(reftechProject) reftechProject();
        } else {
          enqueueSnackbar(t("projectForm.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
        }
      },
      (error) => {
        if(reftechProject) reftechProject();
      }
    )
  }

  const { cfunction: editProjectData } = useMutationAuth(
    UPDATE_PROJECT,
    "EditProject"
  );

  const { cfunction: createProjectData } = useMutationAuth(
    CREATE_PROJECT,
    "CreateProject"
  );



  function createProject(values, actions) {

    var htmlString = values[description.name];
    let updatedDescription = htmlString.replace(/<p>/g, '<p style="margin:0px">');
    if(hasEmptyTags(updatedDescription)) updatedDescription = "";

    createProjectData(
      {
        variables: {
          model: {
            userID: storeUserGuid,
            title: values[title.name],
            description: updatedDescription,
            locationGuid: values[location.name]?.locationGuid,
            projectStatusGuid: values[status.name].projectStatusGuid,
            companyGuid: values[company.name].companyGuid,
            clientGuid: values[client.name]?.clientGuid || null,
            externalReference: values[externalReference.name],
            tenantGuid: storeUserTenant,
            media: mediaList
          },
        },
      },
      (response) => {
        if (
          response["CreateProject"].errorCode !== null &&
          response["CreateProject"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["CreateProject"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
        } else {
          enqueueSnackbar(t("projectForm.createdSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
          projectID.current = response?.CreateProject?.project?.projectGuid;
          projectName.current = response?.CreateProject?.project?.title;
          navigate(
            // fromTenant
            //   ? tenant_project.replace(
            //     "/:tName/:tID",
            //     `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`
            //   )
            //   : (

            fromFavorites === true
              ? my_favorite_projects_form.replace(
                "/:pName/:pID",
                `/${encodeURIComponent(projectName.current)}/${encodeURIComponent(projectID.current)}`
              )
              : my_projects_form.replace(
                "/:pName/:pID",
                `/${encodeURIComponent(projectName.current)}/${encodeURIComponent(projectID.current)}`
              )

          );

        }
        actions?.setSubmitting(false);
      },
      (error) => {
        actions?.setSubmitting(false);
      }
    );
  }

  function editProject(values, actions) {

    var htmlString = values[description.name];
    let updatedDescription = htmlString.replace(/<p>/g, '<p style="margin:0px">');
    if(hasEmptyTags(updatedDescription)) updatedDescription = "";

    editProjectData(
      {
        variables: {
          model: {
            projectGuid: pID,
            userID: storeUserGuid,
            title: values[title.name],
            description: updatedDescription,
            locationGuid: values[location.name]?.locationGuid,
            projectStatusGuid: values[status.name].projectStatusGuid,
            companyGuid: values[company.name].companyGuid,
            clientGuid: values[client.name]?.clientGuid || null,
            externalReference: values[externalReference.name],
          },
        },
      },
      (response) => {
        if (
          response["EditProject"].errorCode !== null &&
          response["EditProject"].errorCode !== ""
        ) {
          enqueueSnackbar(t(response["EditProject"].errorMessage), {
            variant: "error",
            autoHideDuration: 5000
          });
        } else {
          enqueueSnackbar(t("projectForm.updatedSuccessfully"), {
            variant: "success",
          });
          sessionStorage.removeItem("unsavedChanges");
          navigate(
            fromFavorites === true
              ? my_favorite_projects_form.replace(
                "/:pName/:pID",
                `/${encodeURIComponent(response["EditProject"].project?.title)}/${encodeURIComponent(response["EditProject"].project?.projectGuid)}`
              )
              : my_projects_form.replace(
                "/:pName/:pID",
                `/${encodeURIComponent(response["EditProject"].project?.title)}/${encodeURIComponent(response["EditProject"].project?.projectGuid)}`
              )
          );

        }
        actions?.setSubmitting(false);
      },
      (error) => {
        actions?.setSubmitting(false);
      }
    );
  }

  const handleSubmit = (values, actions) => {
    if (createMode) {
      createProject(values, actions);
    } else {
      editProject(values, actions);
    }
  };

  const handleChangeFlag = (touched) => {
    if (touched) {
      sessionStorage.setItem("unsavedChanges", JSON.stringify(true));
      setUnTouchedForm(false)
    }
  };

  useEffect(() => {
    if (performance.navigation.type === performance.navigation.TYPE_NAVIGATE && unTouchedForm) {
      sessionStorage.clear();
    }
  }, [])

  

  return (
    <>
      {getDataByIDLoading ? (
        <ContainerCard sx={{ height: "50vh" }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        </ContainerCard>
      ) : (
        <Box mb={5}>
          <Grid
            container
            justifyContent="left"
            alignItems="stretch"
            sx={{ height: "100%", mt: 2 }}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ContainerCard>
                <Formik
                  innerRef={formikRef}
                  key={`${form.formID}-form-${createMode ? "creation" : "edit"
                    }`}
                  initialValues={formData}
                  validationSchema={validation[0]}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setTouched,
                  }) =>  {
                    formikValuesRef.current = values;
                    return (
                    <Form
                      id={form.formID}
                      autoComplete="off"
                      onChange={() => {
                        handleChangeFlag(touched);
                      }}
                    >
                      <Box p={2}>
                        <Box>
                          <Main
                            formData={{
                              values,
                              touched,
                              formField: form.formField,
                              errors,
                              setFieldValue,
                              setTouched,
                              createMode,
                              readOnly: manageTimeEntry === "R",

                            }}
                            favorite={favorite}
                            setFavorite={setFavorite}
                            tenantID={storeUserTenant}
                            projectGuid={pID}
                            handleChangeFlag={handleChangeFlag}
                            setMediaList={setMediaList}
                            mediaList={mediaList}
                            updateProjectQuick={updateProjectQuick}
                            handleSubmit={handleSubmit}
                          />
                        </Box>
                        <Box
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <IALoadingButton
                            disabled={isSubmitting || manageTimeEntry !== "M"}
                            loading={isSubmitting}
                            type="submit"
                            label={t("projectForm.save")}
                          />
                        </Box>
                      </Box>
                    </Form>
                  )}}
                </Formik>
              </ContainerCard>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ProjectForm;
