import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";

//graphql
import {
  DELETE_LOCATIONS,
  GET_ALL_LOCATIONS,
  GET_ALL_COUNTRIES,
} from "../../../../graphql/location";
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";

const LocationsList = ({ fromTenant = false, myTenant = false }) => {
  const { tID, tName } = useParams();

  const {
    new_tenant_location,
    tenant_locations_form,
    my_locations_form,
    my_new_location,
  } = urls;

  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageSystemConfigurations } = userRoles;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [fetchCountries, setFetchCountries] = useState(false);
  const [countriesList, setCountriesList] = useState([]);

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_LOCATIONS,
    "DeleteLocations"
  );

  const {
    data: countries,
    loading: countriesLoading,
    tokenChange: countriesWait,
  } = useQueryAuth(GET_ALL_COUNTRIES, "GetAllCountries", {skip: !fetchCountries, isWait: fetchCountries});

  useEffect(() => {
    if(countries) setCountriesList(countries?.GetAllCountries?.countries || [])
  }, [countries])

  const SITE_OPTIONS = [
    {
      label: "location.list.filters.isOnSite",
      value: true,
    },
    { label: "location.list.filters.isNotOnSite", value: false },
  ];

  const filtersObject = [
    {
      type: "text",
      label: "locaiton.list.filters.name",
      name: "name",
    },
    {
      type: "text",
      label: "location.list.filters.address",
      name: "address",
    },
    {
      label: "location.list.filters.countries",
      name: "countries",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.name : ""),
      options: countriesList,
      access: "countryGuid",
      loading: countriesLoading,
      fetchData: setFetchCountries,
    },
    {
      label: "location.list.filters.onSite",
      name: "isOnSite",
      type: "multiple",
      getOptionLabel: (option) => (option ? t(option.label) : ""),
      options: SITE_OPTIONS,
      access: "value",
    },
  ];

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables["Tenant_Locations"],
          userID: storeUserGuid,
        },
      },
      skip: countriesWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        const col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) setColumns(parsed.columns);

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: getAllLocations, loading } = useLazyQueryAuth(
    GET_ALL_LOCATIONS,
    "GetAllLocations"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllLocations(
        {
          variables: {
            model: {
              enableFilters: true,
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyWord: search,

              tenantID: fromTenant
                ? tID
                : myTenant
                ? storeUserTenant
                : undefined,
              mnemonic: Mnemonic.Tables["Tenant_Locations"],
              userID: storeUserGuid,
              filters: filters,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData, re]);

  const rows =
    data &&
    data.GetAllLocations &&
    data.GetAllLocations.locationsList &&
    data.GetAllLocations.locationsList.locations
      ? data.GetAllLocations.locationsList.locations
      : [];
      
  const numberOfRecords =
    data &&
    data.GetAllLocations &&
    data.GetAllLocations.locationsList &&
    data.GetAllLocations.locationsList.totalNumberOfRecords
      ? data.GetAllLocations.locationsList.totalNumberOfRecords
      : 0;

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            locationIDs: selected,
          },
        },
      },
      () => {
        enqueueSnackbar(t("location.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  };

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText:
      fromTenant || myTenant
        ? "location.list.search.label.fromTenant"
        : "location.list.search.label",
    refetch,
    tableMnemonic:
      Mnemonic.Tables[fromTenant || myTenant ? "Tenant_Locations" : ""],
    filtersObject,
    generateRoute: (row) =>
      fromTenant
        ? tenant_locations_form
            .replace("/:tName/:tID", `/${tName}/${tID}`)
            .replace(
              "/:lName/:lID",
              `/${encodeURIComponent(row?.name)}/${encodeURIComponent(
                row?.locationGuid
              )}`
            )
        : myTenant
        ? my_locations_form.replace(
            "/:lName/:lID",
            `/${encodeURIComponent(row?.name)}/${encodeURIComponent(
              row?.locationGuid
            )}`
          )
        : "",
    newText: "location.list.new.label",
    newLink: fromTenant
      ? new_tenant_location.replace("/:tName/:tID", `/${encodeURIComponent(tName)}/${encodeURIComponent(tID)}`)
      : myTenant
      ? my_new_location
      : "",
    deleteText: "location.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    readOnly: manageSystemConfigurations !== "M",
  };

  return (
    <div>
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable {...pagination} identifier={"locationGuid"} hasCheckbox />
      )}
    </div>
  );
};

export default LocationsList;
