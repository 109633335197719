import React, { useEffect, useRef, useState } from "react";
import { Drawer } from "antd";
import ContainerCard from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TaskTabs from "../../pages/admin-configuration/Tasks/Tasks-tabs";
import TaskForm from "../../pages/admin-configuration/Tasks/Tasks-form";
import ConfirmationPopup from "../Popup";

import { styled } from "@mui/material/styles";
import { Box, IconButton, Typography } from "@mui/material";
import { CloseIcon } from "../Icon";
import ProjectTree from "../../pages/admin-configuration/Projects/Project-tree";
import { v4 as uuidv4 } from "uuid";
import { GET_ALL_TASK_STATUSES } from "../../graphql/taskStatus";
import useQueryAuth from "../../hooks/useQueryAuth";
import useAuth from "../../hooks/useAuth";

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    transform: translateX(0px) !important;
  }
`;

const TaskTabsModal = ({
  showModal,
  setShowModal,
  loading,
  projectID,
  projectName,
  handleChangeTask,
  handleChangeTaskName,
  taskID,
  taskName,
  refetch,
  fromProject,
  fromDashboard,
  open = false,
  editMode = false,
  parent,
  type,
  setType,
  removeSearchParams,
  handleChangeProject,
  taskStatusList,
  data,
  updateData,
  quickAdd,
  addNewSubtask,
}) => {
  const [openDialog, setOpenDialog] = useState(open);
  const samePage = useRef(false);
  const [changePage, setChangePage] = useState(false);
  const resetFields = useRef(false);
  const edit = useRef(editMode);
  const [formChanged, setFormChanged] = useState(false);
  // const [newSubtask, setNewSubtask] = useState(addNewSubtask != null ? addNewSubtask : false);
  const newSubtask = useRef(addNewSubtask != null ? addNewSubtask : false);
  const [reset, setReset] = useState(false);
  const [selectedTask, setSelectedTask] = useState(taskID ? {taskGuid: taskID, title: taskName} : null);
  const [tempTask, setTempTask] = useState(null);
  const keyValue = useRef(uuidv4());
  const [parentObj, setParent] = useState(
    parent !== null && parent !== undefined ? parent : null
  );
  
  const { storeUserTenant, storeUserTeams, projectPermissions, userRoles, storeUserGuid } = useAuth();
  const { isAdmin } = userRoles;
  const projectGuid = useRef(null);
  const refetchEnabled = useRef(false);
  const new_guid = useRef("");

  useEffect(() => {
    edit.current = quickAdd ? quickAdd : taskID ? true : false
  }, [taskID, quickAdd, showModal])

  useEffect(() => {
    if(taskID && taskName) {
      setSelectedTask({taskGuid: taskID, title: taskName})
      new_guid.current = "";
    }
  }, [showModal, taskID, taskName])
  
  useEffect(() => {
    if(showModal) new_guid.current = uuidv4()
   }, [showModal]);
  // useEffect(() => {
  //   if(showModal && !taskID && !selectedTask) resetFields.current = true;
  // }, [showModal, taskID, selectedTask]);

  const getPermission = (task) => {
    if (isAdmin) return true;
    else if (
      task?.assignedToGuid === storeUserGuid ||
      storeUserTeams.some((x) => x.team?.teamGuid === task?.assignedToGuid) ||
      projectPermissions?.some((x) => x.projectGuid === projectID)
    )
      return true;
    else return false;
  };
  
  const handleAddSubtask = (task) => {
    if (JSON.parse(sessionStorage.getItem("unsavedChanges")) === true) {
      setTempTask(task);
      setOpenDialog(true);
    } else if (formChanged) {
      setTempTask(task);
      setOpenDialog(true);
    } else addSubtaskFct(task);
  };

  const addSubtaskFct = (task) => {
    edit.current = true;
    newSubtask.current = true;
    setSelectedTask(null);
    setParent({
      taskGuid: task?.taskGuid,
      title: task?.title,
      ticketGuid: task?.ticketGuid,
      project: {
        projectGuid: task?.ticket?.project?.projectGuid,
        title: task?.ticket?.project?.title,
      },
      area: task?.area,
      externalReference: task?.externalReference,
    });
    if(setType) setType("subtask");
    edit.current = false;
    // setDisableSelectTask(true);
    setReset(true);
    resetFields.current = false;
  };
  
  const handleAgree = () => {
    newSubtask.current = true;
    resetFields.current = true;
    sessionStorage.removeItem("unsavedChanges");
    setOpenDialog(false);
    if(tempTask) {
      addSubtaskFct(tempTask)
      setOpenDialog(false);
    }
    else if (handleChangeTask) {
      handleChangeTask(null);
      setTempTask(null);
      if(samePage.current === false) {
        setShowModal(false);
        if (refetch != undefined && refetchEnabled.current) refetch();
      } else {
        setChangePage(true);
      }
      if(removeSearchParams) removeSearchParams();
      setSelectedTask(null);
      setParent(null);
      samePage.current = false;
      setFormChanged(true);
      resetFields.current = false;
      setReset(false);
      newSubtask.current = false;
      new_guid.current = uuidv4();
      edit.current = editMode;
    }
  };

  const miniSidenav = JSON.parse(localStorage.getItem("miniSidenav"));
  
  return (
    <>
      <ConfirmationPopup
        openDialog={openDialog}
        handleOpenDialog={(v) => setOpenDialog(v)}
        handleAgree={handleAgree}
      />
      <div
        style={
          openDialog
            ? { marginLeft: miniSidenav === true ? "76px" : "256px","& .ant-drawer-body": { overflow: "hidden !important"} }
            : {".ant-drawer-body": { overflow: "hidden !important"}}
        }
      >
        <StyledDrawer
          placement={"right"}
          width={
            miniSidenav === true ? "calc(100vw - 76px)" : "calc(100vw - 256px)"
          }
          onClose={() => {
            if (JSON.parse(sessionStorage.getItem("unsavedChanges")) === true) {
              setOpenDialog(true);
            } else {
              newSubtask.current = true;
              resetFields.current = true;
              setShowModal(false);
              if (removeSearchParams) removeSearchParams();
              if (handleChangeTask) handleChangeTask(null);
              if (handleChangeProject) handleChangeProject(null);
              if (refetch != undefined && refetchEnabled.current) refetch();
              setSelectedTask(null);
              setTempTask(null);
              setParent(null);
              resetFields.current = false;
              setReset(false);
              new_guid.current = uuidv4();
              edit.current = editMode;
            }
            newSubtask.current = false;
          }}
          headerStyle={{ display: "none" }}
          bodyStyle={{ padding: 0, marginTop: "5px", overflowY: "hidden" }}
          open={showModal}
          zIndex={1400}
          closeIcon={null}
          keyboard={true}
        >
          {loading ? (
            <ContainerCard
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                width: "100%"
              }}
            >
              <CircularProgress />
            </ContainerCard>
          ) : (
            <Grid container 
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  marginTop: "10px !important",
                  width: "100%",
                }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ paddingTop: "0 !important", display: "flex" }}
              >
            <Paper
              sx={{
                padding: "20px 50px 0px 50px",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                overflowY: "auto",
                backgroundColor: "transparent",
                boxShadow: "none",
                width: "100%"
              }}
            >
                  {/* </Grid>
                  <Grid item xs={12} sm={12} md={10} lg={10} xl={10}> */}
                  <TaskForm
                    key={new_guid.current ? new_guid.current : selectedTask?.taskGuid ? JSON.stringify(selectedTask?.taskGuid + " " + projectID) : JSON.stringify(taskID + " " + projectID)}
                    formKey={new_guid.current ? new_guid.current : selectedTask?.taskGuid ? JSON.stringify(selectedTask?.taskGuid + " " + projectID) : JSON.stringify(taskID + " " + projectID)}
                    fromTree={true}
                    keyValue={keyValue.current}
                    taskID={edit.current ? selectedTask?.taskGuid : null}
                    parent={parent || parentObj}
                    type={type}
                    showModal={showModal}
                    fromProject={fromProject}
                    fromDashboard={fromDashboard}
                    projectID={projectID || selectedTask?.ticket?.project?.projectGuid}
                    projectName={projectName ||  selectedTask?.ticket?.project?.title}
                    handleChangeProject={(v) => (projectGuid.current = v)}
                    handleChangeTask={(v) => {
                      if (handleChangeTask !== undefined)
                        return handleChangeTask();
                      else return setSelectedTask({ taskGuid: v, title: "" });
                    }}
                    handleChangeTaskName={handleChangeTaskName}
                    handleChangeTaskObject={(v) => setSelectedTask(v)}
                    taskName={taskName}
                    resetFields={
                      resetFields.current === true
                        ? resetFields.current
                        : reset === true
                          ? reset
                          : false
                    }
                    handleReset={(v) => setReset(v)}
                    handleCloseModal={() => {
                      if (JSON.parse(sessionStorage.getItem("unsavedChanges")) === true) {
                        setOpenDialog(true);
                      } else {
                        if (refetch != undefined && refetchEnabled.current) refetch();
                        newSubtask.current = true;
                        resetFields.current = true;
                        setShowModal(false);
                        if (removeSearchParams) removeSearchParams();
                        if (handleChangeTask) handleChangeTask(null);
                        if (handleChangeProject) handleChangeProject(null);
                        setSelectedTask(null);
                        setTempTask(null);
                        setParent(null);
                        resetFields.current = false;
                        setReset(false);
                        new_guid.current = uuidv4();
                        edit.current = editMode;
                      }
                      newSubtask.current = false;
                    }}
                    handleSetParent={(v) => setParent(v)}
                    ticket_status_list={taskStatusList}
                    selectedTaskObject={selectedTask}
                    handleEnableRefetch={() => refetchEnabled.current = true}
                    handleFormChanged={(v) => setFormChanged(v)}
                    disabledFields={!getPermission(selectedTask)}
                    wait={loading}
                    data={data}
                    updateData={updateData}
                    quickAdd={quickAdd}
                    handleAddSubtask={handleAddSubtask}
                    newSubtask={newSubtask}
                    handleNewSubtask={(v) => newSubtask.current = v}
                    handleResetFields={(v) => resetFields.current = v}
                    handleEditMode={(v) => edit.current = v}
                    edit={edit.current}
                    setType={setType}
                  />
            </Paper>
                </Grid>
              </Grid>
          )}
        </StyledDrawer>
      </div>
    </>
  );
};

export default TaskTabsModal;
