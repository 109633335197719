import React, { useState, useEffect, useCallback } from "react";

//components
import DataTable from "../../../../components/Tables/DataTable";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useMutationAuth from "../../../../hooks/useMutationAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";
//graphql
import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";
import { GET_MIDDLEWARE_JOBS } from "../../../../graphql/middlewareJobs";
//editors
import { Parse } from "../../../../JSON.editor";
import { useNavigate } from "react-router-dom";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

//urls
import urls from "../../../../urls";

//external components
import { CircularProgress, Dialog, Grid, IconButton } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

import { GET_ALL_TENANTS } from "../../../../graphql/tenant";

import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { GET_ALL_EMPLOYEES_DROPDOWN } from "../../../../graphql/employee";
import PaperComponent from "../../../../components/Modal/PaperComponent";
import ConfirmationPopup from "../../../../components/Popup";

import { DELETE_NEWS, GET_ALL_NEWS } from "../../../../graphql/news";

const News = ({ refetchTable = false, fromTenant = false }) => {
  const { storeUserGuid, userRoles, storeUserTenant } = useAuth();
  const { isEntelligence, isAdmin } = userRoles;
  const [newsGuid, setNewsGuid] = useState("");
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [fetchTenants, setFetchTenants] = useState(false);
  const [fetchEmployees, setFetchEmployees] = useState(false);
  const { news_details, new_news } = urls;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAgree = () => {
    sessionStorage.removeItem("unsavedChanges");
    setOpenDialog(false);
    setNewsGuid(null);
  };

  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_NEWS,
    "DeleteNews"
  );

  const {
    data: tenantsData,
    loading: tenantsLoading,
    tokenChange: tenantsWait,
  } = useQueryAuth(GET_ALL_TENANTS, "GetAllTenants", {
    variables: {
      model: {
        enableFilters: false,
      },
    },
    skip: !fetchTenants,
    isWait: fetchTenants,
  });

  const tenantsList = tenantsData?.GetAllTenants?.tenantsList?.tenants;

  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.NewsList,
          userID: storeUserGuid,
        },
      },
      skip: tenantsWait,
      isWait: true,
    },
    {
      onCompleted: (response) => {
        const col =
          response &&
          response.GetUserTablePreferences &&
          response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          const cols = parsed.columns;
          setColumns(cols);

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const {
    data: employeesData,
    loading: employeeLoading,
    tokenChange: employeeWait,
  } = useQueryAuth(GET_ALL_EMPLOYEES_DROPDOWN, "GetCompanyUsersList", {
    variables: {
      model: {
        enableFilters: false,
        userID: storeUserGuid,
        tenantID: !isEntelligence ? storeUserTenant : null,
        showAll: true,
        all: true,
      },
    },
    skip: !fetchEmployees,
    isWait: fetchEmployees,
  });

  const employeesList =
    employeesData?.GetCompanyUsersList?.usersList?.employees || [];

  const filtersObject = [
    {
      label: "logs.list.filters.tenants",
      name: "tenantsIDs",
      type: "multiple",
      getOptionLabel: (option) => (option ? option.name : ""),
      options: tenantsList,
      access: "tenantGuid",
      loading: tenantsLoading,
      fetchData: setFetchTenants,
    },
    {
      label: "logs.list.filters.employee",
      name: "employeesIDs",
      type: "multiple",
      getOptionLabel: (option) =>
        option ? option.firstName + " " + option.lastName : "",
      options: employeesList,
      access: "employeeGuid",
      loading: employeeLoading,
      fetchData: setFetchEmployees,
    },
    {
      type: "date",
      label: "suggs.list.filters.creationDate",
      name: "creationDate",
    },
  ];

  const {
    mfunction: GetAllNewsList,
    loading,
    refetch: refetchData,
  } = useLazyQueryAuth(GET_ALL_NEWS, "GetAllNewsList");

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      GetAllNewsList(
        {
          variables: {
            model: {
              userID: storeUserGuid,
              tenantGuid: storeUserTenant,

              enableFilters: true,
              filters: filters,
              keyword: search,

              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );

  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [columnsWait, fetchTableData]);

  useEffect(() => {
    if (!columnsWait) refetchData();
  }, [refetchTable]);

  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            newsIDs: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("suggs.list.delete.success"), {
          variant: "success",
        });
        setSelected([]);
        setPage(0);
        fetchTableData(0, rowsPerPage, order, orderBy, search, defaultFilters);
      }
    );
  };

  const rows =
    data &&
    data.GetAllNewsList &&
    data.GetAllNewsList.newsListResponse &&
    data.GetAllNewsList.newsListResponse.newsList
      ? data.GetAllNewsList.newsListResponse.newsList
      : [];

  const numberOfRecords =
    data &&
    data.GetAllNewsList &&
    data.GetAllNewsList.newsListResponse &&
    data.GetAllNewsList.newsListResponse.totalNumberOfRecords
      ? data.GetAllNewsList.newsListResponse.totalNumberOfRecords
      : 0;

  const customButton = [
    {
      label: "news.addNew",
      handleClick: () => navigate(new_news),
      condition: !selected || selected.length === 0,
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color: "var(--color--light-logo-blue)",
            fontSize: "28px !important",
          }}
        />
      ),
    },
  ];

  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    defaultFilters,
    setDefaultFilters,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    filtersObject,
    loading: loading || columnsWait,
    searchText: "news.list.search.label",
    refetch,
    handleDelete,
    customButtons: customButton,
    deleteText: "news.list.delete.label",
    tableMnemonic: Mnemonic.Tables.NewsList,
    generateRoute: (row) =>
      isAdmin
        ? news_details.replace(
            "/:nName/:nID",
            `/${encodeURIComponent(row?.newsID)}/${encodeURIComponent(
              row?.newsGuid
            )}`
          )
        : false,
  };

  return (
    <div>
      <ConfirmationPopup
        openDialog={openDialog}
        handleOpenDialog={(v) => setOpenDialog(v)}
        handleAgree={handleAgree}
      />

      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable {...pagination} identifier={"newsGuid"} hasCheckbox />
      )}
    </div>
  );
};

export default News;
