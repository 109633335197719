import React from "react";

//components
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EmployeeForm from "../../Employee-form";
import EmployeeReferencesList from "../../../EmployeeReferences/EmployeeReferences-list";
import LoanedItemsList from "../../../LoanedItems/LoanedItems-list";
import EmployeePTOList from "../../EmployeePTOs";
import EmployeeSkillsList from "../../EmployeeSkills";
import EmployeeTeamsList from "../../Employee-teams";
import EmployeeOnboradingTasks from "../../Employee-onboarding-tasks"
import ProjectsList from "../../../Projects/Projects-list"


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 0.5, pb: 0.5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function EmployeeTabRoutes({ value, myEmployees, fromTenant }) {
  return (
    <>
      <TabPanel value={value} index={0}>
        <EmployeeForm myEmployees={myEmployees} fromTenant={fromTenant} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EmployeeReferencesList  myEmployees={myEmployees}  fromTenant={fromTenant} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EmployeePTOList myEmployees={myEmployees} fromTenant={fromTenant} />
      </TabPanel>
       {/* <TabPanel value={value} index={7}>
        <ProjectsList myEmployees={myEmployees} fromTenant={fromTenant} />
      </TabPanel>  */}
      <TabPanel value={value} index={3}>
        <EmployeeSkillsList myEmployees={myEmployees} fromTenant={fromTenant} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <EmployeeTeamsList myEmployees={myEmployees} fromTenant={fromTenant} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <LoanedItemsList myEmployees={myEmployees} fromTenant={fromTenant} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <EmployeeOnboradingTasks myEmployees={myEmployees} fromTenant={fromTenant} />
      </TabPanel>
     
    </>
  );
}
