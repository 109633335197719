import React, { useEffect, useState } from "react";
import "./styles.css";
import { Routes, useLocation } from "react-router-dom";
import Routing from "./routing";
import AllRoutes from "./routes";
import variables from "./variables";

import i18 from "i18next";
import { initReactI18next } from "react-i18next";
import EN from "./translations/english";
import FR from "./translations/french";
import useAuth from "./hooks/useAuth";
import { useSelector } from "react-redux";
import { getLanguageFromStore } from "./app/reducers/languageSlice";
import colorsLight from "./assets/theme/light/colors";
import DeviceToken from "./pages/DeviceToken";
import { useCookies } from "react-cookie";
import Activ_BG from "./assets/images/W8_Activ_BG.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import urls from "./urls";
import { getToken } from "firebase/messaging";
import { messaging } from "./firebase";

function App() {

  const { jwtToken, storeUserEmail} = useAuth();

  useEffect(() => {
    const requestPermissionAndSendMessage = async () => {
      // Request permission using Notification API
      const permission = await Notification.requestPermission();

      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey: "BJSs3zFR3SEBrBW-c1bhkvSy-3It7z_W4VNAenI8oNZOn5TbRDhq-YtcOQWe1MvzSVeHdJWxxtgCAFokVNMX9vw",
        });

        // Send JWT token to service worker
        navigator.serviceWorker.ready.then(registration => {
          registration.active.postMessage({ type: 'jwtToken', token: jwtToken });
        });

        // Store token in localStorage
        localStorage.setItem("token", token);
      }
    };

    requestPermissionAndSendMessage();
  }, [jwtToken]);

  const { login, verify_login, forgot_password, reset_password, subscription, subscription_success } = urls;

  const [cookies] = useCookies();
  const language = useSelector(getLanguageFromStore);

  const unsavedChanges = JSON.parse(sessionStorage.getItem('unsavedChanges'));
  i18.use(initReactI18next).init({
    resources: {
      en: { translation: EN },
      fr: { translation: FR },
    },
    lng: language,
    fallbackLng: "en",
    interpolation: { escapeValue: false },
  });

  const COOKIE_NAME = variables.COOKIE_NAME;
  const { grey_background } = colorsLight;
  const location = useLocation();
  const { pathname } = location;
  const currentLink = pathname ? pathname.split("?")[0] : [""];
  const login_paths = [login, verify_login, forgot_password, reset_password, subscription, subscription_success];
  const isAuth = login_paths.includes(currentLink);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if ( JSON.parse(sessionStorage.getItem('unsavedChanges')) === true) {
        const confirmationMessage = "Are you sure you want to leave?";
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    if(performance.navigation.type == performance.navigation.TYPE_RELOAD) {
      window.sessionStorage.clear();
    }

    // Attach the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);
    // window.onbeforeunload = function() {
    //   window.sessionStorage.clear();
    // };

    return () => {
      // Cleanup the event listener when the component is unmounted
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);


  const bg = isAuth
    ? {
      background: `url(${Activ_BG}) no-repeat center center/cover`,
    }
    : {
      backgroundColor: grey_background,
    };

  return (
    <>
      <ToastContainer />
      <div className="main-layout" style={bg}>
        {cookies[COOKIE_NAME + storeUserEmail.toLowerCase()] == undefined &&
          cookies[COOKIE_NAME + storeUserEmail.toLowerCase()] == null && (
            <DeviceToken />
          )}
        <Routes>{AllRoutes.map((route, index) => Routing(route))}</Routes>
      </div>
    </>
  );
}

export default App;
