import React, { useState, useEffect, useCallback } from "react";

//routing
import { useParams } from "react-router-dom";

//components
import DataTable from "../../../../components/Tables/DataTable";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { CloseIcon } from "../../../../components/Icon";

//hooks
import useLazyQueryAuth from "../../../../hooks/useLazyQueryAuth";
import useQueryAuth from "../../../../hooks/useQueryAuth";
import useAuth from "../../../../hooks/useAuth";

//external components

//graphql

import { GET_USER_TABLE_PREFERENCES } from "../../../../graphql/userTablePreferences";

import urls from "../../../../urls";

//editors
import { Parse } from "../../../../JSON.editor";

//mnemonic values
import Mnemonic from "../../../../Mnemonics.json";

import { ASSIGN_PTOS_TO_EMPLOYEES } from "../../../../graphql/pto";

//external components
import { CircularProgress, Button, Modal, IconButton } from "@mui/material";

import ContainerCard from "../../../../components/Cards/ContainerCard";
import UTC2Local from "../../../../UTC2Local";
import componentDisplay from "../../../../componentDisplay";
import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";
import useMutationAuth from "../../../../hooks/useMutationAuth";

import OnboardingTasks from "./OnboardingTasksTable";

import { getEmployeeFromStore } from "../../../../app/reducers/employeeSlice";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../../../components/Modal/ConfirmationModal";
import { styled } from "@mui/material/styles";
import colors from "../../../../assets/theme/light/colors";
import { HelpIcon } from "../../../../components/Icon";
import { ASSIGN_EMPLOYEE_ONBOARDING_TASKS, DELETE_EMPLOYEE_ONBOARDING_TASKS, GET_ALL_EMPLOYEE_ONBOARDING_TASKS } from "../../../../graphql/onBoardingTasks";
import OnBoardingTasksList from "../../OnBoardingTasks/OnBoardingTasks-list";
import EmployeeOnBoardingTaskForm from "./form"

const EmployeeOnboardingTasksList = ({
  wait = false,
  refetchData = false,
  fromProfile = false,
  fromTenant = false,
}) => {
  const { eID, eName, tID, tName, profileID, profileName } = useParams();
  const employee = useSelector(getEmployeeFromStore);



  const { storeUserGuid, storeUserTenant, userRoles } = useAuth();

  const { manageUsers, isEntelligence } = userRoles;


  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const Tasks = [];

  const [displayTable, setDisplayTable] = useState(false);

  const [re, setRe] = useState(false);
  const [data, setData] = useState(null);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [columns, setColumns] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [tasksArray, setTasksArray] = useState([]);
  const [displayTask, setDisplayTask] = useState(false);
  const [taskGuid, setTaskGuid] = useState(null);



  const { mfunction: assignEmployeeOnBoardingTasksData } = useMutationAuth(
    ASSIGN_EMPLOYEE_ONBOARDING_TASKS,
    "AssignEmployeeOnBoardingTasks"
  );
  const { mfunction: deleteRecords, loading: deleteLoading } = useMutationAuth(
    DELETE_EMPLOYEE_ONBOARDING_TASKS,
    "DeleteEmployeeOnBoardingTasks"
  );


  const {
    loading: getColumnsLoading,
    tokenChange: columnsWait,
    refetch,
  } = useQueryAuth(
    GET_USER_TABLE_PREFERENCES,
    "GetUserTablePreferences",
    {
      variables: {
        model: {
          table: Mnemonic.Tables.EmployeeOnboardingTasks,
          userID: storeUserGuid,
        },
      },
    },
    {
      onCompleted: (response) => {
        const col =
          response &&
            response.GetUserTablePreferences &&
            response.GetUserTablePreferences.columns
            ? response.GetUserTablePreferences.columns
            : null;

        if (col) {
          //we parse the json string to a json object
          const parsed = JSON.parse(col, (key, value) =>
            Parse(key, value, UTC2Local, componentDisplay)
          );

          if (parsed.columns) setColumns(parsed.columns);

          if (parsed.defaultSort) setOrderBy(parsed.defaultSort);

          if (parsed.defaultSortDirection)
            setOrder(parsed.defaultSortDirection);
        }
      },
    }
  );

  const { mfunction: getAllEmployeeOnboardingTasksData, loading, refetch: refreshTasks } = useLazyQueryAuth(
    GET_ALL_EMPLOYEE_ONBOARDING_TASKS,
    "GetAllEmployeeOnboardingTasks"
  );

  const fetchTableData = useCallback(
    (
      page = 0,
      pageSize = 0,
      order = "",
      orderBy = "",
      search = "",
      filters = null
    ) => {
      getAllEmployeeOnboardingTasksData(
        {
          variables: {
            model: {
              keys: {
                page: page + 1,
                pageSize: pageSize,
              },
              orderColumn: orderBy,
              orderDirection: order,
              keyword: search,
              // mnemonic: Mnemonic.Tables.EmployeeOnboardingTasks,
              employeeID: eID ? eID : profileID,
            },
          },
        },
        (response) => {
          setData(response);
        }
      );
    },
    []
  );


  useEffect(() => {
    if (!columnsWait)
      fetchTableData(page, rowsPerPage, order, orderBy, search, defaultFilters);
  }, [refetchData, columnsWait, fetchTableData, re]);

  const rows =
    data &&
      data.GetAllEmployeeOnboardingTasks &&
      data.GetAllEmployeeOnboardingTasks.employeeOnBoardingTasksListResponse &&
      data.GetAllEmployeeOnboardingTasks.employeeOnBoardingTasksListResponse.employeeOnBoardingTasks
      ? data.GetAllEmployeeOnboardingTasks.employeeOnBoardingTasksListResponse.employeeOnBoardingTasks
      : [];
  const numberOfRecords =
    data &&
      data.GetAllEmployeeOnboardingTasks &&
      data.GetAllEmployeeOnboardingTasks.employeeOnBoardingTasksListResponse &&
      data.GetAllEmployeeOnboardingTasks.employeeOnBoardingTasksListResponse.totalNumberOfRecords
      ? data.GetAllEmployeeOnboardingTasks.employeeOnBoardingTasksListResponse.totalNumberOfRecords
      : 0;


  const handleDelete = () => {
    deleteRecords(
      {
        variables: {
          model: {
            employeeOnboardingTasksIDs: selected,
          },
        },
      },
      (response) => {
        enqueueSnackbar(t("ptos.list.delete.success"), {
          variant: "success",
        });
        setRe((v) => !v);
        setSelected([]);
        setPage(0);
      }
    );
  };
  const customButtons = [
    {
      label: "employeePtoForm.assignonboardingtasks",
      handleClick: () => setDisplayTable(true),
      condition: (!selected || selected.length === 0) && manageUsers === "M" && employee?.resourceType === Mnemonic.ResourceTypes.Employee,
      icon: true,
      iconButton: (
        <AddCircleRoundedIcon
          sx={{
            color: "var(--color--light-logo-blue)",
            fontSize: "28px !important"
          }}
        />
      ),
    },

  ];



  const pagination = {
    rows,
    columns,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    defaultFilters,
    setDefaultFilters,
    setSelected,
    search,
    setSearch,
    setPage,
    setRowsPerPage,
    numberRecords: numberOfRecords,
    page,
    rowsPerPage,
    fetchTableData,
    loading: loading || columnsWait,
    searchText: "employeePTOs.list.search.label",
    refetch,
    tableMnemonic: Mnemonic.Tables.EmployeeOnboardingTasks,
    removeFilters: true,
    hideEditColumns: false,
    readOnly: manageUsers !== "M",
    customButtons: customButtons,
    deleteText: "ptos.list.delete.label",
    handleDelete: handleDelete,
    deleteLoading,
    generateRoute: () => { },

    handleOpenModal: (row) => handleOpenModal(row),
  };

  const handleOpenModal = (row) => {
    setTaskGuid(row?.employeeOnBoardingTaskGuid);
    setDisplayTask(true);
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        setDisplayTask(false);
        setTaskGuid(null);
        refreshTasks()
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function handleOpenDialog(array) {
    setTasksArray(array);
    setOpenDialog(true)
    handleAddTasks(array)
  }

  function handleCloseDialog() {
    setOpenDialog(false);
    refreshTasks()
  }

  // useEffect(() => {
  //   if (displayTask == false) { refreshTasks() }

  // }, [displayTask])

  function handleAddTasks(array) {
    handleCloseDialog();
    const tasksGuids = array
      ? array.map((val, index) => {
        return val;
      })
      : [];

    assignEmployeeOnBoardingTasksData(
      {
        variables: {
          model: {
            onBoardingTasksIDs: tasksGuids,
            employeeGuid: eID,
          },
        },
      },
      (data) => {
        enqueueSnackbar(t("AssignEmployeeOnBoardingTasks.success"), {
          variant: "success",
        });
        setRe((val) => !val);
        setDisplayTable(false);
      }
    );
  }



  return (
    <div>
      {/* <ConfirmationModal
      contentText={t("dialog.assign")}
      title={t("dialog.confirm")}
      icon={<HelpIcon color={colors.light_logo_blue}/>}
      openDialog={openDialog}
      handleClose={handleCloseDialog}
      customButtons={customModalButtons}
      t={t}
    /> */}
      {taskGuid &&
        <Modal
          open={displayTask}
          onClose={() => setDisplayTask(false)}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <ContainerCard
            sx={{ padding: "5px 10px 5px", width: "60%", height: "fitContent" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "1rem",
                width: "100%",
              }}
            >
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setDisplayTask(false)}
                aria-label="close"
              >
                <CloseIcon color={"#000000"} width={'20'} stroke={'1'} />
              </IconButton>
            </div>
            <EmployeeOnBoardingTaskForm taskGuid={taskGuid} fromDialog refreshTasks={refreshTasks}/>
          </ContainerCard>
        </Modal>}
      {displayTable ? (
        <OnboardingTasks
          display={displayTable}
          tenant={isEntelligence ? tID : storeUserTenant}
          initialVal={Tasks}
          eID={eID}
          handleClick={handleOpenDialog}
          setDisplay={setDisplayTable}
        />
      ) : null}
      {getColumnsLoading ? (
        <ContainerCard
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress />
        </ContainerCard>
      ) : (
        <DataTable
          {...pagination}
          identifier={"employeeOnBoardingTaskGuid"}
          hasCheckbox={eID ? true : false}
          fromEmployee
        />
      )}
    </div>
  );
};

export default EmployeeOnboardingTasksList;
